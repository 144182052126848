import { useContext } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { CardContent, Grid } from "@mui/material";
import { Context } from "../context/DataContext";
import { Navigate } from "react-router-dom";

const Index = () => {
  const { state } = useContext(Context);
  const { user } = state;
  if (user.user_id !== 0) return <Navigate to="/dashboard" />;

  return (
    <div className="container mt-4 mb-4">
      <h3>Funcionalides</h3>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Card raised>
            <CardHeader title="Historial de Pagos" />
            <CardContent>1 </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6}>
          <Card raised>
            <CardHeader title="Reportes de Ingresos" />
            <CardContent>2 </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6}>
          <Card raised>
            <CardHeader title="Empleados / Agentes" />
            <CardContent>3 </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6}>
          <Card raised>
            <CardHeader title="Demo Gratis" />
            <CardContent>4 </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6}>
          <Card raised>
            <CardHeader title="Manejo de Caja y Gastos" />
            <CardContent>5 </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6}>
          <Card raised>
            <CardHeader title="Cuenta X Cobrar" />
            <CardContent>6 </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6}>
          <Card raised>
            <CardHeader title="Disponibilidad" />
            <CardContent>Disponible en todo tipo de dispositos. Eg: Iphones, Android, Computadora</CardContent>
          </Card>
        </Grid>
        <Grid item sm={6}>
          <Card raised>
            <CardHeader title="Recibos Bluetooth" />
            <CardContent>Disponible en todo tipo de dispositos. Eg: Iphones, Android, Computadora</CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
