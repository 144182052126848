import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { Context } from "../context/DataContext";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { RemoveAccents, indentifySearchType } from "@shared/functions";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import SearchBox from "@shared/components/common/SearchBox";
import Loading from "@shared/components/Loading";
import RenderLinkedRouteCard from "../components/RenderLinkedRouteCard";
import { UseUserLinkedRoutes, UseUserLinkedRoutesData } from "@shared/server/fetcher";
// import NoInternetContent from "@shared/components/NoInternetContent";
import useDebounce from "@shared/hooks/useDebounce";
import { useNavigate } from "react-router-dom";

const ChooseRoute = () => {
  const navigate = useNavigate();
  const { state, changeRoute, getJwtToken } = useContext(Context);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const { route } = state;

  const [showFullLoading, setShowFullLoading] = useState(false);
  const localUser = getJwtToken();

  // const { data: user, isLoading: isLoadingUser, error: errorUser } = UseUser(localUser.user_id);
  const { data: linkedRoutes, isLoading: isLoadingLinked } = UseUserLinkedRoutes(localUser?.user_id);
  const { data: linkedRoutesData, isLoading: isLoadingLinkedData } = UseUserLinkedRoutesData(localUser?.user_id);

  const loader = useRef(null);
  const routeXPage = 10;

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useDebounce(
    () => {
      if (!linkedRoutes || !linkedRoutesData) return;
      if (searchText.length < 3) {
        setFilteredData(linkedRoutes.slice(0, page * routeXPage));
        setLoading(false);
      } else {
        setFilteredData(
          linkedRoutes
            .filter(
              (y) =>
                RemoveAccents(y.searchName).toLowerCase().indexOf(indentifySearchType(searchText.toLowerCase())) > -1
            )
            .slice(0, routeXPage + 10)
        );
      }
    },

    [linkedRoutes, linkedRoutesData, searchText, page],
    searchText.length < 3 ? 0 : 800
  );

  const handleRouteChange = async (data) => {
    if (parseInt(data.creditor_id) === parseInt(route.creditor_id)) {
      navigate("/dashboard");
    }

    setShowFullLoading(true);
    await changeRoute(data.creditor_id, localUser, history);
    setShowFullLoading(false);
    navigate("/insert/dianostics", { replace: true });
  };

  const navigate2 = () => {
    navigate("/route/create");
  };

  const handleSearch = (query) => {
    setSearchText(query);
  };

  const RoutesHeader = ({ linkedRoutes }) => {
    return (
      <>
        {linkedRoutes?.length > 0 ? (
          <Grid container>
            <Grid item xs={7}>
              <Typography id="transition-modal-title" variant="h5" component="h4" gutterBottom>
                Mi Rutas ({linkedRoutes?.length})
              </Typography>
            </Grid>
            <Grid item>
              <Button fullWidth color="primary" size="small" variant="outlined" onClick={navigate2}>
                Crear Ruta!
              </Button>
            </Grid>
          </Grid>
        ) : (
          <div className="container mt-5">
            <Typography id="transition-modal-title" variant="h5" component="h4" gutterBottom>
              No hay ruta creada!
            </Typography>

            <Typography id="transition-modal-title" variant="body1" component="h4" gutterBottom>
              Puede crear su primera ruta haciendo clic en <span className="text-info">CREAR NUEVA RUTA!</span>
            </Typography>

            <Box component="div" paddingTop={4}>
              <Button fullWidth color="primary" variant="outlined" onClick={navigate2}>
                Crear nueva Ruta
              </Button>
            </Box>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="container mt-4 mb-5">
      {showFullLoading && <FullLoadingScreen />}
      {loading || isLoadingLinked || isLoadingLinkedData ? (
        <Loading />
      ) : (
        <div>
          <RoutesHeader linkedRoutes={linkedRoutes} />

          {linkedRoutes?.length > 4 && (
            <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar rutas..." value={searchText} />
          )}

          {filteredData.map((route) => (
            <RenderLinkedRouteCard
              key={route.creditor_id}
              route={route}
              linkedRoutesData={linkedRoutesData}
              handleRouteChange={handleRouteChange}
            />
          ))}
        </div>
      )}

      <Box ref={loader} component="div" p={2}>
        {!loading && searchText.length < 3 && linkedRoutes?.length > page * routeXPage && (
          <Typography variant="h5" paragraph>
            Por favor espere...
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default ChooseRoute;
