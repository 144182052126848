import Typography from "@nodes/@mui/material/Typography";
// import Calculator from "./Calculator";
import Box from "@nodes/@mui/material/Box";
import List from "@nodes/@mui/material/List";
import ListItem from "@nodes/@mui/material/ListItem";
import ListItemText from "@nodes/@mui/material/ListItemText";
import Chip from "@nodes/@mui/material/Chip";
import Grid from "@nodes/@mui/material/Grid";
import { printAmount, spanishRouteTypes } from "../functions";
import { colors } from "@/config";

const RenderPaymentInfo = ({ loan }) => {
  const remaining = loan.balance % loan.cuota;
  // const incomplete = remaining != 0 ? loan.cuota - remaining : 0;
  return (
    <div>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h6" component="h4" gutterBottom>
            Aplicar Pago!
            <b className="p-2">
              <Chip label={`P${loan.oldLoanId ?? loan.money_id}`} />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {/* <Calculator /> */}
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <List disablePadding>
              <ListItem disablePadding sx={{ paddingLeft: 2 }}>
                <ListItemText
                  disableTypography
                  primary={<Typography variant="body1">Balance: {printAmount(loan.balance + loan.balMora)}</Typography>}
                  // secondary={
                  //   +loan.balMora > 0 ? (
                  //     <Typography sx={{ color: colors.moraAmount, fontSize: 15 }} variant="body2">
                  //       <b>Esta cantidad incluye {printAmount(loan.balMora)} de mora!</b>
                  //     </Typography>
                  //   ) : null
                  // }
                />
              </ListItem>

              <ListItem disablePadding sx={{ paddingTop: 1, paddingLeft: 2 }}>
                <ListItemText
                  primary={`Cuotas Paga: ${loan.completed + " / " + loan.npayments}`}
                  secondary={
                    remaining > 0 ? (
                      <span className="text-info">
                        <b>
                          Completivo para cuota {loan.completed + 1}.: {printAmount(remaining)}
                        </b>
                      </span>
                    ) : null
                  }
                />
              </ListItem>

              <ListItem disablePadding sx={{ paddingTop: 1, paddingLeft: 2 }}>
                <ListItemText primary={`Cuota: ${printAmount(loan.cuota)} (${spanishRouteTypes(loan.type)})`} />
              </ListItem>

              <ListItem divider disablePadding sx={{ paddingTop: 1, paddingLeft: 2 }}>
                <ListItemText
                  primary={
                    <b>
                      Monto por cobrar: {loan.xCobrarTotal > 0 ? printAmount(loan.xCobrarTotal + loan.balMora) : "$0"}
                    </b>
                  }
                  secondary={
                    <>
                      {+loan.statusAmount > 0 ? (
                        <span style={{ color: colors.AtrasosAmount, fontSize: 15 }}>
                          Esta cantidad incluye {printAmount(loan.statusAmount)} de atrasos!
                        </span>
                      ) : null}

                      {+loan.balMora > 0 ? (
                        <Typography sx={{ color: colors.moraAmount, fontSize: 15 }} variant="body2">
                          <b>Esta cantidad incluye {printAmount(loan.balMora)} de mora!</b>
                        </Typography>
                      ) : null}
                    </>
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default RenderPaymentInfo;
