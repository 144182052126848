// import FormGroup from "@nodes/@mui/material/FormGroup";
// import FormControlLabel from "@nodes/@mui/material/FormControlLabel";
// import Checkbox from "@nodes/@mui/material/Checkbox";
// import Grid from "@nodes/@mui/material/Grid";
import Box from "@nodes/@mui/material/Box";
import Typography from "@nodes/@mui/material/Typography";
import Card from "@nodes/@mui/material/Card";
import { printAmount } from "@shared/functions";
// import CardContent from "@nodes/@mui/material/CardContent";
// import {NumericFormat } from "@nodes/react-number-format";
// import { printAmount, printDate, newDate } from "../functions";

// const concatPayoffLabel = (loan) => {
//   // const renewalLoan = (
//   //   <Card>
//   //     <CardContent>
//   //       <Typography variant="h6">
//   //         {printAmount(loan.amount)} | {printDate(newDate(loan.given_date), "dd-MMM-yy")}
//   //       </Typography>
//   //       <Typography variant="h6">Balance {printAmount(loan.balance)}</Typography>
//   //     </CardContent>
//   //   </Card>
//   // );
//   // return renewalLoan;

//   return (
//     <Typography variant="body2">
//       Saldar {printAmount(loan.amount)} | {printDate(newDate(loan.given_date), "dd-MMM-yy")} Balance{" "}
//       {printAmount(loan.balance)}!
//     </Typography>
//   );
// };

//eslint-disable-next-line
const RenderOldBalancePayOff = ({ balance, mora }) => {
  const balance2 = balance + +mora;
  if (balance2 === 0) return null;

  return (
    <div>
      <Card variant="outlined">
        <Box m={1}>
          {/* <Typography variant="subtitle1" component="p">
            Cliente tiene prestamo(s) con balance!
          </Typography> */}
          <Typography variant="subtitle1" component="p" className="text-warning font-weight-bold">
            Descontar Balance actual {printAmount(balance2)}
          </Typography>
          {mora > 0 && <p className="text-danger">Este balance incluye {printAmount(mora)}</p>}
        </Box>
      </Card>

      {/* <Grid container>
        {oldBalance.loansBalance.map((loan) => (
          <Grid key={loan.money_id} item xs={12}>
            <FormGroup row>
              <Box paddingBottom={0}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={loan.isChecked}
                      onChange={() => handlePayoffBalanceToggle(loan.money_id, loan.isChecked)}
                      name={loan.money_id}
                      value={loan.balance}
                    />
                  }
                  label={concatPayoffLabel(loan)}
                />
              </Box>
            </FormGroup>
          </Grid>
        ))}
      </Grid> */}
    </div>
  );
};

export default RenderOldBalancePayOff;
