
import Typography from "@nodes/@mui/material/Typography";
import { printAmount } from "../functions";

const RenderReferralBalance = ({ referrals = [], balance = 0 }) => {
  return (
    <div>
      {referrals?.length > 0 ? (
        <div>
          <Typography component="h3" variant="h5" gutterBottom>
            Balance {printAmount(balance)}
          </Typography>
          <Typography variant="body1" component="p" paragraph>
            Este balance incluye el saldo total sin pagar por cada prestamista que usted a referido.
          </Typography>
        </div>
      ) : (
        <div>
          <Typography variant="h5" component="h3" gutterBottom>
            Invite prestamistas a usar mi Sistema!
          </Typography>
          <Typography variant="body1" component="p" paragraph>
            Gane dinero por cada prestamista que invite a usar mi Sistema.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default RenderReferralBalance;
