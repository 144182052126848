import { useContext, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import { MdModeEditOutline } from "react-icons/md";
import Divider from "@mui/material/Divider";
import { Context } from "../context/DataContext";
import { toast } from "react-toastify";
// import { printDate } from "@shared/functions";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderNewLoanCalculation from "@shared/components/RenderNewLoanCalculation";
import RenderLoanModifyForm from "@shared/components/RenderLoanModifyForm";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import { UseLoanv2, UseRoute } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";
import RenderBackButton from "@shared/components/RenderBackButton";

const calcMonto = (percentage = 0, amount = 0, npayments = 0) => {
  const amount2 = amount * 1;
  const npayments2 = npayments * 1;
  const percentage2 = percentage * 1;
  if (npayments2 === 0) return 0;

  return Math.ceil(((percentage2 / 100) * amount2 + amount2) / npayments2);
};

const calcEntrega = (amount = 0, actanotarial = 0, adelanto = 0, balance = 0) => {
  const amount2 = amount * 1;
  const balance2 = balance * 1;
  const adelanto2 = adelanto * 1;
  const actanotarial2 = actanotarial * 1;
  return amount2 - actanotarial2 - balance2 - adelanto2;
};

const calcProfits = (npayments = 0, monto = 0, amount = 0) => {
  const npayments2 = npayments * 1;
  const monto2 = monto * 1;
  const amount2 = amount * 1;
  return npayments2 * monto2 - amount2;
};

const initialFormValues = { amount: "", percentage: "", npayments: "", actanotarial: "", adelanto: "" };
const ModifyLoan = () => {
  const { mutate } = useSWRConfig();
  const { id: money_id, id2: debtor_id } = useParams();
  const { state, modifyLoan } = useContext(Context);
  const [formValues, setFormValues] = useState(initialFormValues);
  const { user, route: r2 } = state;

  const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  const { data: loan, isLoading } = UseLoanv2(r2.creditor_id, money_id, debtor_id, r2.creditor_id, user.user_id);

  const monto = useMemo(() => {
    return calcMonto(formValues.percentage, formValues.amount, formValues.npayments);
  }, [formValues.percentage, formValues.amount, formValues.npayments]);

  const entrega = useMemo(() => {
    return calcEntrega(formValues.amount, formValues.actanotarial, formValues.adelanto);
  }, [formValues.amount, formValues.actanotarial, formValues.adelanto]);

  const profit = useMemo(() => {
    return calcProfits(formValues.npayments, monto, formValues.amount);
  }, [formValues.npayments, monto, formValues.amount]);

  if (isLoading || routeLoading) return <Loading />;

  const initialValues = {
    user_id: user.user_id,
    debtor_id,
    creditor_id: route.creditor_id,
    name: loan.name,
    mora: loan.mora,
    percentage: loan.percentage || route.rPerc || "",
    npayments: loan.npayments || route.rWeeks || "",
    amount: loan.amount,
    actanotarial: loan.actanotarial,
    given_date: loan.given_date,
    start_date: loan.start_date,
    payoffBalance: 0,
    adelanto: loan.adelanto,
    discount: loan.discount,
    payoff_type: 3,
    type: 7,
    signature: false,
    confirmNewLoan: false,
  };

  let button;
  const submithandler = async (data) => {
    await submitLoanModify(data);

    mutate(`/loan/detail/${debtor_id}/${money_id}/${apertura.a_id}`);
    mutate(`/loansv2/${route.creditor_id}/${user.user_id}/${apertura.a_id}`);
    // mutate(`/xcobrar/loansv2/${route.creditor_id}/${user.user_id}/${apertura.a_id}`);
  };

  button = { label: "Modificar Pr&eacute;stamo", icon: <MdModeEditOutline />, variant: "contained", color: "primary" };

  const submitLoanModify = async (data) => {
    if (entrega < 1) {
      toast.error("Cantidad a entregar es Invalida!");
      return;
    }

    const oldAdelanto = loan.adelanto;

    const newData = { ...loan, ...data, oldAdelanto, wPayment: monto, isCobro: route.isCobro };
    await modifyLoan(newData, () => history.replace(`/loan/detail/${money_id}/${debtor_id}`));
  };

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={loan}>
          <LoanDetailMenu loan={loan} />
        </DebtorNameHeader>

        <Box component="div" p={1}>
          <Box paddingBottom={1}>
            <Divider />
          </Box>

          <Box div="div" p={1} paddingBottom={2}>
            <RenderNewLoanCalculation data={{ monto, entrega, profit }} />
          </Box>

          <RenderLoanModifyForm
            setFormValues={setFormValues}
            initialValues={initialValues}
            submithandler={submithandler}
            // duplexNewLoan={duplexNewLoan}
            button={button}
          />

          <div className="mt-4">
            <RenderBackButton />
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default ModifyLoan;
