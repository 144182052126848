import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../context/DataContext";
import Typography from "@mui/material/Typography";
import SubmitButton from "@shared/components/SubmitButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { printAmount } from "@shared/functions";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getPaymentSelector } from "../context/Selectors";
import Loading from "@shared/components/Loading";
import { UseLoanPaymentsv2, UseRoute } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";
import RenderBackButton from "@shared/components/RenderBackButton";
import { deletePayment } from "@shared/api/payment";
import { useSnackbar } from "notistack";

const DeletePayment = () => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const { state } = useContext(Context);
  const [currentPayment, setCurrentPayment] = useState(null);
  const { id: payment_id, id2: money_id, id3: debtor_id } = useParams();
  const { user, route } = state;

  const { enqueueSnackbar } = useSnackbar();

  const { data: LoanPayments, isLoading: isLoading, key } = UseLoanPaymentsv2(money_id, debtor_id);
  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);

  useEffect(() => {
    if (LoanPayments) {
      setCurrentPayment(getPaymentSelector(LoanPayments, payment_id));
    }
    //eslint-disable-next-line
  }, [LoanPayments]);

  if (isLoading || routeLoading || !currentPayment) return <Loading />;

  const handleDeletePayment = async () => {
    try {
      await deletePayment({
        debtor_id: currentPayment.debtor_id,
        mUniqueId: currentPayment.mUniqueId,
        pUniqueId: currentPayment.pUniqueId,
        user_id: user.user_id,
        creditor_id: routeN.creditor_id,
      });

      enqueueSnackbar("Pago borrado con exito!", { variant: "warning" });

      mutate(key);
      navigate(-1, { replace: true });
    } catch (error) {}
    // mutate(`/xcobrar/loansv2/${route.creditor_id}/${user.user_id}/${apertura.a_id}`);
  };

  return (
    <div className="container mt-5">
      <Card>
        <CardContent>
          <Typography variant="h5" component="h3" className="text-danger">
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom>
            Decea borrar este pago?
          </Typography>
          <Typography variant="body1" component="p" paragraph>
            Pago de {printAmount(currentPayment.pAmount)} para {currentPayment.name}
          </Typography>

          <Box paddingTop={2}>
            <SubmitButton
              autoFetch={false}
              color="secondary"
              text="Borrar Pago!"
              startIcon={<DeleteForeverIcon />}
              callBack={handleDeletePayment}
            />
            <div className="mt-4">
              <RenderBackButton />
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeletePayment;
