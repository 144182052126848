import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Context } from "../context/DataContext";
import FavoriteDebtor from "../components/FavoriteDebtor";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FaHandHoldingUsd, FaHandHoldingHeart } from "react-icons/fa";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderDebtorLoans from "@shared/components/RenderDebtorLoans";
// import RenderDebtorImages from "@shared/components/RenderDebtorImages";
import RenderDebtorProfile from "@shared/components/RenderDebtorProfile";
import DebtorMenu from "@shared/components/DebtorMenu";
import Loading from "@shared/components/Loading";
import { UseDebtors, UseDebtorLoansv2 } from "@shared/server/fetcher";
import { FcCancel } from "react-icons/fc";
// import NoInternetContent from "@shared/components/NoInternetContent";

const LoanCreate = () => {
  const navigate = useNavigate();
  const { id: dUniqueId } = useParams();
  const { state, toggleFaveDebtor } = useContext(Context);
  const { route: r2, user } = state;
  const [value, setValue] = useState(0);
  const [filteredLoans, setFilteredLoans] = useState([]);

  // const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  // const { data: debtor, isLoading: isLoadingDebtor } = UseDebtor(debtor_id, user.user_id);
  const { data: debtors, isLoading: debtorLoading, mutate } = UseDebtors(r2.creditor_id, user.user_id);
  const debtor = debtors?.find((x) => x.dUniqueId === +dUniqueId);

  // const { data: apertura, isLoading: aLoading } = UseSharedApertura(r2?.creditor_id, r2.r_type, r2.day);

  const { data: loans, isLoading: isLoadingLoans } = UseDebtorLoansv2(debtor?.debtor_id, r2.creditor_id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!loans) return;
    if (value === 0) {
      setFilteredLoans(loans.filter((x) => x.balance > 0 && x.deletedBy === 0));
    } else if (value === 1) {
      setFilteredLoans(loans.filter((x) => x.balance === 0 && x.deletedBy === 0));
    } else if (value === 2) {
      setFilteredLoans(loans.filter((x) => x.deletedBy > 0));
    }
  }, [loans, value]);

  if (debtorLoading) return <Loading />;
  if (!debtor)
    return (
      <Box component="div">
        <div className="alert alert-danger">
          <Typography variant="subtitle2" component="p">
            Este cliente fue eliminado!
          </Typography>
        </div>
      </Box>
    );

  return (
    <div className="mt-2 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={debtor}>
          <div className="" style={{ display: "inline-flex" }}>
            <FavoriteDebtor debtor={debtor} toggleFaveDebtor={toggleFaveDebtor} mutate={mutate} />

            <DebtorMenu debtor={debtor} profile />
          </div>
        </DebtorNameHeader>

        <Box component="div" p={1}>
          <RenderDebtorProfile debtor={debtor} />
        </Box>

        {isLoadingLoans ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <Box p={1}>
              <Typography variant="h6" component="h4">
                Lista de Prestamos
              </Typography>

              <Tabs value={value} onChange={handleChange} aria-label="icon label tabs example">
                <Tab icon={<FaHandHoldingUsd size="1.6em" />} label="Con Balance" />
                <Tab icon={<FaHandHoldingHeart size="1.7em" />} label="Saldado" />
                <Tab icon={<FcCancel size="1.8em" />} label="Cancelado" />
              </Tabs>
            </Box>
            <RenderDebtorLoans loans={filteredLoans} />

            {value == 0 && filteredLoans.length === 0 && (
              <div>
                <Box component="div">
                  <div className="alert alert-primary">
                    <Typography variant="body1" component="p">
                      No pr&eacute;stamos con balance!
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <Button
                    fullWidth
                    startIcon={<FaHandHoldingUsd size="1.2em" />}
                    variant="outlined"
                    onClick={() => navigate(`/redirect/page/${debtor.dUniqueId}/0/${debtor.cedula}`)}>
                    Nuevo Pr&eacute;stamo!
                  </Button>
                </Box>
              </div>
            )}

            {value == 1 && filteredLoans.length === 0 && (
              <div>
                <Box component="div">
                  <div className="alert alert-primary">
                    <Typography variant="body1" component="p">
                      No pr&eacute;stamos saldado!
                    </Typography>
                  </div>
                </Box>
              </div>
            )}

            {value == 2 && filteredLoans.length === 0 && (
              <div>
                <Box component="div">
                  <div className="alert alert-primary">
                    <Typography variant="body1" component="p">
                      No pr&eacute;stamos cancelado!
                    </Typography>
                  </div>
                </Box>
              </div>
            )}
          </Box>
        )}

        <Box paddingBottom={2} paddingTop={2}>
          <Button
            fullWidth
            className="text-info"
            startIcon={<FaHandHoldingUsd size="1.2em" />}
            variant="outlined"
            onClick={() => navigate("/loan/old/create/" + debtor.debtor_id)}>
            Pr&eacute;stamo Antiguo!
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default LoanCreate;
