import Typography from "@nodes/@mui/material/Typography";
import { printAbsAmount, getBadgeColor } from "../functions";
import Grid from "@nodes/@mui/material/Grid";
import Alert from "@nodes/@mui/material/Alert";
import Box from "@nodes/@mui/material/Box";

const DisplayLoanStatus = ({ loan }) => {
  return (
    <div>
      {loan.syncRequired && (
        <Box paddingBottom={3}>
          <Alert variant="outlined" severity="warning">
            <Typography variant="h6" component="p">
              Prestamos Actualizado!
            </Typography>
            <Typography variant="body1" component="p">
              Conexion de internet requeridad para auto sincronizar este prestamo.
            </Typography>
          </Alert>
        </Box>
      )}

      <Grid container spacing={1}>
        <Grid item>
          <span className="badge badge-pill badge-secondary">
            <Typography variant="body1" component="p" style={{ fontSize: 18 }}>
              <span style={{ padding: 5 }}>{loan.a_count}</span>
            </Typography>
          </span>
        </Grid>
        <Grid item>
          {loan.statusText === "Al Dia" ? (
            <Typography variant="body1" component="p" className="badge badge-pill badge-success">
              Al Dia
            </Typography>
          ) : (
            <Typography variant="body1" component="p" className={getBadgeColor(loan.statusText)}>
              {loan.statusText} {printAbsAmount(loan.statusAmount)}
            </Typography>
          )}
        </Grid>

        {loan.sale_hoy && (
          <Grid item>
            <Typography variant="body1" component="p" className="badge badge-pill badge-dark">
              Sale Hoy!
            </Typography>
          </Grid>
        )}

        {loan.new_loan && (
          <Grid item>
            <Typography variant="body1" component="p" className="badge badge-pill badge-primary">
              Nuevo!
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default DisplayLoanStatus;
