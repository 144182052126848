import { isAndroid } from "@nodes/react-device-detect";
import { toast } from "@nodes/react-toastify";
// import { calcEndDate } from "@shared/functions";
// import { localStoragePrintApp } from "@/config";
import {
  RemoveAccents,
  printAmount,
  printAbsAmount,
  newDate,
  printDate,
  formatPhoneNumber,
  replaceArguments,
  getFormattedLoanContract,
  spanishRouteTypes,
} from "./";

function verifyPrintCompactibility(printApp) {
  if (!isAndroid && import.meta.env.PROD && !["whatsapp", "html"].includes(printApp)) {
    toast.warning("Imprimir recibo solo funiona en Telefono Android!", {
      autoClose: 4000,
      hideProgressBar: true,
      progress: undefined,
    });
    return null;
  }
  return true;
}

function sendToPrinter(printApp, receipt, printMessage) {
  if (["html", "whatsapp"].includes(printApp)) return receipt;

  if (import.meta.env.DEV) {
    console.log(receipt);
    return;
  }

  if (printApp === "RawBt") {
    BtPrint(receipt);
  } else {
    QuickPrinter(receipt);
  }

  toast.info(printMessage, { autoClose: 2500 });
}

function getPrinterApp(configs) {
  if (configs.printApp === "whatsapp") return "whatsapp";

  return configs.printApp;
  // return localStorage.getItem(localStoragePrintApp) ?? "QuickPrinter";
}

export function printReceiptHandler(loan = {}, route = {}) {
  if (!verifyPrintCompactibility(route.printApp)) return;

  const printApp = getPrinterApp(route);

  let receipt = generatePaymentReceipt({ ...loan }, { ...route });
  receipt = FormatReceipt(receipt, printApp);

  return sendToPrinter(printApp, receipt, "Imprimiendo recibo!");
}

export function printCuadreHandler(cuadre = {}, configs = {}) {
  if (!verifyPrintCompactibility(configs.printApp)) return;

  const printApp = getPrinterApp(configs);

  let receipt = generateCuadre({ ...cuadre }, { ...configs });
  receipt = FormatReceipt(receipt, printApp);

  return sendToPrinter(printApp, receipt, "Imprimiendo Cuadre!");
}

export function printEntregaHandler(loan = {}, route = {}, configs = {}) {
  if (!verifyPrintCompactibility(configs.printApp)) return;

  const printApp = getPrinterApp(configs);

  let receipt = generateNewLoan({ ...loan }, { ...route }, { ...configs });
  receipt = FormatReceipt(receipt, printApp);

  return sendToPrinter(printApp, receipt, "Imprimiendo Entrega!");
}

export function printLoanContractHandler(loan = {}, route = {}, configs = {}) {
  if (!verifyPrintCompactibility(configs.printApp)) return;

  const printApp = getPrinterApp(configs);

  let receipt = generateLoanContract({ ...loan }, { ...route }, { ...configs });
  receipt = FormatReceipt(receipt, printApp);

  return sendToPrinter(printApp, receipt, "Imprimiendo Contrato!");
}

function generateCuadre(cuadre = {}, configs) {
  cuadre.route_name = RemoveAccents(cuadre.route_name);
  cuadre.location = RemoveAccents(cuadre.location);
  cuadre.collected_by = RemoveAccents(cuadre.collected_by);

  const options = { collector: cuadre.collected_by, printApp: configs.printApp };
  let data = getPrintHeader("RESUMEN DE CUADRE!", { ...cuadre }, options);

  data += `%align_right%Fecha: ${printDate(newDate(cuadre.today_date))}%break_line%%break_line%`;

  data += `Estado de la Ruta (${cuadre.cuadre_count}): ${printAbsAmount(cuadre.cuadre_amount)}%break_line%%break_line%`;
  data += `Prestamos x Cobrar:    ${printAbsAmount(cuadre.xCobrar)}%break_line%%break_line%`;
  data += `Caja / Banco:          ${printAbsAmount(cuadre.bank_amount)}%break_line%%break_line%`;
  data += `Acta Notarial:         ${printAbsAmount(cuadre.actanotarial)}%break_line%%break_line%`;
  data += `Pagos Aplicado:        ${printAbsAmount(cuadre.collected)}%break_line%%break_line%`;
  data += `Pagos de Mora:         ${printAbsAmount(cuadre.moras)}%break_line%%break_line%`;
  data += `Entregas:              ${printAbsAmount(cuadre.newLoans)}%break_line%%break_line%`;
  data += `Gastos:                ${printAbsAmount(cuadre.gastos)}%break_line%%break_line%`;
  data += `%align_right%%medium1%%bold%Efectivo: ${printAmount(cuadre.efectivo)}%break_line%%break_line%`;
  data += `%align_right%%medium1%%bold%Transferencia: ${printAmount(
    cuadre.deposits
  )}%break_line%%break_line%%break_line%`;

  return data;
}

function generateNewLoan(loan, route, user) {
  const entregada = loan.amount - loan.actanotarial - loan.adelanto - loan.discount - loan.payoffBalance;

  route.route_name = RemoveAccents(route.route_name);
  route.location = RemoveAccents(route.location);
  user.fname = RemoveAccents(user.fname);
  loan.name = RemoveAccents(loan.name);

  const options = { phone: route.phone, phone2: route.phone2, collector: user.fname, printApp: user.printApp };
  let data = getPrintHeader("DETALLE DE ENTREGA!", { ...route }, options);

  data += `%bold%%medium2%%wAppbold%${loan.name}%wAppbold%%break_line%%break_line%`;

  data += `Fecha de Entrega:   ${printDate(newDate(route.today_date))}%break_line%%break_line%`;
  data += `Cantidad Prestada:  ${printAbsAmount(loan.amount)}%break_line%%break_line%`;
  data += `Tipo de Prestamo:   ${spanishRouteTypes(loan.a_type)}%break_line%%break_line%`;
  data += `Monto Cuota:        ${printAbsAmount(loan.cuota)} X ${loan.npayments}%break_line%%break_line%`;
  data += `Pago de Adelanto:   ${printAbsAmount(loan.adelanto)}%break_line%%break_line%`;
  data += `Balance Anterior:   ${printAbsAmount(loan.payoffBalance)}%break_line%%break_line%`;
  data += `Acta Notarial:     ${printAbsAmount(loan.actanotarial)}%break_line%%break_line%`;
  data += `Descuento Adicional: ${printAbsAmount(loan.discount)}%break_line%%break_line%`;
  data += `Cantidad Entregada: ${printAbsAmount(entregada)}%break_line%%break_line%`;

  return data;
}

function generateLoanContract(loan, route, user) {
  const entregada = loan.amount - loan.actanotarial - loan.adelanto - loan.discount - loan.payoffBalance;

  route.route_name = RemoveAccents(route.route_name);
  route.location = RemoveAccents(route.location);
  user.firstname = RemoveAccents(user.firstname);
  loan.name = RemoveAccents(loan.name);

  let data = getPrintHeader("CONTRATO DE PRESTAMO!", { ...route }, { printApp: user.printApp });

  data += `%bold%%medium2%%wAppbold%${loan.name}%wAppbold%%break_line%%break_line%`;

  data += getFormattedLoanContract({ ...loan, ...route });

  data += `%break_line%`;
  data += `%dashed_line%`;
  data += `%bold%Detalle de Prestamo!%break_line%`;
  data += `Fecha de Entrega:   ${printDate(newDate(route.today_date))}%break_line%`;
  data += `Cantidad Prestada:  ${printAbsAmount(loan.amount)}%break_line%`;
  data += `Tipo de Prestamo:   ${spanishRouteTypes(loan.a_type)}%break_line%`;
  data += `Monto Cuota:        ${printAbsAmount(loan.cuota)} X ${loan.npayments}%break_line%`;
  data += `Pago de Adelanto:   ${printAbsAmount(loan.adelanto)}%break_line%`;
  data += `Balance Anterior:   ${printAbsAmount(loan.payoffBalance)}%break_line%`;
  data += `Acta Notarial:     ${printAbsAmount(loan.actanotarial)}%break_line%`;
  data += `Descuento Adicional: ${printAbsAmount(loan.discount)}%break_line%`;
  data += `Cantidad Entregada: ${printAbsAmount(entregada)}%break_line%%break_line%%break_line%%break_line%`;
  data += `%bold%%medium2%________________________________%break_line%%break_line%`;
  data += `%center%%bold%${loan.name}%break_line%`;
  data += `%align_right%%bold%Firma del Cliente%break_line%%break_line%%break_line%%break_line%`;
  data += `%bold%%medium2%________________________________%break_line%%break_line%`;
  data += `%align_right%%bold%Firma del Garante%break_line%%break_line%%break_line%`;

  return data;
}

function generatePaymentReceipt(loan, route) {
  //This new function will insert completed payment first for incomplete payments.
  const { ...p } = loan;

  const debugging = import.meta.env.DEV;

  let atrasos = p.pending * p.cuota - p.pAmount - p.incomplete;

  //Todo: this condition check is only temporarily until all customers are using the same version of cobro App.
  if (p.previousAmount) {
    atrasos -= p.previousAmount;
  }

  if (p.mainApp === true) {
    atrasos = (p.pending - 1) * p.cuota - p.incomplete;
  }

  // const verifiedEndDate = calcEndDate(p);
  // const endDate = verifiedEndDate === "N/A" ? "Proceso..." : printDate(verifiedEndDate);

  p.name = RemoveAccents(p.name);
  route.route_name = RemoveAccents(route.route_name);
  route.location = RemoveAccents(route.location);

  let pAmount = p.pAmount;
  let pMora = p.pMora;

  // if (specificPayment?.pAmount || specificPayment?.pMora) {
  //   pAmount = specificPayment.pAmount;
  //   pMora = specificPayment.pMora;
  // }

  const options = { phone: route.phone, phone2: route.phone2, printApp: route.printApp, collector: route.fname };

  let data = "";

  data = getPrintHeader("RECIBO DE PAGO!", route, options);

  data += `%align_right%%p_right%Fecha: ${printDate(newDate(loan.payment_date))}%_p%%break_line%%break_line%`;

  // <BOLD><MEDIUM2> is not the same as <MEDIUM2><BOLD>
  if (route.printApp !== "html") {
    data += `%bold%%medium2%%wAppbold%%h5%${p.name}%_h5%%wAppbold%%break_line%%break_line%`;
  }

  data += "%p%"; //Aplly paragragh tag to all following texts

  if (p.adelantoPayment !== true) {
    data += `Prestamo No.: P${loan.oldLoanId ?? loan.money_id}%break_line%%br%`;
  }

  data += `Cantidad Prestada ${printAbsAmount(p.amount)}%break_line%%br%`;

  const printSpecialUser1 = [2248, 2252];
  const printSpecialUser2 = [2653];
  if (printSpecialUser1.includes(+route.owner_id) && p.balance + p.balMora > 0) {
    data += `Emp: ${printDate(newDate(p.start_date))} Ter: ${printDate(newDate(p.end_date))}%break_line%%br%%br%`;
    data += `Solo puede renovar en la No.: ${p.npayments}%break_line%%break_line%%br%%br%`;
  } else if (printSpecialUser2.includes(+route.owner_id) && p.balance + p.balMora > 0) {
    data += `Emp: ${printDate(newDate(p.start_date))} Ter: ${printDate(newDate(p.end_date))}%break_line%%br%%br%`;
    data += `Solo puede renovar en la No.: ${p.npayments - 2}%break_line%%break_line%%br%%br%`;
  } else if (+route.owner_id === 2103 && p.balance + p.balMora > 0 && (p.print3 || atrasos > 0)) {
    data += `Emp: ${printDate(newDate(p.start_date))} Ter: ${printDate(newDate(p.end_date))}%break_line%%br%%br%`;
    data += `Solo puede renovar en la No.: ${p.npayments}%break_line%%break_line%%br%%br%`;
  } else {
    data += `Emp: ${printDate(newDate(p.start_date))} Ter: ${printDate(
      newDate(p.end_date)
    )}%break_line%%break_line%%br%%br%`;
  }

  const prevBalance = pAmount + p.balance;

  const prevIncompletePmtAmount = prevBalance % p.cuota;
  // let incompletePmtAmount = p.balance % p.cuota;
  // let initalPmtAmount = p.cuota - pAmount;
  const isAbonoPmtAmount = pAmount % p.cuota;

  const loopCount = Math.max(Math.floor((pAmount - prevIncompletePmtAmount) / p.cuota), 0);
  const loopAmount = loopCount * p.cuota;

  const abonoPmtAmount = pAmount - loopAmount - prevIncompletePmtAmount;

  // console.log({
  //   loopAmount,
  //   // initalPmtAmount,
  //   isAbonoPmtAmount,
  //   abonoPmtAmount,
  //   pAmount,
  //   // incompletePmtAmount,
  //   prevIncompletePmtAmount,
  // });

  let debugStr = "";
  let printedAmountSum = 0;
  let newCompleted = p.completed - loopCount;

  if (prevIncompletePmtAmount > 0 && pAmount >= prevIncompletePmtAmount) {
    debugStr = debugging ? "#1- " : "";
    printedAmountSum += prevIncompletePmtAmount;
    data += debugStr + getPaymentPrint("Pagada", prevIncompletePmtAmount, newCompleted, p.npayments);
  }

  data += getLoppedPayments(p, loopCount);

  newCompleted += loopCount;

  if (prevIncompletePmtAmount <= 0 || pAmount < prevIncompletePmtAmount) {
    if (isAbonoPmtAmount > 0) {
      newCompleted += 1;
      if (route.showAbonoPayments === 1) {
        debugStr = debugging ? "#2- " : "";
        printedAmountSum += isAbonoPmtAmount;
        data += debugStr + getPaymentPrint("Abono", isAbonoPmtAmount, newCompleted, p.npayments);
      } else {
        printedAmountSum += isAbonoPmtAmount;
        if (loopAmount === pAmount) {
          debugStr = debugging ? "#3- " : "";
          printedAmountSum += isAbonoPmtAmount;
          data += debugStr + getPaymentPrint("Pagada", isAbonoPmtAmount, newCompleted - 1, p.npayments);
        }
      }
    }
  }

  if (abonoPmtAmount > 0 && isAbonoPmtAmount !== abonoPmtAmount) {
    newCompleted += 1;
    if (route.showAbonoPayments === 1) {
      debugStr = debugging ? "#4- " : "";
      printedAmountSum += abonoPmtAmount;
      data += debugStr + getPaymentPrint("Abono", abonoPmtAmount, newCompleted, p.npayments);
    } else {
      debugStr = debugging ? "#5- " : "";
      printedAmountSum += abonoPmtAmount;
      data += debugStr + getPaymentPrint("Pagada", abonoPmtAmount, newCompleted - 1, p.npayments);
    }
  }

  if (printedAmountSum + loopAmount !== pAmount) {
    console.error("Incorrect printedAmountSum, differ from pAmount");
  }

  if (pMora > 0) {
    data += `Mora Pagada:         ${printAbsAmount(pMora)}%break_line%%br%`;
  }

  if (p.mora > 0 && route.showMoraBalance === 1) {
    data += `Mora Pendiente:      ${printAbsAmount(p.mora)}%break_line%%br%`;
  }

  if (atrasos > 0 && p.balance > 0) {
    data += `Total Monto Pagado:  ${printAbsAmount(pAmount + pMora)}%break_line%%br%`;
    data += `Atrasos Pendiente:   ${printAbsAmount(atrasos)}%break_line%%break_line%%br%`;
  } else if (atrasos < 0 && p.balance > 0) {
    data += `Total Monto Pagado:  ${printAbsAmount(pAmount + pMora)}%break_line%%br%`;
    data += `En Adelanto:         ${printAbsAmount(-1 * atrasos)}%break_line%%break_line%%br%`;
  } else {
    data += `Total Monto Pagado:  ${printAbsAmount(pAmount + pMora)}%break_line%%break_line%%br%`;
  }

  data += "%_p%";

  if (route.showLoanBalance === 1) {
    data += `%medium1%%wAppMonoSpace%%h6%Balance: ${printAbsAmount(
      p.balance + p.balMora
    )}%_h6%%wAppMonoSpace%%break_line%%break_line%`;
  }

  if (route.excludeHeaders !== true) {
    data += `%br%%small%%h6%%i%SIN RECIBO, NO HAY RECLAMOS!%_i%%_h6%%break_line%%break_line%`;

    data += `%small%%wAppItalic%En caso de incumplimiento, sus%wAppItalic%%break_line%`;
    data += `%small%%wAppItalic%datos seran procesados a data%wAppItalic%%break_line%`;
    data += `%small%%wAppItalic%credito!%wAppItalic%%wrapper_end%`;
  }

  return data;
}

function getPrintHeader(title, data, options = {}) {
  let header = `%wrapper_start%`;

  if (options.pritnLogo) {
    const url = window.location.origin;
    const logo = "https://grkids.com/wp-content/uploads/2022/07/senior-pictures.jpg";

    header += `<IMAGE>${url}/logo.png%break_line%`;
    header += `<IMAGE>${logo}%break_line%`;
  }

  let route_name = data.route_name;
  if (options.printApp === "whatsapp") {
    route_name = route_name.replace(" & ", "&");
    route_name = route_name.replace("&", " ");

    route_name = route_name.replace(" # ", "#");
    route_name = route_name.replace("#", "");
  }

  header += `%center%%medium2%%wAppMonoSpace%%h6_center%%b%${title}%_b%%_h5%%wAppMonoSpace%%break_line%`;
  header += `%center%%p_center%${printDate(new Date(), "dd-MMMM-Y - hh:mm:ssaaa")}%_p%%break_line%`;
  header += `%hr2%%double_line%%break_line%`;

  if (data.excludeHeaders === true) return header;

  header += `%medium2%%bold%%h5%${route_name}%_h5%%break_line%`;
  header += "%p%";
  header += `%normal%Zona: ${data.location}%break_line%%br%`;

  if (options.collector) {
    header += `Cobrado por: ${options.collector}%break_line%%br%`;
  }

  if (options.phone) {
    header += `${formatPhoneNumber(options.phone)}`;
    if (options.phone2) {
      header += ` / ${formatPhoneNumber(options.phone2)}`;
    }
    header += "%br%";
    header += `%break_line%`;
  }

  header += "%_p%";

  header += `%hr%%dashed_line%`;
  return header;
}

function getLoppedPayments(p, loopCount = 0) {
  let data = "";

  const debugging = import.meta.env.DEV ? "#L- " : "";

  if (loopCount > 5) {
    data += `${debugging}Multiple Pagos ${p.completed}/${p.npayments}: ${printAbsAmount(
      p.cuota * loopCount
    )}%break_line%%br%`;
    return data;
  }

  for (let i = 0; i < loopCount; i++) {
    const completedPrint = p.completed - loopCount + i + 1;
    let spacer = completedPrint > 9 ? "..." : "....";
    data += `${debugging}Cuota Pagada ${spacer}${completedPrint}/${p.npayments}: ${printAbsAmount(
      p.cuota
    )}%break_line%%br%`;
  }
  return data;
}

function getPaymentPrint(type, amount, completedPrint, npayments) {
  let spacer = completedPrint > 9 ? "..." : "....";
  spacer += type === "Abono" ? "." : "";
  return `Cuota ${type} ${spacer}${completedPrint}/${npayments}: ${printAbsAmount(amount)}%break_line%%br%`;
}

function BtPrint(data) {
  var s = "#Intent;scheme=rawbt;package=ru.a402d.rawbtprinter;end;";
  var textEncoded = encodeURI(data);
  window.location.href = "intent:" + textEncoded + s;
}

function QuickPrinter(data) {
  var s = "#Intent;scheme=quickprinter;package=pe.diegoveloper.printerserverapp;end;";
  var textEncoded = encodeURI(data);
  window.location.href = "intent://" + textEncoded + s;
}

function thermer(data) {
  var textEncoded = encodeURI(data);
  window.location.href = "my.bluetoothprint.scheme://" + textEncoded;
}

function FormatReceipt(data, printApp) {
  let replacements = {};

  if (printApp === "RawBt") {
    replacements = {
      "%wrapper_start%": "\r\n",
      "%wrapper_end%": "\r\n",
      "%dashed_line%": "\r\n",
      "%break_line%": "\r\n",
    };
  } else if (printApp === "whatsapp") {
    replacements = {
      "%dashed_line%": "%0a",
      "%wAppbold%": "*",
      "%wAppItalic%": "_",
      "%wAppMonoSpace%": "```",
      "%break_line%": "%0a",
    };
  } else if (printApp === "html") {
    replacements = {
      "%br%": "<br/>",
      "%hr%": "<hr style='border-top: dotted 1px;'>",
      "%hr2%": "<hr style='border-top: dotted 2px;'>",
      "%h6_center%": "<h6 align='center'>",
      "%h5_center%": "<h5 align='center'>",
      "%h5%": "<h5>",
      "%_h5%": "</h5>",
      "%h6%": "<h6>",
      "%_h6%": "</h6>",
      "%b%": "<b>",
      "%_b%": "</b>",
      "%i%": "<i>",
      "%_i%": "</i>",
      "%p_right%": "<p style='font-size:16px' align='right'>",
      "%p_center%": "<p align='center'>",
      "%p%": "<p style='font-size:15px'>",
      "%_p%": "</p>",
    };
  } else {
    replacements = {
      "%wrapper_start%": "\r\n",
      "%dashed_line%": "<LINE>",
      "%center%": "<CENTER>",
      "%small%": "<SMALL>",
      "%bold%": "<BOLD>",
      "%normal%": "<NORMAL>",
      "%medium1%": "<MEDIUM1>",
      "%medium2%": "<MEDIUM2>",
      "%double_line%": "<DLINE>",
      "%align_right%": "<RIGHT>",
      "%break_line%": "\r\n",
    };
  }

  return replaceArguments(data, replacements);
}
