import Typography from "@nodes/@mui/material/Typography";
import Box from "@nodes/@mui/material/Box";
import { printAmount, printDate, newDate } from "../functions";

const RenderRoutesBalance = ({ userRoutesBalance = { routes: [], balance: 0 } }) => {
  if (userRoutesBalance.routes.length === 0) return null;

  return (
    <div>
      <Box component="div">
        <Typography variant="h6" gutterBottom>
          Rutas con pagos pendientes!
        </Typography>

        {userRoutesBalance.routes.map((item) => (
          <Box key={item.creditor_id} paddingBottom={2}>
            <Typography variant="body1">
              {item.route_name} ({item.location})
            </Typography>
            <Typography variant="body1">
              Balance {item.balance > 0 ? printAmount(item.balance) : "$0"} ({item.pending}{" "}
              {+item.pending === 1 ? "Mes" : "Meses"})
            </Typography>
            {/* <Typography variant="body1" className={item.isDisabled ? "text-danger" : ""}> */}
            <Typography variant="body1" className={item.daysRemanining < 16 ? "text-danger font-weight-bold" : ""}>
              Pagar antes de {printDate(newDate(item.lastDate), "dd-MMM-y")}!
            </Typography>
          </Box>
        ))}

        <Typography variant="subtitle1" gutterBottom>
          <b>Total Balance: {printAmount(userRoutesBalance.balance)}</b>
        </Typography>
      </Box>
    </div>
  );
};

export default RenderRoutesBalance;
