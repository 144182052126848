import { useState, forwardRef } from "@nodes/react";
import { useForm, Controller } from "@nodes/react-hook-form";
import { NumericFormat } from "@nodes/react-number-format";
import Grid from "@nodes/@mui/material/Grid";
import Typography from "@nodes/@mui/material/Typography";
import Box from "@nodes/@mui/material/Box";
import FormControl from "@nodes/@mui/material/FormControl";
import Button from "@nodes/@mui/material/Button";
import Alert from "@nodes/@mui/material/Alert";
import Input from "@nodes/@mui/material/Input";
import IconButton from "@nodes/@mui/material/IconButton";
import InputLabel from "@nodes/@mui/material/InputLabel";
import InputAdornment from "@nodes/@mui/material/InputAdornment";
import BackspaceIcon from "@nodes/@mui/icons-material/Backspace";
import { toast } from "@nodes/react-toastify";
import Grow from "@nodes/@mui/material/Grow";
// import ToggleButton from "@nodes/@mui/material/ToggleButton";
import Stack from "@nodes/@mui/material/Stack";
// import ToggleButtonGroup from "@nodes/@mui/material/ToggleButtonGroup";
// import { InputText } from "./form/InputText";
import { InputDate } from "./form/InputDate";
// import { InputToggleButtons } from "./form/InputToggleButtons";
import { InputButtonGroup } from "./form/InputButtonGroup";
import { InputCheckbox } from "./form/InputCheckbox";
import { InputDropdown } from "./form/InputDropdown";
// import { InputDropdownMulti } from "./form/InputDropdownMulti";
import { printAmount, printDate, newDate } from "../functions";
import { GiTakeMyMoney } from "@nodes/react-icons/gi";
import { FaRegMoneyBillAlt } from "@nodes/react-icons/fa";
import { AiFillBank } from "@nodes/react-icons/ai";
import Table from "@nodes/@mui/material/Table";
import TableBody from "@nodes/@mui/material/TableBody";
import TableCell from "@nodes/@mui/material/TableCell";
import TableContainer from "@nodes/@mui/material/TableContainer";
import TableHead from "@nodes/@mui/material/TableHead";
import TableRow from "@nodes/@mui/material/TableRow";
import Paper from "@nodes/@mui/material/Paper";
import { DevTool } from "@nodes/@hookform/devtools";
import { mainApp, colors } from "@/config";

// function customRoundAmount(amount, number = 5) {
//   return Math.round(amount / number) * number;
// }

const NumericFormatCustom = forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, prefix, suffix, thousandSeparator, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      valueIsNumericString
      prefix={prefix}
      suffix={suffix}
    />
  );
});

const TAX_RATE = 0.07;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow("Paperclips (Box)", 100, 1.15),
  createRow("Paper (Case)", 10, 45.99),
  createRow("Waste Basket", 2, 17.99),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const RenderPaymentForm = ({ defaultValues, loan, configs, submithandler }) => {
  if (+loan.deletedBy !== 0) {
    return (
      <Box component="div" p={1}>
        <Typography variant="h6" component="h4" gutterBottom>
          Prestamo Cancelado!
        </Typography>

        <Typography variant="body1" component="p">
          Este prestamo ha sido cancelado desde la app Central.
        </Typography>
      </Box>
    );
  }

  if (+loan.balance + +loan.balMora === 0) {
    return (
      <Box component="div" p={1}>
        <Typography variant="h6" component="h4" gutterBottom>
          Prestamo Saldado!
        </Typography>

        <Typography variant="body1" component="p">
          El balance de este prestamo es $0.
        </Typography>

        <Typography variant="body1" component="p">
          Prestamo saldado en {printDate(newDate(loan.last_date), "dd-MMM-y")}.
        </Typography>
      </Box>
    );
  }

  const [duplexPayment, setDuplexPayment] = useState(false);
  const [fullBalanceWarning, setFullBalanceWarning] = useState(false);
  // const [autoPrintReceipt, setAutoPrintReceipt] = useState(configs.autoPrintPayment);

  // const [paymentType, setPaymentType] = useState("cash");

  const { handleSubmit, control, setValue, formState, watch } = useForm({
    defaultValues,
  });

  const { isSubmitting } = formState;

  const handleSubmitHandler = async (data) => {
    const pMora = +data.pMora;
    const pInterest = +data.pInterest;
    const pCash = +data.pCash;
    const pDeposit = +data.pDeposit;

    const paymentAmount = pCash + pDeposit;

    if (paymentAmount < 1) {
      return;
    }

    // if (paymentAmount - pMora > loan.balance) {
    //   toast.error(`Balance del prestamo es ${printAmount(loan.balance)}!`);
    //   return;
    // }

    // if (pCash && paymentAmount !== 0 && !duplexPayment) {
    //   setDuplexPayment(true);
    //   return;
    // }

    // if (pCash - pMora === loan.balance && loan.npayments - loan.completed !== 1 && !fullBalanceWarning) {
    //   setFullBalanceWarning(true);
    //   return;
    // }

    await submithandler({ ...data, pDeposit, pCash, pMora, pInterest });
  };

  // const handleChange = (event, type) => {
  //   // if (!type) return;
  //   setPaymentType(type);
  // };

  const handlepAmount = (amount) => {
    if (watch("paymentType") === "cash") {
      setValue("pCash", amount + +watch("pMora"));
      return;
    }

    setValue("pDeposit", amount + +watch("pMora"));
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitHandler)}>
      {mainApp === 1 && (
        <Box paddingBottom={0}>
          <InputDate name="payment_date" control={control} label="Fecha de Pago" />
        </Box>
      )}

      {+configs?.showPaymentTransfer === 1 && (
        // <Box>
        //   <FormControl fullWidth variant="standard">
        //     <Box paddingBottom={6}>
        //       <InputLabel>Metodo de Pago</InputLabel>
        //     </Box>

        //     <ToggleButtonGroup
        //       fullWidth
        //       color="info"
        //       value={paymentType}
        //       exclusive
        //       onChange={handleChange}
        //       aria-label="Platform">
        //       <ToggleButton value="cash">Efectivo</ToggleButton>
        //       <ToggleButton value="deposit">Deposito</ToggleButton>
        //       <ToggleButton value="mix">Mixto</ToggleButton>
        //     </ToggleButtonGroup>
        //   </FormControl>
        // </Box>
        <Box>
          <InputButtonGroup
            name="paymentType"
            control={control}
            value={defaultValues.paymentType}
            setValue={setValue}
            label="Metodo de Pago"
            configs={{ variant: "outlined" }}
            options={[
              //if these values ever get changed, server must be updated as well.
              { label: "Efectivo", value: "cash" },
              { label: "Deposito", value: "deposit" },
              { label: "Mixto", value: "mix" },
            ]}
          />
        </Box>
      )}

      <Box paddingTop={3} paddingBottom={2}>
        <Stack spacing={2}>
          {(watch("paymentType") === "cash" || watch("paymentType") === "mix") && (
            <Controller
              name="pCash"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => (
                <>
                  <FormControl fullWidth variant="standard">
                    <Box paddingBottom={3}>
                      <InputLabel sx={{ fontSize: 16 }} htmlFor="pCash">
                        <b>Monto en Efectivo</b>
                      </InputLabel>
                    </Box>

                    <Input
                      sx={{ fontSize: 20 }}
                      placeholder="Escriba cantidad de pago!"
                      label="Monto en Efectivo"
                      error={!!error}
                      onBlur={onBlur}
                      value={value}
                      name="pCash"
                      onChange={onChange}
                      fullWidth
                      inputProps={{
                        thousandSeparator: true,
                        prefix: watch("paymentType") === "mix" ? "Efectivo $" : "$",
                      }}
                      inputComponent={NumericFormatCustom}
                      startAdornment={
                        <InputAdornment position="start">
                          <FaRegMoneyBillAlt size="1.3em" />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setValue("pCash", "")}
                            size="large">
                            <BackspaceIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {error?.message && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                </>
              )}
            />
          )}

          {(watch("paymentType") === "deposit" || watch("paymentType") === "mix") && (
            <>
              <Controller
                name="pDeposit"
                control={control}
                render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => (
                  <>
                    <FormControl fullWidth variant="standard">
                      <Box paddingBottom={3}>
                        <InputLabel sx={{ fontSize: 16 }} htmlFor="pDeposit">
                          <b>Monto por Deposito</b>
                        </InputLabel>
                      </Box>

                      <Input
                        sx={{ fontSize: 20 }}
                        placeholder="Escriba cantidad de pago!"
                        label="Monto por Deposito"
                        error={!!error}
                        onBlur={onBlur}
                        value={value}
                        name="pDeposit"
                        onChange={onChange}
                        fullWidth
                        inputProps={{
                          thousandSeparator: true,
                          prefix: watch("paymentType") === "mix" ? "Deposito $" : "$",
                        }}
                        inputComponent={NumericFormatCustom}
                        startAdornment={
                          <InputAdornment position="start">
                            <AiFillBank size="1.4em" />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setValue("pDeposit", "")}
                              size="large">
                              <BackspaceIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {error?.message && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                  </>
                )}
              />

              <Box paddingBottom={2}>
                <InputDropdown
                  name="bank"
                  control={control}
                  label="Nombre del Banco"
                  configs={{ variant: "standard" }}
                  options={[
                    { label: "Seleccione a cual banco!", value: "N/A" },
                    {
                      label: "Ademis",
                      value: "ade",
                      img: "ade.png",
                    },
                    {
                      label: "Banco BHD",
                      value: "bhd",
                      img: "bhd.png",
                    },
                    {
                      label: "BanReservas",
                      value: "ban",
                      img: "ban.png",
                    },
                    {
                      label: "Banco Popular",
                      value: "pop",
                      img: "pop.png",
                    },
                    {
                      label: "ScotiaBank",
                      value: "sco",
                      img: "sco.png",
                    },
                  ]}
                />
              </Box>
            </>
          )}
        </Stack>
      </Box>

      {watch("paymentType") !== "mix" && (
        <Box paddingBottom={1}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Button size="small" variant="outlined" onClick={() => handlepAmount(Math.min(loan.cuota, loan.balance))}>
                <small>Pagar Cuota</small>
              </Button>
            </Grid>

            <Grid item xs={5}>
              <Button size="small" variant="outlined" onClick={() => handlepAmount(loan.balance)}>
                <small>Saldar Balance</small>
              </Button>
            </Grid>

            {loan.xCobrarTotal > loan.xCobrarAmount && (
              <Grid item xs={7}>
                <Button size="small" variant="outlined" onClick={() => handlepAmount(loan.xCobrarTotal)}>
                  <small>Pagar Cuota + Atrasos</small>
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      {fullBalanceWarning && (
        <Box paddingBottom={1}>
          <Grow direction="up" in>
            <Alert variant="outlined" severity="info">
              <Typography variant="body1" component="p">
                Desea saldar este prestamo?, si quiere saldar el prestamo trate nuevamente!
              </Typography>
            </Alert>
          </Grow>
        </Box>
      )}

      {duplexPayment && (
        <Box paddingBottom={1}>
          <Grow direction="up" in>
            <Alert variant="outlined" severity="warning">
              <Typography variant="body1" component="p">
                Este prestamo, <b>ya tiene un pago de {printAmount(+loan.pCash + +loan.pDeposit)}</b> aplicado,
                <b> si quiere aplicar le otro pago de {printAmount(+loan.pCash + +loan.pDeposit)}</b> trate nuevamente!
              </Typography>
            </Alert>
          </Grow>
        </Box>
      )}

      {/* <div className="mt-2 alert  font-weight-bold">
        <strong>
          Aplicar Cantidad:{" "}
          {watch("pCash") || watch("pDeposit") ? printAmount(+watch("pCash") + +watch("pDeposit")) : "$0"}
          <p style={{ color: colors.moraAmount }}>
            Monto descontado de Mora .: {watch("pMora") ? printAmount(watch("pMora")) : "$0"}
          </p>
        </strong>
      </div> */}

      {+watch("pMora") > 0 ? (
        <Box paddingBottom={2}>
          <div
            className="alert alert-secondary"
            style={{ marginBottom: 0, paddingTop: "0.50rem", paddingBottom: "0.50rem" }}>
            <strong>
              Monto aplicado al Capital ....:{" "}
              {watch("pCash") || watch("pDeposit")
                ? printAmount(Math.max(+watch("pCash") + +watch("pDeposit") - watch("pMora"), 0))
                : "$0"}
            </strong>
            <br />

            <strong style={{ color: colors.moraAmount }}>
              Monto descontado de Mora .:{" "}
              {+watch("pMora")
                ? printAmount(
                    +watch("pMora") > +watch("pCash") + +watch("pDeposit")
                      ? +watch("pCash") + +watch("pDeposit")
                      : +watch("pMora")
                  )
                : "$0"}
            </strong>
          </div>
        </Box>
      ) : (
        <div className="alert alert-secondary font-weight-bold">
          <strong>
            Cobrar Cantidad:{" "}
            {watch("pCash") || watch("pDeposit") ? printAmount(+watch("pCash") + +watch("pDeposit")) : "$0"}
          </strong>
        </div>
      )}

      {/* <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={1}>
                  Detalle de Pago
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Concepto</TableCell>
                <TableCell align="right">Monto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Cuota Completivo (8)</TableCell>
                <TableCell align="right">$100</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Cuota Pagada (9)</TableCell>
                <TableCell align="right">$100</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Cuota Abono (10)</TableCell>
                <TableCell align="right">$100</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={0} />
                <TableCell colSpan={0} align="right">
                  Atrasos ($600)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={0} />
                <TableCell colSpan={0} align="right">
                  Mora ($100)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell colSpan={0} align="right">
                  Total $1,000
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}

      <Box paddingBottom={2}>
        <Button
          startIcon={<GiTakeMyMoney size="1.2em" />}
          disabled={isSubmitting}
          type="submit"
          fullWidth
          variant={"contained"}>
          Aplicar Pago!
        </Button>
      </Box>

      {/* <Box paddingBottom={1}>
        <InputCheckbox
          name="autoPrintReceipt"
          control={control}
          label="Imprimir recibo para este Pago!"
          configs={{}}
          options={[
            { label: "Si", value: 1 },
            { label: "No", value: 0 },
          ]}
        />
      </Box> */}

      <DevTool control={control} />
    </form>
  );
};

export default RenderPaymentForm;
