import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";

const RenderGreenCheckbox = ({ user, route, handleToggleChange }) => {
  const [isLoading, setLoading] = useState(false);
  const handleClick = async (user, route) => {
    setLoading(true);
    await handleToggleChange(user, route);
    setLoading(false);
  };
  return (
    <>
      {isLoading ? (
        <CircularProgress size="1.5rem" />
      ) : (
        <>
          <Checkbox
            color="default"
            size="medium"
            checked={user.is_active === 1 ? true : false}
            onChange={() => handleClick(user, route)}
            name="checkedG"
          />
        </>
      )}
    </>
  );
};

export default RenderGreenCheckbox;
