import { Table, Thead, Tbody, Tr, Th, Td } from "@nodes/react-super-responsive-table";
// import Button from "@nodes/@mui/material/Button";
import Typography from "@nodes/@mui/material/Typography";
import Box from "@nodes/@mui/material/Box";
import Stack from "@nodes/@mui/material/Stack";
// import Grid from "@nodes/@mui/material/Grid";
import { printDate, newDate, printAbsAmount } from "../functions";
// import HistoryPaymentMenu from "./HistoryPaymentMenu";
import { Link } from "@nodes/react-router-dom";
import "@nodes/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { colors } from "@/config";

export default function RenderLoanPayment({ payments = [] }) {
  if (payments?.length === 0)
    return (
      <div className="container mt-4 mb-4">
        <Typography variant="h6">No hay historial de pagos!</Typography>
      </div>
    );

  const paymentsTotal = payments.reduce((acc, obj) => {
    return obj.deletedBy === 0 ? acc + +obj.pAmount : acc;
  }, 0);

  const morasTotal = payments.reduce((acc, obj) => {
    return obj.deletedBy === 0 ? acc + +obj.pMora : acc;
  }, 0);

  const interestTotal = payments.reduce((acc, obj) => {
    return obj.deletedBy === 0 ? acc + +obj.pInterest : acc;
  }, 0);

  return (
    <>
      <Typography variant="h5" gutterBottom className="text-mora">
        Historial de Pagos!
      </Typography>

      <Table>
        <Thead>
          <Tr className="font-weight-bold">
            <Th>Fecha</Th>
            <Th>Cobrado</Th>
            <Th>Balance</Th>
            {/* <Th>Atraso(s)</Th> */}
            <Th>Estado</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {payments.map((payment, index) => (
            <Tr key={index} className="mb-1">
              <Td>
                #{index + 1} | {printDate(newDate(payment.payment_date))}
              </Td>
              <Td>
                {printAbsAmount(payment.pAmount)} (
                <span style={{ color: payment.pMora > 0 ? colors.moraAmount : "" }}>
                  {printAbsAmount(payment.pMora)} Mora
                </span>
                )
              </Td>
              <Td>
                {printAbsAmount(payment.balance)} ({payment.completed} / {payment.npayments})
              </Td>
              {/* <Td className={payment.atrasos > 0 ? "text-danger" : ""}>
                {printAbsAmount(payment.atrasos)} ({Math.ceil(payment.atrasos / payment.cuota)} Cuotas)
              </Td> */}
              <Td>
                {+payment.atrasos === 0 && <span className="text-success">Al Dia</span>}
                {+payment.atrasos > 0 && (
                  <span className="text-warning">{printAbsAmount(payment.atrasos)} atrasos</span>
                )}
                {+payment.atrasos < 0 && <span className="text-info">{printAbsAmount(payment.atrasos)} Adelanto</span>}
              </Td>

              <Td>
                <Link to={`/payment/receipt/${payment.payment_id}/${payment.money_id}/${payment.debtor_id}`}>
                  <u>Ver Recibo!</u>
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Box component="div" paddingTop={3} p={1}>
        <Typography variant="h6" component="p">
          Total dinero cobrado:
        </Typography>

        <Stack alignItems="center" direction="row" spacing={{ xs: 4, sm: 6, md: 8 }} useFlexGap>
          <Typography variant="body1" component="p" sx={{ color: colors.capitalAmount }}>
            <b>Capital {printAbsAmount(paymentsTotal)}</b>
          </Typography>
          <Typography variant="body1" component="p" sx={{ color: colors.interestAmount }}>
            <b>Inter&eacute;s {printAbsAmount(interestTotal)}</b>
          </Typography>
          <Typography variant="body1" component="p" sx={{ color: colors.moraAmount }}>
            <b>Mora {printAbsAmount(morasTotal)}</b>
          </Typography>
        </Stack>
      </Box>
    </>
  );
}
