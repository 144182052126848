import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FloatingButton from "@shared/components/FloatingButton";
import { Form, RenderInput } from "@shared/components/Form";
import { number, string, object } from "yup";
import AddIcon from "@mui/icons-material/Add";
import { getUniqueID } from "@shared/functions";

const CreateSpense = ({ handleCreateGasto, creditor_id, date, user_id }) => {
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(getUniqueID(`Gasto${creditor_id}`));

  const initialValues = {
    id,
    offline_id: id,
    user_id,
    creditor_id,
    date,
    description: "",
    amount: "",
  };

  const validation = object({
    description: string()
      .required("Descripcion es requerida!")
      .min(3, "Descripcion tiene que ser al menos 3 letras!")
      .max(25, "Descripcion tiene que ser menos de 25 letras!"),
    amount: number()
      .required("Cantidad es requerida!")
      .min(1, "Cantidad es muy corta!")
      .max(999999, "Cantidad es muy alta!"),
  });

  const button = { label: "Registrar Gasto", color: "primary", variant: "contained" };

  const submithandler = async (data) => {
    handleCreateGasto(data);
    setId(getUniqueID(`Gasto${user_id}`));
  };

  return (
    <>
      <span className="text-info" onClick={() => setShowModal(!showModal.show)}>
        <FloatingButton icon={<AddIcon />} tooltip="Crear Gasto" goto="" />
      </span>

      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography variant="h5" className="text-success">
              Nuevo Gasto
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-4">
            <Typography variant="body1" paragraph>
              Escriba una descripcion y la cantidad del gasto.
            </Typography>
            <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
              <Box paddingBottom={3}>{RenderInput("description", "Nota", "text", true, "outlined")}</Box>
              <Box paddingBottom={3}>{RenderInput("amount", "Cantidad", "number", false, "outlined")}</Box>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateSpense;
