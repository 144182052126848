import { useState, useContext } from "react";
import { Context } from "../context/DataContext";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import AccordionActions from "@mui/material/AccordionActions";
import Typography from "@mui/material/Typography";
import AccessCodeComponent from "@shared/components/AccessCodeComponent";
import SubmitButton from "@shared/components/SubmitButton";
import { BiRefresh } from "react-icons/bi";
import Grid from "@mui/material/Grid";
import Loading from "@shared/components/Loading";
import { UseRouteAccessCodes, UseRoute } from "@shared/server/fetcher";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { generateAccessCodes } from "@shared/api";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const { state } = useContext(Context);
  const [expanded, setExpanded] = useState("panel1");
  const { route, user } = state;

  const { data: accessCodes, isLoading, mutate } = UseRouteAccessCodes(route.user_id);
  const { data: routeN } = UseRoute(route.creditor_id, user.user_id);

  if (isLoading || !routeN) return <Loading />;

  const handleGenerate = async (type) => {
    const codes = [];
    codes[0] = Math.floor(100000 + Math.random() * 900000);

    await generateAccessCodes({ owner_id: route.user_id, type, codes });
    mutate([...accessCodes, { code: codes[0], type }]);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Card className="p-2 m-2">
        <Typography variant="h5" gutterBottom>
          Codigos de Acesos
        </Typography>
        <div className="m-2" id="user-panel" style={{ display: "inline-flex" }}>
          <span className="pr-1">
            <b>
              <Chip label={`R${routeN.creditor_id}`} />
            </b>
          </span>

          <Typography variant="subtitle1" component="p">
            {routeN.route_name} ({routeN.location})
          </Typography>
        </div>
      </Card>

      <Accordion square expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Borrar Pagos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "deletePayment")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            color="primary"
            callBack={() => handleGenerate("deletePayment")}
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>
      <Accordion square expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Cancelar Entrega</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "deleteNewLoan")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            color="primary"
            callBack={() => handleGenerate("deleteNewLoan")}
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>
      <Accordion square expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Modificar Entrega</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "modifyNewLoan")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            callBack={() => handleGenerate("modifyNewLoan")}
            color="primary"
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>
      <Accordion square expanded={expanded === "panel22"} onChange={handleChange("panel22")}>
        <AccordionSummary aria-controls="panel22d-content" id="panel22d-header">
          <Typography>Aprobar Pr&eacute;stamo</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "loanApproval")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            callBack={() => handleGenerate("loanApproval")}
            color="primary"
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>

      <Accordion square expanded={expanded === "panel221"} onChange={handleChange("panel221")}>
        <AccordionSummary aria-controls="panel221d-content" id="panel22d-header">
          <Typography>Desbloquear Cuadre</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "unLockCuadre")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            callBack={() => handleGenerate("unLockCuadre")}
            color="primary"
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>
    </div>
  );
}
