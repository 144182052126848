import Typography from "@nodes/@mui/material/Typography";
import { phoneNumber, emailAddress } from "../constants";
import { formatPhoneNumber, concatPhoneLink } from "../functions";

const RenderContactInfo = () => {
  return (
    <div>
      <Typography variant="h6" component="h3">
        Informaccion de Contacto
      </Typography>
      <Typography variant="subtitle1" component="">
        Nombre: <b>Wilson Benavides</b>
      </Typography>
      <Typography variant="subtitle1" component="">
        Correo: <b>{emailAddress}</b>
      </Typography>
      <Typography variant="subtitle1" component="p">
        Celular:
        <a style={{ color: "#000" }} href={concatPhoneLink(phoneNumber)}>
          <b> {formatPhoneNumber(phoneNumber)}</b>
        </a>
      </Typography>
      <Typography variant="subtitle1" component="p">
        WhatsApp:
        <a style={{ color: "#000" }} href={`https://wa.me/+1${phoneNumber}`}>
          <b>{formatPhoneNumber(phoneNumber)}</b>
        </a>
      </Typography>
    </div>
  );
};

export default RenderContactInfo;
