import { useState, useContext } from "react";
import { Context } from "../context/DataContext";
import { Navigate, useParams } from "react-router-dom";
import Loading from "@shared/components/Loading";
import { UseDebtorLoansCheck, UseDebtorCedulaData, UseDebtorCedulaImg } from "@shared/server/fetcher";
import { printAmount, getLoansBreakDown } from "@shared/functions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Alert from "@nodes/@mui/material/Alert";
import Modal from "react-bootstrap/Modal";
import { NumericFormat } from "react-number-format";
// import Grow from "@mui/material/Grow";
// import NoLoanFound from "@shared/components/NoLoanFound";
// import NoInternetCreditCheck from "@shared/components/NoInternetCreditCheck";
// import { mainApp } from "@shared/@shared/config";
import RenderLoanDetailCredit from "@shared/components/RenderLoanDetailCredit";
import RenderRouteDetailCredit from "@shared/components/RenderRouteDetailCredit";
import RenderCreditCheckLoans from "@shared/components/RenderCreditCheckLoans";
import RenderCedulaImage from "@shared/components/RenderCedulaImage";
import Button from "@mui/material/Button";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
import { useNavigate } from "@nodes/react-router-dom";

const DebtorLoansCheck = () => {
  const { state } = useContext(Context);
  const { route } = state;
  const navigate = useNavigate();
  // const newDebtors = useSelector(selectNewDebtors);

  // const [currentDebtor, setCurrentDebtor] = useState({});
  // const [cedula, setCedula] = useState(null);
  // const [isOnline, setNetwork] = useState(null);
  const [modalData, setModalData] = useState({});
  // const [cedulaError, setCedulaError] = useState(false);
  const { ced: cedula, dUn: dUniqueId, mId: money_id } = useParams();

  // const { data: debtors, refetch } = useGetDebtorsQuery({
  //   creditor_id: common.creditor_id,
  //   user_id: common.user_id,
  //   collect_date: common.collect_date,
  // });

  const { data: dbLoans, failed } = UseDebtorLoansCheck(cedula, route.creditor_id);
  const { data: cedulaData } = UseDebtorCedulaData(cedula);
  const { data: cedulaImage, isLoading: cedulaLoading } = UseDebtorCedulaImg(cedula, cedulaData?.timestamp);

  // const controller = new AbortController();

  // useInterval(async () => {
  //   fetch("//google.com", {
  //     mode: "no-cors",
  //     signal: controller.signal,
  //   })
  //     .then(() => {
  //       setNetwork(true);
  //       return clearInterval(webPing);
  //     })
  //     .catch(() => setNetwork(false));
  // }, 5000);

  // useEffect(() => {
  //   if (!debtors) return;
  //   const debtor = [...debtors, ...newDebtors].filter((x) => x.dUniqueId === dUniqueId)[0];
  //   setCurrentDebtor(debtor);

  //   if (!debtor?.cedula || replaceNonDigits(debtor?.cedula) < 1) {
  //     setCedulaError(true);
  //   } else {
  //     setCedula(debtor.cedula);
  //     setCedulaError(false);
  //   }

  //   return () => {
  //     controller.abort();
  //   };

  //   //eslint-disable-next-line
  // }, [debtors]);

  const loansBreakDown = getLoansBreakDown(dbLoans);

  const RenderContinueButton = ({ title }) => {
    return (
      <Box p={1}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={
            money_id
              ? () => navigate(`/loan/renewal/${dUniqueId}/${money_id}`, { replace: true })
              : dUniqueId === "newdebtor"
              ? () => navigate(`/debtor/create/${cedula}`, { replace: true })
              : () => navigate(`/loan/create/${dUniqueId}`, { replace: true })
          }>
          {title}
        </Button>
      </Box>
    );
  };

  // if (cedulaError)
  //   return (
  //     <Card>
  //       <CardContent>
  //         <CardHeader
  //           action={<LoanDetailMenuCredit loan={currentDebtor}  />}
  //           title={
  //             <Typography variant="body1" component="p" className="font-weight-bold">
  //               <PersonIcon /> {currentDebtor?.name} {currentDebtor?.nickname && `(${currentDebtor?.nickname})`}
  //             </Typography>
  //           }
  //         />

  //         <CedulaWarning debtor={currentDebtor} updateDebtorCedula={updateDebtorCedula} refetch={refetch} />
  //       </CardContent>
  //       <Divider />
  //       <Divider />
  //       <Box component="div" p={1}>
  //         <RenderContinueButton title="Continuar sin depurificar!" />
  //       </Box>
  //     </Card>
  //   );

  // if (isOnline === null)
  if (!dbLoans)
    return (
      <>
        <Loading />
        <Box component="div" p={2}>
          <Typography variant="body1" paragraph>
            Si esta pagina dura demaciado en cargar puede hacer clic en el siguiente boton!
          </Typography>
          <RenderContinueButton title="Continuar sin depurificar!" />
        </Box>
      </>
    );

  // if (isOnline === false && !dbLoans)
  //   return (
  //     <>
  //       <NoInternetCreditCheck />
  //       <RenderContinueButton title="Continuar sin Internet!" />
  //     </>
  //   );

  return failed ? (
    <Box component="div" p={1}>
      <Alert variant="outlined" severity="error">
        <Typography variant="h5" component="p" gutterBottom>
          Error!
        </Typography>

        <Typography variant="body1" component="p" paragraph>
          Hubo error hay intentar cargar los datos de esta pagina!
        </Typography>
      </Alert>
    </Box>
  ) : dbLoans?.length === 0 ? (
    <Navigate
      to={
        money_id
          ? `/loan/renewal/${dUniqueId}/${money_id}`
          : dUniqueId === "newdebtor"
          ? `/debtor/create/${cedula}`
          : `/loan/create/${dUniqueId}`
      }
    />
  ) : (
    // money_id ? () => history.replace(`/loan/renewal/${dUniqueId}/${money_id}`) :
    // dUniqueId === "newdebtor"  ? () => history.replace(`/debtor/create/${cedula}`):
    // () => history.replace(`/loan/create/${dUniqueId}`)

    <>
      {/* <CardHeader
        action={<LoanDetailMenuCredit loan={currentDebtor}  />}
        title={
          <Typography variant="body1" component="p" className="font-weight-bold">
            <PersonIcon /> {currentDebtor.name} {currentDebtor.nickname && `(${currentDebtor.nickname})`}
          </Typography>
        }
      /> */}

      <div className="container mt-3">
        <Typography variant="body1" component="p" gutterBottom>
          <b>{cedulaData?.name}</b>
        </Typography>
      </div>

      <RenderCedulaImage cedula={cedulaImage} loading={cedulaLoading && cedulaData ? true : false} />

      <div className="container">
        <Typography variant="h6" component="p" gutterBottom align="center">
          <b>{<NumericFormat value={cedula} displayType={"text"} format="###-#######-#" />}</b>
        </Typography>

        {/* <ul className="list-group mb-3">
          <li className="list-group-item">
            <Typography variant="body1" component="p">
              Nombre: {cedulaImage.name}
            </Typography>
          </li>

        <li className="list-group-item">
            <Typography variant="body1" component="p">
              Numero de Telefono: {cedulaImage.phone}
            </Typography>
          </li>
          <li className="list-group-item">
            <Typography variant="body1" component="p">
              Dir de Casa: {cedulaImage.address}
            </Typography>
          </li>
          <li className="list-group-item">
            <Typography variant="body1" component="p">
              Dir de Trabajo: {cedulaImage.occupation}
            </Typography>
          </li> 
        </ul> */}

        <Typography variant="subtitle1" componet="p" paragraph>
          La cedula este cliente esta vincula a mas clientes con pr&eacute;stamos.
        </Typography>

        <Grid container>
          <Grid item xs={8}>
            <Typography variant="subtitle1" component="p">
              Deuda Total:
            </Typography>
            <Typography variant="h5" component="h3" className="font-weight-bold">
              {printAmount(loansBreakDown.balanceTotal || 0)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" component="p">
              Cuota Total:
            </Typography>
            <Typography variant="h5" component="h3" className="font-weight-bold">
              {printAmount(loansBreakDown.wPaymentTotal || 0)}
            </Typography>
          </Grid>
        </Grid>

        <Divider />
        <Divider />
        <br />

        <RenderCreditCheckLoans loansBreakDown={loansBreakDown} setModalData={setModalData} />

        <Typography variant="body1" component="p" paragraph>
          Solo pr&eacute;stamos registrado en WPrestamos son mostrado en esta pagina!
        </Typography>

        <Box component="div" p={1} className="mb-4">
          <RenderContinueButton title="Continuar!" />
        </Box>

        <Modal
          show={modalData.name ? true : false}
          onHide={() => setModalData({})}
          backdrop="static"
          keyboard={false}
          centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <Typography variant="h6">
                {modalData.name}
                {/* <b className="p-2">
                    <Chip label={`C${modalData.debtor_id}`} />
                  </b> */}
              </Typography>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Box component="div" p={1}>
              <DisplayLoanStatus loan={modalData} />
            </Box>

            <Box component="div" p={1}>
              <RenderLoanDetailCredit loan={modalData} />
            </Box>

            {((modalData.current_week > modalData.npayments && modalData.balance > 0) ||
              modalData.user_id === route.owner_id) && (
              <Box component="div" p={1}>
                <RenderRouteDetailCredit loan={modalData} />
              </Box>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => setModalData({})}>
              Cerrar
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default DebtorLoansCheck;
