import { useContext } from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Context } from "../context/DataContext";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
// import RenderPaymentForm from "@shared/components/RenderPaymentForm";
// import RenderPaymentDepositForm from "@shared/components/RenderPaymentDepositForm";
import RenderPaymentForm from "@shared/components/RenderPaymentForm";
import RenderPaymentInfo from "@shared/components/RenderPaymentInfo";
import DisplayLastPaymentAlert from "@shared/components/DisplayLastPaymentAlert";
import RenderDisabledCustomer from "@shared/components/RenderDisabledCustomer";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import RenderAPerutaCountDebugging from "../components/RenderAPerutaCountDebugging";
import Loading from "@shared/components/Loading";
import {
  UseLoanv2,
  UseLoansV2,
  UseRoute,
  UseOwnerConfig,
  UseCustomerBalance,
  UseAPerturaCuadre,
  UseBankInformationv2,
  UseLoanAperturaDebug,
} from "@shared/server/fetcher";
import { paymentCreate } from "@shared/api/payment";
import { useSWRConfig } from "swr";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useConfirm } from "@nodes/material-ui-confirm";

const PaymentCreate = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { mutate: globalMutate } = useSWRConfig();
  const { state } = useContext(Context);
  const { id: money_id, id2: debtor_id } = useParams();
  const { route, user } = state;

  const { enqueueSnackbar } = useSnackbar();

  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);
  const { data: apertura, isLoading: aLoading } = UseAPerturaCuadre(route.creditor_id);
  const { data: ownerConfig, isLoading: routeLoadingConfig } = UseOwnerConfig(route.user_id);
  const { data: customer } = UseCustomerBalance(routeN?.user_id);
  const { data: bankInformation } = UseBankInformationv2(routeN?.user_id);

  const { data: loans, mutate: loansMutate, isLoading: isLoadingL } = UseLoansV2(route.creditor_id, user.user_id);
  const filteredLoan = loans?.find((x) => +x.money_id === +money_id);
  const shouldFetch = !isLoadingL && !filteredLoan ? true : false;
  const { data: currentLoan2, isLoading: isLoadingS } = UseLoanv2(route.creditor_id, money_id, debtor_id, shouldFetch);
  const currentLoan = filteredLoan ?? currentLoan2;

  const { data: loanApertura } = UseLoanAperturaDebug(currentLoan?.type, currentLoan?.start_date, money_id);

  if (isLoadingL || aLoading || (shouldFetch && isLoadingS) || routeLoading || routeLoadingConfig) return <Loading />;
  if (customer?.isDisabled && bankInformation?.BANK_OWNER_NAME)
    return <RenderDisabledCustomer bankInformation={bankInformation} />;

  const habdlePaymentCreate = async (data) => {
    try {
      const appendData = {
        money_id: currentLoan.money_id,
        mUniqueId: currentLoan.mUniqueId,
        debtor_id: currentLoan.debtor_id,
        user_id: currentLoan.user_id,
        creditor_id: currentLoan.creditor_id,
        loan_type: currentLoan.type,
        start_date: currentLoan.start_date,
        loan_variant: 1,
      };

      const payment_id = await paymentCreate({ ...data, ...appendData });

      if (payment_id) {
        enqueueSnackbar("Pago aplicado con exito!", { variant: "success" });
        loansMutate();
        globalMutate(`/loan/paymentsv2/${money_id}/${debtor_id}`);
        navigate(`/payment/receipt/${payment_id}/${money_id}/${debtor_id}`, { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = async (data) => {
    confirm({ description: "Esta seguro de querer aplicar este pago!", title: "Aplicar Pago?" })
      .then(() => {
        habdlePaymentCreate(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  // const defaultValues = {
  //   sleepDelay: +currentLoan.pCash !== 0 ? 5 : undefined,
  //   money_id: currentLoan.money_id,
  //   mUniqueId: currentLoan.mUniqueId,
  //   debtor_id: currentLoan.debtor_id,
  //   user_id: currentLoan.user_id,
  //   creditor_id: currentLoan.creditor_id,
  //   payment_date: today_date.payment_date,
  //   loan_type: currentLoan.type,
  //   start_date: currentLoan.start_date,
  //   pCash,
  //   pMora,
  //   loan_variant: 1,
  //   pInterest: 0,
  // };

  // console.log({ mora: currentLoan.balMora, cuota: currentLoan.cuota, xCobrarTotal: currentLoan.xCobrarTotal });

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentLoan}>
          <LoanDetailMenu loan={currentLoan} />
        </DebtorNameHeader>

        <DisplayLastPaymentAlert loan={currentLoan} />

        {/* {currentLoan.balance > 0 && ( */}
        <Box component="div" p={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <RenderAPerutaCountDebugging loan={currentLoan} apertura={loanApertura} />
            <DisplayLoanStatus loan={currentLoan} />
          </Stack>
        </Box>
        {/* )} */}

        <Box component="div" p={1}>
          <RenderPaymentInfo loan={currentLoan} />
        </Box>

        <Box component="div" p={1}>
          <RenderPaymentForm
            defaultValues={{
              paymentType: "cash",
              payment_date: apertura.today_date,
              pDeposit: "",
              bank: "N/A",
              pCash: "",
              pInterest: "",
              pMora: currentLoan.balMora,
              autoPrintReceipt: false,
            }}
            loan={currentLoan}
            configs={{ ...ownerConfig }}
            submithandler={handleClick}
          />
        </Box>

        {/* <Box component="div" p={1}>
          <RenderPaymentDepositForm
            showPaymentDate
            config={{ ...ownerConfig, autoPrintPayment: "0", moraPercent: routeN.mora, moraWeeks: routeN.mWeeks }}
            loan={{
              ...currentLoan,
              user_id: user.user_id,
              payment_date: apertura.today_date,
              // day: route.day,
              creditor_id: route.creditor_id,
            }}
            createPayment={habdlePaymentCreate}
          />
        </Box> */}

        {/* <Box component="div" p={1}>
          <RenderPaymentForm
            showPaymentDate
            config={{ ...ownerConfig, autoPrintPayment: "0", moraPercent: routeN.mora, moraWeeks: routeN.mWeeks }}
            loan={{
              ...currentLoan,
              user_id: user.user_id,
              collect_date: routeN.current_week_date,
              payment_date: route.today_date,
              creditor_id: route.creditor_id,
            }}
            createPayment={createPaymentHandlder}
          />
        </Box> */}
      </Container>
    </div>
  );
};

export default PaymentCreate;
