import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RenderDebtorForm from "@shared/components/RenderDebtorForm";
import { Context } from "../context/DataContext";
import Loading from "@shared/components/Loading";
import { UseRoute, UseOwnerConfig, UseDebtors, UseCustomerBalance, UseBankInformationv2 } from "@shared/server/fetcher";
import RenderDisabledCustomer from "@shared/components/RenderDisabledCustomer";
import { createDebtor } from "@shared/api/debtor";
import { useSnackbar } from "notistack";
// import { useSWRConfig } from "swr";

const DebtorCreate = () => {
  const navigate = useNavigate();
  const { isAtraso } = useParams();
  // const { mutate } = useSWRConfig();
  const { state } = useContext(Context);
  const { route, user, misc } = state;

  const { enqueueSnackbar } = useSnackbar();

  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);
  const { data: customer } = UseCustomerBalance(routeN?.user_id);
  const { data: bankInformation } = UseBankInformationv2(routeN?.user_id);
  const { data: routeConfig, isLoading: routeLoadingConfig } = UseOwnerConfig(route?.user_id);
  const { mutate } = UseDebtors(route.creditor_id, user.user_id);

  if (routeLoading || routeLoadingConfig) return <Loading />;

  const defaultValues = {
    user_id: user.user_id,
    owner_id: routeN.user_id,
    creditor_id: route.creditor_id,
    name: "Juana Mendez Gonzalez",
    email: "wilson_382@hotmail.com",
    phone: "3478127103",
    phone2: "2019259669",
    nickname: "CEO",
    birthdate: "",
    day: "24",
    year: "1991",
    month: "02",
    address: "1810 Brucker Blvd",
    work_address: "Truck Driver differen area",
    next_to: "Regional",
    cosigner: "No body",
    idType: 1,
    idNumber: "123456789012",
    note: "Here goes a long note.",
    occupation: "Truck Driver",
    // zone_id: misc.id,
    uploadImage: false,
  };

  const button = { label: "Registrar Cliente", variant: "contained" };

  if (customer?.isDisabled && bankInformation?.BANK_OWNER_NAME)
    return <RenderDisabledCustomer bankInformation={bankInformation} />;

  const submithandler = async (data) => {
    try {
      const res = await createDebtor(data);

      enqueueSnackbar("Cliente registrado con exito!", { variant: "success" });

      if (isAtraso) {
        navigate(`/loan/old/create/${res.dUniqueId}`, { replace: true });
      } else {
        navigate(`/loan/create/${res.dUniqueId}`, { replace: true });
      }
      await mutate();
    } catch (error) {}
  };

  return (
    <div className="container mb-5 mt-3">
      <RenderDebtorForm
        title="Registrar nuevo Cliente!"
        defaultValues={defaultValues}
        submithandler={submithandler}
        button={button}
        uploadImage
        cedula={false}
      />
    </div>
  );
};

export default DebtorCreate;
