import { Routes, Route, Navigate } from "react-router-dom";
import Index from "./pages/Index";
import Debtors from "./pages/Debtors";
import Loans from "./pages/Loans";
import DebtorCreate from "./pages/DebtorCreate";
import Profile from "./pages/Profile";
import DebtorEdit from "./pages/DebtorEdit";
import DebtorDelete from "./pages/DebtorDelete";
import LoanCreate from "./pages/LoanCreate";
import LoanCreateAtrasos from "./pages/LoanCreateAtrasos";
import NewUserContact from "./pages/NewUserContact";
import LoanRenewal from "./pages/LoanRenewal";
import LoanSignature from "./pages/LoanSignature";
import PaymentCreate from "./pages/PaymentCreate";
import DeletePayment from "./pages/DeletePayment";
import DeleteLoan from "./pages/DeleteLoan";
import PaymentReceipt from "./pages/PaymentReceipt";
import DeleteRoute from "./pages/DeleteRoute";
import DeleteEmployee from "./pages/DeleteEmployee";
import ModifyLoan from "./pages/ModifyLoan";
import Login from "./pages/Login";
import LoanDetail from "./pages/LoanDetail";
import Spenses from "./pages/Spenses";
import Contact from "./pages/Contact";
import PaymentDetails from "./pages/PaymentDetails";
import DebtorsOrganize from "./pages/DebtorsOrganize";
import DebtorProfile from "./pages/DebtorProfile";
import DebtorConfig from "./pages/DebtorConfig";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./pages/Dashboard";
import Cuadre from "./pages/Cuadre";
import Settings from "./pages/Settings";
import AccessCodes from "./pages/AccessCodes";
import Reports from "./pages/Reports";
import UserPhoneVerify from "./pages/UserPhoneVerify";
import Invoice from "./pages/Invoice";
import EmployeeProfile from "./pages/EmployeeProfile";
import RouteCreate from "./pages/RouteCreate";
// import SignUp from "./pages/SignUp";
import Register from "./pages/signup/Register";
import SignupUsername from "./pages/signup/UsernamePw";
import UploadImage from "./pages/UploadImage";
import RouteWarning from "./pages/RouteWarning";
import Employees from "./pages/Employees";
import UsersLinks from "./pages/UsersLinks";
import UserLinkConfigs from "./pages/UserLinkConfigs";
import EmployeeCreate from "./pages/EmployeeCreate";
import RoleCreate from "./pages/RoleCreate";
import Approval from "./pages/Approval";
import Collected from "./pages/Collected";
import NewLoans from "./pages/NewLoans";
import PasswordReset from "./pages/PasswordReset";
import DebtorImages from "./pages/DebtorImages";
import Caja from "./pages/Caja";
import RedirectPage from "./pages/RedirectPage";
import ChooseRoute from "./pages/ChooseRoute";
import CobroList from "./pages/CobroList";
import LoanContract from "./pages/LoanContract";
import CobroChanges from "./pages/CobroChanges";
import DiagnosticInsert from "./pages/DiagnosticInsert";
import DebtorPaidLoans from "./pages/DebtorPaidLoans";
import MoveDebtor from "./pages/MoveDebtor";
import LoanPayments from "./pages/LoanPayments";
import DebtorLoansCheck from "./pages/DebtorLoansCheck";
import DataCreditoSearch from "./pages/DataCreditoSearch";
import SecurityQuestions from "./pages/SecurityQuestions";
import SecurityVerify from "./pages/SecurityVerify";
import UserPersonal from "./pages/UserPersonal";
import UserUsername from "./pages/UserUsername";
import Referrals from "./pages/Referrals";
import UserLookup from "./pages/UserLookup";
import UserPassword from "./pages/UserPassword";
import PasswordResetMethod from "./pages/PasswordResetMethod";
import PasswordPhoneVerify from "./pages/PasswordPhoneVerify";
// import SharedRoutes from "@shared/SharedRoutes";
import NotFound from "@shared/pages/NotFound";
import CustomerBalance from "@shared/pages/CustomerBalance";
import QuickPrinter from "@shared/pages/QuickPrinter";

const RoutesDefinication = () => {
  return (
    <>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/referrals" element={<Referrals />} />
          <Route path="/user/personal/:id/:t?" element={<UserPersonal />} />
          <Route path="/user/username/:id/:t?" element={<UserUsername />} />
          <Route path="/user/password/:id/:t?" element={<UserPassword />} />
          <Route path="/employee/delete/:id/:id2" element={<DeleteEmployee />} />
          <Route path="/employee/:id" element={<EmployeeProfile />} />
          <Route path="/debtor/create/:isAtraso?" element={<DebtorCreate />} />
          <Route path="/loan/payments/:id/:id2" element={<LoanPayments />} />
          <Route path="/user/phone/verify" element={<UserPhoneVerify />} />
          <Route path="/debtor/loans/check/:dUn/:ced?/:mId?" element={<DebtorLoansCheck />} />
          <Route path="/data/credito" element={<DataCreditoSearch />} />
          <Route path="/debtor/edit/:id" element={<DebtorEdit />} />
          <Route path="/debtor/delete/:id" element={<DebtorDelete />} />
          <Route path="/debtor/paid/:id" element={<DebtorPaidLoans />} />
          <Route path="/debtor/move/:id" element={<MoveDebtor />} />
          <Route path="/loan/create/:id" element={<LoanCreate />} />
          <Route path="/view/loan/signature/:id/:id2/:id3" element={<LoanContract />} />
          <Route path="/loan/old/create/:id" element={<LoanCreateAtrasos />} />
          <Route path="/loan/renewal/:id/:id2" element={<LoanRenewal />} />
          <Route path="/debtor/upload/:id/:page?" element={<UploadImage />} />
          <Route path="/security/questions" element={<SecurityQuestions />} />
          <Route path="/debtor/view/images/:id/:id2" element={<DebtorImages />} />
          <Route path="/xcobrar/list" element={<CobroList />} />
          <Route path="/xcobrar/changes/:date?" element={<CobroChanges />} />
          <Route path="/insert/dianostics" element={<DiagnosticInsert />} />
          <Route path="/loan/signature" element={<LoanSignature />} />
          {/* <Route path="/invoice/:id/:id2" element={<Invoice   />} /> */}
          <Route path="/loan/detail/:id/:id2" element={<LoanDetail />} />
          <Route path="/loan/delete/:id/:id2" element={<DeleteLoan />} />
          <Route path="/data/delete/:id/:id2/:type" element={<DeleteRoute />} />
          <Route path="/redirect/page/:dUn/:mId/:ced?" element={<RedirectPage />} />
          <Route path="/loan/modify/:id/:id2" element={<ModifyLoan />} />
          <Route path="/debtor/profile/:id" element={<DebtorProfile />} />
          <Route path="/debtor/config/:id" element={<DebtorConfig />} />
          <Route path="/payment/create/:id/:id2" element={<PaymentCreate />} />
          <Route path="/payment/details/:id/:id2/:id3" element={<PaymentDetails />} />
          <Route path="/access/codes" element={<AccessCodes />} />
          <Route path="/payment/receipt/:id/:id2/:id3" element={<PaymentReceipt />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/route/create" element={<RouteCreate />} />
          <Route path="/spenses" element={<Spenses />} />
          <Route path="/loans" element={<Loans />} />
          <Route path="/debtors/organize" element={<DebtorsOrganize />} />
          <Route path="/debtors" element={<Debtors />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/UsersLinks" element={<UsersLinks />} />
          <Route path="/UserLinkConfigs/:id" element={<UserLinkConfigs />} />
          <Route path="/user/create" element={<EmployeeCreate />} />
          <Route path="/role/create/:id?" element={<RoleCreate />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/caja" element={<Caja />} />
          <Route path="/cuadre" element={<Cuadre />} />
          <Route path="/collected" element={<Collected />} />
          <Route path="/entregas" element={<NewLoans />} />
          <Route path="/delete/payment/:id/:id2/:id3" element={<DeletePayment />} />
          <Route path="/approval" element={<Approval />} />
          <Route path="/routes/choose" element={<ChooseRoute />} />
        </Route>

        <Route path="/contact" element={<Contact />} />
        <Route path="/new/user" element={<NewUserContact />} />
        <Route path="/user/lookup" element={<UserLookup />} />
        <Route path="/password/reset/:id_key/:token" element={<PasswordReset />} />
        <Route path="/password/resetmethod" element={<PasswordResetMethod />} />
        <Route path="/password/phone/verify" element={<PasswordPhoneVerify />} />
        <Route path="/invoice/:id/:id2" element={<Invoice />} />
        <Route path="/route/pending" element={<RouteWarning />} />
        <Route path="/security/verify" element={<SecurityVerify />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register/:invitationToken?" element={<Register />} />
        <Route path="/SignupUsername" element={<SignupUsername />} />
        {/* <Route path="/signup/:invitationToken?" element={<SignUp />} /> */}
        <Route path="/" element={<Index />} />

        {/* Shared routes */}
        <Route path="/quickprinter" element={<QuickPrinter />} />
        <Route path="/customer/balance/:id/:id2?" element={<CustomerBalance />} />
        <Route path="/notfound" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/notfound" />} />
      </Routes>
      {/* <SharedRoutes /> */}
    </>
  );
};

export default RoutesDefinication;
