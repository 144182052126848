import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Context } from "../context/DataContext";
import Button from "@mui/material/Button";
import Alert from "@nodes/@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Modal from "@nodes/react-bootstrap/Modal";
import Grow from "@mui/material/Grow";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import { printReceiptHandler } from "@shared/functions/print";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
// import PaymentNotFound from "../components/PaymentNotFound";
import RenderBackButton from "@shared/components/RenderBackButton";
import RenderPaymentDetail from "@shared/components/RenderPaymentDetail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PrintIcon from "@mui/icons-material/Print";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import parse from "html-react-parser";
import Loading from "@shared/components/Loading";
import {
  UseLoanv2,
  UseLoansV2,
  UseRoute,
  UseLoanPaymentsv2,
  UseUserConfig,
  UseOwnerConfig,
} from "@shared/server/fetcher";
import { IoMdInformationCircleOutline, IoMdDownload } from "react-icons/io";
import { MdAlternateEmail } from "react-icons/md";
import { FaRegShareSquare } from "react-icons/fa";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import { LiaSmsSolid } from "react-icons/lia";

const PaymentReceipt = () => {
  const navigate = useNavigate();
  const { state } = useContext(Context);
  const { id: payment_id, id2: money_id, id3: debtor_id } = useParams();
  const [receipt, setReceipt] = useState("");
  const [showModal, setModal] = useState(false);
  const [downloadReceipt, setDownloadReceipt] = useState("");
  const [modalData, setModalData] = useState({});
  const { route: r2, user } = state;

  const { data: route, isLoading: rLoading } = UseRoute(r2.creditor_id, user.user_id);
  // const { data: apertura, isLoading: aLoading } = UseSharedApertura(r2.creditor_id, r2.r_type, r2.day);

  const { data: loans, isLoading: isLoadingL } = UseLoansV2(r2.creditor_id, user.user_id);
  const filteredLoan = loans?.find((x) => +x.money_id === +money_id);
  const shouldFetch = !isLoadingL && !filteredLoan ? true : false;
  const { data: currentLoan2, isLoading: isLoadingS } = UseLoanv2(r2.creditor_id, money_id, debtor_id, shouldFetch);
  const currentLoan = filteredLoan ?? currentLoan2;

  const { data: userConfig, isLoading: ucLoading } = UseUserConfig(user.user_id);
  const { data: ownerConfig, isLoading: rcLoading } = UseOwnerConfig(user.user_id);
  const { data: payments, isLoading: pLoading, key } = UseLoanPaymentsv2(money_id, debtor_id);
  const payment = payments?.find((x) => x.payment_id === +payment_id);

  useEffect(() => {
    if (!currentLoan || !payment || !route || !ownerConfig || !userConfig) return;
    setReceipt(
      printReceiptHandler(
        { ...currentLoan, ...payment },
        { ...route, ...ownerConfig, ...userConfig, printApp: "html", excludeHeaders: true }
      )
    );
    setDownloadReceipt(
      printReceiptHandler({ ...currentLoan, ...payment }, { ...route, ...ownerConfig, ...userConfig, printApp: "html" })
    );
  }, [currentLoan, payment, route, ownerConfig, userConfig]);

  if (!payment || (shouldFetch && isLoadingS) || rLoading || ucLoading || rcLoading || pLoading || isLoadingL)
    return <Loading />;

  const handleWhatsAppPrint = () => {
    handlePrinting(payment, { printDestination: "whatsapp" });
  };

  const toggleModalDisplay = () => {
    setModal(!showModal);
  };

  const handlePaymentPrint = () => {
    handlePrinting(payment, { printDestination: "printer" });
  };

  const handleCaptureClick = async () => {
    const canvas = await html2canvas(document.querySelector("#receipt"));
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, `recibo-pago-${payment_id}.png`, "image/png");
  };

  const handlePrinting = (payment, option) => {
    if (option.printDestination === "whatsapp") {
      window.location = `whatsapp://send?text=${printReceiptHandler(
        { ...currentLoan, ...payment },
        {
          ...route,
          ...ownerConfig,
          printApp: "whatsapp",
        }
      )}`;
    } else {
      printReceiptHandler({ ...currentLoan, ...payment }, { ...route, ...ownerConfig, ...userConfig });
    }
  };

  return (
    <div className="mb-5">
      <DebtorNameHeader debtor={currentLoan}>
        <LoanDetailMenu loan={currentLoan} />
      </DebtorNameHeader>

      <Box component="div" p={1}>
        {payment.deletedBy !== 0 && (
          <Box component="div" p={1}>
            <Grow direction="up" in>
              <Alert variant="outlined" severity="error">
                <Typography variant="body1" component="p" className="font-weight-bold">
                  Este pago fue eliminado!
                </Typography>
              </Alert>
            </Grow>
          </Box>
        )}

        <div style={{ borderStyle: "ridge", borderWidth: 4 }}>
          <div style={{ padding: 15, paddingRight: 25 }}>{parse(receipt)}</div>
        </div>

        {payment.deletedBy === 0 ? (
          <>
            <Box paddingTop={2}>
              <Button
                fullWidth
                startIcon={<PrintIcon size="1.3em" />}
                variant="outlined"
                size="small"
                color="primary"
                onClick={handlePaymentPrint}>
                Imprimir Recibo
              </Button>
            </Box>

            <Box paddingTop={2}>
              <Button
                fullWidth
                startIcon={<FaRegShareSquare size="1.3em" />}
                variant="outlined"
                size="small"
                onClick={toggleModalDisplay}>
                Compartir Recibo
              </Button>
            </Box>

            <Box paddingTop={2}>
              <Button
                fullWidth
                startIcon={<IoMdInformationCircleOutline size="1.3em" />}
                variant="outlined"
                color="info"
                onClick={() => setModalData(payment)}
                size="small">
                Detalle del Pago
              </Button>
            </Box>

            <Box paddingTop={2} paddingBottom={2}>
              <Button
                fullWidth
                size="small"
                startIcon={<DeleteForeverIcon size="1.3em" />}
                color="secondary"
                variant="outlined"
                onClick={() => navigate(`/delete/payment/${payment_id}/${money_id}/${debtor_id}`)}>
                Borrar Pago
              </Button>
            </Box>
          </>
        ) : (
          <Box paddingTop={2}>
            <Button
              fullWidth
              startIcon={<IoMdInformationCircleOutline size="1.3em" />}
              variant="outlined"
              color="info"
              onClick={() => setModalData(payment)}
              size="small">
              Detalle del Pago
            </Button>
          </Box>
        )}

        <div className="mt-3">
          <RenderBackButton />
        </div>
      </Box>

      <Modal
        show={modalData.name ? true : false}
        onHide={() => setModalData({})}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography variant="h6">{modalData.name}</Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RenderPaymentDetail payment={payment} />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setModalData({})}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={toggleModalDisplay} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography variant="h6">Compartir Recibo!</Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box paddingTop={2}>
            <Button
              fullWidth
              startIcon={<WhatsAppIcon size="1.3em" />}
              variant="outlined"
              color="green"
              size="small"
              onClick={handleWhatsAppPrint}>
              Enviar WhatsApp
            </Button>
          </Box>
          <Box paddingTop={2}>
            <Button
              disabled
              fullWidth
              startIcon={<MdAlternateEmail size="1.3em" />}
              variant="outlined"
              size="small"
              onClick={handleWhatsAppPrint}>
              Correo Electronico
            </Button>
          </Box>
          <Box paddingTop={2}>
            <Button
              disabled
              fullWidth
              startIcon={<LiaSmsSolid size="1.3em" />}
              variant="outlined"
              size="small"
              onClick={handleWhatsAppPrint}>
              Mensaje de Texto
            </Button>
          </Box>
          <Box paddingTop={2}>
            <Button
              fullWidth
              startIcon={<IoMdDownload size="1.3em" />}
              variant="outlined"
              size="small"
              onClick={handleCaptureClick}>
              Descargar imagen
            </Button>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={toggleModalDisplay}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>

      <div id="receipt" style={{ padding: 15, paddingRight: 25, paddingTop: 30, paddingBottom: 30, marginTop: 1000 }}>
        {parse(downloadReceipt)}
      </div>
    </div>
  );
};

export default PaymentReceipt;
