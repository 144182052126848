import { useForm } from "@nodes/react-hook-form";
// import { InputNumber } from "./form/InputNumber";
// import { InputCustomFormat } from "./form/InputCustomFormat";
import * as Yup from "@nodes/yup";
import { InputDate } from "./form/InputDate";
import { InputText } from "./form/InputText";
import { InputDropdownMulti } from "./form/InputDropdownMulti";
import { Button, Box } from "@nodes/@mui/material";
import { yupResolver } from "@nodes/@hookform/resolvers/yup";
import { GrAnnounce } from "@nodes/react-icons/gr";
import { DevTool } from "@nodes/@hookform/devtools";

export const schema = Yup.object({
  announcement: Yup.string().min(5, "Debe escribir un mensaje para ser anunciado!"),
  expire_date: Yup.date("Fecha es invalida!").nullable(),
  typeR: Yup.array().of(Yup.string()).min(1, "Por favor elija una de la opciones!"),
});

export const RenderCobroAnnouncement = ({ submithandler }) => {
  const defaultValues = { announcement: "", expire_date: null, typeR: [] };
  const { handleSubmit, control, formState } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isSubmitting } = formState;

  const handleSubmitHandler = async (data) => {
    if (JSON.stringify(defaultValues) === JSON.stringify(data)) return;
    await submithandler(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitHandler)}>
        <Box paddingBottom={2}>
          <InputText
            name="announcement"
            control={control}
            label="Anunciar cambios importante!"
            placeholder="Anuncio va salir en el recibo selecionado!"
            configs={{ rows: 3, multiline: true, variant: "outlined" }}
          />
        </Box>

        <Box paddingBottom={2}>
          <InputDropdownMulti
            name="typeR"
            control={control}
            label="Selecione en que recibo(s) adjuntar"
            configs={{
              size: "large",
              backgroundColor: "#cfeeff",
              helperText: <span>Este anuncio, va ser impreso al final de cada recibo selecionado!</span>,
            }}
            options={[
              { value: "payment", label: "Imprimir despues del Recibos de Pago" },
              { value: "loanDetail", label: "Imprimir despues de Detalle de Entrega" },
              { value: "loanContract", label: "Imprimir despues del contracto" },
            ]}
          />
        </Box>

        <Box paddingBottom={2}>
          <InputDate
            name="expire_date"
            control={control}
            label="Fecha de Vencimiento"
            configs={{
              disablePast: true,
              helperText: <span>Para guardar este recibo permanentemente, deje esta fecha en blanco!</span>,
            }}
          />
        </Box>

        <Box paddingBottom={2} paddingTop={2}>
          <Button startIcon={<GrAnnounce />} disabled={isSubmitting} type="submit" fullWidth variant={"contained"}>
            Guardar Configuraci&oacute;n!
          </Button>
        </Box>
      </form>

      <DevTool control={control} />
    </>
  );
};

export default RenderCobroAnnouncement;
