import Typography from "@nodes/@mui/material/Typography";
import Grid from "@nodes/@mui/material/Grid";
import { NumericFormat } from "@nodes/react-number-format";

const RenderNewLoanCalculation = ({ data }) => {
  return (
    <Grid container>
      <Grid item xs={8} className={data.entrega < 0 ? "text-danger" : ""}>
        <Typography variant="body1" component="p">
          Entregar:
        </Typography>
        <Typography variant="h5" component="h3" className="font-weight-bold">
          <NumericFormat value={data.entrega} displayType={"text"} thousandSeparator={true} prefix={"$"} />
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1" component="p">
          Cuota:
        </Typography>
        <Typography variant="h5" component="h3" className="font-weight-bold">
          <NumericFormat value={data.cuota} displayType={"text"} thousandSeparator={true} prefix={"$"} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="h4" className="text-center">
          Ganancia Potencial{" "}
          <NumericFormat value={data.profit} displayType={"text"} thousandSeparator={true} prefix={"$"} />!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RenderNewLoanCalculation;
