import Box from "@nodes/@mui/material/Box";
import Alert from "@nodes/@mui/material/Alert";
import { printAmount, printDate, newDate } from "../functions";

const DisplayLastPaymentAlert = ({ loan }) => {
  if (!loan.pAmount && !loan.pMora) return null;

  return (
    <Box p={1}>
      <Alert icon={false} variant="filled" severity="info">
        Pago(s) aplicado {printAmount(loan.pAmount)} + Mora {printAmount(loan.pMora)} |{" "}
        {printDate(newDate(loan.last_date))}!
      </Alert>
    </Box>
  );

  // return (
  //   <>
  //     <div style={{ background: "#7ddd81" }} className="alert">
  //       {/* <Typography variant="body1" component="p">
  //         Pago Aplicado {printAmount(loan.pCash)} + Mora {printAmount(loan.pMora)}!
  //       </Typography> */}

  //This was the one being used in production.
  //       <Typography variant="body2" component="p">
  //         Pago(s) aplicado {printAmount(loan.pCash)} + Mora {printAmount(loan.pMora)} |{" "}
  //         {printDate(newDate(loan.last_date))}!
  //       </Typography>
  //     </div>

  //     {/* <Alert variant="outlined" severity="info">
  //       <Typography variant="body1" component="p">
  //         Último pago {printAmount(loan.pCash)} + Mora {printAmount(loan.pMora)} |{" "}
  //         {printDate(newDate(loan.last_date))}!
  //       </Typography>
  //     </Alert> */}
  //   </>
  // );
};

export default DisplayLastPaymentAlert;
