import { Link } from "@nodes/react-router-dom";
import Card from "@nodes/@mui/material/Card";
import CardContent from "@nodes/@mui/material/CardContent";
import Grid from "@nodes/@mui/material/Grid";
import Divider from "@nodes/@mui/material/Divider";
import Box from "@nodes/@mui/material/Box";
import Chip from "@nodes/@mui/material/Chip";
import Typography from "@nodes/@mui/material/Typography";
import { NumericFormat } from "@nodes/react-number-format";

const RenderUserProfile = ({ user = {} }) => {
  return (
    <div>
      <Box paddingBottom={1}>
        <Card raised>
          <Grid container>
            <Grid item xs={9}>
              <Typography className="m-3" variant="h6" component="h4">
                <b className="pr-1">
                  <Chip label={`W${user.user_id}`} />
                </b>
                Informacion
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <h6 className="m-3">
                <Link to={`/user/personal/${user.user_id}`}>Editar</Link>
              </h6>
            </Grid>
          </Grid>

          <Divider />
          <CardContent>
            <Typography variant="body1" component="p">
              {user.fname} {user.lname}
            </Typography>

            {user.phone && (
              <Typography variant="body1" component="p">
                <NumericFormat value={user.phone} displayType={"text"} format="(###) ###-####" />
                {+user.verified === 0 && (
                  <span className="text-danger pl-1" onClick={() => navigate("user/phone/verify")}>
                    Sin verificar
                  </span>
                )}
              </Typography>
            )}
            {user.cedula && (
              <Typography variant="body1" component="p">
                <NumericFormat value={user.cedula} displayType={"text"} format="###-#######-#" />
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>

      <Box paddingBottom={1}>
        <Card raised>
          <Grid container>
            <Grid item xs={9}>
              <Typography className="m-3" variant="h6" component="h4">
                Usuario
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <h6 className="m-3">
                <Link to={`/user/username/${user.user_id}`}>Editar</Link>
              </h6>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Typography variant="body1" component="p">
              Usuario: {user.username}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box paddingBottom={1}>
        <Card raised>
          <Grid container>
            <Grid item xs={9}>
              <Typography className="m-3" variant="h6" component="h4">
                Contraseña
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <h6 className="m-3">
                <Link to={`/user/password/${user.user_id}`}>Editar</Link>
              </h6>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Typography variant="body1" component="p">
              Contraseña: *********
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box paddingBottom={1}>
        <Card raised>
          <Grid container>
            <Grid item xs={9}>
              <Typography className="m-3" variant="h6" component="h4">
                Preguntas de Seguridad
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <h6 className="m-3">
                <s>Editar</s>
                {/* <Link to="/security/questions">
                </Link> */}
              </h6>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Typography variant="body1" component="p">
              Preguntas de seguridad para recuperar su contraseña si alguna vez la olvida.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default RenderUserProfile;
