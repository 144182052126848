import { Checkbox, FormControl, FormControlLabel } from "@nodes/@mui/material";
import { Controller } from "@nodes/react-hook-form";

export const InputCheckbox = ({ name, control, label, configs = {} }) => {
  return (
    <FormControl size={configs.size ?? "small"} variant={configs.variant ?? "outlined"}>
      {/* <FormLabel component="legend">{label}</FormLabel> */}
      <>
        <FormControlLabel
          control={
            <Controller
              name={name}
              render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => (
                <>
                  <Checkbox
                    name={name}
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    size={configs.size ?? "medium"}
                    checked={Boolean(Number(value))}
                  />

                  {configs.helperText && <FormHelperText>{configs.helperText}</FormHelperText>}
                  {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
                </>
              )}
              control={control}
            />
          }
          label={label}
          key={name}
        />
      </>
    </FormControl>
  );
};
