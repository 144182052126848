import Card from "@nodes/@mui/material/Card";
import CardActions from "@nodes/@mui/material/CardActions";
import Divider from "@nodes/@mui/material/Divider";
import Grid from "@nodes/@mui/material/Grid";
import CardHeader from "@nodes/@mui/material/CardHeader";
import IconButton from "@nodes/@mui/material/IconButton";
import { FaRegMoneyBillAlt } from "@nodes/react-icons/fa";
import CardContent from "@nodes/@mui/material/CardContent";
import Typography from "@nodes/@mui/material/Typography";
import { NumericFormat } from "@nodes/react-number-format";
import Chip from "@nodes/@mui/material/Chip";
import { printDate, newDate, printAbsAmount, getBadgeColor } from "../functions";
import VisibilityIcon from "@nodes/@mui/icons-material/Visibility";
import { useNavigate } from "@nodes/react-router-dom";
import { parseISO } from "@nodes/date-fns";

const DebtorLoanCard = ({ loan }) => {
  const navigate = useNavigate();

  return (
    <Card raised>
      <CardHeader
        title={
          <Typography variant="h6" component="h4">
            <b className="p-1">
              <Chip label={`P${loan.oldLoanId ?? loan.money_id}`} />
            </b>
            | Prestamo {printAbsAmount(loan.amount)}
          </Typography>
        }
        subheader={
          <>
            <Typography variant="body2" component="p">
              <NumericFormat
                value={loan.balance + loan.balMora}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Balance $"}
              />
            </Typography>
            <Typography variant="body2" component="p">
              Ultimo Pago: {printDate(newDate(loan.last_date), "dd-MMM-y")}
            </Typography>
          </>
        }
      />
      <CardContent>
        <Typography variant="body1" component="p">
          Entregado en: {printDate(parseISO(loan.created_time), "dd-MMM-yy")}
        </Typography>
        <Typography variant="body1" component="p"></Typography>
        <Typography variant="body1" component="p">
          Cuotas Pagada:{" "}
          {<NumericFormat value={loan.cuota} displayType={"text"} thousandSeparator={true} prefix={"$"} />} |{" "}
          {loan.completed} / {loan.npayments}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions disableSpacing>
        <Grid container>
          <Grid item xs={6}>
            {loan.balance + loan.balMora > 0 ? (
              <IconButton
                aria-label="add sector"
                onClick={() => navigate(`/payment/create/${loan.money_id}/${loan.debtor_id}`)}
                size="large">
                <FaRegMoneyBillAlt className="text-success" />
              </IconButton>
            ) : (
              <IconButton size="large">
                <FaRegMoneyBillAlt />
              </IconButton>
            )}

            <IconButton
              aria-label="share"
              onClick={() => navigate(`/loan/detail/${loan.money_id}/${loan.debtor_id}`)}
              size="large">
              <VisibilityIcon className="text-primary" />
            </IconButton>
          </Grid>
          <Grid item xs={6} align="right">
            {loan.deletedBy !== 0 ? (
              <Typography variant="subtitle2" component="p" className="badge badge-pill badge-danger">
                <b>Cencelado</b>
              </Typography>
            ) : loan.statusText === "Al Dia" ? (
              <Typography variant="subtitle2" component="p" className="badge badge-pill badge-success">
                <i>{loan.statusText}</i>
              </Typography>
            ) : (
              <Typography variant="subtitle2" component="p" className={getBadgeColor(loan.statusText)}>
                <i>
                  {loan.statusText}: {printAbsAmount(loan.statusAmount)}
                </i>
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default DebtorLoanCard;
