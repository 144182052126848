import { useContext } from "react";
import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { NumericFormat } from "react-number-format";
import { Context } from "../context/DataContext";
import { printDate } from "@shared/functions";
import Alert from "@nodes/@mui/material/Alert";
import Grow from "@mui/material/Grow";
import RolesDropdown from "../components/RolesDropdown";
import RenderGreenCheckbox from "../components/RenderGreenCheckbox";
import Chip from "@mui/material/Chip";
// import { FaHandHoldingUsd } from "react-icons/fa";
// import DataFetchWrapper from "@shared/components/DataFetchWrapper";
import EmployeeNameHeader from "../components/EmployeeNameHeader";
import EmployeeMenu from "../components/EmployeeMenu";
import { UseEmployee, UseOwnerRoutes, UseRoute, UseRoles, UseCustomRoles } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
// import RenderDebtorImages from "@shared/components/RenderDebtorImages";
// import RenderDebtorProfile from "@shared/components/RenderDebtorProfile";
import { parseISO } from "date-fns";

const EmployeeProfile = () => {
  const { id: employee_id } = useParams();
  const { state, toggleLinkedRoute, setUserRole } = useContext(Context);
  const { user, route } = state;

  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);
  const { data: employee, isLoading } = UseEmployee(routeN?.user_id, employee_id);
  const { data: routes, isLoading: isL2, mutate } = UseOwnerRoutes(routeN?.user_id, employee_id);
  const { data: roles, isLoading: RolesLoading } = UseRoles(routeN?.user_id);
  const { data: customRoles, isLoading: cRolesLoading } = UseCustomRoles(routeN?.user_id);

  console.log({ isLoading, routeLoading, RolesLoading, cRolesLoading, isL2 });
  if (isLoading || routeLoading || RolesLoading || cRolesLoading || isL2) return <Loading />;

  const handleToggleChange = async (user, route) => {
    await mutate(toggleLinkedRoute(user, route), {
      populateCache: false,
    });
  };

  const handleSetUser = async (user, role, creditor_id) => {
    await mutate(setUserRole(user, role, creditor_id), {
      populateCache: false,
    });
  };

  return (
    <div className="mt-2 mb-5">
      <Container maxWidth="md" disableGutters>
        <EmployeeNameHeader user={employee}>
          <EmployeeMenu user_id={employee_id} owner_id={routeN.user_id} />
        </EmployeeNameHeader>

        {employee.deletedBy !== 0 && (
          <Box paddingBottom={2} component="div" p={1}>
            <Grow direction="up" in>
              <Alert variant="outlined" severity="error">
                <Typography variant="body1" gutterBottom component="p" className="font-weight-bold">
                  Este usuario ha sido borrado!
                </Typography>
                <Typography variant="body2" component="p" className="font-weight-bold">
                  Este usuario no puede ingresar o cobrar ninguna ruta!
                </Typography>
              </Alert>
            </Grow>
          </Box>
        )}

        <Box component="div" p={1}>
          <Typography variant="h6" component="h4" paragraph>
            <b className="pr-1">
              <Chip label={`W${employee.user_id}`} />
            </b>
            Perfil de Empleado!
          </Typography>

          <Box component="div" paddingBottom={1}>
            <Typography variant="body2" component="p" gutterBottom>
              Usuario
            </Typography>
            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{employee.username}</span>
            </Typography>
          </Box>

          <Box component="div" paddingBottom={1}>
            <Typography variant="body2" component="p" gutterBottom>
              Numero de Telefono
            </Typography>
            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                <NumericFormat value={employee.phone || "0000000000"} displayType={"text"} format="(###)-###-####" />
              </span>
            </Typography>
          </Box>

          <Box component="div" paddingBottom={1}>
            <Typography variant="body2" component="p" gutterBottom>
              Numero de C&eacute;dula
            </Typography>
            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                <NumericFormat value={employee.cedula || "00000000000"} displayType={"text"} format="###-#######-#" />
              </span>
            </Typography>
          </Box>

          {employee.email && (
            <Box component="div" paddingBottom={1}>
              <Typography variant="body2" component="p" gutterBottom>
                Correo Electronico
              </Typography>
              <Typography variant="h6" component="h4">
                <span className="badge badge-dark p-2">{employee.email}</span>
              </Typography>
            </Box>
          )}

          <Box component="div" paddingBottom={1}>
            <Typography variant="body2" component="p" gutterBottom>
              Ultima vez Conectado
            </Typography>
            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                {employee.last_login
                  ? printDate(parseISO(employee.last_login), "dd-MMM-yy hh:mm:ss aaa")
                  : "Nada, por el momento!"}
              </span>
            </Typography>
          </Box>

          {employee.created_time && (
            <Box component="div" paddingBottom={1}>
              <Typography variant="body2" component="p" gutterBottom>
                Creado en
              </Typography>
              <Typography variant="h6" component="h4">
                <span className="badge badge-dark p-2">
                  {printDate(parseISO(employee.created_time), "dd-MMM-yy hh:mm:ss aaa")}
                </span>
              </Typography>
            </Box>
          )}
        </Box>

        {/* <Box component="div" paddingBottom={1}>
          <Typography variant="body2" component="p" gutterBottom>
            Creado En
          </Typography>
          <Typography variant="h6" component="h4">
            <span className="badge badge-dark p-2">
              {printDate(parseISO(employee.created_time), "dd-MMM-yy hh:mm:ss aaa")}
            </span>
          </Typography>
        </Box> */}

        {routes.length === 0 ? (
          <div className="container mt-5 mb-5">
            <Typography variant="h5" componet="h3" gutterBottom>
              Rutas!
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              En esta pagina puede ver todas las rutas disponible.
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              Por el momento, no se ha creado ninguna ruta!
            </Typography>
          </div>
        ) : (
          <div className="mt-3 mb-5">
            <table style={{ marginBottom: 120 }} className="table">
              <thead>
                <tr>
                  <th scope="col" className="d-none d-md-table-cell">
                    #
                  </th>
                  <th scope="col" className="d-none d-md-table-cell">
                    Ruta
                  </th>
                  <th scope="col">Zona</th>
                  <th scope="col">Rol</th>
                  <th scope="col">Activo</th>
                </tr>
              </thead>
              <tbody>
                {routes.map((route) => (
                  <tr key={route.creditor_id}>
                    <td className="d-none d-md-table-cell">{route.creditor_id}</td>
                    <td className="d-none d-md-table-cell">{route.route_name}</td>
                    <td>{route.location}</td>
                    <td>
                      <RolesDropdown
                        user={{ ...route, user_id: employee_id }}
                        roles={[...customRoles, ...roles]}
                        creditor_id={route.creditor_id}
                        handleSetUser={handleSetUser}
                      />
                    </td>
                    <td>
                      <RenderGreenCheckbox
                        user={{ ...route, user_id: employee_id }}
                        route={route}
                        handleToggleChange={handleToggleChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Container>
    </div>
  );
};

export default EmployeeProfile;
