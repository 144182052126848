import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
// import Grid from "@mui/material/Grid";
import { Context } from "../context/DataContext";
// import { toast } from "react-toastify";
// import { spanishRouteTypes } from "@shared/functions";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
// import RenderNewLoanCalculation from "@shared/components/RenderNewLoanCalculation";
import RenderLoanCreateForm from "@shared/components/RenderLoanCreateForm";
import RenderLoanTypeTabs from "@shared/components/RenderLoanTypeTabs";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import { createLoan } from "@shared/api/loan";
// import LoanTypeSelection from "@shared/components/LoanTypeSelection";
// import BasicDatePicker from "@shared/components/BasicDatePicker";
import RenderDisabledCustomer from "@shared/components/RenderDisabledCustomer";
import {
  UseRoute,
  UseDebtors,
  UseCustomerBalance,
  UseBankInformationv2,
  UseRouteLoanType,
  UseRouteLoanVariant,
} from "@shared/server/fetcher";
import { FaHandPointUp } from "react-icons/fa";
import { useSWRConfig } from "swr";
import { useSnackbar } from "notistack";

// const calcMonto = (percentage = 0, amount = 0, npayments = 0) => {
//   const amount2 = amount * 1;
//   const npayments2 = npayments * 1;
//   const percentage2 = percentage * 1;
//   if (npayments2 === 0) return 0;

//   return Math.ceil(((percentage2 / 100) * amount2 + amount2) / npayments2);
// };

// const calcEntrega = (amount = 0, actanotarial = 0, adelanto = 0, balance = 0, discount = 0) => {
//   const amount2 = amount * 1;
//   const balance2 = balance * 1;
//   const adelanto2 = adelanto * 1;
//   const actanotarial2 = actanotarial * 1;
//   const discount2 = discount * 1;
//   return amount2 - actanotarial2 - balance2 - adelanto2 - discount2;
// };

// const calcProfits = (npayments = 0, monto = 0, amount = 0) => {
//   const npayments2 = npayments * 1;
//   const monto2 = monto * 1;
//   const amount2 = amount * 1;
//   return npayments2 * monto2 - amount2;
// };

// const initialFormValues = { amount: "", percentage: "", npayments: "", actanotarial: "", adelanto: "", discount: "" };

const LoanCreate = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { id: dUniqueId } = useParams();
  const { state, createLoanSignature } = useContext(Context);
  // const [given_date, setGivenDate] = useState(null);
  const [loantypeData, setLoantypeData] = useState(null);
  const [loan_type, setLoanType] = useState(false);
  const { user, route: r2 } = state;

  const { enqueueSnackbar } = useSnackbar();

  const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  // const { data: aperturaNext, isLoading: aLoading } = UseNextAperturas();
  const { data: routeLoanTypes, isLoading: ltLoading } = UseRouteLoanType(r2.creditor_id);
  const { data: loansVariant, isLoading: lvLoading } = UseRouteLoanVariant(r2.creditor_id);
  // const { data: currentDebtor, isLoading: isLoadingDebtor } = UseDebtor(debtor_id, user.user_id);
  const { data: debtors, isLoading: debtorLoading } = UseDebtors(r2.creditor_id, user.user_id);
  // const { data: routeConfig, isLoading: routeLoadingConfig } = UseOwnerConfig(r2.user_id);
  const { data: customer } = UseCustomerBalance(route?.user_id);
  const { data: bankInformation } = UseBankInformationv2(route?.user_id);

  useEffect(() => {
    if (!routeLoanTypes) return null;

    setLoantypeData(routeLoanTypes[loan_type]);

    //eslint-disable-next-line
  }, [loan_type, routeLoanTypes]);

  const currentDebtor = debtors?.find((x) => +x.dUniqueId === +dUniqueId);

  if (debtorLoading || routeLoading || !currentDebtor || ltLoading || lvLoading) return <Loading />;
  if (customer?.isDisabled && bankInformation?.BANK_OWNER_NAME)
    return <RenderDisabledCustomer bankInformation={bankInformation} />;

  const defaultValues = {
    user_id: user.user_id,
    creditor_id: route.creditor_id,
    name: currentDebtor.name,
    debtor_id: currentDebtor.debtor_id,
    percentage: currentDebtor.percentage || loantypeData?.percent || "",
    apertura_day: loantypeData?.a_day,
    start_date: loantypeData?.start_date ?? null,
    loan_type: loantypeData?.type ?? null,
    type_id: loantypeData?.type_id ?? null,
    daysOff: loantypeData?.daysOff,
    loan_variant: loansVariant.length === 1 ? loansVariant[0].v_id : 0,
    cuota: "",
    npayments: 0,
    customCuotas: "",
    amount: "",
    actanotarial: "",
    adelanto: 0,
    discount: 0,
    payoffBalance: 0,
  };

  // let button;
  // const submithandler = async (data) => {
  //   await submitnewLoan(data);
  //   mutate(`/loansv2/${route.creditor_id}/${user.user_id}`);
  // };

  // const allowSignaturesRoutes = [];

  //TODO: state.route doesnt has all props required.
  // if (!allowSignaturesRoutes.includes(state.route.creditor_id)) {
  //   button = { label: "Guardar Pr&eacute;stamo", variant: "contained", color: "primary" };
  // } else {
  //   button = {
  //     icon: <BorderColorIcon />,
  //     label: "Firma del Cliente",
  //     color: "primary",
  //     variant: "outlined",
  //   };
  // }

  // const submitnewLoan = async (data) => {
  //   const cuota = Math.ceil(((+data.percentage / 100) * +data.amount + +data.amount) / +data.npayments);
  //   const newData = { ...data, cuota };

  //   let redirect = "/loan/signature";

  //   if (!allowSignaturesRoutes.includes(state.route.creditor_id)) {
  //     redirect = "/entregas";

  //     await createLoan(newData, () => navigate(redirect, { replace: true }));
  //   } else {
  //     await createLoanSignature(newData, () => navigate(redirect, { replace: true }));
  //   }
  // };

  const habdleLoanCreate = async (data) => {
    try {
      let redirect = "/loan/signature";
      redirect = "/entregas";

      await createLoan(data);

      enqueueSnackbar("Pr&eacute;stamo guardado con exito", { variant: "success" });
      mutate(`/loansv2/${route.creditor_id}/${user.user_id}`);
      navigate(redirect, { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentDebtor}>
          <LoanDetailMenu loan={currentDebtor} />
        </DebtorNameHeader>

        <Box component="div" p={1}>
          <Typography variant="h5" component="h4">
            Nuevo Pr&eacute;stamo!
          </Typography>

          <Box paddingBottom={1}>
            <Divider />
          </Box>

          <Box div="div">
            <RenderLoanTypeTabs loanType={loan_type} setLoanType={setLoanType} types={routeLoanTypes.types} />
          </Box>

          {loantypeData ? (
            <Box div="div" p={1}>
              {/* <Box paddingBottom={2}>
              <BasicDatePicker date={route.today_date} setDate={setGivenDate} label="Fecha de Entrega" />
            </Box> */}
              {/* <Box paddingBottom={2}>
                <BasicDatePicker
                  date={loantypeData?.start_date}
                  setDate={handleDateChange}
                  label="Fecha de Inicio"
                  disableFuture={false}
                />
              </Box> */}

              <RenderLoanCreateForm
                defaultValues={defaultValues}
                submithandler={habdleLoanCreate}
                loantypeData={loantypeData}
                loansVariant={loansVariant}
                // extraCuotas={currentDebtor.npayments ? [currentDebtor.npayments] : []}
              />
            </Box>
          ) : (
            <Box component="div">
              <div className="alert alert-info text-center">
                <FaHandPointUp size="2em" />

                <Typography variant="h6" component="p" className="mt-2">
                  <b>Selecione tipo de pr&eacute;stamo!</b>
                </Typography>
              </div>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default LoanCreate;
