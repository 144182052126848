import Tooltip from "@nodes/@mui/material/Tooltip";
import Zoom from "@nodes/@mui/material/Zoom";
import Fab from "@nodes/@mui/material/Fab";
import { useNavigate } from "@nodes/react-router-dom";

const handleOnClick = (navigate, goto) => {
  return goto ? navigate(goto) : null;
};

const FloatingButton = ({ icon, goto, tooltip }) => {
  const navigate = useNavigate();

  return (
    <Zoom in>
      <Tooltip title={tooltip}>
        <Fab onClick={() => handleOnClick(navigate, goto)} color="primary">
          {icon}
        </Fab>
      </Tooltip>
    </Zoom>
  );
};

export default FloatingButton;
