import { useContext, useState } from "react";
import { Context } from "../context/DataContext";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";
import DatePicker from "@shared/components/DatePicker";
import XcobrarLoanListChanges from "@shared/components/XcobrarLoanListChanges";
// import CobroListChanges from "@shared/components/CobroListChanges";
import { printAmount, printAbsAmount } from "@shared/functions";
import { FiTrendingDown, FiTrendingUp } from "react-icons/fi";
import { UseCobroListChanges } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";

const CobroList = () => {
  const { date } = useParams();
  const { state } = useContext(Context);
  const { route } = state;
  const [currentDate, setCurrentDate] = useState(date ?? route?.next_week_date);

  const { data: cobroListChanges, isLoading } = UseCobroListChanges(route.creditor_id, currentDate);

  if (isLoading) return <Loading />;

  return (
    <div className="container mt-3 mb-5">
      {cobroListChanges.changes < 0 ? (
        <Typography variant="h6" component="h4">
          Estado de la ruta ({cobroListChanges.count}): {printAmount(cobroListChanges.amount)}{" "}
          <FiTrendingDown size="1.3em" className="text-danger" />
        </Typography>
      ) : (
        <Typography variant="h6" component="h4">
          Estado de la ruta ({cobroListChanges.count}): {printAmount(cobroListChanges.amount)}{" "}
          <FiTrendingUp className="text-success" size="1.3em" />
        </Typography>
      )}

      <Grid container>
        <Grid item>
          <Typography variant="h6" component="h4">
            Cambios {printAbsAmount(cobroListChanges.changes)}{" "}
            {cobroListChanges.changes < 0 ? (
              <FiTrendingDown size="1.3em" className="text-danger" />
            ) : (
              <FiTrendingUp className="text-success" size="1.3em" />
            )}
          </Typography>
        </Grid>
        <Grid item>
          <DatePicker setCurrentDate={setCurrentDate} date={currentDate} route={route} />
        </Grid>
      </Grid>

      {cobroListChanges.loans.map((loan) => (
        <XcobrarLoanListChanges key={loan.debtor_id} loan={loan} />
      ))}
    </div>
  );
};

export default CobroList;
