import { mainApp, localStorageSync, localStorageToken, localStoragePrintApp } from "@/config";
import { getLocalData } from "@shared/functions";
import backend from "@shared/server/backend";
// import { toast } from "react-toastify";
import axios from "@nodes/axios";

function getLocalStorageToken() {
  let token = localStorage.getItem(localStorageToken);
  if (!token) {
    token = Date.now() + Math.random().toString(36);
    localStorage.setItem(localStorageToken, token);
  }
  return token;
}

const updatePersonalInfo = (dispatch) => {
  return async (data, callback, type = null) => {
    try {
      //TODO: fname, lname, lastname,firstname are being sent only once should be sent to
      // the server depending which one is being used at the server.

      const response = await backend.post("/user/personalInfo", {
        data: { ...data, firstname: data.fname, lastname: data.lname },
      });
      console.log("AppWorkflow - updatePersonalInfo from dataContext - Promise Resolved data:", response.data);
      dispatch({ type: "changePersonalInfo", payload: data });

      let header = type === "employee" ? `/employee/${data.user_id}` : "/profile";

      if (response.data.verified === 0 && type !== "employee") {
        header = "/user/phone/verify";
        dispatch({ type: "changeUserVerified", payload: { verified: "0" } });
      }

      callback(header, { replace: true });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "info", message: "Su informacion fue actualizada!" },
      });
    } catch (err) {
      console.log("AppWorkflow - updatePersonalInfo from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const saveSecurityQuestions = (dispatch) => {
  return async (data, callback) => {
    if (data.question1 === 0 || data.question2 === 0 || data.question3 === 0) {
      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "error", message: "Selecione todas las preguntas de Seguridad!" },
      });
      return;
    }

    try {
      const questions = {
        user_id: data.user_id,
        questions: [
          { question_id: data.question1, answer: data.answer1 },
          { question_id: data.question2, answer: data.answer2 },
          { question_id: data.question3, answer: data.answer3 },
        ],
      };

      await backend.post("/questions/create", { questions });

      dispatch({ type: "saveSecurityQuestions" });

      callback();

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "info", message: "Su pregutas y repuestras fueron guardada!" },
      });
    } catch (err) {
      console.log("AppWorkflow - saveSecurityQuestions from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const passwordResetLookup = (dispatch) => {
  return async (data, callback) => {
    try {
      const client = await axios.get("https://ipapi.co/json/");
      data.ipAddress = client.data.ip;
      data.token = getLocalStorageToken();

      const response = await backend.post("/username/lookup", { data });
      console.log("AppWorkflow - passwordResetLookup from dataContext - Promise Resolved data:", response.data);
      dispatch({ type: "passwordResetLookup", payload: response.data });
      callback();
    } catch (err) {
      console.log("AppWorkflow - passwordResetLookup from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const updateOwnerConfig = () => {
  return async (owner_id, data) => {
    try {
      await backend.put("/owner/config", { owner_id, ...data });
    } catch (err) {
      console.log("AppWorkflow - updateDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const updateDebtorCedula = () => {
  return async (debtor_id, { cedula }) => {
    try {
      await backend.post("/save/debtor/cedula", { debtor_id, cedula });
      return true;
    } catch (err) {
      console.log("AppWorkflow - createDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const updateUserConfig = () => {
  return async (user_id, data) => {
    try {
      await backend.put("/user/config", { user_id, ...data });
      localStorage.setItem(localStoragePrintApp, data.printApp);
    } catch (err) {
      console.log("AppWorkflow - updateDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const dataCreditSearch = () => {
  return async (creditor_id, cedula, name, phone) => {
    cedula = cedula ? cedula : "c";
    name = name ? name : "ced";
    phone = phone ? phone : "p";
    try {
      const { data } = await backend.get(`/debtor/loans/checkv2/${creditor_id}/${cedula}/${name}/${phone}`);
      return data;
    } catch (err) {
      console.log("AppWorkflow - updateDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const appDiagnosticLog = () => {
  return async (data) => {
    const picked = (({ user_id, creditor_id, collect_date, collector_id, appVersion }) => ({
      user_id,
      creditor_id,
      collect_date,
      collector_id,
      appVersion,
    }))(data);

    try {
      let syncCount = 0;
      if (mainApp === 0) {
        syncCount = (getLocalData(localStorageSync) || []).length;
      }
      const url = window.location.href;
      const token = getLocalStorageToken();
      return await backend.post("/insert/app/diagnostic", { ...picked, url, token, app: mainApp, syncCount });
    } catch (err) {
      console.log("AppWorkflow - appDiagnosticLog from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const verifySecurityQuestions = () => {
  return async (data, callback) => {
    try {
      const client = await axios.get("https://ipapi.co/json/");
      data.ipAddress = client.data.ip;
      data.token = getLocalStorageToken();

      const { data: res } = await backend.post("/questions/verify", { data });

      callback(`/password/new/${res.user_id}/${res.salt}/${res.expTimestamp}`);
    } catch (err) {
      console.log("AppWorkflow - verifySecurityQuestions from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const appendDebtorImage = (dispatch) => {
  return async (data) => {
    try {
      dispatch({ type: "appendDebtorImage", payload: data });
    } catch (err) {
      console.log("AppWorkflow - appendDebtorImage from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const updateUsername = (dispatch) => {
  return async (data, callback) => {
    try {
      const response = await backend.post("/user/username", { data });
      console.log("AppWorkflow - updateUsername from dataContext - Promise Resolved data:", response.data);
      dispatch({ type: "changeUsername", payload: data.username });
      callback();

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "info", message: "Su usuario fue actualizado!" },
      });
    } catch (err) {
      console.log("AppWorkflow - updateUsername from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const updatePassword = (dispatch) => {
  return async ({ data }, callback) => {
    try {
      const response = await backend.post("/user/password", { data });
      console.log("AppWorkflow - updatePassword from dataContext - Promise Resolved data:", response.data);
      callback();
      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "info", message: "Su contraseña fue actualizado!" },
      });
    } catch (err) {
      console.log("AppWorkflow - updatePassword from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const createVerifyPhone = () => {
  return async (data, setSmsSent, setShowRetryButton) => {
    try {
      const response = await backend.post("/phone/verify", { data });
      console.log("AppWorkflow - createVerifyPhone from dataContext - Promise Resolved data:", response.data);
      if (response.data !== "pending") return;
      setSmsSent(true);
    } catch (err) {
      setShowRetryButton(true);
      console.log("AppWorkflow - createVerifyPhone from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const resetPassword = (dispatch) => {
  return async (data, callback) => {
    try {
      const response = await backend.post("/user/password/reset", { data });
      console.log("AppWorkflow - resetPassword from dataContext - Promise Resolved data:", response.data);

      dispatch({ type: "logout" });

      callback();
    } catch (err) {
      console.log("AppWorkflow - resetPassword from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const getReferrals = (dispatch) => {
  return async (user_id) => {
    try {
      const response = await backend.get("/referrals/{user_id}");
      console.log("AppWorkflow - getReferrals from dataContext - Promise Resolved data:", response.data);

      dispatch({ type: "getReferrals", payload: response.data, user_id });
    } catch (err) {
      console.log(err.message);
      console.log("AppWorkflow - getReferrals from dataContext - Catch Error");
    }
  };
};

const createReferralLink = () => {
  return async (data) => {
    try {
      const response = await backend.post("/referrals/link/create", { ...data });
      console.log("AppWorkflow - createReferralLink from dataContext - Promise Resolved data:", response.data);
      return response.data;
    } catch (err) {
      console.log(err.message);
      console.log("AppWorkflow - createReferralLink from dataContext - Catch Error");
    }
  };
};

const createOldReferral = (dispatch) => {
  return async (data) => {
    try {
      const response = await backend.post("/referrals/old/create", { ...data });
      console.log("AppWorkflow - createOldReferral from dataContext - Promise Resolved data:", response.data);

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 4000, type: "success", message: "Persona referida guardada!" },
      });
    } catch (err) {
      console.log(err.message);
      console.log("AppWorkflow - createOldReferral from dataContext - Catch Error");
    }
  };
};

const phoneVerification = (dispatch) => {
  return async (data, callback) => {
    try {
      await backend.post("/phone/verification", { data });

      dispatch({
        type: "phoneVerification",
        payload: { username: data.username, phoneVerified: true, user_id: data.user_id },
      });

      callback();

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "info", message: "Telefono fue verificado!" },
      });
    } catch (err) {
      console.log("AppWorkflow - phoneVerification from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const phoneVerificationPwReset = () => {
  return async (data, callback) => {
    try {
      const client = await axios.get("https://ipapi.co/json/");
      data.ipAddress = client.data.ip;
      data.token = getLocalStorageToken();

      const { data: res } = await backend.post("/phone/verification/pw/reset", { data });
      callback(`/password/new/${res.user_id}/${res.salt}/${res.expTimestamp}`);
    } catch (err) {
      console.log("AppWorkflow - phoneVerificationPwReset from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const getSecurityQuestions = (dispatch) => {
  return async () => {
    try {
      const response = await backend.post("/questions");
      console.log("AppWorkflow - getSecurityQuestions from dataContext - Promise Resolved data:", response.data);
      dispatch({ type: "getSecurityQuestions", payload: response.data, id: 1 });
    } catch (err) {
      console.log("AppWorkflow - getSecurityQuestions from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const dispatchAction = (dispatch) => {
  //This Dispatch action is only responsible of updating the app State.
  return (action, data) => {
    dispatch({ type: action, payload: data });
  };
};

const fetchLoanSignature = (dispatch) => {
  return async (money_id) => {
    try {
      const response = await backend.post("/loan/signature", { money_id });
      console.log("AppWorkflow - fetchLoanSignature from dataContext - Promise Resolved data:", response.data);
      dispatch({ type: "fetchLoanSignature", payload: response.data });
    } catch (err) {
      console.log("AppWorkflow - fetchLoanSignature from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

export {
  fetchLoanSignature,
  updateDebtorCedula,
  getSecurityQuestions,
  updatePassword,
  updateUsername,
  updatePersonalInfo,
  appendDebtorImage,
  getReferrals,
  verifySecurityQuestions,
  phoneVerification,
  phoneVerificationPwReset,
  createReferralLink,
  createOldReferral,
  dispatchAction,
  createVerifyPhone,
  updateOwnerConfig,
  updateUserConfig,
  resetPassword,
  saveSecurityQuestions,
  passwordResetLookup,
  appDiagnosticLog,
  dataCreditSearch,
};
