import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { printAbsAmount } from "@shared/functions";
import { printCuadreHandler } from "@shared/functions/print";
import DatePicker from "@shared/components/DatePicker";
import RenderCuadreData from "@shared/components/RenderCuadreData";
import UpdateBankAmount from "../components/UpdateBankAmount";
import UpdateEfectivoAmount from "../components/UpdateEfectivoAmount";
import Loading from "@shared/components/Loading";
import { UseCuadre, UseCollected, UseEntregas, UseGastos, UseRoute, UseAPerturaCuadre } from "@shared/server/fetcher";
import { AiFillBank } from "react-icons/ai";
import LinearProgressWithLabel from "@shared/components/LinearProgressWithLabel";
// import NoInternetContent from "@shared/components/NoInternetContent";

const Cuadre = () => {
  const { state, printCuadreData, updateEfectivoAmount, updateBankAmount } = useContext(Context);
  const { route: r2, user } = state;

  const { data: route, isLoading: rLoading } = UseRoute(r2.creditor_id, user.user_id);
  const { data: cuadre, isLoading: acLoading } = UseAPerturaCuadre(r2.creditor_id);
  const { data: data, isLoading: dLoading } = UseCuadre(r2.creditor_id, cuadre?.start_date, cuadre?.range_date);
  const { data: entregas, isLoading: eLoading } = UseEntregas(r2?.creditor_id, cuadre?.start_date, cuadre?.range_date);
  const { data: gastos, isLoading: gLoading } = UseGastos(r2.creditor_id, cuadre?.start_date, cuadre?.range_date);
  const { data: collected, isLoading: pLoading } = UseCollected(r2.creditor_id, cuadre?.start_date, cuadre?.range_date);

  console.log(data);

  // useEffect(() => {
  //   if (route) setCurrentDate(route?.collect_week_date);
  //   //eslint-disable-next-line
  // }, [route]);

  if (rLoading || acLoading || dLoading) return <Loading />;

  if (!cuadre.cuadre_id)
    return (
      <div className="container m-2">
        <Typography variant="h6">No Cuadre encontrado!</Typography>
        {/* <DatePicker setCurrentDate={setCurrentDate} date={currentDate} route={route} /> */}
      </div>
    );

  const handleBankUpdate = async (data) => {
    await updateBankAmount(data);
    mutate();
  };

  const handleEfectivoUpdate = async (data) => {
    await updateEfectivoAmount(data);
    mutate();
  };

  const transferedSum = collected?.reduce((acc, obj) => {
    return obj.pCash > 0 ? acc + +obj.pCash : acc;
  }, 0);

  const cashPaymentsSum = collected?.reduce((acc, obj) => {
    return obj.pDeposit > 0 ? acc + +obj.pDeposit : acc;
  }, 0);

  const totalMoraSum = collected?.reduce((acc, obj) => {
    return acc + +obj.pMora;
  }, 0);

  const gastosSum = gastos?.reduce((acc, obj) => {
    return acc + +obj.amount;
  }, 0);

  const actaNoctarialSum = entregas?.reduce((acc, obj) => {
    return acc + +obj.actanotarial;
  }, 0);

  const newLoansSum = entregas?.reduce((acc, obj) => {
    return acc + +obj.amount;
  }, 0);

  const cashMoney = cuadre.bank * 1 + actaNoctarialSum + cashPaymentsSum + totalMoraSum - gastosSum - newLoansSum;

  const progress =
    data.totalCuentaXcobrarAllSum > 0
      ? ((data.totalCuentaXcobrarAllSum - data.totalCuentaXcobrarSum) / data.totalCuentaXcobrarAllSum) * 100
      : 0;

  console.log({ progress });

  return (
    <div className="container mt-3 mb-5">
      <Typography variant="h6" component="h4">
        Estado de la ruta ({data.cuentaXcobrarAll?.length}): {printAbsAmount(data.totalCuentaXcobrarAllSum)}
      </Typography>

      {/* <DatePicker setCurrentDate={setCurrentDate} date={currentDate} route={route} /> */}

      <LinearProgressWithLabel value={progress} />
      {/* <UpdateBankAmount
        cashMoney={cashMoney}
        print={printCuadreHandler}
        handleBankUpdate={handleBankUpdate}
        route={{ ...route, today_date: apertura.today_date }}
        data={data}
      />  */}

      <RenderCuadreData
        data={data}
        collected={{
          data: collected,
          totalCash: cashPaymentsSum,
          totalMora: totalMoraSum,
          totalDeposit: transferedSum,
          loading: pLoading,
        }}
        gastos={{ data: gastos, total: gastosSum, loading: gLoading }}
        entregas={{ data: entregas, totalNewLoans: newLoansSum, totalActa: actaNoctarialSum, loading: eLoading }}
      />

      <Box component="div" p={1}>
        <Typography variant="body2">
          <span className="text-primary">Capital: {printAbsAmount(data.capitalSum)}</span>
          <span className="text-danger pl-5">Reditos: {printAbsAmount(data.interestSum)}</span>
        </Typography>
      </Box>

      {/* <UpdateEfectivoAmount
        cashMoney={cashMoney}
        handleEfectivoUpdate={handleEfectivoUpdate}
        data={{ ...data, today_date: currentDate }}
        print={printCuadreData}
      /> */}

      {transferedSum > 0 && (
        <Card variant="outlined" className="mt-1">
          <CardContent>
            <b>
              <span>
                <AiFillBank size="1.3em" /> Dinero de Transferencia: {printAbsAmount(transferedSum)}
              </span>
            </b>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Cuadre;
