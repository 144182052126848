import { useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@nodes/react-super-responsive-table";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AccordionActions from "@mui/material/AccordionActions";
import Typography from "@mui/material/Typography";
// import AccessCodeComponent from "@shared/components/AccessCodeComponent";
// import SubmitButton from "@shared/components/SubmitButton";
// import { BiRefresh } from "react-icons/bi";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Grid from "@mui/material/Grid";
import Loading from "@shared/components/Loading";
// import { UseRouteAccessCodes, UseRoute } from "@shared/server/fetcher";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function UsersLinks() {
  const [expandedUser, setExpandedUser] = useState("");
  const [expandedRoute, setExpandedRoute] = useState("");
  const [value, setValue] = useState(0);

  const users = [
    {
      user_id: 1,
      fname: "Wilson",
      lname: "Benavides",
      phone: "(347) 812-7103",
      linkedRoutes: [
        { creditor_id: 1, name: "Nombre de la ruta", location: "Cabrera" },
        { creditor_id: 2, name: "Nombre de la ruta", location: "La Vega" },
        { creditor_id: 3, name: "Nombre de la ruta", location: "Puerto Plata" },
      ],
    },
    {
      user_id: 2,
      fname: "Ramon",
      lname: "Benavides",
      phone: "(917) 312-9940",
      linkedRoutes: [],
    },
    {
      user_id: 3,
      fname: "Minerva",
      lname: "Mercado",
      phone: "(646) 938-2668",
      linkedRoutes: [{ creditor_id: 3, name: "Nombre de la ruta", location: "Puerto Plata" }],
    },
  ];

  const routes = [
    {
      creditor_id: 1,
      name: "Nombre de la ruta",
      location: "Cabrera",
      linkedUsers: [
        {
          user_id: 1,
          fname: "Wilson",
          phone: "(347) 812-7103",
        },
        {
          user_id: 2,
          fname: "Ramon",
          phone: "(917) 312-9940",
        },
      ],
    },
    {
      creditor_id: 2,
      name: "Ruta de Prueba S.R.L",
      location: "Samana",
      linkedUsers: [
        {
          user_id: 1,
          fname: "Wilson",
          phone: "(347) 812-7103",
        },
      ],
    },
    {
      creditor_id: 3,
      name: "Route Test Name",
      location: "Matancita arriba",
      linkedUsers: [],
    },
  ];

  const handleUserChange = (panel) => (event, newExpanded) => {
    setExpandedUser(newExpanded ? panel : false);
  };

  const handleRouteChange = (panel) => (event, newExpanded) => {
    setExpandedRoute(newExpanded ? panel : false);
  };

  console.log({ expandedUser, expandedRoute });

  return (
    <div className="container mt-5 mb-4">
      <Typography variant="h5" gutterBottom>
        Listado de Usuarios!
      </Typography>
      {users.map((user) => (
        <Accordion
          key={user.user_id}
          expanded={expandedUser === user.user_id}
          onChange={handleUserChange(user.user_id)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>
              {user.fname} {user.lname} {user.phone}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ margin: 1 }}>
              {user.linkedRoutes.length > 0 ? (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    Rutas
                  </Typography>
                  <Table>
                    <Thead>
                      <Tr className="font-weight-bold">
                        <Th>Ruta</Th>
                        <Th>Zona</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {user.linkedRoutes.map((route) => (
                        <Tr key={`${user.user_id}-${route.creditor_id}`} className="mb-1">
                          <Td>{route.name}</Td>
                          <Td>{route.location}</Td>
                          <Td>
                            <Link to="#">
                              <u>Ver Detalle!</u>
                            </Link>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </>
              ) : (
                <Typography variant="body1" gutterBottom component="div">
                  No rutas vinculada
                </Typography>
              )}
            </Box>
          </AccordionDetails>
          <AccordionActions>
            <Button>Vincunlar Ruta</Button>
          </AccordionActions>
        </Accordion>
      ))}

      <Typography variant="h5" gutterBottom className="mt-5">
        Listado de rutas!
      </Typography>

      {routes.map((route) => (
        <Accordion
          key={route.creditor_id}
          square
          expanded={expandedRoute === route.creditor_id}
          onChange={handleRouteChange(route.creditor_id)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>
              {route.name} ({route.location})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ margin: 1 }}>
              {route.linkedUsers.length > 0 ? (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    Usuarios
                  </Typography>
                  <Table>
                    <Thead>
                      <Tr className="font-weight-bold">
                        <Th>Nombre</Th>
                        <Th>Telefono</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {route.linkedUsers.map((user) => (
                        <Tr key={`${route.creditor_id}-${user.user_id}`} className="mb-1">
                          <Td>{user.fname}</Td>
                          <Td>{user.phone}</Td>
                          <Td>
                            <Link to="#">
                              <u>Ver Detalle!</u>
                            </Link>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </>
              ) : (
                <Typography variant="body1" gutterBottom component="div">
                  No Usuarios vinculado!
                </Typography>
              )}
            </Box>
          </AccordionDetails>
          <AccordionActions>
            <Button>Vincular Usuario</Button>
          </AccordionActions>
        </Accordion>
      ))}
    </div>
  );
}
