import { useEffect } from "react";
import { useContext } from "react";
import { Context } from "../context/DataContext";
import { useParams } from "react-router-dom";
import Loading from "@shared/components/Loading";
import { UseOwnerConfig } from "@shared/server/fetcher";
import { useNavigate } from "@nodes/react-router-dom";
import { replaceNonDigits } from "@shared/functions";

const RedirectPage = () => {
  const navigate = useNavigate();
  const { ced: cedula, dUn: dUniqueId, mId: money_id } = useParams();
  const { state } = useContext(Context);
  const { route } = state;

  const { data: ownerConfig } = UseOwnerConfig(route.user_id);

  useEffect(() => {
    if (!ownerConfig) return;
    if (ownerConfig.autoDepurar === 1 && cedula && replaceNonDigits(cedula) > 0) {
      if (+money_id > 0) {
        navigate(`/debtor/loans/check/${dUniqueId}/${cedula}/${money_id}`, { replace: true });
      } else {
        navigate(`/debtor/loans/check/${dUniqueId}/${cedula}`, { replace: true });
      }
    } else {
      if (+money_id > 0) {
        navigate(`/loan/renewal/${dUniqueId}/${money_id}`, { replace: true });
      } else {
        if (dUniqueId === "newdebtor") {
          navigate(`/debtor/create/${cedula}`, { replace: true });
        } else {
          navigate(`/loan/create/${dUniqueId}`, { replace: true });
        }
      }
    }
  }, [ownerConfig]);

  return <Loading />;
};

export default RedirectPage;
