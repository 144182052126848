import { useState } from "react";
import { formatPhoneNumber } from "../functions";
import Menu from "@nodes/@mui/material/Menu";
import MenuItem from "@nodes/@mui/material/MenuItem";
import { AiOutlinePhone, AiOutlineWhatsApp } from "@nodes/react-icons/ai";
import { MdOutlineSms } from "@nodes/react-icons/md";
import { FcPhone } from "@nodes/react-icons/fc";
import { isAndroid } from "@nodes/react-device-detect";

const RenderPhoneNumber = ({ phone }) => {
  //   if (!phone) return "No Telefono";

  const [anchorEl, setAnchorEl] = useState(null);

  const toggleMenuDisplay = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePhoneCall = () => {
    let url = `tel:1${phone}`;
    window.location.href = url;
  };

  const hanldeWhatsappCall = () => {
    const url = `https://wa.me/1${phone}`;
    window.location.href = url;
  };

  const handleSmsClick = () => {
    let url = `sms:1${phone}`;

    if (isAndroid) {
      url = `sms:1${phone}`;
    }

    window.location.href = url;
  };

  return (
    <div>
      <span aria-controls="simple-menu" aria-haspopup="true" onClick={toggleMenuDisplay}>
        <FcPhone size="1.4em" /> {formatPhoneNumber(phone)}
      </span>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handlePhoneCall}>
          <AiOutlinePhone size="1.4em" /> Llamar {formatPhoneNumber(phone)}
        </MenuItem>
        <MenuItem className="text-success" onClick={hanldeWhatsappCall}>
          <AiOutlineWhatsApp size="1.4em" /> WhatsApp {formatPhoneNumber(phone)}
        </MenuItem>
        <MenuItem className="text-secondary" onClick={handleSmsClick}>
          <MdOutlineSms size="1.4em" /> Texto {formatPhoneNumber(phone)}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default RenderPhoneNumber;
