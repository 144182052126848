import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CheckIcon from "@mui/icons-material/Check";
import SignaturePad from "./SignaturePad";

export default function SignatureDialog({ signature, setSignature, name }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetSignature = (signature) => {
    setOpen(false);
    setSignature(signature);
  };

  return (
    <div>
      <Button
        size="small"
        startIcon={signature ? <CheckIcon /> : <BorderColorIcon />}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}>
        Firma del Cliente
      </Button>

      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <SignaturePad setSignature={handleSetSignature} name={name} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
