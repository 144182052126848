import Box from "@nodes/@mui/material/Box";
import Typography from "@nodes/@mui/material/Typography";
import Grid from "@nodes/@mui/material/Grid";
import { printAmount, printDate, newDate } from "../functions";
import Chip from "@nodes/@mui/material/Chip";

const RenderLoanDetailCredit = ({ loan = {} }) => {
  return (
    <div>
      <Typography variant="h6" component="h4">
        Detalle del Prestamo
        <b className="p-2">
          <Chip label={`P${loan.money_id}`} />
        </b>
      </Typography>

      <Grid container>
        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Entregado
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(newDate(loan.given_date))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              &Uacute;ltimo Pago
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(newDate(loan.last_date))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cantidad
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.amount)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Monto
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.cuota)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Balance
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.balance)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cuotas Pagas
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                {loan.completed} / {loan.npayments}
              </span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default RenderLoanDetailCredit;
