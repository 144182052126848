import { useContext } from "react";
import Box from "@mui/material/Box";
import { Context } from "../context/DataContext";
import { useForm } from "react-hook-form";
import FormLabel from "@mui/material/FormLabel";
import Paper from "@mui/material/Paper";
import { InputText } from "@shared/components/form/InputText";
import { InputRadio } from "@shared/components/form/InputRadio";
import { InputNumber } from "@shared/components/form/InputNumber";
import { InputCustomFormat } from "@shared/components/form/InputCustomFormat";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { compareObjects } from "@shared/functions";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { FaSearch } from "react-icons/fa";
import { DevTool } from "@nodes/@hookform/devtools";

const UserLookup = () => {
  const { passwordResetLookup } = useContext(Context);

  const defaultValues = {
    name: "",
    cedula: "",
    month: "",
    year: "",
    day: "",
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues,
  });

  // const handleSubmitHandler = (data) => passwordResetLookup(data, () => history.replace("/password/resetmethod"));
  const handleSubmitHandler = (data) => {
    if (compareObjects(defaultValues, data)) return;
    console.log(data);
  };

  return (
    <>
      <div className="container mt-3 mb-5">
        <Box sx={{ maxWidth: 500, flexGrow: 1 }}>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              height: 50,
              pl: 2,
              bgcolor: "background.default",
            }}>
            <Typography variant="h5" component="h3" gutterBottom>
              Restablecer Contraña!
            </Typography>
          </Paper>

          <Box sx={{ maxWidth: 600, width: "100%", p: 2 }}>
            <form onSubmit={handleSubmit(handleSubmitHandler)}>
              <Typography variant="subtitle1" component="p" paragraph>
                Ingrese su informacion y le ayudare a establecer una nueva contraseña.
              </Typography>

              <Box paddingBottom={2}>
                <InputText
                  name="name"
                  label="Nombre Completo"
                  control={control}
                  placeholder={"Escriba su nombre nombre!"}
                />
              </Box>

              <Box paddingBottom={3}>
                <InputCustomFormat
                  name="cedula"
                  control={control}
                  label="Cedula"
                  placeholder={"Numero de Cedula!"}
                  configs={{
                    type: "tel",
                    overwrite: false,
                    mask: "#00-0000000-0",
                    definitions: {
                      "#": /[0-9]/,
                    },
                  }}
                />
              </Box>

              <Box paddingBottom={2}>
                <FormLabel component="legend">Fecha de Nacimiento</FormLabel>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <InputNumber
                      name="day"
                      control={control}
                      label="Dia"
                      placeholder={"Que dia nacio?"}
                      configs={{ thousandSeparator: false }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputNumber
                      name="month"
                      control={control}
                      label="Mes"
                      placeholder={"Que mes nacio?"}
                      configs={{ thousandSeparator: false }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputNumber
                      name="year"
                      control={control}
                      label="Año"
                      placeholder={"Que año nacio?"}
                      configs={{ thousandSeparator: false }}
                    />
                  </Grid>
                </Grid>
              </Box>
              {/* 
              <Box paddingTop={2}>
                <InputRadio
                  name="method"
                  control={control}
                  label={<span>Utilizar n&uacute;mero de tel&eacute;fono o correo electronico?</span>}
                  options={[
                    { label: "Telefono", value: "phone" },
                    { label: "Correo Electronico", value: "email" },
                  ]}
                />
              </Box>

              {watch("method") === "phone" && (
                <Box paddingBottom={2}>
                  <InputCustomFormat
                    name="phone"
                    control={control}
                    label="Telefono"
                    placeholder={"Escriba su numero de telefono!"}
                    configs={{
                      helperText: "En el siguiente paso necesitar verificar su numero de telefono!",
                      type: "tel",
                      overwrite: false,
                      mask: "(#00) 000-0000",
                      definitions: {
                        "#": /[1-9]/,
                      },
                    }}
                  />
                </Box>
              )}

              {watch("method") === "email" && (
                <Box paddingBottom={2}>
                  <InputText
                    name="email"
                    label="Correo Electronico"
                    control={control}
                    configs={{
                      type: "email",
                      helperText: "En el siguiente paso necesitar verificar su correo electronico!",
                    }}
                    placeholder={"Escriba su correo electronico!"}
                  />
                </Box>
              )} */}

              <Box paddingBottom={2} paddingTop={2}>
                <Button type="submit" startIcon={<FaSearch />} fullWidth variant={"contained"}>
                  Encontrar Usuario!
                </Button>
              </Box>
              <DevTool control={control} />
            </form>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default UserLookup;
