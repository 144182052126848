import { Controller } from "@nodes/react-hook-form";
import TextField from "@nodes/@mui/material/TextField";
import FormHelperText from "@nodes/@mui/material/FormHelperText";

export const InputText = ({ control, name, label, placeholder, configs = {} }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => (
        <>
          <TextField
            placeholder={placeholder}
            label={label}
            helperText={error ? error.message : null}
            error={!!error}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            fullWidth={configs.fullWidth ?? true}
            multiline={configs.multiline ?? false}
            rows={configs.rows ?? 0}
            maxRows={configs.maxRows ?? 0}
            size={configs.size ?? "small"}
            type={configs.type ?? "text"}
            variant={configs.variant ?? "standard"}
          />
          {configs.helperText && <FormHelperText>{configs.helperText}</FormHelperText>}
        </>
      )}
    />
  );
};
