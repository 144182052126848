import { useContext, useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { Context } from "../context/DataContext";
// import { getLoanSelector } from "../context/Selectors";
// import { toast } from "react-toastify";
// import { printAmount } from "@shared/functions";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderLoanTypeTabs from "@shared/components/RenderLoanTypeTabs";
// import DisplayModal from "@shared/components/DisplayModal";
import RenderNewLoanCalculation from "@shared/components/RenderNewLoanCalculation";
import RenderNewLoanForm from "@shared/components/RenderNewLoanForm";
import RenderOldBalancePayOff from "@shared/components/RenderOldBalancePayOff";
import BasicDatePicker from "@shared/components/BasicDatePicker";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import RenderDisabledCustomer from "@shared/components/RenderDisabledCustomer";
import {
  UseRoute,
  UseDebtors,
  UseDebtorLoansv2,
  UseCustomerBalance,
  UseBankInformationv2,
  UseRouteLoanType,
} from "@shared/server/fetcher";
import { useSWRConfig } from "swr";

const calcMonto = (percentage = 0, amount = 0, npayments = 0) => {
  const amount2 = amount * 1;
  const npayments2 = npayments * 1;
  const percentage2 = percentage * 1;
  if (npayments2 === 0) return 0;

  return Math.ceil(((percentage2 / 100) * amount2 + amount2) / npayments2);
};

const calcEntrega = (amount = 0, actanotarial = 0, adelanto = 0, balance = 0, discount = 0) => {
  const amount2 = amount * 1;
  const balance2 = balance * 1;
  const adelanto2 = adelanto * 1;
  const actanotarial2 = actanotarial * 1;
  const discount2 = discount * 1;
  return amount2 - actanotarial2 - balance2 - adelanto2 - discount2;
};

const calcProfits = (npayments = 0, monto = 0, amount = 0) => {
  const npayments2 = npayments * 1;
  const monto2 = monto * 1;
  const amount2 = amount * 1;
  return npayments2 * monto2 - amount2;
};

const initialFormValues = { amount: "", percentage: "", npayments: "", actanotarial: "", adelanto: "", discount: "" };

const LoanCreate = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { id: dUniqueId, id2: money_id } = useParams();
  const { state, createLoan, createLoanSignature, dispatchAction } = useContext(Context);
  // const [oldBalance, setOldBalance] = useState({ totalBalance: 0, payoffBalance: 0, loansBalance: [] });
  const [formValues, setFormValues] = useState(initialFormValues);
  // const [loanType, setLoanType] = useState(null);

  // const [balanceHighAlert, setBalanceHighAlert] = useState(false);
  // const [balanceLowAlert, setBalanceLowAlert] = useState(false);
  // const [balanceLowModal, setBalanceLowModal] = useState(false);
  // const [balanceHighModal, setBalanceHighModal] = useState(false);
  const { user, route: r2 } = state;
  // const [given_date, setGivenDate] = useState(null);
  const [start_date, setStartDate] = useState(null);

  const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  // const { data: aperturaNext, isLoading: aLoading } = UseNextAperturas();
  const { data: debtors, isLoading: debtorLoading } = UseDebtors(r2.creditor_id, user.user_id);
  const { data: routeLoanTypes, isLoading: ltLoading } = UseRouteLoanType(r2.creditor_id);

  const { data: customer } = UseCustomerBalance(route?.user_id);
  const { data: bankInformation } = UseBankInformationv2(route?.user_id);

  const currentDebtor = debtors?.find((x) => x.dUniqueId === +dUniqueId);

  const { data: loans, isLoading: isLoadingLoans } = UseDebtorLoansv2(currentDebtor?.debtor_id, r2.creditor_id);

  const currentLoan = loans?.find((x) => x.money_id === +money_id);

  useEffect(() => {
    if (!routeLoanTypes) return null;
    setStartDate(routeLoanTypes[currentLoan.type]?.start_date ?? routeLoanTypes?.today_date);
    //eslint-disable-next-line
  }, [currentLoan, routeLoanTypes]);

  const cuota = useMemo(() => {
    return calcMonto(formValues.percentage, formValues.amount, formValues.npayments);
  }, [formValues.percentage, formValues.amount, formValues.npayments]);

  const entrega = useMemo(() => {
    return calcEntrega(formValues.amount, formValues.actanotarial, formValues.adelanto, formValues.discount);
  }, [formValues.amount, formValues.actanotarial, formValues.adelanto, formValues.discount]);

  const profit = useMemo(() => {
    return calcProfits(formValues.npayments, cuota, formValues.amount);
  }, [formValues.npayments, cuota, formValues.amount]);

  if (isLoadingLoans || debtorLoading || routeLoading || ltLoading || !currentLoan) return <Loading />;
  if (customer?.isDisabled && bankInformation?.BANK_OWNER_NAME)
    return <RenderDisabledCustomer bankInformation={bankInformation} />;

  // const handlePayoffBalanceToggle = (money_id, toggle) => {
  //   const { loansBalance, payoffBalance: payOff } = oldBalance;
  //   const index = loansBalance.findIndex((loan) => loan.money_id === money_id);
  //   const balanceLoan = loansBalance[index];
  //   const newBalance = (balanceLoan.isChecked = !toggle);
  //   const payoffBalance = toggle ? payOff - balanceLoan.balance : payOff + balanceLoan.balance;
  //   setOldBalance({ ...oldBalance, payoffBalance, ...newBalance });
  // };

  const initialValues = {
    user_id: user.user_id,
    creditor_id: route.creditor_id,
    name: currentLoan.name,
    today_date: routeLoanTypes?.today_date,
    debtor_id: currentDebtor.debtor_id,
    npayments: currentLoan.npayments,
    percentage: currentLoan.percentage,
    loan_type: currentLoan.type,
    amount: currentLoan.amount,
    actanotarial: currentLoan.actanotarial ?? "",
    payoffBalance: currentLoan.balance,
    adelanto: 0,
    discount: 0,
    confirmNewLoan: false,
  };

  let button;
  const submithandler = async (data) => {
    await submitnewLoan(data);

    mutate(`/loansv2/${route.creditor_id}/${user.user_id}`);
    mutate(`/loan/paymentsv2/${currentLoan.money_id}/${currentLoan.debtor_id}`);
  };

  const allowSignaturesRoutes = [];

  if (!allowSignaturesRoutes.includes(state.route.creditor_id)) {
    button = { label: "Guardar Pr&eacute;stamo", variant: "contained", color: "primary" };
  } else {
    button = {
      icon: <BorderColorIcon />,
      label: "Firma del Cliente",
      color: "primary",
      variant: "outlined",
    };
  }

  const submitnewLoan = async (data) => {
    if (entrega < 1 || data.amount < currentLoan.balance) {
      dispatchAction("setSnackbar", {
        open: true,
        type: "error",
        duration: 2400,
        message: "Cantidad a entregar es Invalida!",
      });
      return;
    }

    // const configAlertPercentage = 30;
    // if (oldBalance.totalBalance > 0) {
    //   const balanceCount = oldBalance.loansBalance.length;
    //   for (let i = 0; i < balanceCount; i++) {
    //     const { npayments, wPayment, balance, isChecked } = oldBalance.loansBalance[i];
    //     const configAlertBalance = (wPayment * npayments * configAlertPercentage) / 100;
    //     if (balance > configAlertBalance && isChecked && !balanceHighAlert) {
    //       setBalanceHighModal(true);
    //       setBalanceHighAlert(true);
    //       return;
    //     }

    //     if (balance <= configAlertBalance && !isChecked && !balanceLowAlert) {
    //       setBalanceLowModal(true);
    //       setBalanceLowAlert(true);
    //       return;
    //     }
    //   }
    // }

    const newData = { ...data, currentLoan, cuota, start_date };

    let redirect = "/loan/signature";

    if (!allowSignaturesRoutes.includes(state.route.creditor_id)) {
      // redirect = "/print/receipt/newloan/" + id;
      // if (state.userConfig.autoPrintRenewal === false) {
      //   redirect = "/entregas";
      // }
      redirect = "/entregas";

      await createLoan(newData, () => navigate(redirect, { replace: true }));
    } else {
      await createLoanSignature(newData, () => navigate(redirect, { replace: true }));
    }
  };

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentLoan}>
          <LoanDetailMenu loan={currentLoan} />
        </DebtorNameHeader>

        <Box component="div" p={1}>
          <Typography variant="h5" component="h4">
            Renovar Pr&eacute;stamo!
          </Typography>

          <Box paddingBottom={1}>
            <Divider />
          </Box>

          <Box div="div" p={1}>
            <RenderNewLoanCalculation data={{ cuota, monto: 0, entrega: entrega - currentLoan.balance, profit }} />
          </Box>

          <Box div="div" p={1}>
            <RenderOldBalancePayOff balance={currentLoan.balance} mora={currentLoan.balMora} />
            {/* <RenderOldBalancePayOff oldBalance={oldBalance} handlePayoffBalanceToggle={handlePayoffBalanceToggle} /> */}
          </Box>

          <Box div="div" p={1}>
            {/* <Box paddingBottom={2}>
              <BasicDatePicker date={given_date} setDate={setGivenDate} label="Fecha de Entrega" />
            </Box> */}
            <Box paddingBottom={2}>
              <BasicDatePicker date={start_date} setDate={setStartDate} label="Fecha de Inicio" disableFuture={false} />
            </Box>
            <RenderNewLoanForm
              setFormValues={setFormValues}
              initialValues={initialValues}
              submithandler={submithandler}
              // duplexNewLoan={duplexNewLoan}
              button={button}
            />
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default LoanCreate;
