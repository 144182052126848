import { useContext } from "react";
import { Context } from "../context/DataContext";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@nodes/@mui/material/Alert";
import Grow from "@mui/material/Grow";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderLoanDetail from "@shared/components/RenderLoanDetail";
import RenderLoanDetailButtons from "@shared/components/RenderLoanDetailButtons";
// import { printAmount } from "@shared/functions";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
import DisplayLastPaymentAlert from "@shared/components/DisplayLastPaymentAlert";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import { UseLoanv2, UseRoute, UseLoansV2 } from "@shared/server/fetcher";
// import NoInternetContent from "@shared/components/NoInternetContent";
import LoanPinned from "../components/LoanPinned";

const LoanDetail = () => {
  const { id: money_id, id2: debtor_id } = useParams();
  const { state, toggleLoanPin } = useContext(Context);
  const { user, route } = state;

  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);
  // const { data: apertura, isLoading: aLoading } = UseSharedApertura(route.creditor_id, route.r_type, route.day);

  const { data: loans, mutate, isLoading: isLoadingL } = UseLoansV2(route.creditor_id, user.user_id);
  const filteredLoan = loans?.find((x) => +x.money_id === +money_id);
  const shouldFetch = !isLoadingL && !filteredLoan ? true : false;
  const { data: currentLoan2, isLoading: isLoadingS } = UseLoanv2(route.creditor_id, money_id, debtor_id, shouldFetch);
  const currentLoan = filteredLoan ?? currentLoan2;

  if ((shouldFetch && isLoadingS) || routeLoading || isLoadingL) return <Loading />;

  // console.log(currentLoan);

  // if (!loan) return <NoInternetContent />;

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentLoan}>
          <div className="" style={{ display: "inline-flex" }}>
            <LoanPinned loan={{ ...routeN, ...currentLoan }} toggleLoanPin={toggleLoanPin} mutate={mutate} />

            <LoanDetailMenu loan={currentLoan} />
          </div>
        </DebtorNameHeader>

        {currentLoan.deletedBy !== 0 && (
          <Box component="div" p={1}>
            <Grow direction="up" in>
              <Alert variant="outlined" severity="error">
                <Typography variant="body1" component="p" className="font-weight-bold">
                  Este pr&eacute;stamo fue cancelado!
                </Typography>
              </Alert>
            </Grow>
          </Box>
        )}

        {currentLoan.paidFromRenewal === true && (
          <Box component="div">
            <div className="alert alert-primary">
              <Typography variant="subtitle2" component="p">
                Este pr&eacute;stamo fue saldado con un renovo!
              </Typography>
            </div>
          </Box>
        )}

        <DisplayLastPaymentAlert loan={currentLoan} />

        {/* {currentLoan.balance > 0 && ( */}
        <Box component="div" p={1}>
          <DisplayLoanStatus loan={currentLoan} />
        </Box>
        {/* )} */}

        <Box component="div" p={1}>
          <RenderLoanDetail loan={currentLoan} showLoanPin />
          <RenderLoanDetailButtons loan={currentLoan} />
        </Box>
      </Container>
    </div>
  );
};

export default LoanDetail;
