import LinearProgress from "@nodes/@mui/material/LinearProgress";
import Typography from "@nodes/@mui/material/Typography";
import Box from "@nodes/@mui/material/Box";

export default function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" size="md" {...props} color="primary" />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          <strong>
            <b>{`${Math.floor(props.value)}%`}</b>
          </strong>
        </Typography>
      </Box>
    </Box>
  );
}
