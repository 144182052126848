// Data key name for Persisting App State.
export const localStorageKey = "wbo-data";
export const localStorageSync = "wbo-sync";
export const localStorageRememberUser = "wbo-remeberUser";
export const localStorageToken = "wbo-tokenV2";
export const localStorageAuth = "authTokenv2";
export const localStorageCurrentRoute = "ummYearsggdjjj";
export const currentWeekRouteDate = "currentWeekRouteDate";
export const localStoragePrintApp = "printApp";

// Charts Colors
// export const colorAdelantos = "#98ee8d";
// export const colorAtrasos = "#3e5a3b";
// export const colorRegularPayment = "#72c068";
// export const colorNewLoans = "#4d88df";
// export const colorActaNoctarial = "#9e85af";
// export const colorGastos = "#cf7777";
// export const colorXcobrar = "#aeb1af";
// export const colorLoanRenewal = "#75aafa";
export const colors = {
  capitalAmount: "#448aff",
  interestAmount: "#ff5252",
  moraAmount: "#7e57c2",
  AtrasosAmount: "#ff9800",
};

//This constant should be 1 on WPrestamos main & 0 on WPrestamos cobro project.
export const mainApp = 1;
export const projectName = "WPrestamos Main";
