import { forwardRef } from "@nodes/react";
import { Controller } from "@nodes/react-hook-form";
import Input from "@nodes/@mui/material/Input";
import { NumericFormat } from "@nodes/react-number-format";
import InputLabel from "@nodes/@mui/material/InputLabel";
import FormControl from "@nodes/@mui/material/FormControl";
import FormHelperText from "@nodes/@mui/material/FormHelperText";

const NumericFormatCustom = forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, prefix, suffix, thousandSeparator, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      valueIsNumericString
      prefix={prefix}
      suffix={suffix}
    />
  );
});

export function InputNumber({ name, control, label, placeholder, configs = {} }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => (
        <>
          <FormControl fullWidth error={!!error} variant="standard">
            <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
            <Input
              placeholder={placeholder ?? label}
              label={label}
              error={!!error}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              name={name}
              fullWidth
              id="formatted-text-mask-input"
              inputProps={{
                // ...configs, this throw out a warning.
                prefix: configs.prefix ?? "",
                suffix: configs.suffix ?? "",
                thousandSeparator: configs.thousandSeparator ?? true,
              }}
              inputComponent={NumericFormatCustom}
            />
            {error?.message && <FormHelperText>{error.message}</FormHelperText>}
            {configs?.helperText && <FormHelperText>{configs.helperText}</FormHelperText>}
          </FormControl>
        </>
      )}
    />
  );
}
