import { useState } from "react";
import Menu from "@nodes/@mui/material/Menu";
import MenuItem from "@nodes/@mui/material/MenuItem";
import MoreVertIcon from "@nodes/@mui/icons-material/MoreVert";
import Divider from "@nodes/@mui/material/Divider";
import IconButton from "@nodes/@mui/material/IconButton";
import { FaRegMoneyBillAlt, FaHandHoldingUsd } from "@nodes/react-icons/fa";
import { AiOutlineFileSync, AiOutlineOrderedList } from "@nodes/react-icons/ai";
import VisibilityIcon from "@nodes/@mui/icons-material/Visibility";
import CancelIcon from "@nodes/@mui/icons-material/Cancel";
import { useNavigate } from "@nodes/react-router-dom";

export default function LoanMenu({ loan }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="settings" onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          className="text-success"
          onClick={() => navigate(`/payment/create/${loan.money_id}/${loan.debtor_id}`)}>
          <FaRegMoneyBillAlt size="1.5em" className="pr-1" />
          Aplicar Pago
        </MenuItem>
        <Divider />
        <MenuItem
          className="text-warning"
          onClick={() => navigate(`/redirect/page/${loan.dUniqueId}/${loan.money_id}/${loan.cedula}`)}>
          <AiOutlineFileSync size="1.4em" className="pr-1" />
          Renovar Prestamo
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate(`/redirect/page/${loan.dUniqueId}/0/${loan.cedula}`)}>
          <FaHandHoldingUsd size="1.4em" className="pr-1" />
          Nuevo Prestamo!
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate(`/loan/detail/${loan.money_id}/${loan.debtor_id}`)} className="text-primary">
          <VisibilityIcon className="pr-1" />
          Prestamo Detalle
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate(`/loan/payments/${loan.money_id}/${loan.debtor_id}`)}>
          <AiOutlineOrderedList size="1.4em" className="pr-1" />
          Historial de pagos
        </MenuItem>
        <Divider />
        <MenuItem className="text-danger" onClick={() => navigate(`/loan/delete/${loan.money_id}/${loan.debtor_id}`)}>
          <CancelIcon className="pr-1" />
          Cancelar Prestamo
        </MenuItem>
      </Menu>
    </div>
  );
}
