
import Typography from "@nodes/@mui/material/Typography";
import Grid from "@nodes/@mui/material/Grid";
import Card from "@nodes/@mui/material/Card";
import CardContent from "@nodes/@mui/material/CardContent";
import { printDate, newDate, replaceArguments } from "../functions";
import RenderShareButtons from "./RenderShareButtons";
import { publicAddress, freeTrialsMonth } from "../constants";

const RenderReferralsInvitations = ({ invitations = [] }) => {
  if (invitations.length === 0) return null;

  const getReferralMessage = () => {
    let message = `Aplicacion de Rutas de Prestamos!\n
Trate esta Web Aplicacion para manejar sus finanzas de rutas de prestamos.
Pruebela gratis por ${freeTrialsMonth} meces, haga Clic en el enlance para registrarse.\n
https://${publicAddress}signup/%token%`;

    return message;
  };

  const getReferralWhatsapp = () => {
    let message = `*Aplicacion de Rutas de Prestamos!*

Trate esta Web Aplicacion para manejar sus finanzas de rutas de prestamos.
Pruebela gratis por ${freeTrialsMonth} meces, haga Clic en el enlance para registrarse.

_https://${publicAddress}signup/%token%_`;

    return message;
  };

  return (
    <div>
      <Typography variant="h5" className="mt-3" gutterBottom>
        Invitaciones Pendiente!
      </Typography>

      <Grid container spacing={1}>
        {invitations.map((referral) => (
          <Grid item xs={12} sm={9} md={6} key={referral.token}>
            <Card raised>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={7}>
                    <Typography variant="h6" component="h3" noWrap>
                      {referral.name}
                    </Typography>
                  </Grid>

                  <Grid align="right" item xs={5}>
                    <span className="badge bg-warning text-dark">
                      <Typography variant="body2">Pendiente</Typography>
                    </span>
                  </Grid>

                  <Grid item xs={7}>
                    <Typography variant="body1">Creado {printDate(newDate(referral.created_date))}</Typography>
                  </Grid>

                  <Grid align="right" item xs={5}>
                    <RenderShareButtons
                      message={replaceArguments(getReferralMessage(), { "%token%": referral.token })}
                      messageWhatsApp={replaceArguments(getReferralWhatsapp(), { "%token%": referral.token })}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RenderReferralsInvitations;
