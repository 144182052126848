import { useState, useEffect } from "@nodes/react";
import { Controller } from "@nodes/react-hook-form";
import { FormControl, FormHelperText, ButtonGroup, Button, Box, InputLabel } from "@nodes/@mui/material";

export const InputButtonGroup = ({ control, name, setValue, value, label, options = [], configs = {} }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setValue(name, inputValue);
  }, [name, setValue, inputValue, value]);

  const handleChange = ({ target }) => {
    setInputValue(target.value);
  };

  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <Button key={option.value} value={option.value} onClick={handleChange} disabled={inputValue === option.value}>
          {option.label}
        </Button>
      );
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => (
        <>
          <FormControl fullWidth={configs.fullWidth ?? true} variant={configs.variant ?? "standard"}>
            <Box paddingBottom={6}>
              <InputLabel>{label}</InputLabel>
            </Box>

            <ButtonGroup
              fullWidth={configs.fullWidth ?? true}
              variant={configs.variant ?? "outlined"}
              color={configs.color ?? "primary"}
              size={configs.size ?? "medium"}>
              {generateSingleOptions()}
            </ButtonGroup>
          </FormControl>
          {configs.helperText && <FormHelperText>{configs.helperText}</FormHelperText>}
        </>
      )}
    />
  );
};
