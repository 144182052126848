import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { string, object } from "yup";
import { Form, RenderInput } from "@shared/components/Form";
import { Context } from "../context/DataContext";
import { toast } from "react-toastify";
import Loading from "@shared/components/Loading";
import { UseUser } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";
import MenuItem from "@mui/material/MenuItem";
import RenderBackButton from "@shared/components/RenderBackButton";
import { useNavigate } from "react-router-dom";

const RouteCreate = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { createRoutev2, getJwtToken } = useContext(Context);
  const localUser = getJwtToken();
  const [newData, setNewData] = useState({
    percentage: "none",
    npayments: "none",
    collectDay: "none",
    routeType: "none",
  });
  const [isSubmitted, setSubmitted] = useState(false);

  const { data: user, isLoading } = UseUser(localUser.user_id);

  if (isLoading) return <Loading />;

  const initialValues = {
    user_id: user.user_id,
    parent_id: user.parent_id,
    routeName: "",
    routePhone: "",
    routePhone2: "",
    address: "",
    routeLocation: "",
  };

  const validation = object({
    routeName: string().required("Nombre de la ruta es requerido!").min(5, "Nombre de la ruta es muy corto!"),
    routePhone: string().required("Numero de telefono es requerido!").min(10, "Numero de telefono invalido!"),
    routePhone2: string().min(10, "Numero de telefono 2 invalido!"),
    routeLocation: string().required("Nombre de la ubicacion es requerido!").min(4, "Nombre de la zona es muy corto!"),
  });

  const button = { label: "Crear Ruta", variant: "contained", color: "primary" };

  const handleDataChange = (event) => {
    if (event.target.name === "routeType") {
      setNewData({ ...newData, [event.target.name]: event.target.value, npayments: "none" });
    } else {
      setNewData({ ...newData, [event.target.name]: event.target.value });
    }
  };

  const submithandler = (routeData) => handleRouteCreate(routeData);

  const handleRouteCreate = async (data) => {
    let { percentage, npayments, collectDay, routeType } = newData;

    if (!["7", "14"].includes(newData.routeType)) {
      collectDay = "0";
    }

    setSubmitted(true);

    if (percentage === "none" || npayments === "none" || collectDay === "none" || routeType === "none") return;

    const res = await createRoutev2({ ...data, percentage, npayments, collectDay, routeType });

    if (res) {
      navigate("/routes/choose", { replace: true });
      mutate(`/user/linked/routes/${user.user_id}`);
      mutate(`/cobro/data/routes/${user.user_id}`);
      // await changeRoute(creditor_id, user, history);
      toast.success("Ruta fue creada!");
    }
  };

  const routeTypeLoopOptions = {
    1: {
      label: "Dias",
      options: [16, 20, 24, 26, 28, 30, 46],
    },
    7: {
      label: "Semanas",
      options: [8, 10, 11, 12, 13, 15],
    },
    14: {
      label: "Quincenas",
      options: [4, 5, 6, 7, 8],
    },
    15: {
      label: "Quincenas",
      options: [4, 5, 6, 7, 8],
    },
    30: {
      label: "Meses",
      options: [3, 4, 5, 6, 7, 8, 9, 10],
    },
  };

  const routeTypeOptions = [
    {
      value: "none",
      label: "Elija tipo de pr&eacute;stamos!",
    },
    {
      value: "1",
      label: "Diarios",
    },
    {
      value: "7",
      label: "Semanales",
    },
    {
      value: "14",
      label: "Bi-Semanal",
    },
    {
      value: "15",
      label: "Quincenales (15 & 30)",
    },
    //This is not displayed because monthly loans doesnt have any option to pay interest.
    // {
    //   value: "30",
    //   label: "Mensuales",
    // },
  ];

  const collectDayOptions = [
    {
      value: "none",
      label: "Elija dia de cobro!",
    },
    {
      value: "1",
      label: "Los Lunes",
    },
    {
      value: "2",
      label: "Los Martes",
    },
    {
      value: "3",
      label: "Los Miercoles",
    },
    {
      value: "4",
      label: "Los Jueves",
    },
    {
      value: "5",
      label: "Los Viernes",
    },
    {
      value: "6",
      label: "Los Sabado",
    },
    {
      value: "7",
      label: "Los Domingo",
    },
  ];

  const percentageOptions = [
    {
      value: "none",
      label: "Elija el porciento de su pr&eacute;stamos!",
    },
    {
      value: "10",
      label: "10%",
    },
    {
      value: "15",
      label: "15%",
    },
    {
      value: "20",
      label: "20%",
    },
    {
      value: "25",
      label: "25%",
    },
    {
      value: "30",
      label: "30%",
    },
    {
      value: "35",
      label: "35%",
    },
    {
      value: "40",
      label: "40%",
    },
  ];

  return (
    <div className="container mt-4 mb-5">
      <Container maxWidth="md" disableGutters>
        <Typography variant="h5" component="h3" gutterBottom>
          Crear nueva Ruta!
        </Typography>
        <Typography variant="subtitle1" component="p" paragraph>
          Las siguientes informacion son requeridad para crear una ruta.
        </Typography>

        <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
          <Box paddingBottom={2}>{RenderInput("routeName", "Nombre de la Empresa", "text", true)}</Box>
          <Box paddingBottom={2}>{RenderInput("routeLocation", "Zona o Ubicacion de la Ruta", "text")}</Box>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("routePhone", "Telefono", "number")}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("routePhone2", "Telefono 2", "number")}</Box>
            </Grid>
          </Grid>

          <Box paddingBottom={2}>
            <TextField
              id="standard-select-currency"
              select
              fullWidth
              label="Tipo de Pr&eacute;stamos"
              value={newData.routeType}
              name="routeType"
              error={isSubmitted && newData.routeType === "none"}
              onChange={handleDataChange}
              // helperText="Cada nuevo pr&eacute;stamo autom&aacute;ticamente usar&aacute; este Porcentaje!"
            >
              {routeTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {["7", "14"].includes(newData.routeType) && (
            <Box paddingBottom={2}>
              <TextField
                error={isSubmitted && newData.collectDay === "none"}
                id="standard-select-currency"
                select
                fullWidth
                name="collectDay"
                label="Dia de Cobro"
                value={newData.collectDay}
                onChange={handleDataChange}
                // helperText="Cada nuevo pr&eacute;stamo autom&aacute;ticamente usar&aacute; este Porcentaje!"
              >
                {collectDayOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}

          <Box paddingBottom={2}>
            <TextField
              error={isSubmitted && newData.percentage === "none"}
              id="standard-select-currency"
              select
              fullWidth
              label="Porcentaje"
              name="percentage"
              value={newData.percentage}
              onChange={handleDataChange}
              helperText="Cada nuevo pr&eacute;stamo autom&aacute;ticamente usar&aacute; este Porcentaje!">
              {percentageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box paddingBottom={2}>
            <TextField
              error={isSubmitted && newData.npayments === "none"}
              id="standard-select-currency"
              select
              fullWidth
              label="Cantidad de Cuotas"
              value={newData.npayments}
              name="npayments"
              onChange={handleDataChange}
              helperText="Cada nuevo pr&eacute;stamo autom&aacute;ticamente usar&aacute; esta cantidad de Cuotas!">
              <MenuItem key="none" value="none">
                Elija la cantidad de cuotas!
              </MenuItem>
              {routeTypeLoopOptions[newData.routeType]?.options.map((npayment) => (
                <MenuItem key={newData.routeType + npayment} value={npayment}>
                  {npayment} {routeTypeLoopOptions[newData.routeType]?.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Form>
        <div className="mt-4">
          <RenderBackButton />
        </div>
      </Container>
    </div>
  );
};

export default RouteCreate;
