import { useContext } from "react";
import { Context } from "../context/DataContext";
import Snacky from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Snackbar = () => {
  const { state, dispatchAction } = useContext(Context);
  const { snackbar } = state;

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    //Default Snackbar propertys.
    dispatchAction("setSnackbar", { open: false, message: "" });
  };

  return (
    <div>
      <Snacky open={snackbar.open} autoHideDuration={snackbar.duration} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.type}>
          {snackbar.message}!
        </Alert>
      </Snacky>
    </div>
  );
};

export default Snackbar;
