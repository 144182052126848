import Box from "@nodes/@mui/material/Box";
import Container from "@nodes/@mui/material/Container";
import Loading from "@shared/components/Loading";

const RenderCedulaImage = ({ cedula, loading }) => {
  if (loading)
    return (
      <div className="mb-5">
        <Loading />
      </div>
    );
  return (
    <Box component="div" paddingBottom={1}>
      <Container>
        <img
          alt="Imagen Cliente"
          src={cedula?.cedulaImg ? cedula.cedulaImg : "/../../images/cedulapalceholder.png"}
          style={{ width: "100%", height: 220 }}
        />
      </Container>
    </Box>
  );
};

export default RenderCedulaImage;
