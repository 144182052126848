import Typography from "@nodes/@mui/material/Typography";
import { UseCustomerBalance } from "../server/fetcher";

const UserBalance = ({ history, owner_id, mainOnly = 1 }) => {
  const { data } = UseCustomerBalance(owner_id, mainOnly);

  const RenderCustomerPaymentReminder = ({ data = {} }) => {
    return (
      <>
        {data.isDisabled ? (
          <div
            className="text-center"
            style={{ backgroundColor: "#000", color: "#fff", fontSize: 12, marginLeft: -1, marginTop: -1 }}>
            <Typography
              variant="body1"
              component="p"
              onClick={() => navigate(`/customer/balance/${owner_id}/${mainOnly}`)}>
              <b>Cuenta bloqueada por falta de pagos!</b>
            </Typography>
          </div>
        ) : data.isDisabledDays > 0 ? (
          <div className="text-center bg-danger" style={{ fontSize: 13, marginLeft: -1, marginTop: -1 }}>
            <Typography
              variant="body1"
              component="p"
              onClick={() => navigate(`/customer/balance/${owner_id}/${mainOnly}`)}>
              <b>Acceso ser&aacute; bloqueado en {data.isDisabledDays} dias!</b>
            </Typography>
          </div>
        ) : (
          <div className="text-center bg-warning" style={{ fontSize: 13, marginLeft: -1, marginTop: -1 }}>
            <Typography
              variant="body1"
              component="p"
              onClick={() => navigate(`/customer/balance/${owner_id}/${mainOnly}`)}>
              <b>Usted tiene pago(s) vencido!</b>
            </Typography>
          </div>
        )}
      </>
    );
  };

  return +data?.balance > 0 ? <RenderCustomerPaymentReminder data={data} /> : null;
};

export default UserBalance;
