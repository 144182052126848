import Typography from "@nodes/@mui/material/Typography";
import Grid from "@nodes/@mui/material/Grid";
import XcobrarCreditCheck from "./XcobrarCreditCheck";
import { getSpanishDays, printAmount } from "../functions";

const RenderCreditCheckActive = ({ loansBreakDown, setModalData }) => {
  return (
    <>
      {loansBreakDown.activeCount > 0 && (
        <div className="mt-2 mb-0">
          <Typography variant="h6" componet="p" align="center" className="bg-success" gutterBottom>
            <b>Prestamos Activos ({loansBreakDown.activeCount})!</b>
          </Typography>
        </div>
      )}

      {Object.keys(loansBreakDown.active).map((collect_day, index) => (
        <div key={index} className="mb-2">
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6" component="p" className="text-warning">
                <b>{getSpanishDays(loansBreakDown.active[collect_day].collect_day)}</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" component="p" className="text-warning text-right">
                <b>Cuota: {printAmount(loansBreakDown.active[collect_day].loanTotal)} </b>
              </Typography>
            </Grid>
          </Grid>

          {loansBreakDown.active[collect_day].loans.map((loan, index2) => (
            <XcobrarCreditCheck key={index + index2} handleLoanClick={setModalData} loan={loan} />
          ))}
        </div>
      ))}
    </>
  );
};

const RenderCreditCheckVencidos = ({ loansBreakDown, setModalData }) => {
  return (
    <>
      {loansBreakDown.vencidosCount > 0 && (
        <div className="mt-3 mb-2">
          <Typography variant="h6" componet="p" align="center" className="bg-danger" gutterBottom>
            <b>Prestamos Lista Negra ({loansBreakDown.vencidosCount})!</b>
          </Typography>
          {loansBreakDown.vencidos.map((loan) => (
            <XcobrarCreditCheck key={loan.mUniqueid} handleLoanClick={setModalData} loan={loan} />
          ))}
        </div>
      )}
    </>
  );
};

const RenderCreditCheckSaldado = ({ loansBreakDown, setModalData }) => {
  return (
    <>
      {loansBreakDown.saldadoCount > 0 && (
        <div className="mt-3 mb-2">
          <Typography variant="h6" componet="p" align="center" className="bg-secondary" gutterBottom>
            <b>Prestamos Saldado ({loansBreakDown.saldadoCount})!</b>
          </Typography>
          {loansBreakDown.saldado.map((loan) => (
            <XcobrarCreditCheck key={loan.mUniqueid} handleLoanClick={setModalData} loan={loan} />
          ))}
        </div>
      )}
    </>
  );
};

const RenderCreditCheckLoans = ({ loansBreakDown, setModalData }) => {
  if (!loansBreakDown) return null;
  return (
    <>
      <RenderCreditCheckActive loansBreakDown={loansBreakDown} setModalData={setModalData} />
      <RenderCreditCheckVencidos loansBreakDown={loansBreakDown} setModalData={setModalData} />
      <RenderCreditCheckSaldado loansBreakDown={loansBreakDown} setModalData={setModalData} />
    </>
  );
};

export default RenderCreditCheckLoans;
