// import Alert from "@mui/material/Alert";
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import Tooltip from "@nodes/@mui/material/Tooltip";

const RenderAPerutaCountDebugging = ({ loan, apertura }) => {
  if (!loan || !apertura)
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading ...</span>
      </div>
    );

  return +loan.a_count === +apertura.a_count ? (
    <>
      <FaCheckCircle size="2.1em" style={{ color: "green" }} />
    </>
  ) : (
    <Tooltip title={`Correct Calculation ${apertura.a_count}!`}>
      <span>
        <MdError size="2.5em" style={{ color: "red" }} />
      </span>
    </Tooltip>
  );
};

export default RenderAPerutaCountDebugging;
