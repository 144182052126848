import backend from "@shared/server/backend";
import { toast } from "react-toastify";
import {
  localStorageAuth,
  localStorageCurrentRoute,
  localStorageRememberUser,
  localStorageToken,
  localStoragePrintApp,
} from "@/config";
import { getTimestamp } from "@shared/functions";
import axios from "@nodes/axios";

const fetchEmployeesLinkedRoutes = (dispatch) => {
  return async (user_id, owner_id) => {
    try {
      const response = await backend.post("/employee/linked/routes", { user_id, owner_id });
      console.log("AppWorkflow - fetchEmployeesLinkedRoutes from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchEmployeesLinkedRoutes", payload: response.data, user_id });
    } catch (err) {
      console.log("AppWorkflow - fetchEmployeesLinkedRoutes from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const fetchAccessCodes = (dispatch) => {
  return async (creditor_id) => {
    try {
      const response = await backend.post("/route/access/codes", { creditor_id });
      console.log("AppWorkflow - fetchAccessCodes from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchAccessCodes", payload: response.data });
    } catch (err) {
      console.log("AppWorkflow - fetchAccessCodes from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const generateAccessCodes = (dispatch) => {
  return async (owner_id, type, codes) => {
    try {
      await backend.post("/central/access/codes/create", { owner_id, type, codes });
      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "success", duration: 3000, message: "Codigos de aceso guardado!" },
      });
    } catch (err) {
      console.log("AppWorkflow - generateAccessCodes from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const registerUser = () => {
  return async (data, callback) => {
    try {
      const client = await axios.get("https://ipapi.co/json/");
      const { country_code: ISO, ip: ipAddress } = client.data;
      const response = await backend.post("/register", { data: { ...data, ISO, ipAddress } });

      const jsonValue = JSON.stringify(response.data.jwt);
      localStorage.setItem(localStorageAuth, jsonValue);

      callback("/new/user");
    } catch (err) {
      console.log("AppWorkflow - registerUser from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const signinUser = () => {
  return async (data, callback) => {
    try {
      let token = localStorage.getItem(localStorageToken);
      if (!token) {
        token = Date.now() + Math.random().toString(36);
        localStorage.setItem(localStorageToken, token);
      }

      data.token = token;
      data.ipAddress = "1.0.0.27";

      if (import.meta.env.PROD) {
        let { data: remoteClient } = await axios.get("https://ipapi.co/json/");
        data.ipAddress = remoteClient.ip;
      }

      const response = await backend.post("/login", { data });

      if (data.rememberUsername) {
        const deviceObj = { username: data.username, firstname: response.data.user.firstname };
        const jsonContent = JSON.stringify(deviceObj);
        localStorage.setItem(localStorageRememberUser, jsonContent);
      }

      const { data: userConfig } = await backend.get(`/user/config/${response.data.user.user_id}`);
      localStorage.setItem(localStoragePrintApp, userConfig.printApp);

      const jsonValue = JSON.stringify(response.data.jwt);
      localStorage.setItem(localStorageAuth, jsonValue);

      callback();
    } catch (err) {
      console.log("AppWorkflow - signinUser from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const createEmployee = (dispatch) => {
  return async (data, callback) => {
    try {
      const response = await backend.post("/employee/create", { data });
      console.log("AppWorkflow - createEmployee from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "createEmployee", payload: response.data });

      callback();
      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "success", duration: 3000, message: "Empleado fue creado!" },
      });
    } catch (err) {
      console.log("AppWorkflow - createEmployee from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const createRoute = (dispatch) => {
  return async (data) => {
    try {
      const response = await backend.post("/route/create", { data });
      console.log("AppWorkflow - createRoute from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "createRoute", payload: response.data });

      return response.data.creditor_id;
    } catch (err) {
      console.log("AppWorkflow - createRoute from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const createRoutev2 = () => {
  return async (data) => {
    try {
      const response = await backend.post("/route/createv2", { data });
      console.log("AppWorkflow - createRoute from dataContext - Promise Resolved data:", response.data);

      return response.data.creditor_id;
    } catch (err) {
      console.log("AppWorkflow - createRoute from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const moveDebtorRoute = () => {
  return async (data) => {
    try {
      const response = await backend.post("/debtor/move", { ...data });
      return response.data;
    } catch (err) {
      console.log("AppWorkflow - createRoute from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const createNewRole = (dispatch) => {
  return async (data) => {
    try {
      const response = await backend.post("/roles/create", data);

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "success", duration: 3000, message: "Nuevo Rol creado!" },
      });

      return response.data;
    } catch (err) {
      console.log(err.message);
    }
  };
};

const modifyRoute = (dispatch) => {
  return async (data) => {
    try {
      const response = await backend.put("/route/modify", { data });
      console.log("AppWorkflow - modifyRoute from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "modifyRoute", payload: response.data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "info", message: "Ruta fue actualizada!" },
      });
    } catch (err) {
      console.log("AppWorkflow - modifyRoute from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const modifyRouteConfig = (dispatch) => {
  return async (data) => {
    try {
      const response = await backend.put("/route/modify/config", { data });
      console.log("AppWorkflow - modifyRoute from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "modifyRoute", payload: response.data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "info", message: "Ruta configuracion guardada!" },
      });
    } catch (err) {
      console.log("AppWorkflow - modifyRoute from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const setDebtorsReorderedMain = () => {
  return async (debtors) => {
    try {
      await backend.post("/debtors/organize", { debtors });
    } catch (err) {
      console.log("AppWorkflow - organizeDebtorsOrder from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const modifyLoan = (dispatch) => {
  return async (loan, callBack) => {
    try {
      await backend.put("/loan/modify", { data: loan });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 5000, type: "info", message: `Pr&eacute;stamo modificado con exito!` },
      });

      callBack();
    } catch (err) {
      console.log("AppWorkflow - loanModify from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const setReferral = (dispatch) => {
  return async (data) => {
    dispatch({ type: "setReferral", payload: data });
  };
};

const toggleLinkedRoute = (dispatch) => {
  return async (user, { creditor_id, route_location }) => {
    try {
      const response = await backend.post("/toggleLinkRoute", { user, creditor_id, route_location });
      console.log("AppWorkflow - toggleLinkedRoute from dataContext - Promise Resolved data:", response.data);

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "info", duration: 3000, message: "Empleado actualizado con exito!" },
      });
    } catch (err) {
      console.log("AppWorkflow - toggleLinkedRoute from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const toggleLoanPin = (dispatch) => {
  return async ({ money_id, isCobro, creditor_id, mUniqueId, pinned }) => {
    try {
      const response = await backend.put("/loan/pinned", { money_id, isCobro, mUniqueId, creditor_id, pinned });
      console.log("AppWorkflow - toggleLinkedRoute from dataContext - Promise Resolved data:", response.data);

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "info", duration: 3000, message: "Pr&eacute;stamo actualizado con exito!" },
      });
    } catch (err) {
      console.log("AppWorkflow - toggleLinkedRoute from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const toggleFaveDebtor = (dispatch) => {
  return async ({ debtor_id, creditor_id, fave }) => {
    try {
      const response = await backend.put("/debtor/fave", { debtor_id, fave });
      console.log("AppWorkflow - toggleLinkedRoute from dataContext - Promise Resolved data:", response.data);

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "info", duration: 3000, message: "Cliente actualizado con exito!" },
      });
    } catch (err) {
      console.log("AppWorkflow - toggleLinkedRoute from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const getDebtorRenewal = (dispatch) => {
  return async (data) => {
    try {
      const response = await backend.post("/debtor/renewal", { data });
      console.log("AppWorkflow - getDebtorRenewal from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "getDebtorRenewal", payload: response.data });
    } catch (err) {
      console.log("AppWorkflow - getDebtorRenewal from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const fetchDebtorImages = (dispatch) => {
  return async (debtor_id, creditor_id) => {
    try {
      const response = await backend.post("/debtor/images", { debtor_id, creditor_id });
      console.log("AppWorkflow - fetchDebtorImages from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchDebtorImages", payload: response.data });
      return response.data;
    } catch (err) {
      console.log("AppWorkflow - fetchDebtorImages from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const createLoan = (dispatch) => {
  return async (data, callback) => {
    console.log("received data", data);
    data.amount = data.amount * 1;
    data.actanotarial = data.actanotarial * 1;
    data.adelanto = data.adelanto * 1;
    const newData = {
      ...data,
      payoffOldLoans: undefined,
      signature: undefined,
      signature2: undefined,
      template: undefined,
      currentLoan: undefined,
    };

    try {
      if (data.payoffBalance > 0) {
        // const totalPayoff = data.payoffOldLoans.length;
        // const loans = data.payoffOldLoans;

        // for (let i = 0; i < totalPayoff; i++) {
        //   if (loans[i].isChecked === false) continue;
        const { money_id, mUniqueId, type, balance, start_date } = data.currentLoan;
        const payoffData = {
          pCash: balance,
          paidFromRenewal: true,
          pMora: 0,
          creditor_id: data.creditor_id,
          name: data.name,
          money_id,
          mUniqueId,
          start_date,
          loan_type: type,
          // mUniqueId: data.mUniqueId,
          // modified_time,
          // payoffLoanId: data.money_id,
          user_id: data.user_id,
          debtor_id: data.debtor_id,
          payment_date: data.today_date,
          // new_debtor: data.new_debtor,
        };

        console.log("payment data", payoffData);

        await backend.post("/payment/createv2", { ...payoffData });
        // }
      }

      const { data: newLoan } = await backend.post("/loan/createv2", { ...newData });

      if (data.adelanto > 0) {
        const adelantaData = {
          pCash: data.adelanto,
          pMora: 0,
          name: data.name,
          money_id: newLoan.money_id,
          creditor_id: data.creditor_id,
          modified_time: "byPass",
          user_id: data.user_id,
          debtor_id: data.debtor_id,
          payment_date: data.given_date,
          new_debtor: data.new_debtor,
        };

        await backend.post("/payment/createv2", { ...adelantaData });
      }

      if (data.signature) {
        const sigData = { ...data, money_id: newLoan.money_id, payoffOldLoans: undefined };

        await backend.post("/save/loan/signature", { data: sigData });
      }

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 5000, type: "success", message: `Pr&eacute;stamo guardado con exito!` },
      });

      callback();
    } catch (err) {
      console.log("AppWorkflow - createNewLoan from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const createLoanWithAtrasos = (dispatch) => {
  return async (data, callback) => {
    data.amount = data.amount * 1;
    data.actanotarial = data.actanotarial * 1;
    data.adelanto = data.adelanto * 1;
    const newData = {
      ...data,
      payoffOldLoans: "replaced",
      signature: "replaced",
      signature2: "replaced",
      template: "replaced",
    };

    try {
      const { data: newLoan } = await backend.post("/loan/old/create", { data: { ...newData } });

      if (data.signature) {
        const sigData = { ...data, money_id: newLoan.money_id, payoffOldLoans: undefined };

        await backend.post("/save/loan/signature", { data: sigData });
      }

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 5000, type: "success", message: `Pr&eacute;stamo guardado con exito!` },
      });

      callback();
    } catch (err) {
      console.log("AppWorkflow - createNewLoan from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const setUserRole = (dispatch) => {
  return async (user, role, creditor_id) => {
    let { role_id } = role;
    let { user_id } = user;
    try {
      const response = await backend.post("/setUserRole", { user_id, role_id, creditor_id });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "info", duration: 3000, message: "Empleado actualizado con exito!" },
      });

      console.log("AppWorkflow - setUserRole from dataContext - Promise Resolved data:", response.data);
    } catch (err) {
      toast.error("Hubo un problema guardando su ultima transacion!");
      console.log("AppWorkflow - setUserRole from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const fetchLoanPayments = (dispatch) => {
  return async (money_id, user_id) => {
    try {
      const response = await backend.post("/loan/payments", { money_id, user_id });
      console.log("AppWorkflow - getLoanPayments from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchLoanPayments", payload: response.data, money_id });
    } catch (err) {
      console.log("AppWorkflow - getLoanPayments from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const changeRoute = (dispatch) => {
  return async (creditor_id, user, history) => {
    // const { user_id: owner_id, creditor_id } = routeData;

    try {
      const { data: route } = await backend.get(`/route/v2/${creditor_id}/${user.user_id}`);
      // const response = await backend.get(`/loans/${creditor_id}/${user_id}/${response3.data.next_week_date}`);
      // const response2 = await backend.get(`/debtors/${creditor_id}/${user_id}`);
      // const response4 = await backend.post(`/route/config/${creditor_id}`);
      // const response5 = await backend.post("/user/route/permission", { user_id, creditor_id, owner_id });

      // dispatch({ type: "fetchRouteInfo", payload: route });

      const routeInfo = {
        creditor_id: route.creditor_id,
        user_id: route.user_id,
        r_type: route.r_type,
        day: route.day,
        owner_id: route.user_id,
        route_name: route.route_name,
        location: route.location,
        // current_week_date: route.current_week_date,
        // current_range_date: route.current_range_date,
        // next_week_date: route.next_week_date
      };

      const jsonValue = JSON.stringify({
        ...routeInfo,
      });

      localStorage.setItem(localStorageCurrentRoute, jsonValue);
      dispatch({ type: "fetchRouteInfo", payload: { route: routeInfo, user: user } });

      // dispatch({ type: "fetchRouteInfo", payload: response3.data, creditor_id });
      // dispatch({ type: "updateRouteConfig", payload: response4.data, creditor_id });
      // dispatch({ type: "setPermissions", payload: response5.data, creditor_id });
      // dispatch({ type: "fetchLoans", payload: response.data, creditor_id });
      // dispatch({ type: "fetchDebtors", payload: response2.data, creditor_id });

      //From these pages, route change will not redirect to /dashboard Page.
      // const excludedPath = ["/debtors", "/collected", "/entregas", "/employees", "/loans", "/settings", "/spenses"];
      // if (!excludedPath.includes(history.location.pathname)) {
      //   history.replace("/insert/dianostics");
      // }
    } catch (e) {
      console.log("AppWorkflow - getRouteInfo from dataContext");
    }
  };
};

//This is not needed because sharedAction share the same fucntion for both projects
// const updateUserConfig = () => {
//   return async (user_id, data, callback) => {
//     try {
//       await backend.put("/user/config", { user_id, ...data });
//       await callback();
//       // dispatch({ type: "updateUserConfig", payload: response.data });
//     } catch (err) {
//       console.log("AppWorkflow - updateDebtor from dataContext - Catch error");
//       console.log(err.message);
//     }
//   };
// };

// const cloneRouteConfigs = () => {
//   return async (data) => {
//     try {
//       await backend.post("/route/clone/config", { data });
//     } catch (err) {
//       console.log("AppWorkflow - cloneRouteConfigs from dataContext - Catch error");
//       console.log(err.message);
//     }
//   };
// };

//This is not needed because sharedAction share the same fucntion for both projects
// const updateRouteConfig = () => {
//   return async (creditor_id, data, callback) => {
//     try {
//       await backend.put("/route/config", { creditor_id, ...data });
//       await callback();
//       // dispatch({ type: "updateRouteConfig", payload: response.data });
//     } catch (err) {
//       console.log("AppWorkflow - updateDebtor from dataContext - Catch error");
//       console.log(err.message);
//     }
//   };
// };

const fetchNewLoans = (dispatch) => {
  return async (creditor_id, dates) => {
    try {
      const response = await backend.post("/loans/new", { creditor_id, ...dates });
      console.log("AppWorkflow - fetchNewLoans from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchNewLoans", payload: response.data, creditor_id, dates });
    } catch (e) {
      console.log("AppWorkflow - fetchNewLoans from dataContext - Restored State");
    }
  };
};

const fetchDebtors = (dispatch) => {
  return async (creditor_id, user_id) => {
    try {
      const response = await backend.get(`/debtors/${creditor_id}/${user_id}`);
      console.log("AppWorkflow - getDebtors from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchDebtors", payload: response.data, creditor_id });
    } catch (err) {
      console.log("AppWorkflow - getDebtors from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const fetchDebtor = (dispatch) => {
  return async (debtor_id, user_id) => {
    try {
      const response = await backend.get(`/debtor/${debtor_id}/${user_id}`);
      console.log("AppWorkflow - getDebtor from dataContext - Promise Resolved data:", response.data);
      dispatch({ type: "fetchDebtor", payload: response.data });
    } catch (err) {
      console.log("AppWorkflow - getDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const changeApertura = (dispatch) => {
  return async (creditor_id, user_id, week_date, collect_date) => {
    try {
      await backend.post("/route/toggle/apertura", { creditor_id, user_id, week_date, collect_date });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "success", duration: 3000, message: "Apertura de cobro actualizada!" },
      });
    } catch (err) {
      console.log("AppWorkflow - getDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const fetchLoans = (dispatch) => {
  return async (user_id, creditor_id, next_week_date) => {
    try {
      const response = await backend.get(`/loans/${creditor_id}/${user_id}/${next_week_date}`);
      console.log("AppWorkflow - getLoans from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchLoans", payload: response.data, creditor_id });
    } catch (err) {
      console.log("AppWorkflow - getLoans from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const fetchLoanDetail = (dispatch) => {
  return async (money_id) => {
    try {
      const response = await backend.post("/loan/detail", { money_id });
      console.log("AppWorkflow - getLoan from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchLoan", payload: response.data });
    } catch (e) {
      console.log("AppWorkflow - getLoan from dataContext - Restored State");
    }
  };
};

const updateBankAmount = (dispatch) => {
  return async (data) => {
    try {
      await backend.put("/route/bank", { ...data });
      // dispatch({ type: "updateBankAmount", payload: { data } });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "info", duration: 3000, message: "Banco actualizado con exito!" },
      });
    } catch (err) {
      console.log("AppWorkflow - updateBankAmount from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const updateEfectivoAmount = (dispatch) => {
  return async (data) => {
    try {
      await backend.put("/route/efectivo", { ...data });
      // dispatch({ type: "updateEfectivoAmount", payload: data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "info", duration: 3000, message: "Efectivo actualizado con exito!" },
      });
    } catch (err) {
      console.log("AppWorkflow - updateEfectivoAmount from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const fetchCobroList = (dispatch) => {
  return async (creditor_id, current_week) => {
    try {
      const response = await backend.post("/route/calculate/cobro", { creditor_id, current_week });
      console.log("AppWorkflow - fetchCobroList from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchCobroList", payload: response.data });
    } catch (e) {
      console.log("AppWorkflow - fetchCobroList from dataContext - Restored State");
    }
  };
};

const fetchCobroListChanges = (dispatch) => {
  return async (creditor_id, current_week) => {
    try {
      const response = await backend.post("/route/calculate/cobro/changes", { creditor_id, current_week });
      console.log("AppWorkflow - fetchCobroListChanges from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchCobroListChanges", payload: response.data });
    } catch (e) {
      console.log("AppWorkflow - fetchCobroListChanges from dataContext - Restored State");
    }
  };
};

const fetchCuadreData = (dispatch) => {
  return async (creditor_id, current_week) => {
    try {
      const response = await backend.get(`/route/cuadre/${creditor_id}/${current_week}`);
      console.log("AppWorkflow - fetchCuadre from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchCuadre", payload: response.data });
    } catch (e) {
      console.log("AppWorkflow - fetchCuadre from dataContext - Restored State");
    }
  };
};

const createDebtor = (dispatch) => {
  return async (data2) => {
    try {
      const { data } = await backend.post("/debtor/create", { data: data2 });
      console.log("AppWorkflow - createDebtor from dataContext - Promise Resolved data:", data);
      // dispatch({ type: "createDebtor", payload: data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "success", message: "Cliente fue registrado!" },
      });

      return data.dUniqueId;
    } catch (err) {
      console.log("AppWorkflow - createDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const saveUserCedula = () => {
  return async (user_id, { cedula }) => {
    try {
      await backend.post("/save/user/cedula", { user_id, cedula });
      return true;
    } catch (err) {
      console.log("AppWorkflow - createDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const createDebtorUnique = (dispatch) => {
  return async (data2) => {
    try {
      const { data } = await backend.post("/debtor/create/unique", { data: data2 });
      console.log("AppWorkflow - createDebtor from dataContext - Promise Resolved data:", data);
      // dispatch({ type: "createDebtor", payload: data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "success", message: "Cliente fue registrado!" },
      });

      return data.debtor_id;
    } catch (err) {
      console.log("AppWorkflow - createDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const updateDebtor = (dispatch) => {
  return async (data) => {
    try {
      const response = await backend.put("/debtor", { data });
      console.log("AppWorkflow - updateDebtor from dataContext - Promise Resolved data:", response.data);

      dispatch({
        type: "setSnackbar",
        payload: { open: true, duration: 3000, type: "info", message: `Cliente ${data.name} fue actualizado!` },
      });
    } catch (err) {
      console.log("AppWorkflow - updateDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const fetchRouteData = (dispatch) => {
  return async (user_id, creditor_id) => {
    try {
      const response = await backend.post("/dashboard", { user_id, creditor_id });
      console.log("AppWorkflow - getRouteData from dataContext - Promise Resolved data:", response.data);

      // dispatch({ type: "fetchRouteData", payload: response.data, creditor_id });
    } catch (err) {
      console.log(err.message);
      console.log("AppWorkflow - getRouteData from dataContext - Catch Error");
    }
  };
};

const fetchEmployees = (dispatch) => {
  return async (user_id, creditor_id) => {
    try {
      const response = await backend.post("/employees", { user_id, creditor_id });
      console.log("AppWorkflow - fetchEmployees from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchEmployees", payload: response.data, creditor_id });
    } catch (err) {
      console.log("AppWorkflow - fetchEmployees from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const fetchCollected = (dispatch) => {
  return async (creditor_id, dates) => {
    try {
      const response = await backend.post("/loans/collected", { creditor_id, ...dates });
      console.log("AppWorkflow - fetchCollected from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchCollected", payload: response.data, creditor_id, dates });
    } catch (e) {
      console.log("AppWorkflow - fetchCollected from dataContext - Restored State");
    }
  };
};

const createPayment = (dispatch) => {
  return async (data) => {
    data.pMora = data.pMora * 1;
    data.pCash = data.pCash - data.pMora;
    data.timestamp = getTimestamp();

    try {
      const response = await backend.post("/payment/createv2", { ...data });
      // await mutate({ ...loan, ...response.data });
      // console.log("AppWorkflow - createPayment from dataContext - Promise Resolved data:", response.data);

      // console.log(response.data);
      // return;
      // dispatch({ type: "createPayment", payload: response.data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "success", duration: 3000, message: "Pago registado con exito!" },
      });

      return response.data;
    } catch (err) {
      console.log("AppWorkflow - createPayment from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const createGasto = (dispatch) => {
  return async (data) => {
    try {
      await backend.post("/gasto/create", { data });
      // dispatch({ type: "createGasto", payload: response.data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "success", duration: 3000, message: "Gasto registado con exito!" },
      });
    } catch (err) {
      console.log("AppWorkflow - createGasto from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const deletePayment = (dispatch) => {
  return async (data) => {
    try {
      await backend.delete(
        `/payment/${data.pUniqueId}/${data.debtor_id}/${data.user_id}/${data.mUniqueId}/${data.creditor_id}`
      );

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "warning", duration: 3000, message: "Pago borrado con exito!" },
      });
    } catch (err) {
      console.log("AppWorkflow - deletePayment from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const deleteRoute = (dispatch) => {
  return async (data, callBack) => {
    try {
      await backend.post("/route/delete", { ...data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "warning", duration: 3000, message: "Ruta borrada con exito!" },
      });

      callBack("/routes/choose", { replace: true });
    } catch (err) {
      console.log("AppWorkflow - deletePayment from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const deleteRouteLoans = (dispatch) => {
  return async (data, callBack) => {
    try {
      await backend.post("/routes/debtors/loans/delete", { ...data });
      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "warning", duration: 3000, message: "Pr&eacute;stamos borrado con exitos!" },
      });

      callBack("/dashboard", { replace: true });
    } catch (err) {
      console.log("AppWorkflow - deletePayment from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const deleteOwnerLoans = (dispatch) => {
  return async (data, callBack) => {
    try {
      await backend.post("/owner/debtors/loans/delete", { ...data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "warning", duration: 3000, message: "Pr&eacute;stamos borrado con exitos!" },
      });

      callBack("/dashboard", { replace: true });
    } catch (err) {
      console.log("AppWorkflow - deletePayment from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const uploadDebtorImage = (dispatch) => {
  return async (data) => {
    try {
      await backend.post("/cobro/image/upload", { ...data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "success", duration: 4000, message: "Imagen de cliente guarada!" },
      });
    } catch (err) {
      console.log("AppWorkflow - appendDebtorImage from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const deleteEmployee = (dispatch) => {
  return async ({ owner_id, employee_id, deletedBy }, callBack) => {
    try {
      await backend.delete(`/employee/delete/${owner_id}/${employee_id}/${deletedBy}`);

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "warning", duration: 3000, message: "Empleado borrado con exito!" },
      });

      callBack(`/employee/${employee_id}`, { replace: true });
    } catch (err) {
      console.log("AppWorkflow - deletePayment from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const deleteGasto = (dispatch) => {
  return async (data) => {
    try {
      await backend.delete(`/gasto/${data.id}/${data.creditor_id}/${data.user_id}`);
      // dispatch({ type: "deleteGasto", payload: response.data });

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "warning", duration: 3000, message: "Gasto borrado con exito!" },
      });
    } catch (err) {
      console.log("AppWorkflow - deleteGasto from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const debtorDelete = (dispatch) => {
  return async (debtor_id, user_id, history) => {
    try {
      await backend.delete(`/debtor/${debtor_id}/${user_id}`);
      // dispatch({ type: "debtorDelete", payload: response.data });

      history.replace("/dashboard");

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "warning", duration: 3000, message: "Cliente borrado con exito!" },
      });
    } catch (err) {
      console.log("AppWorkflow - deleteGasto from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const loanDelete = (dispatch) => {
  return async (money_id, mUniqueId, creditor_id, user_id, callBack) => {
    try {
      // await backend.delete(`/loan/${money_id}/${user_id}`);
      await backend.delete(`/loan/${money_id}/${mUniqueId}/${creditor_id}/${user_id}`);
      // dispatch({ type: "loanDelete", payload: response.data });

      callBack();

      dispatch({
        type: "setSnackbar",
        payload: { open: true, type: "warning", duration: 3000, message: "Pr&eacute;stamos borrado con exito!" },
      });
    } catch (err) {
      console.log("AppWorkflow - deleteGasto from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const fetchGastos = (dispatch) => {
  return async (creditor_id, dates) => {
    try {
      const response = await backend.post("/spenses", { creditor_id, ...dates });
      console.log("AppWorkflow - fetchGastos from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "fetchGastos", payload: response.data, creditor_id, dates });
    } catch (err) {
      console.log("AppWorkflow - fetchGastos from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const toggleDarkMode = (dispatch) => {
  return () => {
    dispatch({ type: "toggleDarkMode" });
  };
};

const getDebtorLoans = (dispatch) => {
  return async (data) => {
    try {
      const response = await backend.post("/debtor/loans", { data });
      console.log("AppWorkflow - getDebtorLoans from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "getDebtorLoans", payload: { loans: response.data, ...data } });
    } catch (err) {
      console.log("AppWorkflow - getDebtorLoans from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const getUserRoutesBalance = (dispatch) => {
  return async (owner_id, callback = null) => {
    try {
      const response = await backend.post("/user/routes/balance", { owner_id });
      console.log("AppWorkflow - getUserRoutesBalance from dataContext - Promise Resolved data:", response.data);
      // dispatch({ type: "getUserRoutesBalance", payload: response.data });
      if (response.data.balance > 0 && callback) callback();
    } catch (err) {
      console.log("AppWorkflow - getUserRoutesBalance from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const getContractoLetter = () => {
  return async (creditor_id) => {
    try {
      const response = await backend.post("/route/contract/letter", { creditor_id });
      console.log("AppWorkflow - getContractoLetter from dataContext - Promise Resolved data:", response.data);
      return response.data;
    } catch (err) {
      console.log("AppWorkflow - getContractoLetter from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const getAtrasosLetter = () => {
  return async (creditor_id) => {
    try {
      const response = await backend.post("/route/contract/letter", { creditor_id });
      console.log("AppWorkflow - getAtrasosLetter from dataContext - Promise Resolved data:", response.data);
      return response.data;
    } catch (err) {
      console.log("AppWorkflow - getAtrasosLetter from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

export {
  signinUser,
  createGasto,
  createPayment,
  getUserRoutesBalance,
  fetchGastos,
  getContractoLetter,
  setUserRole,
  createRoute,
  createRoutev2,
  uploadDebtorImage,
  fetchEmployeesLinkedRoutes,
  fetchCollected,
  registerUser,
  toggleLinkedRoute,
  createEmployee,
  fetchEmployees,
  setReferral,
  createLoan,
  toggleDarkMode,
  getDebtorRenewal,
  getAtrasosLetter,
  getDebtorLoans,
  fetchLoans,
  fetchDebtor,
  fetchDebtors,
  createDebtor,
  createDebtorUnique,
  updateDebtor,
  fetchRouteData,
  changeRoute,
  fetchNewLoans,
  fetchCuadreData,
  fetchCobroList,
  deleteGasto,
  loanDelete,
  modifyLoan,
  debtorDelete,
  fetchCobroListChanges,
  fetchLoanDetail,
  fetchLoanPayments,
  modifyRoute,
  deleteOwnerLoans,
  deleteRouteLoans,
  modifyRouteConfig,
  fetchAccessCodes,
  generateAccessCodes,
  createLoanWithAtrasos,
  toggleLoanPin,
  toggleFaveDebtor,
  fetchDebtorImages,
  deletePayment,
  deleteRoute,
  deleteEmployee,
  changeApertura,
  updateBankAmount,
  updateEfectivoAmount,
  moveDebtorRoute,
  saveUserCedula,
  setDebtorsReorderedMain,
  createNewRole,
};
