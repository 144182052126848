// import esLocale from "@nodes/date-fns/locale/es";
// import DateFnsUtils from "@nodes/@date-io/date-fns";
// import DatePicker from "@nodes/@mui/lab/DatePicker";
// import AdapterDateFns from "@nodes/@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@nodes/@mui/lab/LocalizationProvider";
// import { LocalizationProvider } from "@nodes/@mui/x-date-pickers";
import { LocalizationProvider } from "@nodes/@mui/x-date-pickers";
import { DatePicker } from "@nodes/@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@nodes/@mui/x-date-pickers/AdapterDateFns";
import { newDate } from "@shared/functions";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}

function BasicDatePicker({ label, date, setDate, openTo = "day", disableFuture = true }) {
  const handleChange = (e) => {
    setDate(formatDate(e));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableFuture={disableFuture}
        slotProps={{ textField: { fullWidth: true } }}
        // openTo={openTo}
        // inputFormat="dd MMMM, yyyy"
        label={label}
        // views={["year", "month", "date"]}
        value={date ? new Date(newDate(date)) : null}
        onChange={handleChange}
      />
    </LocalizationProvider>
  );
}

export default BasicDatePicker;
