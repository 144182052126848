import { useContext } from "react";
import { Context } from "../context/DataContext";
import DashboardDR from "./DashboardDR";
// import DashboardUS from "./DashboardUS";
import Loading from "@shared/components/Loading";
import { UseLoansV2, UseRoute, UseAPerturaCuadre, UseOwnerConfig } from "@shared/server/fetcher";
// import NoInternetContent from "@shared/components/NoInternetContent";

const Dashboard = () => {
  const { state } = useContext(Context);
  const { user, route: r2 } = state;

  // const { data: apertura, isLoading: aLoading } = UseSharedApertura(r2.creditor_id, r2.r_type, r2.day);
  const { data: loans, isLoading } = UseLoansV2(r2.creditor_id, user.user_id);
  const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  UseAPerturaCuadre(r2.creditor_id);
  UseOwnerConfig(r2.user_id);

  if (isLoading || routeLoading) return <Loading />;

  // console.log({ apertura, loans, route });
  // if (!loans) return <NoInternetContent />;

  // if (!lastFetch.debtors || !lastFetch.loans) return <FullLoadingScreen />;
  // return <DashboardUS />;
  return <DashboardDR loans={loans} route={route} />;
};

export default Dashboard;
