import NewLoansMenu from "../components/NewLoansMenu";
import Card from "@nodes/@mui/material/Card";
import Divider from "@nodes/@mui/material/Divider";
import CardHeader from "@nodes/@mui/material/CardHeader";
import CardContent from "@nodes/@mui/material/CardContent";
import PersonIcon from "@nodes/@mui/icons-material/Person";
import Typography from "@nodes/@mui/material/Typography";
import { NumericFormat } from "@nodes/react-number-format";
// import { MdOutlineCloudDone, MdOutlineCloudOff } from "@nodes/react-icons/md";
// import { mainApp } from "@/config";

// const RenderSyncIcon = ({ loan }) => {
//   if (mainApp === 1) return null;
//   return loan.synced === true ? (
//     <MdOutlineCloudDone size="0.8em" className="text-success" />
//   ) : (
//     <MdOutlineCloudOff size="0.8em" className="text-danger" />
//   );
// };

const NewLoansCard = ({ loan, route, user, userConfig }) => {
  return (
    <Card variant="outlined">
      <CardHeader
        action={<NewLoansMenu route={route} user={user} loan={loan} userConfig={userConfig} />}
        title={
          <Typography variant="h6" component="h4">
            {/* <RenderSyncIcon loan={loan} /> */}
            <PersonIcon size="small" /> {loan.name}
          </Typography>
        }
        subheader={
          <>
            {loan.payoffBalance > 0 && (
              <Typography variant="body2" component="p" className="text-info font-weight-bold">
                Balance anterior saldado:{" "}
                {
                  <NumericFormat
                    value={loan.payoffBalance}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                }
                !
              </Typography>
            )}
          </>
        }
      />
      <CardContent>
        <Typography variant="body1" component="p">
          Cantidad Prestada:{" "}
          {<NumericFormat value={loan.amount} displayType={"text"} thousandSeparator={true} prefix={"$"} />}
        </Typography>
        <Typography variant="body1" component="p">
          Cuotas: {<NumericFormat value={loan.cuota} displayType={"text"} thousandSeparator={true} prefix={"$"} />} X{" "}
          {loan.npayments}
        </Typography>
        <Typography variant="body1" component="p">
          Acta Notarial:{" "}
          {<NumericFormat value={loan.actanotarial} displayType={"text"} thousandSeparator={true} prefix={"$"} />}
        </Typography>
        {+loan.discount > 0 && (
          <Typography variant="body1" component="p" className="text-danger">
            Descuento Adicional:{" "}
            {<NumericFormat value={loan.discount} displayType={"text"} thousandSeparator={true} prefix={"$"} />}
          </Typography>
        )}
        {+loan.adelanto > 0 && (
          <Typography variant="body1" component="p" className="text-success">
            Pago Adelanto:{" "}
            {<NumericFormat value={loan.adelanto} displayType={"text"} thousandSeparator={true} prefix={"$"} />}
          </Typography>
        )}
        <Typography variant="subtitle1" component="p" className="font-weight-bold">
          Cantidad Entregada:{" "}
          {
            <NumericFormat
              value={loan.amount - loan.actanotarial - loan.adelanto - loan.discount - loan.payoffBalance}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          }
        </Typography>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default NewLoansCard;
