import { useContext } from "react";
import { string, object, mixed, addMethod } from "yup";
import { Form, RenderInput } from "@shared/components/Form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Loading from "@shared/components/Loading";
import { Context } from "../context/DataContext";
import { UseEmployees, UseRoute, UseUser } from "@shared/server/fetcher";
import { validateCedula } from "@shared/functions";
import RenderBackButton from "@shared/components/RenderBackButton";
import { useNavigate } from "react-router-dom";

const EmployeeCreate = () => {
  const navigate = useNavigate();
  const { state, createEmployee } = useContext(Context);
  const { user, route } = state;

  const { data: userN, isLoading: isL1 } = UseUser(user.user_id);
  const { data: routeN, isLoading: isL2 } = UseRoute(route.creditor_id, userN?.user_id);
  const { isLoading: isL3, mutate } = UseEmployees(route.creditor_id, routeN?.user_id);

  if (isL1 || isL2 || isL3) return <Loading />;

  const initialValues = {
    parent_id: routeN?.user_id,
    ISO: user.ISO,
    fname: "",
    lname: "",
    email: "",
    cedula: "",
    phone: "",
    username: "",
    password: "",
  };

  addMethod(mixed, "validateCedula", validateCedula);
  const validation = object({
    fname: string().required("Nombre del usuario es requerido!").min(3, "Nombre del usuario es muy corto!"),
    lname: string().required("Apellido del usuario es requerido!").min(4, "Apellido del usuario es muy corto!"),
    phone: string()
      .required("Numero de telefono es requerido!")
      .min(10, "Numero de telefono invalido!")
      .max(18, "Numero de telefono invalido!"),
    username: string()
      .required("Usuario es requerido!")
      .min(4, "Su usuario debe ser minimos 4 letras/ numero!")
      .max(15, "Su usuario debe ser maximo 15 letras / numeros!")
      .matches(/^([a-zA-Z0-9_-]+)$/, "Su usuario solo puede tener letras y numeros!"),
    password: string()
      .required("Contraseña es requerida!")
      .min(7, "Su contraseña debe ser minimo 7 letras / numeros!")
      .max(25, "Su contraseña debe ser maximo 25 letras / numeros!"),
    cedula: mixed().validateCedula(),
  });

  const button = { label: "Crear Usuario", variant: "contained", color: "primary" };

  const submithandler = async (data) => {
    await mutate(
      createEmployee(data, () => navigate("/employees", { replace: true })),
      {
        populateCache: false,
      }
    );
  };

  return (
    <div className="container mt-4 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Crear nuevo Empleado!
      </Typography>
      <Typography variant="subtitle1" component="p" paragraph>
        Ingrese las siguientes Informacion personal del nuevo empleado.
      </Typography>

      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={2}>{RenderInput("fname", "Nombre", "text", true)}</Box>
        <Box paddingBottom={2}>{RenderInput("lname", "Apellido", "text")}</Box>
        <Box paddingBottom={2}>{RenderInput("phone", "Numero de Telefono", "number", false)}</Box>
        <Box paddingBottom={4}>{RenderInput("cedula", "Cedula", "tel")}</Box>

        <hr />
        <h4 className="m-4">Credencial!</h4>
        <p>En la siguiente casilla escriba el usuario y una conraseña que sera utilizada para iniciar session.</p>
        <Box paddingBottom={3}>{RenderInput("username", "Usuario")}</Box>
        <Box paddingBottom={3}>{RenderInput("password", "Contraseña")}</Box>
      </Form>

      <div className="mt-4">
        <RenderBackButton />
      </div>
    </div>
  );
};

export default EmployeeCreate;
