import { useContext } from "react";
import { Context } from "../context/DataContext";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CreateOldReferral from "@shared/components/CreateOldReferral";
import RenderReferrals from "@shared/components/RenderReferrals";
import RenderReferralsInvitations from "@shared/components/RenderReferralsInvitations";
import RenderReferralBalance from "@shared/components/RenderReferralBalance";
import CreateReferralModal from "@shared/components/CreateReferralModal";
import Loading from "@shared/components/Loading";
import { UseReferralsPending, UseReferralsAccepted } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";

const Referrals = () => {
  const { mutate: globalMutate } = useSWRConfig();

  const { state, createReferralLink, createOldReferral } = useContext(Context);
  const { user } = state;
  const { data: referralsAccepted, isLoading: isLoadingAccepted, key } = UseReferralsAccepted(user.user_id);
  const { data: referralsPending, isLoading: isLoadingPending, mutate } = UseReferralsPending(user.user_id);

  const handleCreateReferral = async (data) => {
    const newReferral = await createReferralLink({ ...data, user_id: user.user_id });
    mutate([...referralsPending, newReferral]);
    return newReferral;
  };

  const handleCreateOldReferral = async (data) => {
    await createOldReferral(data);
    globalMutate(key);
  };

  return (
    <div className="mt-4 mb-5">
      <Container maxWidth="lg" disableGutters>
        <Box component="div" p={1}>
          <RenderReferralBalance referrals={referralsAccepted?.referrals} balance={referralsAccepted?.balance} />
        </Box>

        <Container maxWidth="xs">
          <CreateReferralModal callBack={handleCreateReferral} />
        </Container>

        {isLoadingPending ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <RenderReferralsInvitations invitations={referralsPending} />
          </Box>
        )}

        {isLoadingAccepted ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <RenderReferrals referrals={referralsAccepted.referrals} />
          </Box>
        )}

        <Box component="div" p={1} className="mt-3">
          <CreateOldReferral handleCreateReferral={handleCreateOldReferral} user_id={user.user_id} />
        </Box>
      </Container>
    </div>
  );
};

export default Referrals;
