import { useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import RouteModify from "./RouteModify";
import RouteConfig from "./RouteConfig";
// import Modalidad from "./Modalidad";
import LoansTypes from "./LoansTypes";
// import AccessCodes from "./AccessCodes";
// import Aperturas from "./Aperturas";
// import AtrasosLetter from "./AtrasosLetter";
// import Contract from "./Contract";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };

  return (
    <>
      <Tabs
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={selectedTab}
        onChange={handleTabChange}>
        <Tab label="Ruta" />
        <Tab label="Configuaciones" />
        <Tab label="Pr&eacute;stamos" />
        {/* <Tab label="Mora" /> */}
        {/* <Tab label="Codigo Aceso" /> */}
        <Tab label="Cronograma" />
        {/* <Tab label="Apertura" /> */}
        {/* <Tab label="Modalidad" /> */}
        {/* <Tab label="Contrato" /> */}
        {/* <Tab label="Carta de Atraso" />
        <Tab label="Carta de Saldo" /> */}
      </Tabs>

      {selectedTab === 0 && <RouteModify />}
      {selectedTab === 1 && <RouteConfig />}
      {selectedTab === 2 && <LoansTypes />}
      {/* {selectedTab === 2 && <Cronograma />} */}
      {/* {selectedTab === 2 && <AccessCodes />} */}
      {/* {selectedTab === 2 && <Aperturas />} */}
      {/* {selectedTab === 2 && <Modalidad />} */}
      {/* {selectedTab === 1 && <Contract />}
      {selectedTab === 2 && <AtrasosLetter />} */}
    </>
  );
};

export default Settings;
