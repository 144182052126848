import { useForm } from "@nodes/react-hook-form";
// import { InputNumber } from "./form/InputNumber";
// import { InputCustomFormat } from "./form/InputCustomFormat";
// import { InputDate } from "./form/InputDate";
import { InputCheckbox } from "./form/InputCheckbox";
// import { InputRadio } from "./form/InputRadio";
// import { InputDropdown } from "./form/InputDropdown";
// import { InputMultiCheckbox } from "./form/InputMultiCheckbox";
import { Button, Box } from "@nodes/@mui/material";
import { DevTool } from "@nodes/@hookform/devtools";
import { compareObjects } from "@shared/functions";
import { RiUserSettingsFill } from "@nodes/react-icons/ri";

export const RenderGeneralConfigForm = ({ defaultValues, submithandler }) => {
  const { handleSubmit, control, formState } = useForm({
    defaultValues,
  });

  const { isSubmitting } = formState;

  const handleSubmitHandler = async (data) => {
    if (compareObjects(defaultValues, data)) return;
    await submithandler(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitHandler)}>
        <Box>
          <InputCheckbox name="autoPrintPayment" control={control} label="Auto imprimir recibo de pago!" />
        </Box>

        <Box>
          <InputCheckbox name="autoPrintRenewal" control={control} label="Auto imprimir detalles de nuevo Prestamos!" />
        </Box>

        <Box>
          <InputCheckbox
            name="autoPrintLoanContract"
            control={control}
            label="Auto imprimir contrato de nuevo Prestamos!"
          />
        </Box>

        <Box>
          <InputCheckbox name="showAbonoPayments" control={control} label="Mostrar pagos de Abono en Recibo de Pago!" />
        </Box>

        <Box>
          <InputCheckbox
            name="showLoanBalance"
            control={control}
            label="Mostrar balance de prestamo en recibo de Pago!"
          />
        </Box>

        <Box>
          <InputCheckbox name="showMoraBalance" control={control} label="Mostrar balance de Mora en recibo de Pago!" />
        </Box>

        <Box>
          <InputCheckbox
            name="showPaymentTransfer"
            control={control}
            label="Mostrar opciones de pagos realizado mediante deposito!"
          />
        </Box>

        <Box>
          <InputCheckbox name="digitalContract" control={control} label="Habilitar contrato de prestamo Digital!" />
        </Box>

        <Box>
          <InputCheckbox name="autoDepurar" control={control} label="Auto depurar cliente para nueva entregas!" />
        </Box>

        <Box paddingBottom={2} paddingTop={2}>
          <Button
            startIcon={<RiUserSettingsFill />}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            variant={"contained"}>
            Guardar Configuraci&oacute;n!
          </Button>
        </Box>
      </form>

      <DevTool control={control} />
    </>
  );
};

export default RenderGeneralConfigForm;
