import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import EmployeeMenu from "./EmployeeMenu";

const EmployeeNameHeader = ({ user, children }) => {
  return (
    <CardHeader
      action={children}
      title={
        <Typography variant="body1" component="p" className="font-weight-bold">
          <PersonIcon /> {user.fname} {user.lname}
        </Typography>
      }
    />
  );
};

export default EmployeeNameHeader;
