import Box from "@nodes/@mui/material/Box";
import Typography from "@nodes/@mui/material/Typography";
import Divider from "@nodes/@mui/material/Divider";

const QuickPrinter = () => {
  return (
    <div className="container mt-4 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Conectar Impresora Bluetooth
      </Typography>
      <Typography variant="subtitle1" component="p" paragraph>
        Sigas los siguientes pasos para conectar una Impresora Bluetooth a su telefono Movil.
      </Typography>

      <Box>
        <Typography component="p" variant="body1">
          Si prefieres puede ver el siguiente video, donde muestro como hacerlo.
        </Typography>
        <div className="mt-1 text-center">
          <a href="https://youtu.be/TzqcqJKdNyo">Clic para ver video de Youtube!</a>
        </div>
      </Box>

      <Box paddingTop={2}>
        <Typography component="p" variant="body1">
          Puede usar el siguiente enlance para descargar Quick Printer.
        </Typography>
        <div className="mt-1 text-center">
          <a href="https://objects-us-east-1.dream.io/files-hosting/Quick-Printer.apk" download="Quick-Printer.apk">
            <u>Clic para descargar Quick Printer!</u>
          </a>
        </div>
      </Box>

      <Box paddingTop={2}>
        <Typography component="p" variant="body1">
          Puede usar el siguiente enlance para descargar Quick Printer.
        </Typography>
        <div className="mt-1 text-center">
          <a href="https://objects-us-east-1.dream.io/files-hosting/WPrestamos.apk" download="WPrestamos.apk">
            <u>Clic para descargar Wprestamos Cobro!</u>
          </a>
        </div>
      </Box>

      <Divider className="mb-4" />

      <Box>
        <Typography component="p" variant="body1">
          1. En la tienda de aplicaciones Google Play, descargue la aplicacion con el nombre de <b>Quick Printer</b>.
        </Typography>
        <div className="text-center">
          <img width="100%" alt="app-logo" src="/../../images/printer/step1.jpg" />
        </div>
      </Box>

      <Divider className="mb-3" />

      <Box>
        <Typography component="p" variant="body1">
          2. Encienda su Impresora Bluetooth y conecte la al Bluetooth de su Movil.
        </Typography>
        <Typography component="p" variant="body2">
          La manera en que se conecta un disposito Bluetooth es diferente para cada modelo de telefono.
        </Typography>
      </Box>

      <Divider className="mb-3" />

      <Box>
        <Typography component="p" variant="body1">
          3. Habra la aplicacion que descargo y presione el boton Gris con el signo de <strong>+</strong>.
        </Typography>
        <div className="text-center">
          <img width="100%" alt="app-logo" src="/../../images/printer/step3.jpg" />
        </div>
      </Box>

      <Divider className="mb-3" />

      <Box>
        <Typography component="p" variant="body1">
          4. Presione en el Menu para cambiar el tipo de conexion.
        </Typography>
        <div className="text-center">
          <img width="100%" alt="app-logo" src="/../../images/printer/step4.jpg" />
        </div>
      </Box>

      <Divider className="mb-3" />

      <Box>
        <Typography component="p" variant="body1">
          5. Selecione la opcion que dice <b>Impresora Bluetooth</b>.
        </Typography>
        <div className="text-center">
          <img width="100%" alt="app-logo" src="/../../images/printer/step5.jpg" />
        </div>
      </Box>

      <Divider className="mb-3" />

      <Box>
        <Typography component="p" variant="body1">
          6. Presione en el boton gris para Buscar Impresoras vinculada al Bluetooth.
        </Typography>
        <div className="text-center">
          <img width="100%" alt="app-logo" src="/../../images/printer/step6.jpg" />
        </div>
      </Box>

      <Divider className="mb-3" />

      <Box>
        <Typography component="p" variant="body1">
          7. En la siguiente lista de conexiones Bluetooth, elija su Impresora Bluetooth.
        </Typography>
        <Typography component="p" variant="body2">
          Si su impresora no esta en la lista, repita el paso #2
        </Typography>

        <div className="text-center">
          <img width="100%" height="160px" alt="app-logo" src="/../../images/printer/step7.jpg" />
        </div>
      </Box>

      <Divider className="mb-3" />

      <Box>
        <Typography component="p" variant="body1">
          8. Escriba un nombre para la Impresora por ejemplo: bt.
        </Typography>
        <img width="100%" height="180px" alt="app-logo" src="/../../images/printer/step8.jpg" />
      </Box>

      <Divider className="mb-3" />

      <Box>
        <Typography component="p" variant="body1">
          9. Si gusta probar, si la Impresora se conecto con exito, presione el boton marcado en rojo.
        </Typography>
        <Typography component="p" variant="body1">
          Finalmente, presione el boton marcado en azul para guardar la conexion de esta Impresora.
        </Typography>
        <div className="text-center">
          <img width="100%" alt="app-logo" src="/../../images/printer/step9.jpg" />
        </div>
      </Box>

      <Divider className="mb-3" />

      <Box>
        <Typography component="p" variant="body1">
          10. Si siguio todo los pasos correctamente, podras ver la impresora vinculada como la imagen hacia abajo.
        </Typography>
        <div className="text-center">
          <img width="100%" alt="app-logo" src="/../../images/printer/step10.jpg" />
        </div>
      </Box>

      <Divider className="mb-3" />
    </div>
  );
};

export default QuickPrinter;
