import { useContext } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import { Context } from "../context/DataContext";
import FloatingButton from "@shared/components/FloatingButton";
import Typography from "@mui/material/Typography";
import { NumericFormat } from "react-number-format";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
// import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import RolesDropdown from "../components/RolesDropdown";
import RenderGreenCheckbox from "../components/RenderGreenCheckbox";
import { UseEmployees, UseRoute, UseRoles, UseRolesPermission, UseCustomRoles } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
import Chip from "@mui/material/Chip";
import NoInternetContent from "@shared/components/NoInternetContent";
import Button from "@mui/material/Button";
import { BiCommentAdd } from "react-icons/bi";
// import ErrorDataComponent from "@shared/components/ErrorDataComponent";

// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";

const Employees = () => {
  const { state, toggleLinkedRoute, setUserRole } = useContext(Context);
  const { user, route } = state;

  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);
  const { data: employees, isLoading, mutate } = UseEmployees(route.creditor_id, routeN?.user_id);
  const { data: roles, isLoading: RolesLoading } = UseRoles(routeN?.user_id);
  const { data: customRoles, isLoading: cRolesLoading } = UseCustomRoles(routeN?.user_id);
  const { data: rolesPermissions, isLoading: RolePermLoading } = UseRolesPermission(routeN?.user_id);

  if (isLoading || routeLoading || RolesLoading || cRolesLoading) return <Loading />;
  if (!employees) return <NoInternetContent />;

  const handleToggleChange = async (user, route) => {
    await mutate(toggleLinkedRoute(user, route), {
      populateCache: false,
    });
  };

  const handleSetUser = async (user, role, creditor_id) => {
    await mutate(setUserRole(user, role, creditor_id), {
      populateCache: false,
    });
  };

  return (
    <>
      <FloatingButton
        style={{ margin: 0, top: "auto", right: 20, bottom: 20, left: "auto", position: "fixed" }}
        icon={<PersonAddOutlinedIcon />}
        tooltip="Agregar Empleado"
        goto="/user/create"
      />

      {employees.length === 0 ? (
        <div className="container mt-5 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Empleados!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los empleado para su ruta.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no se ha registrado ningun empleado!
          </Typography>
        </div>
      ) : (
        <div className="mt-3 mb-5">
          <Card className="p-2">
            <Typography variant="h5" component="h3" gutterBottom>
              Empleados registrado!
            </Typography>

            <div style={{ display: "inline-flex" }}>
              <span className="pr-1">
                <b>
                  <Chip label={`R${routeN.creditor_id}`} />
                </b>
              </span>

              <Typography variant="subtitle2" component="h4" gutterBottom>
                {routeN.route_name} ({routeN.location})
              </Typography>
            </div>
          </Card>
          <div>
            <table style={{ marginBottom: 40 }} className="table">
              <thead>
                <tr>
                  <th scope="col" className="d-none d-md-table-cell">
                    #
                  </th>
                  <th scope="col">Nombre</th>
                  <th scope="col" className="d-none d-md-table-cell">
                    Telefono
                  </th>
                  <th scope="col" className="d-none d-md-table-cell">
                    Cedula
                  </th>
                  <th scope="col">Rol</th>
                  <th scope="col">Activo</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((user) => (
                  <tr key={user.user_id}>
                    <td className="d-none d-md-table-cell">{user.user_id}</td>
                    <td>
                      <Link to={`/employee/${user.user_id}`} style={{ color: "#589afa" }}>
                        {user.fname} <span className="d-none d-sm-inline">{user.lname}</span>
                      </Link>
                    </td>
                    <td className="d-none d-md-table-cell">{user.phone || "0000000000"}</td>
                    <td className="d-none d-md-table-cell">
                      <NumericFormat value={user.cedula || "00000000000"} displayType={"text"} format="###-#######-#" />
                    </td>
                    <td>
                      <RolesDropdown
                        user={user}
                        roles={[...customRoles, ...roles]}
                        creditor_id={route.creditor_id}
                        handleSetUser={handleSetUser}
                      />
                    </td>
                    <td>
                      <RenderGreenCheckbox user={user} route={route} handleToggleChange={handleToggleChange} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {RolePermLoading ? (
            <Loading />
          ) : (
            <div>
              <Typography variant="h5" componet="p" className="pl-2">
                Rols de Empleados!
              </Typography>

              <div className="mb-3 text-right pr-2">
                <Button
                  startIcon={<BiCommentAdd />}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/role/create")}>
                  Crear nuevo Rol
                </Button>
              </div>

              {[...customRoles, ...roles].map((r) => (
                <div key={r.role_id}>
                  <div>
                    <Typography variant="h6" componet="p" align="center" className={`bg-${r.color}`} gutterBottom>
                      <b>{r.role_name}!</b>
                    </Typography>
                  </div>

                  <ol>
                    <>
                      {rolesPermissions
                        .filter((x) => x.role_id === r.role_id)
                        .map((p) => (
                          <li key={p.id}>{p.permission_description}</li>
                        ))}
                    </>
                  </ol>

                  {r.color === "dark" && (
                    <div className="text-right">
                      <h6 className="m-3">
                        <Link to={`/role/create/${r.role_id}`}>Modificar Permisos</Link>
                      </h6>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Employees;
