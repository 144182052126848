import { useContext, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { Context } from "../context/DataContext";
import { toast } from "react-toastify";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderNewLoanCalculation from "@shared/components/RenderNewLoanCalculation";
import RenderNewLoanFormOld from "@shared/components/RenderNewLoanFormOld";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import { UseRoute, UseDebtor } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";

const calcMonto = (percentage = 0, amount = 0, npayments = 0) => {
  const amount2 = amount * 1;
  const npayments2 = npayments * 1;
  const percentage2 = percentage * 1;
  if (npayments2 === 0) return 0;

  return Math.ceil(((percentage2 / 100) * amount2 + amount2) / npayments2);
};

const calcEntrega = (amount = 0, actanotarial = 0, balance = 0) => {
  const amount2 = amount * 1;
  const balance2 = balance * 1;
  const actanotarial2 = actanotarial * 1;
  return amount2 - actanotarial2 - balance2;
};

const calcProfits = (npayments = 0, monto = 0, amount = 0) => {
  const npayments2 = npayments * 1;
  const monto2 = monto * 1;
  const amount2 = amount * 1;
  return npayments2 * monto2 - amount2;
};

const initialFormValues = { amount: "", percentage: "", npayments: "", actanotarial: "", adelanto: "" };

const LoanCreateAtrasos = () => {
  const { mutate } = useSWRConfig();
  const { id: debtor_id } = useParams();
  const { state, createLoanWithAtrasos } = useContext(Context);
  const [formValues, setFormValues] = useState(initialFormValues);
  const { user, route: stateRoute } = state;

  const { data: route, isLoading: routeLoading } = UseRoute(stateRoute.creditor_id, user.user_id);
  const { data: currentDebtor, isLoading: isLoadingDebtor } = UseDebtor(debtor_id, user.user_id);
  const [givenDate, setGivenDate] = useState(null);
  // const { data: routeConfig, isLoading: routeLoadingConfig } = UseOwnerConfig(stateRoute.user_id);

  const monto = useMemo(() => {
    return calcMonto(formValues.percentage, formValues.amount, formValues.npayments);
  }, [formValues.percentage, formValues.amount, formValues.npayments]);

  const entrega = useMemo(() => {
    return calcEntrega(formValues.amount, formValues.actanotarial);
  }, [formValues.amount, formValues.actanotarial]);

  const profit = useMemo(() => {
    return calcProfits(formValues.npayments, monto, formValues.amount);
  }, [formValues.npayments, monto, formValues.amount]);

  if (isLoadingDebtor || routeLoading) return <Loading />;

  const initialValues = {
    user_id: user.user_id,
    debtor_id,
    creditor_id: route.creditor_id,
    name: currentDebtor.name,
    percentage: currentDebtor.percentage || route.rPerc || "",
    npayments: currentDebtor.npayments || route.rWeeks || "",
    amount: currentDebtor.amount,
    actanotarial: currentDebtor.actanotarial || 0,
    given_date: givenDate,
    start_date: null,
    payoffBalance: 0,
    isCobro: route.isCobro,
    adelanto: 0,
    atrasos: 0,
    discount: 0,
    payoff_type: 3,
    type: 7,
    signature: false,
  };

  let button;
  const submithandler = async (data) => {
    await submitnewLoan(data);

    mutate(`/loans/${route.creditor_id}/${user.user_id}`);
    // mutate(`/xcobrar/loans/${route.creditor_id}/${user.user_id}`);
  };

  const allowSignaturesRoutes = [];

  if (!allowSignaturesRoutes.includes(state.route.creditor_id)) {
    button = { label: "Guardar Pr&eacute;stamo", variant: "contained", color: "primary" };
  } else {
    button = {
      icon: <BorderColorIcon />,
      label: "Firma del Cliente",
      color: "primary",
      variant: "outlined",
    };
  }

  const submitnewLoan = async (data) => {
    if (entrega < 1) {
      toast.error("Cantidad a entregar es Invalida!");
      return;
    }

    if (!givenDate) {
      toast.error("Fecha de Entrega es Invalida!");
      return;
    }

    const newData = { ...currentDebtor, ...data, wPayment: monto };

    let redirect = "/debtors";

    await createLoanWithAtrasos(newData, () => history.replace(redirect));
    // if (!allowSignaturesRoutes.includes(state.route.creditor_id)) {
    //   // redirect = "/print/receipt/newloan/" + id;
    //   // if (state.userConfig.autoPrintRenewal === false) {
    //   //   redirect = "/entregas";
    //   // }
    // } else {
    //   await createLoanSignature(newData, () => history.replace(redirect));
    // }
  };

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentDebtor}>
          <LoanDetailMenu loan={currentDebtor} />
        </DebtorNameHeader>

        <Box component="div" p={1}>
          <Typography variant="h6" component="h4">
            Nuevo Pr&eacute;stamo!
          </Typography>

          <Box paddingBottom={1}>
            <Divider />
          </Box>

          <Box div="div" p={1}>
            <RenderNewLoanCalculation data={{ monto, entrega, profit }} />
          </Box>

          <Box div="div" p={1}>
            <RenderNewLoanFormOld
              setFormValues={setFormValues}
              route={route}
              initialValues={initialValues}
              submithandler={submithandler}
              button={button}
              givenDate={givenDate}
              setGivenDate={setGivenDate}
              label={"Fecha de Entrega"}
            />
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default LoanCreateAtrasos;
