import { useRef } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SignatureCanvas from "react-signature-canvas";
import "./styles/signaturePad.css";

const SignaturePad = ({ setSignature, name }) => {
  let sigPad = useRef({});

  const clear = () => sigPad.current.clear();
  const save = () => {
    if (!sigPad.current.isEmpty()) {
      setSignature(sigPad.current.toDataURL());
    }
  };

  return (
    <div className="signaturePad">
      <Typography variant="h6" component="h5">
        Firma: {name}
      </Typography>
      <SignatureCanvas canvasProps={{ className: "sigPad" }} penColor="blue" ref={sigPad} />
      <br />
      <div className="text-right pr-4">
        <Button variant="outlined" size="small" color="secondary" onClick={clear}>
          Limpiar
        </Button>
        <Button className="ml-2" variant="outlined" size="small" color="primary" onClick={save}>
          Guardar Firma
        </Button>
      </div>
    </div>
  );
};

export default SignaturePad;
