import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import RenderContactInfo from "@shared/components/RenderContactInfo";

const NewUserContact = () => {
  const navigate = useNavigate();
  return (
    <div className="container mt-5">
      <Typography variant="h6" gutterBottom>
        Gracias por tratar WPrestamos!
      </Typography>

      <Typography variant="body1" className="text-info">
        A continuacion esta mi informacion de contacto, por favor guarde mi numero para que pueda escribir me si tiene
        alguna pregunta.
      </Typography>

      <Grid item xs={12} lg={5}>
        <Card variant="outlined" className="mt-5">
          <Box m={2}>
            <RenderContactInfo />
          </Box>
        </Card>
      </Grid>

      <Box p={1} paddingTop={2}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => navigate("/routes/choose", { replace: true })}>
          Continuar!
        </Button>
      </Box>
    </div>
  );
};

export default NewUserContact;
