import CheckIcon from "@nodes/@mui/icons-material/Check";
import Card from "@nodes/@mui/material/Card";
import Typography from "@nodes/@mui/material/Typography";
import Box from "@nodes/@mui/material/Box";
import PersonIcon from "@nodes/@mui/icons-material/Person";
import { MdRestore } from "@nodes/react-icons/md";
import { Link } from "@nodes/react-router-dom";
import RenderPhoneNumber from "./RenderPhoneNumber";
import { printAbsAmount, getBadgeColor } from "../functions";
// import PhoneIcon from "@nodes/@mui/icons-material/Phone";

const XcobrarLoan = ({ loan }) => {
  const xRenewal = loan.npayments - loan.a_count < 3 && loan.statusText !== "Vencido";
  return (
    <Box paddingBottom={1}>
      <Card raised>
        <table className="table table-sm">
          <tbody>
            <tr>
              <td colSpan="4">
                <Typography variant="subtitle2" component="p" className="font-weight-bold">
                  {/* <Link style={{ color: "#000" }} to={`/loan/detail/${loan.money_id}/${loan.debtor_id}`}> */}
                  <Link style={{ color: "#000" }} to={`/payment/create/${loan.money_id}/${loan.debtor_id}`}>
                    <PersonIcon /> {loan.name} {loan.nickname && `(${loan.nickname})`}
                  </Link>
                </Typography>
              </td>
            </tr>

            <tr>
              <td colSpan="3">
                <RenderPhoneNumber phone={loan.phone} />
              </td>
              <td align="right">
                {loan.a_count < 2 && (
                  <Typography variant="body2" component="p" className="badge badge-pill badge-primary">
                    <small>Nuevo!</small>
                  </Typography>
                )}

                {loan.a_count >= 2 && loan.a_count < loan.npayments && (
                  <span className="badge badge-pill badge-secondary">
                    <Typography variant="body1" component="p" style={{ fontSize: 13 }}>
                      <span style={{ padding: 2 }}>{loan.a_count}</span>
                    </Typography>
                  </span>
                )}

                {loan.a_count === loan.npayments && (
                  <Typography variant="body2" component="p" className="badge badge-pill badge-dark">
                    Sale Hoy!
                  </Typography>
                )}

                {loan.postPoned === true && (
                  <span className="pl-2 text-primary">
                    <MdRestore size="1.6em" />
                  </span>
                )}
              </td>
            </tr>

            <tr>
              <td>
                {loan.statusText === "Al Dia" ? (
                  <Typography variant="body2" component="p" className="badge badge-pill badge-success">
                    Al Dia
                  </Typography>
                ) : (
                  <Typography variant="body2" component="p" className={getBadgeColor(loan.statusText)}>
                    <small>
                      {loan.statusText} {printAbsAmount(loan.statusAmount)}
                    </small>
                  </Typography>
                )}
              </td>

              <td>
                <Typography variant="body2" component="p">
                  {printAbsAmount(loan.cuota)}
                  {" - "}
                  {loan.completed}/{loan.npayments}
                </Typography>
              </td>

              <td align="right">
                <Typography variant="body2" component="p">
                  Balance {printAbsAmount(loan.balance + loan.balMora)}
                </Typography>
              </td>

              <td align="right" style={{ width: 10 }}>
                {loan.pAmount > 0 && (
                  <span className="text-info">
                    <CheckIcon />
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Box>
  );
};

export default XcobrarLoan;
