import { useContext } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import { string, object } from "yup";
import { Form, RenderInput } from "@shared/components/Form"; //RenderDropdown
import { Context } from "../context/DataContext";
// import { toast } from "react-toastify";
import { GrEdit } from "react-icons/gr";
import Loading from "@shared/components/Loading";
import { UseRoute, UseRouteLoanType, UseOwnerConfig, UseOwnerCobroConfig, UseUserConfig } from "@shared/server/fetcher";
import { printDate, newDate } from "@shared/functions"; //spanishRouteTypes
import { routeModify } from "@shared/api/route";
import { useSnackbar } from "notistack";

const RouteModify = () => {
  const { state } = useContext(Context);
  const { user, route } = state;

  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, mutate } = UseRoute(route.creditor_id, user.user_id);
  UseRouteLoanType(route.creditor_id);
  UseUserConfig(user.user_id);
  UseOwnerConfig(route.user_id);
  UseOwnerCobroConfig(route.user_id);

  if (isLoading) return <Loading />;

  const initialValues = {
    creditor_id: data.creditor_id,
    route_name: data.route_name,
    location: data.location,
    phone: data.phone,
    phone2: data.phone2,
    // r_type: data.r_type,
    // day: [7, 14].includes(+data.r_type) ? data.day : 0,
  };

  const validation = object({
    route_name: string().required("Nombre de la ruta es requerido!").min(5, "Nombre de la ruta es muy corto!"),
    phone: string().required("Numero de telefono es requerido!").min(10, "Numero de telefono invalido!"),
    phone2: string().min(10, "Numero de telefono invalido!"),
    location: string().required("Nombre de la ubicacion es requerido!").min(4, "Nombre de la zona es muy corto!"),
  });

  const button = { icon: <GrEdit size="0.9em" />, label: "Modificar Ruta", variant: "contained", color: "primary" };

  const submithandler = async (data) => {
    try {
      await routeModify(data);
      mutate();
      enqueueSnackbar("Ruta actualizada con exito", { variant: "info" });
    } catch (error) {}
  };

  return (
    <div className="container mt-4 mb-5">
      <Container maxWidth="md" disableGutters>
        <Typography variant="h5" component="h3" gutterBottom>
          <b className="pr-1">
            <Chip label={`R${route.creditor_id}`} />
          </b>
          Modificar Ruta!
        </Typography>

        <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
          <Box paddingBottom={2}>{RenderInput("route_name", "Nombre de la Ruta", "text")}</Box>
          <Box paddingBottom={2}>{RenderInput("location", "Zona o Ubicacion de la Ruta", "text")}</Box>
          <Grid container>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("phone", "Numero de Telefono", "number")}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("phone2", "Numero de Telefono 2", "number")}</Box>
            </Grid>
          </Grid>

          {/* <Box paddingBottom={2}>
            {RenderDropdown("r_type", "Tipo de Ruta", [
              { label: "Diario (Lunes - Sabado)", value: 1 },
              { label: "Semanales", value: 7 },
              { label: "Quincenal (Bi-Semanal)", value: 14 },
              { label: "Bi-Mensual (15 & 30)", value: 15 },
            ])}
          </Box> */}
        </Form>

        <Box component={"div"} paddingTop={2}>
          <ul className="list-group mb-3">
            {/* {+data.day !== 0 && (
              <li className="list-group-item">
                <Typography variant="body1" component="p">
                  Dia de Cobro: {getSpanishDays(data.day)}
                </Typography>
              </li>
            )} */}

            <li className="list-group-item">
              <Typography variant="body1" component="p">
                Ruta Creada: {printDate(newDate(data.created_date))}
              </Typography>
            </li>

            {/* <li className="list-group-item">
              <Typography variant="body1" component="p">
                Tipo de Ruta: {spanishRouteTypes(data.r_type)}
              </Typography>
            </li> */}
          </ul>
        </Box>

        <br />
        <br />
        <br />

        <Typography variant="h5" className="text-danger text-center" gutterBottom>
          <b>Zona de Peligro!!</b>
        </Typography>

        <Box className="mt-4" component={"div"} p={1}>
          <Typography variant="body1" paragraph>
            Todo los pr&eacute;stamos de esta ruta <i>{route.route_name}</i> seran eliminado, la ruta quedara solamente
            con los clientes creado.
          </Typography>

          <Link className="text-danger" to={`/data/delete/${route.creditor_id}/${user.user_id}/loans`}>
            <b>
              <u> Borrar pr&eacute;stamos de esta ruta</u>
            </b>
          </Link>
        </Box>

        <Divider />

        <Box className="mt-4" component={"div"} p={1}>
          <Typography variant="body1" paragraph>
            Todo los pr&eacute;stamos de esta ruta <i>{route.route_name}</i> y todas sus otras rutas seran eliminado,
            las rutas quedaran solamente con los clientes creado.
          </Typography>

          <Link className="text-danger" to={`/data/delete/${route.creditor_id}/${user.user_id}/owner`}>
            <b>
              <u>Borrar pr&eacute;stamos de todas mi rutas!</u>
            </b>
          </Link>
        </Box>

        <Divider />

        <Box className="mt-4" component={"div"} p={1}>
          <Typography variant="body1" paragraph>
            Completamente elimar y borrar todo el contendido de la ruta <i>{route.route_name}</i>, incluyendo todo los
            clientes, préstamos y pagos!
          </Typography>

          <Link className="text-danger" to={`/data/delete/${route.creditor_id}/${user.user_id}/route`}>
            <b>
              <u>Eliminar Ruta!</u>
            </b>
          </Link>
        </Box>
      </Container>
    </div>
  );
};

export default RouteModify;
