import { useForm } from "@nodes/react-hook-form";
// import { InputNumber } from "./form/InputNumber";
// import { InputCheckbox } from "./form/InputCheckbox";
// import { InputDate } from "./form/InputDate";
// import { InputRadio } from "./form/InputRadio";
import { InputDropdownMulti } from "./form/InputDropdownMulti";
import { GiPartyPopper } from "@nodes/react-icons/gi";
// import { InputMultiCheckbox } from "./form/InputMultiCheckbox";
import { Button, Box } from "@nodes/@mui/material";
import { DevTool } from "@nodes/@hookform/devtools";

export const RenderHolidaysForm = ({ submithandler, data }) => {
  const { defaultValues: ownerHolidays, holidays } = data;
  const { handleSubmit, control, formState } = useForm({
    defaultValues: { ownerHolidays },
  });

  const { isSubmitting } = formState;

  const handleSubmitHandler = async (data) => {
    if (JSON.stringify(data.ownerHolidays) === JSON.stringify(ownerHolidays)) return;
    await submithandler(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitHandler)}>
        <Box paddingBottom={2} paddingTop={1}>
          <InputDropdownMulti
            name="ownerHolidays"
            control={control}
            label="Seleccione dias de Fiesta"
            configs={{
              size: "large",
              backgroundColor: "#f15759",
              helperText: (
                <span>
                  Los dias de fiesta seleccionados, no habr&aacute; apertura autom&aacute;tica para los pr&eacute;stamo.
                </span>
              ),
            }}
            options={holidays}
          />
        </Box>

        <Box paddingBottom={2} paddingTop={2}>
          <Button startIcon={<GiPartyPopper />} disabled={isSubmitting} type="submit" fullWidth variant={"contained"}>
            Guardar Configuraci&oacute;n!!
          </Button>
        </Box>
      </form>

      <DevTool control={control} />
    </>
  );
};

export default RenderHolidaysForm;
