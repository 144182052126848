import { useForm, useWatch } from "@nodes/react-hook-form";
import { InputNumber } from "./form/InputNumber";
// import { InputCustomFormat } from "./form/InputCustomFormat";
// import { InputDate } from "./form/InputDate";
import { InputRadio } from "./form/InputRadio";
import { InputCheckbox } from "./form/InputCheckbox";
import { InputDropdown } from "./form/InputDropdown";
import { InputSlider } from "./form/InputSlider";
// import { InputMultiCheckbox } from "./form/InputMultiCheckbox";
import { Button, Box } from "@nodes/@mui/material";
import { DevTool } from "@nodes/@hookform/devtools";
import { compareObjects } from "@shared/functions";
import { MdAdminPanelSettings } from "@nodes/react-icons/md";

export const RenderCobroConfigForm = ({ defaultValues, submithandler }) => {
  const { handleSubmit, control, formState, setValue } = useForm({
    defaultValues,
  });

  const { isSubmitting } = formState;
  const formValues = useWatch({ control });

  const handleSubmitHandler = async (data) => {
    if (compareObjects(defaultValues, data)) return;
    await submithandler(data);
  };

  const Percentage = () => {
    return `Auto descontar ${formValues.rActa}% de Contracto!`;
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitHandler)}>
        <Box paddingBottom={2} paddingTop={1}>
          <InputDropdown
            name="rRenCuotas"
            control={control}
            label="Permitir renovar prestamos"
            configs={{ helperText: "Restando cuanta cuota los prestamo pueden renovar!", variant: "standard" }}
            options={[
              { label: "En cualquier cuota", value: 0 },
              { label: "Restando 1 cuota", value: 1 },
              { label: "Restando 2 cuotass", value: 2 },
              { label: "Restando 3 cuotass", value: 3 },
              { label: "Restando 4 cuotass", value: 4 },
              { label: "Restando 5 cuotass", value: 5 },
              { label: "Restando 6 cuotass", value: 6 },
              { label: "No permitir Renovos", value: -1 },
            ]}
          />
        </Box>

        {/* <Box paddingBottom={2} paddingTop={1}>
          <InputCustomFormat
            name="phone"
            control={control}
            label="Numero de Telefono"
            placeholder={"Por favor escriba su numero de telefono!"}
            configs={{
              overwrite: false,
              mask: "(#00) 000-0000",
              definitions: {
                "#": /[1-9]/,
              },
            }}
          />
        </Box>

        <Box paddingBottom={2} paddingTop={1}>
          <InputCustomFormat
            name="cedula"
            control={control}
            label="Numero de Cedula"
            placeholder={"Por favor escriba su numero de Cedula!"}
            configs={{
              overwrite: false,
              mask: "#00-0000000-0",
              definitions: {
                "#": /[0-9]/,
              },
            }}
          />
        </Box> */}

        <Box paddingBottom={2} paddingTop={1}>
          <InputNumber
            name="rLimit"
            control={control}
            type="number"
            label="Limite de Prestamos"
            placeholder={"Escriba cantidad limite de prestamos!"}
            configs={{
              prefix: "$",
              helperText:
                "Que cantidad es lo mas que se le puede entregar a los  cliente, si esta cantidad es $0 no hay limite!",
            }}
          />
        </Box>

        <Box>
          <InputRadio
            name="rRCed"
            control={control}
            label="Requerir numero de c&eacute;dula para entrega?"
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
          />
        </Box>

        <Box>
          <InputRadio
            name="rRTel"
            control={control}
            label="Requerir numero de tel&eacute;fono para entrega?"
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
          />
        </Box>

        <Box>
          <InputRadio
            name="rORenew"
            control={control}
            label="Permitir entrega de prestamos sin internet?"
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
          />
        </Box>

        <Box paddingBottom={1}>
          <InputSlider
            name="rActa"
            control={control}
            setValue={setValue}
            value={defaultValues.rActa}
            label={<Percentage />}
            configs={{
              startEndorsement: (
                <span>
                  <b>0%</b>
                </span>
              ),
              endEndorsement: (
                <span>
                  <b>10%</b>
                </span>
              ),
              max: 10,
              min: 0,
              step: 0.5,
            }}
          />
        </Box>

        <Box paddingBottom={1}>
          <InputCheckbox
            name="rActaU"
            control={control}
            label="Permitir modificar el contracto (Acta)?"
            configs={{ size: "small" }}
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
          />
        </Box>

        <Box paddingBottom={2} paddingTop={2}>
          <Button
            startIcon={<MdAdminPanelSettings />}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            variant={"contained"}>
            Guardar Configuraci&oacute;n!!
          </Button>
        </Box>
      </form>

      <DevTool control={control} />
    </>
  );
};

export default RenderCobroConfigForm;
