import { useState } from "react";
import Modal from "@nodes/react-bootstrap/Modal";
import Box from "@nodes/@mui/material/Box";
import { Form, RenderInput } from "./Form";
import { string, object } from "@nodes/yup";
import ShareIcon from "@nodes/@mui/icons-material/Share";
import { HiLink } from "@nodes/react-icons/hi";
import Button from "@nodes/@mui/material/Button";

const CreateReferralModal = ({ callBack }) => {
  const [showModal, setShowModal] = useState(false);

  const initialValues = {
    name: "",
  };

  const validation = object({
    name: string().required("Nombre es requerido!").min(3, "Nombre es muy corto!"),
  });

  const button = { icon: <HiLink />, label: "Crear Invitacion", variant: "contained", color: "primary" };
  const submithandler = async (data) => {
    const result = await callBack({ ...data });
    if (result) setShowModal(false);
  };

  return (
    <>
      <span className="text-primary ml-1" onClick={() => setShowModal(!showModal.show)}>
        <Button fullWidth variant="contained" color="primary" startIcon={<ShareIcon />}>
          Crear Invitacion!
        </Button>
      </span>

      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-primary">A quien invita?</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <p className="mt-2 mb-4">Escriba el nombre del prestamita que quiere invitar.</p>

            <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
              <Box paddingBottom={3}>{RenderInput("name", "Nombre del invitado")}</Box>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateReferralModal;
