import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Context } from "../context/DataContext";
import FullLoadingScreen from "@shared/components/Loading";

const DiagnosticInsert = () => {
  const { state, appDiagnosticLog } = useContext(Context);
  const { route } = state;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function inserDianostic() {
      await appDiagnosticLog({ ...route, collector_id: 1001, appVersion: import.meta.env.VITE_VERSION });
      setLoading(false);
    }
    inserDianostic();
    //eslint-disable-next-line
  }, []);

  return loading ? <FullLoadingScreen /> : <Navigate to="/dashboard" />;
};
export default DiagnosticInsert;
