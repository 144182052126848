import Grid from "@nodes/@mui/material/Grid";
import Box from "@nodes/@mui/material/Box";
import Typography from "@nodes/@mui/material/Typography";
import { printDate, newDate } from "../functions";
import { NumericFormat } from "@nodes/react-number-format";
import Chip from "@nodes/@mui/material/Chip";
import RenderPhoneNumber from "./RenderPhoneNumber";

const RenderDebtorProfile = ({ debtor = {} }) => {
  return (
    <div>
      <Typography variant="h6" component="h4">
        {/* <img
          alt=""
          src={debtor.isMain === 0 ? "/../../cobro-icon-57x57.png" : "/../../main-icon-57x57.png"}
          style={{ width: 22, height: 22, marginRight: 3 }}
        /> */}
        Perfil del Cliente!
        {debtor.new_debtor === undefined && (
          <b className="p-2">
            <Chip label={`C${debtor.debtor_id}`} />
          </b>
        )}
      </Typography>

      {debtor.nickname && (
        <Box component="div" paddingBottom={1}>
          <Typography variant="body2" component="p" gutterBottom>
            Apodo
          </Typography>
          <Typography variant="h6" component="h4">
            <span className="badge badge-dark p-2">{debtor.nickname}</span>
          </Typography>
        </Box>
      )}

      {debtor.cedula && (
        <Box component="div" paddingBottom={1}>
          <Typography variant="body2" component="p" gutterBottom>
            Cedula
          </Typography>
          <Typography variant="h6" component="h4">
            <span className="badge badge-dark p-2">
              <NumericFormat value={debtor.cedula} displayType={"text"} format="###-#######-#" />
            </span>
          </Typography>
        </Box>
      )}

      <Box component="div" paddingBottom={1}>
        <Typography variant="body2" component="p" gutterBottom>
          Telefono
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                <RenderPhoneNumber phone={debtor.phone} />
              </span>
            </Typography>
          </Grid>
          {debtor.phone2 && (
            <Grid item xs={6}>
              <Typography variant="h6" component="h4">
                <span className="badge badge-dark p-2">
                  <RenderPhoneNumber phone={debtor.phone2} />
                </span>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>

      {debtor.address && (
        <Box component="div" paddingBottom={1}>
          <Typography variant="body2" component="p" gutterBottom>
            Direcci&oacute;n de Casa
          </Typography>
          <Typography variant="h6" component="h4">
            <span className="badge badge-dark p-2">{debtor.address}</span>
          </Typography>
        </Box>
      )}

      {debtor.work_address && (
        <Box component="div" paddingBottom={1}>
          <Typography variant="body2" component="p" gutterBottom>
            Direcci&oacute;n de Trabajo
          </Typography>
          <Typography variant="h6" component="h4">
            <span className="badge badge-dark p-2">{debtor.work_address}</span>
          </Typography>
        </Box>
      )}

      {debtor.occupation && (
        <Box component="div" paddingBottom={1}>
          <Typography variant="body2" component="p" gutterBottom>
            Ocupacion
          </Typography>
          <Typography variant="h6" component="h4">
            <span className="badge badge-dark p-2">{debtor.occupation}</span>
          </Typography>
        </Box>
      )}

      {debtor.next_to && (
        <Box component="div" paddingBottom={1}>
          <Typography variant="body2" component="p" gutterBottom>
            Proximo a
          </Typography>
          <Typography variant="h6" component="h4">
            <span className="badge badge-dark p-2">{debtor.next_to}</span>
          </Typography>
        </Box>
      )}

      {debtor.cosigner && (
        <Box component="div" paddingBottom={1}>
          <Typography variant="body2" component="p" gutterBottom>
            Garante
          </Typography>
          <Typography variant="h6" component="h4">
            <span className="badge badge-dark p-2">{debtor.cosigner}</span>
          </Typography>
        </Box>
      )}

      {debtor.note && (
        <Box component="div" paddingBottom={1}>
          <Typography variant="body2" component="p" gutterBottom>
            Nota
          </Typography>
          <Typography variant="h6" component="h4">
            <span className="badge badge-dark p-2">{debtor.note}</span>
          </Typography>
        </Box>
      )}

      <Box component="div" paddingBottom={1}>
        <Typography variant="body2" component="p" gutterBottom>
          Creado en
        </Typography>
        <Typography variant="h6" component="h4">
          <span className="badge badge-dark p-2">{printDate(newDate(debtor.created_date), "dd-MMM-y")}</span>
        </Typography>
      </Box>
    </div>
  );
};

export default RenderDebtorProfile;
