import Button from "@nodes/@mui/material/Button";
import { RiArrowGoBackLine } from "@nodes/react-icons/ri";
import useMediaQuery from "../hooks/useMediaQuery";
import { useNavigate } from "@nodes/react-router-dom";

const RenderBackButton = () => {
  const navigate = useNavigate();
  const fullScreen = useMediaQuery("min-width:820px");
  return (
    <Button
      fullWidth={!fullScreen}
      startIcon={<RiArrowGoBackLine />}
      onClick={() => navigate(-1)}
      variant="outlined"
      color="secondary">
      Volver Atr&aacute;s
    </Button>
  );
};

export default RenderBackButton;
