import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { AiOutlineOrderedList } from "react-icons/ai";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

export default function CollectedPaymentMenu({ loan }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </Button>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem className="text-primary" onClick={() => navigate(`/loan/detail/${loan.money_id}/${loan.debtor_id}`)}>
          <VisibilityIcon className="pr-1" /> Pr&eacute;stamo Detalle
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate(`/loan/payments/${loan.money_id}/${loan.debtor_id}`)}>
          <AiOutlineOrderedList size="1.4em" className="pr-1" /> Historial de Pagos
        </MenuItem>
        <Divider />

        <MenuItem
          className="text-danger"
          onClick={() => navigate(`/delete/payment/${loan.payment_id}/${loan.money_id}/${loan.debtor_id}`)}>
          <DeleteForeverIcon /> Borrar Pago
        </MenuItem>
      </Menu>
    </div>
  );
}
