import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from "@nodes/@mui/material";
import { Controller } from "@nodes/react-hook-form";

export const InputDropdownMulti = ({ name, control, label, options = [], configs = {} }) => {
  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl fullWidth={configs.fullWidth ?? true}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => (
          <>
            <Select
              multiple
              error={!!error}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={label}
              size={configs.size ?? "medium"}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              variant={configs.variant ?? "standard"}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: configs.backgroundColor ?? "#bababa",
                  },
                },
              }}>
              {generateSingleOptions()}
            </Select>
            {configs.helperText && <FormHelperText>{configs.helperText}</FormHelperText>}
            {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function MultipleSelectCheckmarks() {
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}>
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
