import { useState, useContext } from "react";
import { Context } from "../context/DataContext";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
// import MailIcon from "@mui/icons-material/Mail";
import MUIDrawer from "@mui/material/Drawer";
// import List from "@mui/material/List";
import MenuList from "@mui/material/MenuList";
// import Button from "@mui/material/Button";
// import ListItem from "@mui/material/ListItem";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import ListItemIcon from "@mui/material/ListItemIcon";
// import CheckIcon from "@mui/icons-material/Check";
// import BrightnessAutoIcon from "@mui/icons-material/BrightnessAuto";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ListItemText from "@mui/material/ListItemText";
import { DrawerList } from "./DrawerList";
import Notifications from "./Notifications";
// import { GiOpenChest } from "react-icons/gi";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import LoginDialog from "./LoginDialog";
import RenderTestUserWarning from "@shared/components/RenderTestUserWarning";
import InternetConection from "@shared/components/InternetConection";
import Loading from "@shared/components/Loading";
import { UseRoute } from "@shared/server/fetcher";
import UserBalance from "@shared/components/UserBalance";
import {
  AiOutlineUsergroupAdd,
  AiOutlineQuestionCircle,
  AiOutlinePoweroff,
  AiOutlineDollarCircle,
} from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import InstallPWA from "@shared/hooks/InstallPwa";
// import ChangeRouteModal from "./ChangeRouteModal";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import { BiLogIn } from "@nodes/react-icons/bi";
// import DisplayAppEnvName from "@shared/components/DisplayAppEnvName";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    paddingLeft: 5,
    paddingRight: 10,
    paddingBottom: 10,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  selected: {
    backgroundColor: "#7f8691 !important",
    color: "white",
    fontWeight: 600,
  },

  title: {
    flexGrow: 1,
  },

  inputRoot: {
    color: "inherit",
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const { state, toggleDarkMode, logout } = useContext(Context);
  const { user, route } = state;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: routeN, isLoading } = UseRoute(route.creditor_id, user.user_id);

  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [mobileMoreAnchorEl2, setMobileThemeAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isMobileMenuOpen2 = Boolean(mobileMoreAnchorEl2);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileThemeMenuClose = () => {
    setMobileThemeAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileThemeMenuOpen = (event) => {
    setMobileThemeAnchorEl(event.currentTarget);
  };

  const handleMobileNavigation = (to) => {
    handleMobileMenuClose();
    if (to === "/login") {
      logout(() => navigate("/login", { replace: true }));
    } else {
      navigate(to);
    }
  };

  // const handleMobileThemeClicks = () => {
  //   toggleDarkMode();
  //   handleMobileThemeMenuClose();
  // };

  // const handleNavigation = (to) => {
  //   toggleDrawer();
  //   navigate(to);
  // };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const menuId = "primary-search-account-menu";

  const custBalUrl = route?.user_id ? `/customer/balance/${route.user_id}/1` : `/customer/balance/0/1`;

  const mobileMenuId = "primary-search-account-menu-mobile";
  const mobileMenuId2 = "primary-search-account-menu-mobile2";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem onClick={() => setIsVisible(!isVisible)}>
        <IconButton aria-label="show 0 new notifications" color="inherit">
          <Badge badgeContent={0} color="secondary">
            {/* <Notifications visibility={isVisible} user_id={route.user_id} /> */}
          </Badge>
        </IconButton>
        Notificaciones
      </MenuItem>
      {user.user_id === 0 && (
        <MenuItem onClick={() => handleMobileNavigation("/login")}>
          <IconButton aria-label="show 0 new notifications" color="inherit" size="large">
            <BiLogIn />
          </IconButton>
          Iniciar Sesion
        </MenuItem>
      )}

      <MenuItem onClick={() => handleMobileNavigation("/quickprinter")}>
        <IconButton aria-label="show 0 new notifications" color="inherit" size="large">
          <BluetoothIcon />
        </IconButton>
        Conectar Impresora
      </MenuItem>

      {user.user_id !== 0 && (
        <MenuItem onClick={() => handleMobileNavigation("/referrals")}>
          <IconButton aria-label="show 0 new referrals" color="inherit" size="large">
            <AiOutlineUsergroupAdd size="1em" />
          </IconButton>
          Referir un amigo
        </MenuItem>
      )}

      <MenuItem onClick={() => handleMobileNavigation(custBalUrl)}>
        <IconButton aria-label="show 0 new referrals" color="inherit" size="large">
          <AiOutlineDollarCircle size="1em" />
        </IconButton>
        Mi Factura
      </MenuItem>

      {user.user_id !== 0 && (
        <MenuItem onClick={() => handleMobileNavigation("/profile")}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            size="large">
            <FaRegUser size="0.8em" />
          </IconButton>
          Mi Perfil
        </MenuItem>
      )}

      <Divider />

      {user.user_id !== 0 && (
        <MenuItem className="text-danger" onClick={() => handleMobileNavigation("/login")}>
          <IconButton
            aria-label="logout"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            size="large">
            <AiOutlinePoweroff size="0.8em" />
          </IconButton>
          Salir
        </MenuItem>
      )}

      <Divider />

      <MenuItem onClick={() => handleMobileNavigation("/contact")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large">
          <AiOutlineQuestionCircle />
        </IconButton>
        Contactarme
      </MenuItem>
    </Menu>
  );

  // const renderThemeMenu = (
  //   <Menu
  //     anchorEl={mobileMoreAnchorEl2}
  //     anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //     id={mobileMenuId2}
  //     keepMounted
  //     transformOrigin={{ vertical: "top", horizontal: "right" }}
  //     open={isMobileMenuOpen2}
  //     onClose={handleMobileThemeMenuClose}>
  //     <MenuItem onClick={() => handleMobileThemeClicks("light")}>
  //       <IconButton
  //         aria-label="account of current user"
  //         aria-controls="primary-search-account-menu"
  //         aria-haspopup="true"
  //         color="inherit"
  //         size="large">
  //         <Brightness6Icon />
  //       </IconButton>
  //       <p>
  //         Color Claro{" "}
  //         {/* {state.device.darkMode === false && (
  //           <small>
  //             <CheckIcon />
  //           </small>
  //         )} */}
  //       </p>
  //     </MenuItem>
  //     <MenuItem onClick={() => handleMobileThemeClicks("dark")}>
  //       <IconButton
  //         aria-label="account of current user"
  //         aria-controls="primary-search-account-menu"
  //         aria-haspopup="true"
  //         color="inherit"
  //         size="large">
  //         <Brightness7Icon />
  //       </IconButton>

  //       <p>
  //         Color Oscuro{" "}
  //         {/* {state.device.darkMode === true && (
  //           <small>
  //             <CheckIcon />
  //           </small>
  //         )} */}
  //       </p>
  //     </MenuItem>
  //     <MenuItem onClick={() => handleMobileThemeClicks("/profile")}>
  //       <IconButton
  //         aria-label="account of current user"
  //         aria-controls="primary-search-account-menu"
  //         aria-haspopup="true"
  //         color="inherit"
  //         size="large">
  //         <BrightnessAutoIcon />
  //       </IconButton>

  //       <p>Automatico</p>
  //     </MenuItem>
  //   </Menu>
  // );

  return (
    <div className={classes.grow}>
      <InternetConection />
      <AppBar style={{ marginLeft: -1, marginTop: -1 }} position="static">
        {user.user_id !== 0 ? (
          <Toolbar>
            <IconButton
              onClick={toggleDrawer}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              size="large">
              <MenuIcon />
            </IconButton>
            <Typography onClick={() => navigate("/dashboard")} className={classes.title} variant="h6" noWrap>
              WPrestamos
            </Typography>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                edge="end"
                aria-label="show 0 new notifications"
                color="inherit"
                onClick={() => setIsVisible(!isVisible)}>
                <Badge badgeContent={0} color="secondary">
                  {/* <IoMdNotificationsOutline /> */}
                  {/* <Notifications visibility={isVisible} user_id={route.user_id} /> */}
                </Badge>
              </IconButton>

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => navigate("/quickprinter")}
                color="inherit"
                size="large">
                <BluetoothIcon />
              </IconButton>

              <IconButton
                edge="end"
                onClick={() => navigate("/referrals")}
                aria-label="show 0 new referrals"
                color="inherit"
                size="large">
                <AiOutlineUsergroupAdd size="1em" />
              </IconButton>

              <IconButton
                edge="end"
                onClick={() => navigate(custBalUrl)}
                aria-label="show 0 new referrals"
                color="inherit"
                size="large">
                <AiOutlineDollarCircle size="1em" />
              </IconButton>

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => navigate("/profile")}
                color="inherit"
                size="large">
                <FaRegUser size="0.8em" />
              </IconButton>

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => navigate("/contact")}
                color="inherit"
                size="large">
                <AiOutlineQuestionCircle />
              </IconButton>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                size="large">
                <MoreIcon />
              </IconButton>
            </div>

            {/* <div>
              <IconButton
                edge="end"
                aria-label="Admin Panel"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMobileThemeMenuOpen}
                color="inherit"
                size="large">
                {state.device.darkMode === true ? <Brightness7Icon /> : <Brightness6Icon />}
              </IconButton>
            </div> */}
          </Toolbar>
        ) : (
          <Toolbar>
            <Typography onClick={() => navigate("/")} className={classes.title} variant="h6" noWrap>
              Bienvenido
            </Typography>

            {/* <LoginDialog  /> */}
            {/* <Button variant="outlined" color="inherit" size="small" onClick={() => navigate("/login")}>
              Iniciar Sesion
            </Button> */}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/*
              <IconButton edge="end" aria-label="show 0 new mails" color="inherit">
              <Badge badgeContent={0} color="secondary">
              <AiOutlineMail />
              </Badge>
              </IconButton>
              <IconButton edge="end" aria-label="show 0 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
              <IoMdNotificationsOutline />
                </Badge>
              </IconButton> */}

              {+user.user_id === 0 && (
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={() => navigate("/login")}
                  color="inherit"
                  size="large">
                  <BiLogIn />
                </IconButton>
              )}

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => navigate("/quickprinter")}
                color="inherit"
                size="large">
                <BluetoothIcon />
              </IconButton>

              {+user.user_id !== 0 && (
                <IconButton
                  edge="end"
                  onClick={() => navigate("/referrals")}
                  aria-label="show 0 new referrals"
                  color="inherit"
                  size="large">
                  <AiOutlineUsergroupAdd size="1em" />
                </IconButton>
              )}

              <IconButton
                edge="end"
                onClick={() => navigate(custBalUrl)}
                aria-label="show 0 new referrals"
                color="inherit"
                size="large">
                <AiOutlineDollarCircle size="1em" />
              </IconButton>

              {+user.user_id !== 0 && (
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={() => navigate("/profile")}
                  color="inherit"
                  size="large">
                  <FaRegUser size="0.8em" />
                </IconButton>
              )}

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => navigate("/contact")}
                color="inherit"
                size="large">
                <AiOutlineQuestionCircle />
              </IconButton>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                size="large">
                <MoreIcon />
              </IconButton>
            </div>

            <div>
              <IconButton
                edge="end"
                aria-label="Admin Panel"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMobileThemeMenuOpen}
                color="inherit"
                size="large">
                {state.device.darkMode === true ? <Brightness7Icon /> : <Brightness6Icon />}
              </IconButton>
            </div>
          </Toolbar>
        )}
      </AppBar>

      {user.user_id !== 0 && (
        <>
          <UserBalance owner_id={route.user_id} mainOnly={1} />
          <RenderTestUserWarning route={route} />
        </>
      )}

      {+routeN?.isCobro === 1 && (
        <div className="text-center mt-2">
          <Typography variant="body1" className="text-danger" component="h4" gutterBottom>
            Cuadre de la App de cobro abierto!
          </Typography>
        </div>
      )}

      {/* <DisplayAppEnvName avoidProd={true} displayLabel={false} /> */}

      <MUIDrawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
          <div className="text-center">
            <img
              alt="app-logo"
              style={{ width: 165, height: 93, marginBottom: 20, marginTop: 15 }}
              src="/../../full-logo-copy.png"
            />
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Typography variant="body2">{routeN.route_name}</Typography>
              <Typography variant="body1">Zona: {routeN.location}</Typography>
              <Typography variant="body1">Usuario: {user.firstname}</Typography>
            </>
          )}
          <Typography className="mt-1" variant="body2" align="right">
            <Link onClick={toggleDrawer} to="/routes/choose">
              Ver mis Rutas!
            </Link>
          </Typography>

          {/* <ChangeRouteModal  toggleDrawer={toggleDrawer} /> */}
        </div>
        <Divider />

        <MenuList>
          <div onClick={() => toggleDrawer()}>
            {DrawerList.map((item, index) => {
              return (
                <div key={index}>
                  <MenuItem
                    component={Link}
                    to={item.path}
                    classes={{ selected: classes.selected }}
                    selected={pathname === item.path}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </MenuItem>
                </div>
              );
            })}
          </div>
        </MenuList>
      </MUIDrawer>
      <InstallPWA />
      {renderMobileMenu}
      {/* {renderThemeMenu} */}
    </div>
  );
}
