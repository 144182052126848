import { useContext } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../context/DataContext";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import SubmitButton from "@shared/components/SubmitButton";
import CancelIcon from "@mui/icons-material/Cancel";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import Loading from "@shared/components/Loading";
import { UseDebtors } from "@shared/server/fetcher";
// import { useConfirm } from "material-ui-confirm";
import { useSWRConfig } from "swr";
import { useNavigate } from "react-router-dom";

const DebtorDelete = () => {
  const navigate = useNavigate();
  const { mutate: globalMute } = useSWRConfig();
  const { state, debtorDelete } = useContext(Context);
  const { id: debtor_id } = useParams();
  const { route, user } = state;
  // const confirm = useConfirm();

  const { data: debtors, isLoading: debtorLoading, mutate } = UseDebtors(route.creditor_id, user.user_id);
  const debtor = debtors?.find((x) => x.dUniqueId === +debtor_id);

  if (debtorLoading) return <Loading />;

  const submithandler = async () => {
    await debtorDelete(debtor.debtor_id, user.user_id, history);
    mutate(`/debtors/${route.creditor_id}/${user.user_id}`);
    globalMute(`/loans/${route.creditor_id}/${user.user_id}`);
    // globalMute(`/xcobrar/loans/${route.creditor_id}/${user.user_id}`);
    navigate("/dashboard", { replace: true });
  };

  const handleOnClick = async () => {
    confirm("Esta seguro de que quiere borrar este cliente?").then(
      () => {
        submithandler();
      },
      () => {
        console.log("cancel!");
      }
    );
  };

  return (
    <div className="container mt-5">
      <DebtorNameHeader debtor={debtor} />
      <Card>
        <CardContent>
          <Typography variant="h5" component="h3" className="text-danger">
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom>
            Decea borrar este Cliente?
          </Typography>

          <Typography variant="h6" component="h4" paragraph>
            {debtor.name}
          </Typography>

          <Typography variant="body1" className="text-danger" component="h4" gutterBottom>
            <b>Hay borrar este cliente estaria borrando todos sus pr&eacute;stamos!</b>
          </Typography>

          {/* <Box paddingTop={4}>
            <SubmitButton
              autoFetch={false}
              color="secondary"
              text="Eliminar Cliente!"
              startIcon={<CancelIcon />}
              callBack={handleOnClick}
            />
          </Box> */}
        </CardContent>
      </Card>
    </div>
  );
};

export default DebtorDelete;
