import { yupResolver } from "@nodes/@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { string, object } from "yup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "@shared/components/Loading";
import { UsePasswordResetToken } from "@shared/server/fetcher";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { InputPassword } from "@shared/components/form/InputPassword";
import { DevTool } from "@nodes/@hookform/devtools";
import { resetPassword } from "@shared/api/user";

const PasswordReset = () => {
  const navigate = useNavigate();
  const { id_key, token } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const validation = object({
    password: string()
      .required("Contraseña es requerida!")
      .min(7, "Nueva contraseña debe ser minimo 7 letras / numeros!")
      .max(40, "Nueva contraseña debe ser maximo 25 letras / numeros!"),
  });

  const defaultValues = {
    id_key,
    token,
    password: "",
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
  });

  const { isSubmitting } = formState;

  const { data: response, isLoading } = UsePasswordResetToken(id_key, token);

  if (isLoading) return <Loading />;

  if (response.allow_reset !== "yes")
    return (
      <Card className="mt-5 mb-5" raised>
        <CardContent>
          <Typography className="mt-3" component="p" variant="h5">
            Enlance Invalido!
          </Typography>

          <Typography className="mt-3 mb-5" component="p" variant="body1">
            Haga clic en el siguiente boton para ir a la pagina de Incio!
          </Typography>

          <Container maxWidth="xs">
            <Button fullWidth variant="contained" color="primary" onClick={() => navigate("/", { replace: true })}>
              Ir a Inicio
            </Button>
          </Container>
        </CardContent>
      </Card>
    );

  const handleSubmitHandler = async (data) => {
    try {
      await resetPassword(data);
      navigate("/login", { replace: true });
      enqueueSnackbar("Su nueva contraseña fue guardada!", { variant: "info" });
    } catch (error) {
      // enqueueSnackbar(error, { variant: "error" });
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <Typography variant="h6" component="h4" paragraph>
        Elija una nueva contraseña!
      </Typography>

      <Typography variant="subtitle1" component="p" gutterBottom>
        Su usuario es <b>{response.username}</b>
      </Typography>

      <Typography variant="subtitle1" component="p" paragraph>
        Escriba su nueva contraseña en las siguientes casillas
      </Typography>

      <form onSubmit={handleSubmit(handleSubmitHandler)}>
        <Box paddingBottom={2} paddingTop={2}>
          <InputPassword
            name="password"
            label="Nueva Contraseña"
            control={control}
            placeholder={"Escriba su su nueva contraseña!"}
            configs={{ variant: "outlined", size: "large" }}
          />
        </Box>

        <Box paddingBottom={2} paddingTop={2}>
          <Button disabled={isSubmitting} type="submit" fullWidth variant={"contained"}>
            Guardar Contraseña!
          </Button>
        </Box>
        <DevTool control={control} />
      </form>
    </div>
  );
};

export default PasswordReset;
