import { useContext } from "react";
import { Context } from "../context/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { string, ref, object } from "yup";
import { Form, RenderInput } from "@shared/components/Form";
import Typography from "@mui/material/Typography";
import RenderBackButton from "@shared/components/RenderBackButton";

const UserPassword = () => {
  const navigate = useNavigate();
  const { id: user_id, t: type } = useParams();
  const { updatePassword } = useContext(Context);

  const initialPasswordValues = {
    user_id: user_id,
    currentPassword: type === "employee" ? "tempPassword" : "",
    newPassword: "",
    confirmNewPassword: "",
    type,
  };

  const validationPassword = object({
    currentPassword: string().required("Ingrese su contraseña actual!"),
    newPassword: string()
      .required("Contraseña es requerida!")
      .min(7, "Su contraseña debe ser minimo 7 letras / numeros!")
      .max(25, "Su contraseña debe ser maximo 25 letras / numeros!"),
    confirmNewPassword: string()
      .required("Vuelva a ingresar la misma contraseña!")
      .oneOf([ref("newPassword"), null], "Ingrese la misma contraseña 2 veces!"),
  });

  const buttonPassword = {
    label: "Actualizar Contraseña",
    variant: "contained",
    color: "primary",
  };

  const submithandlerPassword = async (data) => {
    const redirect = type === "employee" ? `/employee/${user_id}` : "/profile";
    await updatePassword({ data }, () => navigate(redirect, { replace: true }));
  };

  return (
    <div className="mt-5">
      <Card raised>
        <CardHeader title="Contraseña" />
        <CardContent>
          <Typography variant="body1" paragraph>
            En esta pagina puede cambiar la contrase&ntilde;a!
          </Typography>
          <div>
            <Form
              initialValues={initialPasswordValues}
              validation={validationPassword}
              submithandler={submithandlerPassword}
              button={buttonPassword}>
              {type !== "employee" && (
                <Box paddingBottom={3}>{RenderInput("currentPassword", "Contraseña Actual", "password")}</Box>
              )}
              <Divider />
              <Box paddingBottom={2}>{RenderInput("newPassword", "Nueva Contraseña", "password")}</Box>
              <Box paddingBottom={4}>{RenderInput("confirmNewPassword", "Confirme Nueva Contraña", "password")}</Box>
            </Form>
            <div className="mt-4">
              <RenderBackButton />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserPassword;
