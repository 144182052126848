import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import { string, object } from "yup";
import { yupResolver } from "@nodes/@hookform/resolvers/yup";
import { InputText } from "@shared/components/form/InputText";
import { InputPassword } from "@shared/components/form/InputPassword";
// import { InputRadio } from "@shared/components/form/InputRadio";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { registerUser } from "@shared/api/user";
import { useNavigate } from "react-router-dom";
import { localStorageAuth } from "@/config";

const RenderUsernamePassword = ({ control }) => {
  return (
    <>
      <Typography variant="body1" component="p" paragraph className="text-info">
        <b>Por favor, recuerde esta informacion para ingresar a su cuenta!</b>
      </Typography>

      {/* <Box paddingBottom={2}>
        <InputRadio
          name="allowCobro"
          control={control}
          label="Permitir ingresar en la app de cobro?"
          options={[
            { label: "Si", value: 1 },
            { label: "No", value: 0 },
          ]}
        />
      </Box> */}

      <Box paddingBottom={2}>
        <InputText
          name="username"
          label="Usuario"
          control={control}
          configs={{
            size: "medium",
            variant: "outlined",
            helperText: "Su usuario debe ser Alphanumerico,solamente letras y/o numeros, no espacio.",
          }}
          placeholder={"Elija su usuario!"}
        />
      </Box>

      <Box paddingBottom={2}>
        <InputPassword
          name="password"
          label="Contrasena"
          control={control}
          configs={{
            size: "medium",
            variant: "outlined",
            helperText: "Su contraseña debe ser de minimo 7 letras y/o numeros.",
          }}
          placeholder={"Elija su contraseña!"}
        />
      </Box>

      <Typography variant="body1" component="p" className="text-danger">
        <b>Su usuario y su contraseña es unicamente para usted, no lo comparta con nadie!</b>
      </Typography>
    </>
  );
};

const schema = object({
  username: string()
    .required("Usuario es requerido!")
    .min(4, "Su usuario debe ser minimos 4 letras/ numero!")
    .max(15, "Su usuario debe ser maximo 15 letras / numeros!")
    .matches(/^([a-zA-Z0-9_-]+)$/, "Su usuario solo puede tener letras y numeros!"),
  password: string()
    .required("Contraseña es requerida!")
    .min(7, "Su contraseña debe ser minimo 7 letras / numeros!")
    .max(40, "Su contraseña debe ser maximo 25 letras / numeros!"),
  // allowCobro: boolean().required("Por favor, espefique si o no!").oneOf([0, 1, false, true]),
});

export default function SignupUsername() {
  const navigate = useNavigate();

  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { password: "", username: "", allowCobro: null },
  });

  const { isSubmitting } = formState;

  const handleSubmitHandler = async (data) => {
    const localKey = "registerForm";
    const existing = localStorage.getItem(localKey);
    let prevValues = {};
    if (existing) {
      prevValues = JSON.parse(existing);
    }

    try {
      const resData = await registerUser({ ...data, ...prevValues, parent_id: 0, ISO: "DO", ipAddress: "N/A" });
      const jsonValue = JSON.stringify(resData.jwt);
      localStorage.setItem(localStorageAuth, jsonValue);

      localStorage.removeItem(localKey);

      navigate("/new/user", { replace: true });
    } catch (err) {
      console.log("AppWorkflow - registerUser from dataContext - Catch error");
      console.log(err.message);
    }
  };

  return (
    <div className="container mt-3 mb-5">
      <Box sx={{ maxWidth: 500, flexGrow: 1 }}>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
            bgcolor: "background.default",
          }}>
          <Typography variant="h6">Crear su usuario y contraseña</Typography>
        </Paper>
        <Box sx={{ maxWidth: 600, width: "100%", p: 2 }}>
          <form onSubmit={handleSubmit(handleSubmitHandler)}>
            <Box paddingBottom={2}>
              <RenderUsernamePassword control={control} />
            </Box>

            <Box paddingBottom={2}>
              <Button disabled={isSubmitting} type="submit" fullWidth variant={"contained"}>
                Crear Cuenta!
              </Button>
            </Box>

            <Box paddingBottom={2}>
              <Button fullWidth onClick={() => navigate("/register")} variant={"outlined"} color="secondary">
                Volver Atras
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </div>
  );
}
