import backend from "@shared/server/backend";

export const routeCreate = async (data) => {
  const response = await backend.post("/route/createv2", { data });
  return response.data;
};

export const routeModify = async (data) => {
  const response = await backend.put("/route/modify", { data });
  return response.data;
};

export const routeConfigModify = async (data) => {
  const response = await backend.put("/route/modify/config", { data });
  return response.data;
};

export const ownerConfigModify = async (data) => {
  const response = await backend.put("/owner/config", { ...data });
  return response.data;
};

export const deleteRoute = async (data) => {
  const response = await backend.post("/routes/debtors/loans/delete", { ...data });
  return response.data;
};

export const createAnnouncement = async (data) => {
  const response = await backend.post("/route/announcement", { ...data });
  return response.data;
};

export const deleteRouteLoans = async (data) => {
  const response = await backend.post("/routes/debtors/loans/delete", { ...data });
  return response.data;
};

export const deleteOwnerLoans = async (data) => {
  const response = await backend.post("/owner/debtors/loans/delete", { ...data });
  return response.data;
};
