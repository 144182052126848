import Box from "@nodes/@mui/material/Box";
import Typography from "@nodes/@mui/material/Typography";
import Divider from "@nodes/@mui/material/Divider";
import Container from "@nodes/@mui/material/Container";

const RenderDebtorImages = ({ images = [] }) => {
  if (images.length === 0) return null;

  return (
    <Box>
      <Divider />

      <Box p={1} component="div">
        <Typography variant="h6" component="h4">
          Fotos del Cliente
        </Typography>
      </Box>

      {images.map((image) => (
        <Box key={image.timestamp} component="div" paddingBottom={1}>
          <Container>
            <img alt="Imagen Cliente" src={image.data} style={{ width: "100%" }} />
          </Container>
        </Box>
      ))}
    </Box>
  );
};

export default RenderDebtorImages;
