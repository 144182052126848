import { LocalizationProvider } from "@nodes/@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@nodes/@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@nodes/@mui/x-date-pickers/DatePicker";
import { Controller } from "@nodes/react-hook-form";
import FormHelperText from "@nodes/@mui/material/FormHelperText";
import { newDate } from "@shared/functions";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}

export const InputDate = ({ name, control, label, configs = {} }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => (
          <>
            <DatePicker
              slotProps={{ textField: { fullWidth: true } }}
              size={configs.size ?? "small"}
              disabled={configs.disabled ?? false}
              disablePast={configs.disablePast ?? false}
              disableFuture={configs.disableFuture ?? false}
              label={label}
              value={value ? new Date(newDate(value)) : null}
              onBlur={onBlur}
              onChange={(newValue) => onChange(formatDate(newValue))}
              variant={configs.variant ?? "standard"}
            />
            {error?.message && <FormHelperText>{error.message}</FormHelperText>}
            {configs.helperText && <FormHelperText>{configs.helperText}</FormHelperText>}
          </>
        )}
      />
    </LocalizationProvider>
  );
};
