import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../context/DataContext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { UseEmployee } from "@shared/server/fetcher";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EmployeeNameHeader from "../components/EmployeeNameHeader";
import SubmitButton from "@shared/components/SubmitButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Loading from "@shared/components/Loading";
import RenderBackButton from "@shared/components/RenderBackButton";

const DeleteEmployee = () => {
  const navigate = useNavigate();
  const { state, deleteEmployee } = useContext(Context);
  const { user } = state;

  const { id: owner_id, id2: employee_id } = useParams();
  const { data: employee, isLoading, mutate } = UseEmployee(owner_id, employee_id);

  if (isLoading) return <Loading />;

  const submitHandlder = async () => {
    await mutate(deleteEmployee({ owner_id, employee_id, deletedBy: user.user_id }, navigate), {
      populateCache: false,
    });
  };

  return (
    <div className="container mt-5">
      <EmployeeNameHeader user={employee} />

      <Card>
        <CardContent>
          <Typography variant="h5" component="h3" className="text-danger">
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom>
            Decea borrar este Empleado?
          </Typography>

          <Typography variant="h6" component="h4" paragraph>
            {employee.fname} {employee.lname}
          </Typography>

          <Box paddingTop={2}>
            <SubmitButton
              autoFetch={false}
              color="secondary"
              text="Eliminar Empleado!"
              startIcon={<CancelIcon />}
              callBack={submitHandlder}
            />
            <div className="mt-4">
              <RenderBackButton />
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeleteEmployee;
