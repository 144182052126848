/* eslint-disable*/

import { useState, useEffect } from "react";
import { string, object } from "@nodes/yup";
import { Form, RenderInput } from "../components/Form";
import Box from "@nodes/@mui/material/Box";
import Button from "@nodes/@mui/material/Button";
import Typography from "@nodes/@mui/material/Typography";
import Countdown from "@nodes/react-countdown";
import Alert from "@nodes/@mui/material/Alert";
import Grow from "@nodes/@mui/material/Grow";
import backend from "@shared/server/backend";

const VerifyPhone = ({ submithandler, userData, updateUser = false }) => {
  const [smsSent, setSmsSent] = useState(false);
  const [showRetryButton, setShowRetryButton] = useState(true);
  const [ext, setExt] = useState("+1");

  const createVerifyPhone = async (data, setSmsSent, setShowRetryButton) => {
    try {
      const response = await backend.post("/phone/verify", { data });
      if (response.data !== "pending") return;
      setSmsSent(true);
    } catch (err) {
      setShowRetryButton(true);
      console.log("AppWorkflow - createVerifyPhone from dataContext - Catch error");
      console.log(err.message);
    }
  };

  let initialValues = { user_id: "", username: "", phone: "", method: "sms", code: "" };
  if (userData) {
    initialValues = {
      updateUser,
      user_id: userData.user_id,
      username: userData.username,
      name: userData.name,
      phone: ext + userData.phone,
      method: "sms",
      code: "",
    };
  }

  const sendSmsToken = () => {
    if (!showRetryButton || !initialValues.phone) return;
    setShowRetryButton(false);
    createVerifyPhone(initialValues, setSmsSent, setShowRetryButton);
  };

  useEffect(() => {
    sendSmsToken();
  }, []);

  const validation = object({
    code: string()
      .required("Codico de Verificacion requerido!")
      .min(1, "Codigo de verificacion invalido!")
      .max(10, "Codigo de verificacion invalido!"),
  });

  // Renderer callback with condition
  const renderer = ({ seconds, completed }) => {
    if (completed) {
      setShowRetryButton(true);
      return null;
    } else {
      return <span>{seconds}</span>;
    }
  };

  const phone = String(userData.phone);
  const maskedPhone = phone.substring(10, 7);

  const button = { label: "Verificar Numero", variant: "contained", color: "primary" };

  return (
    <div>
      <Typography variant="h5" component="h3" gutterBottom>
        Verificar telefono!
      </Typography>
      <Typography variant="subtitle1" component="p" paragraph>
        Un mensaje de texto con un codigo de verificacion fue enviado.
      </Typography>

      {smsSent && (
        <Box paddingBottom={1}>
          <Grow direction="up" in>
            <Alert variant="outlined" severity="success">
              <Typography variant="body1" component="p" gutterBottom>
                Codigo de Verificacion enviado!
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                Su numero de telefono: {"(xxx) xxx-x" + maskedPhone}
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                Este codigo puede tardar hasta 1 minuto en llegar!
              </Typography>
            </Alert>
          </Grow>
        </Box>
      )}

      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={3}>{RenderInput("code", "Codido de Verification", "tel", false, "outlined")}</Box>
      </Form>

      <Box paddingTop={4}>
        <Button fullWidth variant="outlined" color="secondary" onClick={sendSmsToken} disabled={!showRetryButton}>
          {showRetryButton === true ? (
            <span>Enviar Codigo Nuevamente</span>
          ) : (
            <span>
              Enviar Codigo Nuevamente (<Countdown date={Date.now() + 60000} renderer={renderer} />)
            </span>
          )}
        </Button>
      </Box>
    </div>
  );
};

export default VerifyPhone;
