import LoanMenu from "./LoanMenu";
import Card from "@nodes/@mui/material/Card";
import CardActions from "@nodes/@mui/material/CardActions";
import Divider from "@nodes/@mui/material/Divider";
import Grid from "@nodes/@mui/material/Grid";
import CardHeader from "@nodes/@mui/material/CardHeader";
import IconButton from "@nodes/@mui/material/IconButton";
import { FaRegMoneyBillAlt } from "@nodes/react-icons/fa";
import PersonIcon from "@nodes/@mui/icons-material/Person";
import CardContent from "@nodes/@mui/material/CardContent";
import Typography from "@nodes/@mui/material/Typography";
import { printDate, newDate, printAbsAmount, getBadgeColor } from "../functions";
import { useNavigate } from "@nodes/react-router-dom";
import { parseISO } from "@nodes/date-fns";

const LoanCard = ({ loan }) => {
  const navigate = useNavigate();

  return (
    <Card raised className="mb-1">
      <CardHeader
        action={<LoanMenu loan={loan} />}
        title={
          <Typography variant="h6" component="h4">
            {loan.name}
          </Typography>
        }
        subheader={
          <>
            <Typography variant="body2" component="p">
              Balance {printAbsAmount(loan.balance + loan.balMora)}
            </Typography>
            <Typography variant="body2" component="p">
              Ultimo Pago: {printDate(newDate(loan.last_date), "dd-MMM-y")}
            </Typography>
          </>
        }
      />
      <CardContent>
        <Typography variant="body1" component="p">
          Entregado en: {printDate(parseISO(loan.created_time), "dd-MMM-yyyy")}
          {/* Entregado en: {printDate(newDate(loan.given_date), "dd-MMM-y")} */}
        </Typography>

        <Typography variant="body1" component="p">
          Cantidad Prestada: {printAbsAmount(loan.amount)}
        </Typography>

        <Typography variant="body1" component="p">
          Cuotas Paga: {printAbsAmount(loan.cuota)} | {loan.completed} / {loan.npayments}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions disableSpacing>
        <Grid container>
          <Grid item xs={6}>
            <IconButton
              aria-label="add sector"
              onClick={() => navigate(`/payment/create/${loan.money_id}/${loan.debtor_id}`)}
              size="large">
              <FaRegMoneyBillAlt className="text-success" />
            </IconButton>
            <IconButton aria-label="share" onClick={() => navigate(`/debtor/profile/${loan.dUniqueId}`)} size="large">
              <PersonIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6} align="right">
            {loan.statusText === "Al Dia" ? (
              <Typography variant="subtitle2" component="p" className="badge badge-pill badge-success">
                <i>{loan.statusText}</i>
              </Typography>
            ) : (
              <Typography variant="subtitle2" component="p" className={getBadgeColor(loan.statusText)}>
                <i>
                  {loan.statusText}: {printAbsAmount(loan.statusAmount)}
                </i>
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default LoanCard;
