import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Loading from "@shared/components/Loading";
import { Context } from "../context/DataContext";
import { UseRoute, UseUser, UsePermissions, UseRolePermission } from "@shared/server/fetcher";
// import { validateCedula } from "@shared/functions";
import RenderBackButton from "@shared/components/RenderBackButton";
import Checkbox from "@mui/material/Checkbox";
import SubmitButton from "@shared/components/SubmitButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import { BiCommentAdd } from "react-icons/bi";
import FormHelperText from "@mui/material/FormHelperText";
import { useSWRConfig } from "swr";

const RoleCreate = () => {
  const { mutate } = useSWRConfig();
  const { id: role_id } = useParams();

  const [picked2, setPermissions2] = useState({});
  const { state, createNewRole } = useContext(Context);
  const { user, route } = state;

  const [picked, setPermissions] = useState({});

  const { data: userN, isLoading: isL1 } = UseUser(user.user_id);
  const { data: routeN, isLoading: isL2 } = UseRoute(route.creditor_id, userN?.user_id);
  const { data: permissions, isLoading: permLoading } = UsePermissions(routeN?.user_id);
  const { data: perm, isLoading: permLoading2 } = UseRolePermission(role_id);

  useEffect(() => {
    if (!perm || !permissions) return;
    const picked2 = Object.keys(perm.object).reduce((acc, curr) => ((acc[curr] = true), acc), {});
    setPermissions2(picked2);
  }, [perm, permissions]);

  const handleOnChange = (event) => {
    const { name, checked } = event.target;
    setPermissions({ ...picked, [name]: checked });
  };

  const handleOnSubmit = async () => {
    await mutate(`/roles/${routeN.user_id}`);
    await mutate(`/rolespermissions`);

    if (role_id) {
      await mutate(`/rolespermissions/${role_id}`);
    }

    await createNewRole({ user_id: routeN.user_id, role_id, permissions: Object.entries({ ...picked2, ...picked }) });

    history.replace("/employees");
  };

  if (isL1 || isL2 || permLoading || (permLoading2 && role_id)) return <Loading />;

  return (
    <div className="container mt-4 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Crear nuevo Rol!
      </Typography>

      <Typography variant="subtitle1" component="p" paragraph>
        Aqui puede crear un rol con permissos personalizado
      </Typography>

      <FormControl component="fieldset">
        <FormLabel component="legend"> Selecione todo los permisos que quieres para este rol.</FormLabel>
        <FormGroup aria-label="position">
          {permissions.map((item) => (
            <FormControlLabel
              key={item.permission_id}
              control={
                <Checkbox
                  checked={{ ...picked2, ...picked }[item.permission_id] ? true : false}
                  onChange={handleOnChange}
                  color="secondary"
                  value={item.permission_id}
                  name={item.permission_id}
                />
              }
              label={item.permission_description}
              labelPlacement="end"
            />
          ))}
        </FormGroup>
        <FormHelperText>Error Text goes here</FormHelperText>
      </FormControl>

      <Box component={"div"} p={1} className="mt-3">
        <SubmitButton
          text={role_id ? "Modificar Permisos" : "Crear Nuevo Rol"}
          startIcon={<BiCommentAdd />}
          color="primary"
          callBack={handleOnSubmit}
          autoFetch={false}
        />
      </Box>

      <div className="mt-4">
        <RenderBackButton />
      </div>
    </div>
  );
};

export default RoleCreate;
