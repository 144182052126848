import { useContext, useState, useMemo } from "react";
import Drawer from "./components/Drawer";
// import ChangeRouteModal from "./components/ChangeRouteModal";
import { ToastContainer } from "react-toastify";
import Snackbar from "./components/Snackbar";
import ResolveAuth from "./pages/ResolveAuth";
// import { hot } from "react-hot-loader";
// import Paper from "@material-ui/core/Paper";
// import { Context } from "./context/DataContext";
import "react-toastify/dist/ReactToastify.css";
// import { withProfiler } from "@sentry/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { red, purple } from "@mui/material/colors";
import Routes from "./Routes";
import CssBaseline from "@mui/material/CssBaseline";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import "./index.css";

// import StickyState from "@shared/components/StickyState.jsx";

const App = () => {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = false;
  // const [mode, setMode] = useState(true);

  // const { state } = useContext(Context);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#23569e",
          },
          green: {
            main: "#307b31",
          },
          purple: {
            main: purple[500],
          },
          secondary: {
            main: "#f50057",
          },
          error: {
            main: red.A400,
          },
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ResolveAuth />
      <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
        <ConfirmProvider>
          <Drawer />
          <ToastContainer />
          <Snackbar />
          <Routes />
        </ConfirmProvider>
      </SnackbarProvider>
      ;
    </ThemeProvider>
  );
};

// export default withProfiler(App);
export default App;
