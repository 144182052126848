import { useState, useEffect, useCallback, useRef } from "react";
import SearchBox from "@shared/components/common/SearchBox";
import DebtorCard from "@shared/components/DebtorCard";
import Grid from "@nodes/@mui/material/Grid";
import Typography from "@nodes/@mui/material/Typography";
import Box from "@nodes/@mui/material/Box";
import Container from "@nodes/@mui/material/Container";
import { RemoveAccents, indentifySearchType } from "@shared/functions";
import useDebounce from "@shared/hooks/useDebounce";
import Loading from "@shared/components/Loading";

function getDaysAgoData(data, daysAgo) {
  // Get current date
  let t = new Date();
  // Create UTC date for daysAgo
  let d = new Date(Date.UTC(t.getFullYear(), t.getMonth(), t.getDate() - daysAgo));
  // Filter and sort data
  return data
    .filter((item) => new Date(item.created_time) >= d)
    .sort((a, b) => a.created_time.localeCompare(b.created_time));
}

const RenderDebtorsNew = ({ debtors = [] }) => {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [filteredDebtors, setFilteredDebtors] = useState([]);
  const loader = useRef(null);
  const debtorsXPage = 10;
  const [loading, setLoading] = useState(true);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  console.log(filteredDebtors[0]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useDebounce(
    () => {
      if (searchText.length < 3) {
        setFilteredDebtors(getDaysAgoData(debtors.slice(0, page * debtorsXPage), 5));
        setLoading(false);
      } else {
        setFilteredDebtors(
          filteredDebtors
            .filter(
              (x) =>
                RemoveAccents(x.debtorSearch).toLowerCase().indexOf(indentifySearchType(searchText.toLowerCase())) > -1
            )
            .slice(0, debtorsXPage + 10)
        );
      }
    },
    [debtors, searchText, page],
    searchText.length < 3 ? 0 : 800
  );

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      {loading ? (
        <Loading />
      ) : (
        <div className="mt-4 mb-5">
          <Box component="div" p={1}>
            <Typography variant="h5" componet="h3">
              Clientes Nuevo ({filteredDebtors?.length})
            </Typography>
            <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar clientes nuevo..." value={searchText} />
          </Box>

          <Grid container spacing={1}>
            {filteredDebtors.map((debtor) => (
              <Grid key={debtor.debtor_id} item xs={12} sm={10} md={6} lg={4} xl={3}>
                <DebtorCard debtor={debtor} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      <Box ref={loader} component="div" p={2}>
        {!loading && searchText.length < 3 && debtors?.length > page * debtorsXPage && (
          <Typography variant="h5" paragraph>
            Por favor espere...
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default RenderDebtorsNew;
