import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import NewLoansCard from "@shared/components/NewLoansCard";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { printAmount } from "@shared/functions";
import { getNewLoansSelector } from "../context/Selectors";
import DateRangePicker from "@shared/components/DateRangePicker";
// import BottomNavigation from "../components/BottomNavigation";
import { UseEntregas, UseRoute, UseAPerturaCuadre } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
// import NoInternetContent from "@shared/components/NoInternetContent";

const NewLoans = () => {
  const [currentData, setCurrentData] = useState({ amount: 0, count: 0, list: [] });
  const { state } = useContext(Context);
  const { route: r2, user, userConfig } = state;
  const [currentDates, setCurrentDates] = useState(null);

  const { data: apertura, isLoading: acLoading } = UseAPerturaCuadre(r2.creditor_id);
  const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);

  const { data: newLoans, isLoading } = UseEntregas(
    r2?.creditor_id,
    currentDates?.start_date ?? apertura?.start_date,
    currentDates?.end_date ?? apertura?.range_date
  );

  useEffect(() => {
    if (apertura && !currentDates?.start_date) {
      setCurrentDates({
        start_date: apertura.start_date,
        end_date: apertura.range_date,
      });
    }

    if (newLoans) {
      setCurrentData(getNewLoansSelector(newLoans, false));
    }
    //eslint-disable-next-line
  }, [newLoans]);

  if (isLoading || routeLoading || acLoading) return <Loading />;
  // if (!newLoans) return <NoInternetContent />;

  return (
    <>
      {currentData.count === 0 ? (
        <div className="container mt-5 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Entrega de Pr&eacute;stamos!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los nuevos pr&eacute;stamos registrado.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no se ha registrado ningun renovo o nuevo pr&eacute;stamo!
          </Typography>
          <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} />
        </div>
      ) : (
        <div className="mt-3 mb-5">
          <Box component="div" p={1}>
            <Typography variant="h6" componet="h4">
              Entrega de Pr&eacute;stamos ({currentData.count}): {printAmount(currentData.amount)}
            </Typography>
          </Box>

          <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} />
          <Grid container>
            {currentData.list.map((loan) => (
              <Grid key={loan.money_id} item xs={12}>
                <NewLoansCard loan={loan} route={route} user={user} userConfig={userConfig} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {/* <BottomNavigation  /> */}
    </>
  );
};

export default NewLoans;
