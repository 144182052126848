import backend from "@shared/server/backend";

export * from "./route";
export * from "./user";
export * from "./loan";
export * from "./debtor";
export * from "./payment";

export const verifyAccessCode = async (data) => {
  const { accessCode, owner_id, type } = data;
  const response = await backend.post("/cobro/verify/accesscodes", { accessCode, owner_id, type });
  return response.data;
};

export const uploadDebuggingData = async (state) => {
  const response = await backend.post("/debugging", { state });
  return response.data;
};

export const updateDebtorCedula = async (debtor_id, { cedula }) => {
  const response = await backend.post("/save/debtor/cedula", { debtor_id, cedula });
  return response.data;
};

export const createReferralLink = async (data) => {
  const response = await backend.post("/referrals/link/create", { ...data });
  return response.data;
};

export const createOldReferral = async (data) => {
  const response = await backend.post("/referrals/old/create", { ...data });
  return response.data;
};

export const uploadCobroCuadre = async (data) => {
  const response = await backend.post("/cobro/logout/v5", { data });
  return response.data;
};

export const generateAccessCodes = async (data) => {
  const response = await backend.post("/central/access/codes/create", { ...data });
  return response.data;
};
