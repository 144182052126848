import { useContext, useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import useMediaQuery from "@shared/hooks/useMediaQuery";
import { Context } from "../context/DataContext";
import RenderRoutesBalance from "@shared/components/RenderRoutesBalance";
import RenderPaymentAccount from "@shared/components/RenderPaymentAccount";
import { UseCustomerBalance } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
import { MAX_PENDING_MONTHS_ALLOWED } from "@shared/constants";

const RouteWarning = () => {
  const { state } = useContext(Context);
  const { route } = state;
  // const { route: creditor_id } = useParams();
  const [currentRoute, setCurrentRoute] = useState({});
  const fullScreen = useMediaQuery("min-width:820px");

  const { data, isLoading } = UseCustomerBalance(route.user_id);

  useEffect(() => {
    if (data) {
      const result = data.routes.filter((x) => x.creditor_id === route.creditor_id)[0];
      setCurrentRoute(result);
    }
    //eslint-disable-next-line
  }, [data]);

  if (isLoading) return <Loading />;

  return (
    <div className="container mt-3 mb-5">
      <Typography variant="h4" align="center">
        Aviso!
      </Typography>

      {currentRoute?.route_name && (
        <div>
          <Typography variant="h6" noWrap>
            {currentRoute.route_name} ({currentRoute.location})
          </Typography>

          <div className="mt-2 mb-3">
            <Divider />
            <Divider />
          </div>
        </div>
      )}

      <Typography variant="body1" paragraph>
        <b>Esta ruta tiene pagos vencido, asegurese de no llegar a deber más de {MAX_PENDING_MONTHS_ALLOWED} meses.</b>
      </Typography>

      <RenderRoutesBalance userRoutesBalance={data} />

      <RenderPaymentAccount />

      <Box component="div" p={2}>
        <Link to={"/dashboard"}>
          <Button fullWidth={!fullScreen} variant="outlined" color="primary">
            Continuar
          </Button>
        </Link>
      </Box>
    </div>
  );
};

export default RouteWarning;
