import { useContext } from "react";
import { Context } from "../context/DataContext";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import RenderLoanContract from "@shared/components/RenderLoanContract";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import {
  UseLoanv2,
  UseLoansV2,
  UseLoanSignature,
  UseLoanSignaturev2,
  UseLoanIdentityPhoto,
} from "@shared/server/fetcher";

const LoanContract = () => {
  const { id: money_id, id2: debtor_id, id3: mUniqueId } = useParams();
  const { state } = useContext(Context);
  const { route, user } = state;

  const { data: loans, isLoading: isLoadingL } = UseLoansV2(route.creditor_id, user.user_id);
  const filteredLoan = loans?.find((x) => +x.money_id === +money_id);
  const shouldFetch = !isLoadingL && !filteredLoan ? true : false;
  const { data: currentLoan2, isLoading: isLoadingS } = UseLoanv2(route.creditor_id, money_id, debtor_id, shouldFetch);
  const currentLoan = filteredLoan ?? currentLoan2;

  const { data: signatureDataTemp, isLoading: isLoadingSig } = UseLoanSignature(route.creditor_id, mUniqueId);
  const { data: signatureData2, isLoading: isLoadingSig2 } = UseLoanSignaturev2(mUniqueId);
  const { data: identityPhoto, isLoading: isLoadingPhoto } = UseLoanIdentityPhoto(mUniqueId);

  if ((shouldFetch && isLoadingS) || isLoadingL) return <Loading />;

  const signatureData = Object.keys(signatureData2 || {}).length > 2 ? signatureData2 : signatureDataTemp;

  const RenderSignature = ({ signature }) => {
    return (
      <>
        {signature.name && signature.signature ? (
          <div className="container">
            <Box p={1} component="div">
              <RenderLoanContract
                title="Contrao de Pr&eacute;stamo!"
                template={signatureData.template}
                data={signatureData}
              />
            </Box>

            <Box component="div" p={1}>
              <img alt="Imagen Cliente" src={signatureData.signature} style={{ height: "25vh", width: "100%" }} />
              <Divider />
              <Divider />
              <Typography variant="body1">{signatureData.name}</Typography>
            </Box>

            {signatureData.signature2 && (
              <Box component="div" p={1}>
                <img alt="Imagen Cliente" src={signatureData.signature2} style={{ height: "25vh", width: "100%" }} />
                <Divider />
                <Divider />
                <Typography variant="body1">{`Garante de ${signatureData.name}`}</Typography>
              </Box>
            )}
          </div>
        ) : (
          <div className="container">
            <Typography variant="h5" gutterBottom>
              Contrato no fue encontrado!
            </Typography>
            {/* <Typography variant="body1" paragraph>
              Los contratos son automaticamente borrados despues de 12 meces que el pr&eacute;stamo ha saldado!
            </Typography> */}
          </div>
        )}
      </>
    );
  };

  const RenderIdentityPhoto = ({ data }) => {
    return (
      <>
        {data.money_id && data.dataImage ? (
          <div className="container">
            <Box component="div" p={1}>
              <img alt="Imagen Cliente" src={data.dataImage} style={{ height: "25vh", width: "100%" }} />
            </Box>
          </div>
        ) : (
          <div className="container mt-4">
            <Typography variant="h5" gutterBottom>
              No Foto encontrada!
            </Typography>
            {/* <Typography variant="body1">
              Los contratos son automaticamente borrados despues de 12 meces que el pr&eacute;stamo ha saldado!
            </Typography> */}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="mb-5">
      <DebtorNameHeader debtor={currentLoan}>
        <LoanDetailMenu loan={currentLoan} />
      </DebtorNameHeader>

      {isLoadingSig || isLoadingSig2 ? <Loading /> : <RenderSignature signature={signatureData} />}
      {isLoadingPhoto ? <Loading /> : <RenderIdentityPhoto data={identityPhoto} />}
    </div>
  );
};

export default LoanContract;
