import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import SearchBox from "@shared/components/common/SearchBox";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import { Link } from "react-router-dom";
import { getGastosSelector } from "../context/Selectors";
import { printAmount, newDate, printDate, RemoveAccents } from "@shared/functions";
import CreateSpense from "../components/CreateSpense";
import DateRangePicker from "@shared/components/DateRangePicker";
import DeleteGastoModal from "../components/DeleteGastoModal";
import { UseGastos, UseAPerturaCuadre } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
import { useSWRConfig } from "swr";

const Spenses = () => {
  const { mutate } = useSWRConfig();
  const { state, createGasto, deleteGasto } = useContext(Context);
  const [searchText, setSearchText] = useState("");
  const [currentData, setCurrentData] = useState({ amount: 0, count: 0, list: [] });
  const { user, route: r2 } = state;
  const [currentDates, setCurrentDates] = useState(null);

  const { data: apertura, isLoading: acLoading } = UseAPerturaCuadre(r2.creditor_id);
  const {
    data: gastos,
    isLoading,
    key,
  } = UseGastos(
    r2.creditor_id,
    currentDates?.start_date ?? apertura?.start_date,
    currentDates?.end_date ?? apertura?.range_date
  );

  useEffect(() => {
    if (apertura && !currentDates?.start_date) {
      setCurrentDates({
        start_date: apertura.start_date,
        end_date: apertura.range_date,
      });
    }

    if (gastos) {
      setCurrentData(getGastosSelector(gastos));
    }
    //eslint-disable-next-line
  }, [gastos]);

  if (isLoading || acLoading) return <Loading />;

  const handleCreateGasto = async (data) => {
    await createGasto(data);
    mutate(key);
  };

  const handleDeleteGasto = async (data) => {
    await deleteGasto(data);
    mutate(key);
  };

  const getFilteredDebtor = (debtors) => {
    let filtered = debtors;

    if (searchText.length > 2) {
      filtered = filtered.filter(
        (m) => RemoveAccents(m.description).toUpperCase().indexOf(searchText.toUpperCase()) >= 0
      );
    }

    return filtered;
  };

  const loansFilter = getFilteredDebtor(gastos);

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <>
      <CreateSpense
        handleCreateGasto={handleCreateGasto}
        creditor_id={r2.creditor_id}
        date={apertura.today_date}
        // sx={{ bottom: 0, right: 0 }}
        user_id={user.user_id}
      />

      {currentData.count === 0 ? (
        <div className="container mt-4 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Gastos Registrado!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los gastos registrado.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no ha ah registrado ningun gasto!
          </Typography>
          <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} />
        </div>
      ) : (
        <div className="mt-3">
          <Box component="div" p={1}>
            <Typography variant="h6" component="h4">
              Gastos ({currentData.count}):
              {printAmount(currentData.amount)}
            </Typography>
            <Typography variant="body1" component="h4">
              Lista de gastos para esta fecha
            </Typography>
            <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} />
            <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar Gasto..." value={searchText} />
          </Box>

          <div className="mb-5">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Gasto</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col">Fecha</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {loansFilter.map((gasto) => (
                  <tr key={gasto.id}>
                    <td>
                      <Typography variant="subtitle2" component="p" className="font-weight-bold">
                        {/* <Link style={{ color: "#000" }} to={`/loan/detail/${gasto.money_id}`}> */}
                        {gasto.description}
                        {/* </Link> */}
                      </Typography>
                    </td>
                    <td>{printAmount(gasto.amount)}</td>
                    <td>{printDate(newDate(gasto.date))}</td>
                    <td>
                      <DeleteGastoModal handleDeleteGasto={handleDeleteGasto} gasto={gasto} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* <BottomNavigation  /> */}
    </>
  );
};

export default Spenses;
