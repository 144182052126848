import { useContext } from "react";
import { Context } from "../context/DataContext";
import Card from "@mui/material/Card";
// import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Divider from "@mui/material/Divider";
// import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Chip from "@mui/material/Chip";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Container from "@mui/material/Container";
// import { object, number } from "yup";
import RenderCobroConfigForm from "@shared/components/RenderCobroConfigForm";
import RenderGeneralConfigForm from "@shared/components/RenderGeneralConfigForm";
import RenderHolidaysForm from "@shared/components/RenderHolidaysForm";
import RenderCobroAnnouncement from "@shared/components/RenderCobroAnnouncement";
// import { Form, RenderDropdown, RenderInput } from "@shared/components/Form";
// import CloneAllRouteConfigs from "../components/CloneAllRouteConfigs";
// import RenderOwnerConfig from "@shared/components/RenderOwnerConfig";
// import { toast } from "react-toastify";
// import { GrEdit } from "react-icons/gr";
import Loading from "@shared/components/Loading";
import { UseRoute, UseUserConfig, UseOwnerConfig, UseOwnerCobroConfig, UseOwnerHolidays } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";
import { updateConfig, updateOwnerHolidays, updateSecurirityConfig, updateGeneralConfigs } from "@shared/api/user";
import { createAnnouncement } from "@shared/api/route";
// import { ownerConfigModify } from "@shared/api/route";
import { useSnackbar } from "notistack";

const RouteConfig = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();
  const { state } = useContext(Context);
  const { user, route: r2 } = state;
  // const [selectedHolidays, setSelectedHolidays] = useState(null);

  const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  const { data: userConfig, isLoading: userLoading, key: k1 } = UseUserConfig(user.user_id);
  const { data: ownerConfig, isLoading: routeLoadingConfig, key: k2 } = UseOwnerConfig(route?.user_id);
  const { data: ownerCobroConfigs, isLoading: ocL, key: k3 } = UseOwnerCobroConfig(route?.user_id);
  const { data: ownerHolidays, isLoading: ohL, key: k4 } = UseOwnerHolidays(route?.user_id);

  // useEffect(() => {
  //   if (!ownerHolidays) return;
  //   setSelectedHolidays(ownerHolidays.filter((x) => x.select_holidays).map((a) => a.select_holidays));
  // }, [ownerHolidays]);

  if (userLoading || routeLoading || routeLoadingConfig || ocL || ohL) return <Loading />;

  const handlePrintAppChange = (event) => {
    mutate(k1, updateConfig({ user_id: user.user_id, printApp: event.target.value }), {
      optimisticData: { ...userConfig, printApp: event.target.value },
      populateCache: false,
    });
  };

  const submitGeneralConfigs = async (data) => {
    // console.log("Route config values", data);
    try {
      await updateGeneralConfigs({ ...data, owner_id: route.user_id });
      enqueueSnackbar("Configuraciones guardada!", { variant: "success" });
      mutate(k3);
    } catch (error) {}
  };

  const submitOwnerConfigs = async (data) => {
    try {
      await updateSecurirityConfig({ ...data, owner_id: route.user_id });
      enqueueSnackbar("Configuraciones guardada!", { variant: "success" });
      mutate(k3);
    } catch (error) {}
  };

  const submitOwnerHolidays = async (data) => {
    try {
      await updateOwnerHolidays({ ...data, owner_id: route.user_id });
      enqueueSnackbar("Dias de fiestas actualizado!", { variant: "success" });
      mutate(k4);
    } catch (error) {}
  };

  const submitAnnouncement = async (data) => {
    console.log(data);
    try {
      await createAnnouncement({ ...data, creditor_id: route.creditor_id });
      enqueueSnackbar("Anuncio fue guardado!", { variant: "success" });
      // mutate(k4);
    } catch (error) {}
  };

  return (
    <div className="container mt-3 mb-5">
      <Container className="mt-3" maxWidth="md" disableGutters>
        <Typography variant="h5" component="h3" gutterBottom>
          Configuraci&oacute;nes
        </Typography>

        <Box paddingTop={1}>
          <div style={{ display: "inline-flex" }}>
            <span className="pr-1">
              <b className="p-2">
                <Chip label={`W${user.user_id}`} />
              </b>
            </span>
            <Typography variant="subtitle1" component="p">
              {user.firstname} {user.lastname}
            </Typography>
          </div>

          <Card>
            <CardContent>
              <FormControl component="fieldset">
                <FormLabel component="legend">Que aplicacion de imprimir usar?</FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="printApp"
                  value={userConfig.printApp}
                  onChange={handlePrintAppChange}>
                  <FormControlLabel
                    value="RawBt"
                    control={<Radio />}
                    label={
                      <span>
                        <img alt="" src="/../../rawbt_24x24.png" /> RawBt
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="QuickPrinter"
                    control={<Radio />}
                    label={
                      <span>
                        <img alt="" src="/../../quickprinter_24x24.png" /> Quick Printer
                      </span>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>
          </Card>
        </Box>

        <Box paddingTop={3}>
          <Typography variant="h6" component="p" gutterBottom>
            Configuraci&oacute;nes General!
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Estas configuraci&oacute;nes son usadas globalmente!
          </Typography>

          <Card>
            <CardContent>
              <RenderGeneralConfigForm defaultValues={ownerConfig} submithandler={submitGeneralConfigs} />
            </CardContent>
          </Card>
        </Box>

        <Box paddingTop={3}>
          <Typography variant="h6" component="p" gutterBottom>
            Configuraci&oacute;nes de Seguridad!
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Estas configuraci&oacute;nes aplican &uacute;nicamente en la App de Cobro.
          </Typography>

          <Card>
            <CardContent>
              <RenderCobroConfigForm defaultValues={ownerCobroConfigs} submithandler={submitOwnerConfigs} />
            </CardContent>
          </Card>
        </Box>

        <Box paddingTop={3}>
          <Typography variant="h6" component="p" gutterBottom>
            Configuraci&oacute;n de Dias de Fiesta!
          </Typography>

          <Card>
            <CardContent>
              <RenderHolidaysForm data={ownerHolidays} submithandler={submitOwnerHolidays} />
            </CardContent>
          </Card>
        </Box>

        <Box paddingTop={3}>
          <Typography variant="h6" component="p" gutterBottom>
            Auncios en los recibos!
          </Typography>

          <Card>
            <CardContent>
              <RenderCobroAnnouncement submithandler={submitAnnouncement} />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </div>
  );
};

export default RouteConfig;
