import backend from "@shared/server/backend";

export const createLoan = async (data) => {
  const response = await backend.post("/loan/createv2", { ...data });
  return response.data;
};

export const modifyLoan = async (data) => {
  const response = await backend.put("/loan/modify", { data });
  return response.data;
};

export const deleteLoan = async (data) => {
  const response = await backend.delete(`/loan/${data.money_id}/${data.mUniqueId}/${data.creditor_id}/${data.user_id}`);
  return response.data;
};
