import backend from "@shared/server/backend";

export const paymentCreate = async (data) => {
  const response = await backend.post("/payment/createv2", { ...data });
  return response.data;
};

export const deletePayment = async (data) => {
  const response = await backend.delete(
    `/payment/${data.pUniqueId}/${data.debtor_id}/${data.user_id}/${data.mUniqueId}/${data.creditor_id}`
  );
  return response.data;
};
