// import { useState } from "react";
import Box from "@nodes/@mui/material/Box";
import * as Yup from "@nodes/yup";
import { Link } from "@nodes/react-router-dom";
import Divider from "@nodes/@mui/material/Divider";
import Button from "@nodes/@mui/material/Button";
import Typography from "@nodes/@mui/material/Typography";
import { BiLogIn } from "@nodes/react-icons/bi";
import { useForm } from "@nodes/react-hook-form";
import { InputText } from "./form/InputText";
import { InputPassword } from "./form/InputPassword";
import { yupResolver } from "@nodes/@hookform/resolvers/yup";
import { InputCheckbox } from "./form/InputCheckbox";
import { compareObjects } from "@shared/functions";
import { localStorageRememberUser } from "@/config";
import { DevTool } from "@nodes/@hookform/devtools";

export const loanCreateSchema = Yup.object({
  username: Yup.string().required("El ususuario es requerido!"),
  password: Yup.string().required("La contraseña es requeridad!"),
});

const RenderLoginForm = ({ submithandler }) => {
  let jsonValue = localStorage.getItem(localStorageRememberUser);
  const deviceObj = jsonValue != null ? JSON.parse(jsonValue) : null;

  const defaultValues = {
    rememberUsername: true,
    env: import.meta.env.VITE_ENV,
    domain: import.meta.env.VITE_PUBLIC_DOMAIN,
    appVersion: import.meta.env.VITE_VERSION,
    clientUrl: window.location.origin,
    username: deviceObj?.username || "",
    password: "",
  };

  const handleSubmitHandler = async (data) => {
    if (compareObjects(defaultValues, data)) return;
    await submithandler(data);
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues,
    resolver: yupResolver(loanCreateSchema),
  });
  const { isSubmitting } = formState;

  return (
    <div>
      <div className="mt-2 mb-1 text-center">
        <img alt="" src="/../../apple-touch-icon-144x144.png" />
      </div>

      {deviceObj?.firstname ? (
        <>
          <Typography variant="h5" component="h3" gutterBottom>
            Iniciar Sesion
          </Typography>
          <Typography variant="h6" component="h4" paragraph>
            Bienvenido, {deviceObj.firstname}!
          </Typography>
        </>
      ) : (
        <Typography variant="h5" component="h3" gutterBottom>
          Iniciar Sesion
        </Typography>
      )}

      <form className="mt-3" onSubmit={handleSubmit(handleSubmitHandler)}>
        <Box paddingBottom={2}>
          <InputText
            name="username"
            placeholder="Ingrese su usuario"
            control={control}
            label="Usuario"
            configs={{ variant: "outlined", size: "medium" }}
          />
        </Box>

        <Box>
          <InputPassword
            name="password"
            placeholder="Ingrese su Contraseña"
            control={control}
            label="Contraseña"
            configs={{ variant: "outlined", size: "medium", type: "password" }}
          />
        </Box>

        <Box paddingTop={1}>
          <InputCheckbox name="rememberUsername" label="Recordar mi usuario!" control={control} />
        </Box>

        <Box paddingBottom={2} paddingTop={1}>
          <Button
            startIcon={<BiLogIn />}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            variant={"contained"}
            color="primary">
            Iniciar Sesion!
          </Button>
        </Box>
      </form>

      <Link style={{ color: "#000" }} to="/user/lookup">
        <Typography variant="subtitle1" component="p">
          <u>Olvido su contraseña?</u>
        </Typography>
      </Link>

      <Link style={{ color: "#000" }} to="/contact">
        <Typography variant="subtitle1" component="p">
          <u>Tiene alguna pregunta?</u>
        </Typography>
      </Link>

      <Divider className="mt-3" />

      <DevTool control={control} />
    </div>
  );
};

export default RenderLoginForm;
