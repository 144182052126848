import { useEffect } from "@nodes/react";
import Box from "@nodes/@mui/material/Box";
import Grid from "@nodes/@mui/material/Grid";
import { useFormikContext } from "@nodes/formik";
// import Alert from "@nodes/@mui/lab/Alert";
// import Grow from "@nodes/@mui/material/Grow";
import { Form, RenderInput, RenderCheckboxLabel, RenderDropdown } from "./Form";
import Radio from "@nodes/@mui/material/Radio";
import RadioGroup from "@nodes/@mui/material/RadioGroup";
import FormControlLabel from "@nodes/@mui/material/FormControlLabel";
import FormControl from "@nodes/@mui/material/FormControl";
import FormLabel from "@nodes/@mui/material/FormLabel";
import { loanCreate } from "../functions/validations";
// import { mainApp } from "@/config";

const GetFormikValues = ({ setValues }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    setValues(values);
  }, [values, setValues]);
  return null;
};

const RenderNewLoanForm = ({ setFormValues, initialValues, submithandler, button, children }) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <Form initialValues={initialValues} validation={loanCreate} submithandler={submithandler} button={button}>
        {/* {mainApp === 1 && <Box paddingBottom={3}>{RenderInput("given_date", "Fecha de Entrega", "date")}</Box>}
        {mainApp === 1 && <Box paddingBottom={2}>{RenderInput("start_date", "Fecha de Inicio", "date")}</Box>} */}

        {/* <Box paddingBottom={2}>
          {RenderDropdown("loan_type", "Que tipo de prestamo?", [
            { label: "Elegir tipo de prestamo", value: "" },
            { label: "Diario", value: 1 },
            { label: "Semanal", value: 7 },
            { label: "Quincenal", value: 14 },
            { label: "15 & 30", value: 15 },
            { label: "Mensual", value: 30 },
          ])}
        </Box> */}

        <Box paddingBottom={4}>
          {RenderInput("percentage", "Porciento", "number", false, "standard")}
          <Grid container>
            <Grid item xs={5}></Grid>
            {/* <Grid item xs={7} className="text-center"> */}
            {/* {RenderInput("npayments", "Numero de Cuotas", "number", false, "standard")} */}
            {/* </Grid> */}
          </Grid>
        </Box>

        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">Numero de Cuotas</FormLabel>
            <RadioGroup row aria-label="npayments" name="npayments1" value="" onChange={handleChange}>
              <FormControlLabel value="10" control={<Radio />} label="10 cuotas" />
              <FormControlLabel value="13" control={<Radio />} label="13 cuotas" />
              {/* <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
            </RadioGroup>
          </FormControl>
        </Box>

        <Box paddingBottom={2}>{RenderInput("amount", "Cantidad", "number")}</Box>
        <Box paddingBottom={2}>{RenderInput("actanotarial", "Contracto (Acta)", "number")}</Box>
        <Box paddingBottom={2}>
          {RenderInput(
            "adelanto",
            "Pago de Adelanto",
            "number",
            false,
            "standard",
            "Cantidad de pago adelantado para este nuevo prestamo!"
          )}
        </Box>
        <Box paddingBottom={2}>{RenderInput("discount", "Descuento Adicional", "number")}</Box>

        {+initialValues.pMora > 0 && <Box paddingBottom={2}>{RenderInput("pMora", "Descontar Mora", "number")}</Box>}

        <Box paddingBottom={0}>{RenderCheckboxLabel("confirmNewLoan", "Confirmar entrega de Prestamo!")}</Box>

        {children}

        <GetFormikValues setValues={setFormValues} />
      </Form>
    </div>
  );
};

export default RenderNewLoanForm;
