import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import FormLabel from "@mui/material/FormLabel";
import Paper from "@mui/material/Paper";
import { string, number, object, mixed, addMethod } from "yup";
import { yupResolver } from "@nodes/@hookform/resolvers/yup";
import { InputText } from "@shared/components/form/InputText";
import { InputNumber } from "@shared/components/form/InputNumber";
import { InputCustomFormat } from "@shared/components/form/InputCustomFormat";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { validateCedula } from "@shared/functions";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { DevTool } from "@nodes/@hookform/devtools";

const RenderPersonalInfo = ({ control }) => {
  return (
    <>
      <Typography variant="subtitle1" component="p" paragraph>
        Por favor llene las siguientes casillas para crear su cuenta.
      </Typography>

      <Box paddingBottom={2}>
        <InputText name="fname" label="Nombre" control={control} placeholder={"Escriba su primer nombre!"} />
      </Box>

      <Box paddingBottom={2}>
        <InputText name="lname" label="Apellido" control={control} placeholder={"Escriba su primer apellido!"} />
      </Box>

      <Box paddingBottom={2}>
        <InputCustomFormat
          name="phone"
          control={control}
          label="Telefono"
          placeholder={"Numero de telefono!"}
          configs={{
            type: "tel",
            overwrite: false,
            mask: "(#00) 000-0000",
            definitions: {
              "#": /[1-9]/,
            },
          }}
        />
      </Box>

      <Box paddingBottom={1}>
        <FormLabel component="legend">Fecha de Nacimiento</FormLabel>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <InputNumber
              name="day"
              control={control}
              label="Dia"
              placeholder={"Que dia nacio?"}
              configs={{ thousandSeparator: false }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputNumber
              name="month"
              control={control}
              label="Mes"
              placeholder={"Que mes nacio?"}
              configs={{ thousandSeparator: false }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputNumber
              name="year"
              control={control}
              label="Año"
              placeholder={"Que año nacio?"}
              configs={{ thousandSeparator: false }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box paddingBottom={2}>
        <InputCustomFormat
          name="cedula"
          control={control}
          label="Cedula"
          placeholder={"Numero de Cedula!"}
          configs={{
            type: "tel",
            overwrite: false,
            mask: "#00-0000000-0",
            definitions: {
              "#": /[0-9]/,
            },
          }}
        />
      </Box>

      <Box paddingBottom={2}>
        <InputText
          name="email"
          label="Correo Electronico"
          control={control}
          configs={{ type: "email" }}
          placeholder={"Escriba su correo electronico!"}
        />
      </Box>
    </>
  );
};

export default function Register() {
  const navigate = useNavigate();
  addMethod(mixed, "validateCedula", validateCedula);

  const localKey = "registerForm";

  const schema = object({
    day: number()
      .typeError("Dia es invalido!")
      .required("Dia es requerido!")
      .min(1, "Dia es muy corto!")
      .max(31, "Dia es muy alto!"),
    month: number()
      .typeError("Mes es invalido!")
      .required("Mes es requerido!")
      .min(1, "Mes es muy corto!")
      .max(12, "Mes es muy alto!"),
    year: number()
      .typeError("Año es invalido!")
      .required("Año es requerido!")
      .min(1900, "Año es muy corto!")
      .max(2015, "Año es muy alto!"),
    fname: string()
      .required("Nombre es requerido!")
      .min(3, "Nombre es muy corto!")
      .matches(/^([^0-9]*)$/, "Nombre no puede tener numeros!"),
    lname: string()
      .required("Apellido es requerido!")
      .min(3, "Apellido es requerido!")
      .matches(/^([^0-9]*)$/, "Apellido no puede tener numeros!"),
    email: string().email("Correo Electronico invalido!").optional(),
    phone: string().required("Telefono es requeirod").min(10, "Telefono invalido").max(14, "Telefono invalido"),
    cedula: mixed().validateCedula(true),
  });

  let defaultValues = { fname: "", lname: "", phone: "", email: "", cedula: "", day: "", year: "", month: "" };
  const existing = localStorage.getItem(localKey);
  if (existing) {
    defaultValues = JSON.parse(existing);
  }

  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleSubmitHandler = (data) => {
    localStorage.setItem(localKey, JSON.stringify(data));
    navigate("/SignupUsername");
  };

  return (
    <div className="container mt-3 mb-5">
      <Box sx={{ maxWidth: 500, flexGrow: 1 }}>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
            bgcolor: "background.default",
          }}>
          <Typography variant="h6">Ingrese Informacion Personal</Typography>
        </Paper>

        <Box sx={{ maxWidth: 600, width: "100%", p: 2 }}>
          <form onSubmit={handleSubmit(handleSubmitHandler)}>
            <Box paddingBottom={2}>
              <RenderPersonalInfo control={control} />
            </Box>
            <Box paddingBottom={2}>
              <Button type="submit" fullWidth variant={"contained"}>
                Continuar!
              </Button>
            </Box>
            <DevTool control={control} />
          </form>
        </Box>
      </Box>
    </div>
  );
}
