import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import Divider from "@mui/material/Divider";
import DatePicker from "@shared/components/DatePicker";
// import XcobrarLoanList from "@shared/components/XcobrarLoanList";
import { printAmount, sortArrayObjs2 } from "@shared/functions";
import { UseCobroList, UseRoute } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import { FcEmptyFilter, FcClearFilters } from "react-icons/fc";
// import { AiOutlinePushpin } from "react-icons/ai";
// import NoInternetContent from "@shared/components/NoInternetContent";
import Box from "@mui/material/Box";

const RenderPresomuestoLoans = ({ week }) => {
  return (
    <Box paddingTop={1}>
      <Card raised>
        <CardContent>
          <div style={{ display: "inline-flex" }} className="mb-2">
            <span className="badge badge-pill badge-dark">
              <Typography variant="body1" component="p" style={{ fontSize: 15 }}>
                <span style={{ padding: 3 }}>{week.elapsed}</span>
              </Typography>
            </span>

            <span className="pl-2">
              <Typography variant="body1">
                <b>
                  Cantidad: {printAmount(week.total.amount)} Cuotas: {printAmount(week.total.wPayment)}
                </b>
              </Typography>
            </span>
          </div>

          <Typography variant="body1">Lista de Pr&eacute;stamos</Typography>

          <ol style={{ paddingLeft: 20, overflowX: "auto", whiteSpace: "nowrap" }}>
            {week.loans.map((loan, index) => (
              <li className="travelcompany-input" key={index}>
                {printAmount(loan.amount)} Cuota {printAmount(loan.cuota)} {loan.name}
              </li>
            ))}
          </ol>
        </CardContent>
      </Card>
    </Box>
  );
};

const Reports = () => {
  const { state } = useContext(Context);
  const { route: stateRoute, user } = state;

  const [currentDate, setCurrentDate] = useState(null);
  const [filteredLoans, setFilteredLoans] = useState([]);

  const { data: route, isLoading: routeLoading } = UseRoute(stateRoute.creditor_id, user.user_id);

  const d22 =
    route?.collect_week_date === route?.today_date && route?.isCobro === 1
      ? route?.collect_week_date
      : route?.next_week_date;
  const { data: cobroList, isLoading } = UseCobroList(route?.creditor_id, currentDate ?? d22);

  useEffect(() => {
    if (route) {
      setCurrentDate(d22);
    }
    //eslint-disable-next-line
  }, [route]);

  useEffect(() => {
    if (!cobroList) return;
    let loansResult;

    loansResult = sortArrayObjs2(cobroList.loans, "elapsed", "money_id").reverse();

    setFilteredLoans(loansResult);
  }, [cobroList]);

  if (isLoading || routeLoading) return <Loading />;
  // if (!cobroList) return <NoInternetContent />;

  const atrasosSum = cobroList.loans.reduce((acc, obj) => {
    return +obj.statusAmount > 0 ? acc + +obj.statusAmount : acc;
  }, 0);

  const adelantoSum = cobroList.loans.reduce((acc, obj) => {
    return +obj.statusAmount < 0 ? acc + +obj.statusAmount : acc;
  }, 0);

  let revStatus = {};
  const reveredWeeks = [];

  for (var i = 0; i < filteredLoans.length; i++) {
    const { npayments, amount, wPayment, elapsed, name } = filteredLoans[i];

    if (revStatus[elapsed]) {
      revStatus[elapsed].total.amount += amount;
      revStatus[elapsed].total.wPayment += wPayment;
      revStatus[elapsed].loans.push({ amount, wPayment, elapsed, npayments, name });
    } else {
      reveredWeeks.push(elapsed);
      revStatus[elapsed] = {
        elapsed,
        total: { amount, wPayment },
        loans: [{ amount, wPayment, elapsed, npayments, name }],
      };
    }
  }

  return (
    <div className="container mt-3 mb-5">
      <Typography variant="h6" component="h4">
        Estado de la ruta ({cobroList.count}): {printAmount(cobroList.amount)}
      </Typography>

      <Grid container>
        <Grid item xs={7}>
          <DatePicker setCurrentDate={setCurrentDate} date={currentDate} route={route} />
        </Grid>
      </Grid>

      {reveredWeeks.map((week, index) => (
        <RenderPresomuestoLoans key={index} week={revStatus[week]} />
      ))}
    </div>
  );
};

export default Reports;
