import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
// import CardContent from "@mui/material/CardContent";
import { Context } from "../context/DataContext";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderDebtorConfigForm from "@shared/components/RenderDebtorConfigForm";
// import RenderDebtorImages from "@shared/components/RenderDebtorImages";
import DebtorMenu from "@shared/components/DebtorMenu";
import Loading from "@shared/components/Loading";
import { UseDebtors, UseDebtorCobroConfig, UseOwnerCobroConfig } from "@shared/server/fetcher";
import RenderBackButton from "@shared/components/RenderBackButton";
import { BiReset } from "react-icons/bi";
// import NoInternetContent from "@shared/components/NoInternetContent";
import { updateDebtorCOnfig, deleteDebtorCOnfig } from "@shared/api/debtor";
import { useSnackbar } from "notistack";

const UserLinkConfigs = () => {
  const navigate = useNavigate();
  const { id: debtor_id } = useParams();
  const { state } = useContext(Context);
  const { route: r2, user } = state;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);

  // const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  // const { data: debtor, isLoading: isLoadingDebtor } = UseDebtor(debtor_id, user.user_id);
  const { data: debtors, isLoading: debtorLoading } = UseDebtors(r2.creditor_id, user.user_id);
  const debtor = debtors?.find((x) => x.debtor_id === +debtor_id);
  const { data: debtorCobroConfigs, isLoading: dcL, mutate } = UseDebtorCobroConfig(debtor_id);
  const { data: ownerCobroConfigs, isLoading: ocL } = UseOwnerCobroConfig(r2?.user_id);

  if (debtorLoading || dcL || ocL) return <Loading />;

  const defaultValues = {
    dRenCuotas: debtorCobroConfigs?.dRenCuotas ?? ownerCobroConfigs.rRenCuotas,
    dLimit: debtorCobroConfigs?.dLimit ?? ownerCobroConfigs.rLimit,
    dRTel: debtorCobroConfigs?.dRTel ?? ownerCobroConfigs.rRTel,
    dRCed: debtorCobroConfigs?.dRCed ?? ownerCobroConfigs.rRCed,
    dMultiLoan: debtorCobroConfigs?.dMultiLoan ?? ownerCobroConfigs.rMultiLoan,
    dMPerc: debtorCobroConfigs?.dMPerc || 0,
    dMCuotas: debtorCobroConfigs?.dMCuotas || 2,
    dMPast: debtorCobroConfigs?.dMPast || 0,
  };

  const submithandler = async (data) => {
    try {
      await updateDebtorCOnfig({ ...data, debtor_id });
      enqueueSnackbar("Configuraciones guardada!", { variant: "success" });
      // navigate(`/debtor/profile/${debtor.dUniqueId}`);
      mutate();
    } catch (error) {}
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteDebtorCOnfig({ debtor_id });
      enqueueSnackbar("Configuraciones borrada!", { variant: "warning" });
      // setLoading(false);
      mutate();
      navigate(`/debtor/profile/${debtor.dUniqueId}`);
    } catch (error) {}
  };

  return (
    <div className="container mt-3 mb-5">
      <Container className="mt-3" maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={debtor}>
          <div className="" style={{ display: "inline-flex" }}>
            <DebtorMenu debtor={debtor} profile />
          </div>
        </DebtorNameHeader>

        <Typography variant="body1" paragraph>
          <b>Las configuraciones en esta pagina aplican unicamente para este cliente!</b>
        </Typography>

        <Card>
          <Box p={1} paddingBottom={3}>
            <RenderDebtorConfigForm defaultValues={defaultValues} submithandler={submithandler} />

            <Box paddingBottom={2} paddingRight={2}>
              <InputSlider
                name="rxCobrar"
                control={control}
                setValue={setValue}
                value={defaultValues.dMPerc}
                label={"Mostrar cantidad de lista de cobro despues de progreso %"}
                configs={{
                  max: 100,
                  min: 0,
                  step: 5,
                }}
              />
            </Box>

            <Box>
              <InputRadio
                name="rInvoice"
                control={control}
                label="Mostrar factura pendiente en la app de cobro?"
                options={[
                  { label: "Si", value: 1 },
                  { label: "No", value: 0 },
                ]}
              />
            </Box>

            <div className="mt-3 mb-4">
              <RenderBackButton />
            </div>
            <Box paddingBottom={2} paddingTop={2}>
              <Button
                startIcon={<BiReset />}
                onClick={handleDelete}
                size="small"
                disabled={Object.keys(debtorCobroConfigs).length === 1 || isLoading}
                fullWidth
                variant={"contained"}
                color="secondary">
                Borrar Configuraciones!
              </Button>
            </Box>
          </Box>
        </Card>
      </Container>
    </div>
  );
};

export default UserLinkConfigs;
