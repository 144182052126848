import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../context/DataContext";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// import { printAmount } from "@shared/functions";
import SubmitButton from "@shared/components/SubmitButton";
import CancelIcon from "@mui/icons-material/Cancel";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import Loading from "@shared/components/Loading";
import { UseLoanv2, UseLoansV2 } from "@shared/server/fetcher";
import RenderLoanDeleteDetail from "../components/RenderLoanDeleteDetail";
import { useSWRConfig } from "swr";
import RenderBackButton from "@shared/components/RenderBackButton";
import { deleteLoan } from "@shared/api/loan";
import { useSnackbar } from "notistack";

const DeleteLoan = () => {
  const { id: money_id, id2: debtor_id } = useParams();
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const { state } = useContext(Context);
  const { route, user } = state;

  const { enqueueSnackbar } = useSnackbar();

  // const { data: apertura, isLoading: aLoading } = UseSharedApertura(route.creditor_id, route.r_type, route.day);

  const { data: loans, mutate: loansMutate, isLoading: isLoadingL } = UseLoansV2(route.creditor_id, user.user_id);
  const filteredLoan = loans?.find((x) => +x.money_id === +money_id);
  const shouldFetch = !isLoadingL && !filteredLoan ? true : false;
  const { data: currentLoan2, isLoading: isLoadingS } = UseLoanv2(route.creditor_id, money_id, debtor_id, shouldFetch);
  const currentLoan = filteredLoan ?? currentLoan2;

  if ((shouldFetch && isLoadingS) || isLoadingL) return <Loading />;

  const submithandler = async () => {
    try {
      await deleteLoan({
        money_id: currentLoan.money_id,
        mUniqueId: currentLoan.mUniqueId,
        creditor_id: route.creditor_id,
        user_id: user.user_id,
      });

      enqueueSnackbar("Pr&eacute;stamo borrado con exito!", { variant: "warning" });

      loansMutate();
      navigate("/dashboard", { replace: true });
    } catch (error) {}
    // mutate(`/xcobrar/loansv2/${route.creditor_id}/${user.user_id}/${apertura.a_id}`);
  };

  return (
    <div className="container mt-5">
      <DebtorNameHeader debtor={currentLoan} />
      <Card>
        <CardContent>
          <Typography variant="h5" component="h3" className="text-danger">
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom>
            Decea borrar este Pr&eacute;stamo?
          </Typography>

          <RenderLoanDeleteDetail loan={currentLoan} />

          <Box paddingTop={4}>
            <SubmitButton
              autoFetch={false}
              color="secondary"
              text="Cancelar Pr&eacute;stamo!"
              startIcon={<CancelIcon />}
              callBack={submithandler}
            />
          </Box>
          <div className="mt-4">
            <RenderBackButton />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeleteLoan;
