import Box from "@nodes/@mui/material/Box";
import Button from "@nodes/@mui/material/Button";
import { FaRegMoneyBillAlt, FaHandHoldingUsd } from "@nodes/react-icons/fa";
import { AiOutlineFileSync } from "@nodes/react-icons/ai";
import { useNavigate } from "@nodes/react-router-dom";

const RenderLoanDetailButtons = ({ loan }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Box paddingTop={3}>
        <Button
          sx={{ color: "#4eb563" }}
          fullWidth
          startIcon={<FaRegMoneyBillAlt size="1.2em" />}
          variant="outlined"
          color="primary"
          onClick={() => navigate(`/payment/create/${loan.money_id}/${loan.debtor_id}`)}>
          Aplicar Pago
        </Button>
      </Box>

      <Box paddingTop={3}>
        <Button
          sx={{ color: "#ffc107" }}
          fullWidth
          disabled={loan.deletedBy !== 0 || loan.balance === 0 ? true : false}
          startIcon={<AiOutlineFileSync size="1.2em" />}
          variant="outlined"
          onClick={() => navigate(`/redirect/page/${loan.dUniqueId}/${loan.money_id}/${loan.cedula}`)}>
          Renovar Prestamo
        </Button>
      </Box>

      <Box paddingTop={3}>
        <Button
          fullWidth
          startIcon={<FaHandHoldingUsd size="1.2em" />}
          variant="outlined"
          onClick={() => navigate(`/redirect/page/${loan.dUniqueId}/0/${loan.cedula}`)}>
          Nuevo Prestamo
        </Button>
      </Box>
    </div>
  );
};

export default RenderLoanDetailButtons;
