export const getObjectByIndex = (loans, index) => {
  return loans[index];
};

export const getNewLoansByDebtor = (loans, debtor_id) => {
  return loans.filter((x) => !x.deleted && x.debtor_id === debtor_id);
};

export const getLoansCobro = (loans, maxWeeks) => {
  return loans.filter((loan) => loan.current_week <= maxWeeks);
};

export const getGastosSelector = (gastos) => {
  const gastosLength = gastos.length;
  let obj = { amount: 0, count: 0, list: [] };

  for (let i = 0; i < gastosLength; i++) {
    obj.amount += gastos[i].amount * 1;
    obj.count++;
    obj.list.push(gastos[i]);
  }

  obj.list.reverse();
  return obj;
};

export const getImagesByDebtor = (images, debtor_id) => {
  return images.filter((image) => image.debtor_id === debtor_id);
};

export const getNewLoansSelector = (loans, reverseOrder = true) => {
  const loansLength = loans.length;
  let obj = { amount: 0, count: 0, list: [] };

  for (let i = 0; i < loansLength; i++) {
    obj.amount += loans[i].amount * 1;
    obj.count++;
    obj.list.push(loans[i]);
  }

  if (reverseOrder === true) {
    obj.list.reverse();
  }

  return obj;
};

export const getCollectedMora = (loans) => {
  const loansLength = loans.length;
  let obj = { amount: 0, list: [] };

  for (let i = 0; i < loansLength; i++) {
    const { name, pMora, amount, debtor_id, money_id } = loans[i];

    if (pMora > 0) {
      obj.amount += pMora;
      obj.list.push({ money_id, debtor_id, name, amount, pMora });
    }
  }
  return obj;
};

export const getXcobrarLoans = (loans, hideVencidos = 15) => {
  const length = loans.length;
  let obj = { count: 0, amount: 0, list: [], failedAmount: 0, failedList: [] };

  for (let i = 0; i < length; i++) {
    if (loans[i].a_count < hideVencidos + loans[i].npayments && loans[i].deletedBy === 0) {
      if (loans[i].xCobrar === true && loans[i].pending > 0) {
        if (loans[i].pAmount >= loans[i].xCobrarAmount) {
          obj.amount += loans[i].xCobrarAmount;
          obj.count++;
        } else {
          if (loans[i].pAmount > 0) {
            obj.count++;
          }

          obj.amount += loans[i].pAmount;
          const failedRemainingAmount = loans[i].xCobrarAmount - loans[i].pAmount;
          obj.failedAmount += failedRemainingAmount;
          obj.failedList.push({ name: loans[i].name, amount: failedRemainingAmount, money_id: loans[i].money_id });
        }
      }

      obj.list.push(loans[i]);
    }
  }
  return obj;
};

export const getXcobrarLoansOnly = (loans, hideVencidos = 15) => {
  const length = loans.length;
  let arr = [];

  for (let i = 0; i < length; i++) {
    const loan = loans[i];
    if (loan.money_id === 199) {
      const { a_count, npayments, deletedBy, xCobrarTotal, xCobrarAmount } = loan;
      console.log(loan);
    }

    if (loan.a_count < hideVencidos + loan.npayments && loan.deletedBy === 0 && loan.xCobrarTotal > 0) {
      arr.push(loans[i]);
    }
  }
  return arr;
};

export const filterVencidosLoans = (loans, hideVencidos) => {
  if (!hideVencidos) return loans;

  const loansLength = loans.length;
  let newLoans = [];

  for (let i = 0; i < loansLength; i++) {
    if (loans[i].statusText !== "Vencido") {
      newLoans.push(loans[i]);
    }
  }
  return newLoans;
};

export const getLoanSignature = (signatures, money_id) => {
  const result = signatures.filter((signature) => signature.money_id === money_id);
  return result[0];
};

export const getModifiedDebtors = (debtors) => {
  return debtors.filter((debtor) => debtor.modified_debtor === true);
};

export const getNewLoan = (loans, money_id) => {
  const result = loans.filter((loan) => loan.money_id === money_id);
  return result[0];
};

export const getLoanSelector = (loans, money_id) => {
  const result = loans.filter((loan) => loan.money_id === +money_id);
  return result[0];
};

export const getPaymentSelector = (collected, payment_id) => {
  const result = collected.filter((payment) => +payment.payment_id === +payment_id);
  return result[0];
};

export const getDebtorSelector = (debtors, debtor_id) => {
  const result = debtors.filter((debtor) => debtor.debtor_id === debtor_id);
  return result[0];
};

export const getEmployeeSelector = (employees, user_id) => {
  const result = employees.filter((employee) => employee.user_id === user_id);
  return result[0];
};

export const getCollectedLoans = (loans) => {
  const loansLength = loans.length;
  let obj = { amount: 0, count: 0, list: [] };

  for (let i = 0; i < loansLength; i++) {
    const totalPayments = loans[i].pAmount + loans[i].pMora;
    if (totalPayments > 0) {
      obj.amount += totalPayments;
      obj.count++;
      obj.list.push(loans[i]);
    }
  }

  return obj;
};

export const getCollectedPayments = (loans) => {
  const loansLength = loans.length;
  let obj = { amount: 0, count: 0, list: [] };

  for (let i = 0; i < loansLength; i++) {
    if (loans[i].pAmountDisplay > 0 || loans[i].pMoraDisplay > 0) {
      obj.amount += loans[i].pAmountDisplay;
      obj.count++;
      obj.list.push(loans[i]);
    }
  }

  return obj;
};

export const getDebtorLoansBalanceSelector = (loans, debtor_id, balance3 = 0, routeConfig = {}) => {
  const loansLength = loans.length;
  let obj = { totalBalance: 0, payoffBalance: 0, loansBalance: [] };

  for (let i = 0; i < loansLength; i++) {
    const { debtor_id: currentID, balance } = loans[i];

    if (currentID === debtor_id && balance > balance3) {
      obj.totalBalance += balance;
      if (routeConfig.autoSelectRenewal === true) {
        obj.payoffBalance += balance;
        obj.loansBalance.push({ isChecked: true, balance, ...loans[i] });
      } else {
        obj.loansBalance.push({ isChecked: false, balance, ...loans[i] });
      }
    }
  }
  return obj;
};
