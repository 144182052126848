import { forwardRef } from "@nodes/react";
import { IMaskInput } from "@nodes/react-imask";
import { Controller } from "@nodes/react-hook-form";
import Input from "@nodes/@mui/material/Input";
import InputLabel from "@nodes/@mui/material/InputLabel";
import FormControl from "@nodes/@mui/material/FormControl";
import FormHelperText from "@nodes/@mui/material/FormHelperText";

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, mask, overwrite, definitions, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={definitions}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite={overwrite}
    />
  );
});

export function InputCustomFormat({ name, control, label, placeholder, configs = {} }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => (
        <>
          <FormControl fullWidth error={!!error} variant="standard">
            <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
            <Input
              placeholder={placeholder ?? label}
              type={configs.type ?? "text"}
              label={label}
              error={!!error}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              name={name}
              fullWidth
              id="formatted-text-mask-input"
              inputProps={configs}
              inputComponent={TextMaskCustom}
            />
            {error?.message && <FormHelperText>{error.message}</FormHelperText>}
            {configs?.helperText && <FormHelperText>{configs.helperText}</FormHelperText>}
          </FormControl>
        </>
      )}
    />
  );
}
