import { useContext, useState } from "react";
import { Context } from "../context/DataContext";
import { Table, Thead, Tbody, Tr, Th, Td } from "@nodes/react-super-responsive-table";
import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
// import CreateSpense from "../components/CreateSpense";
// import LoanTypeCard from "../components/LoanTypeCard";
import Card from "@mui/material/Card";
import Loading from "@shared/components/Loading";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import { UseRouteLoanType, UseRoute, UseAllLoanVariant } from "@shared/server/fetcher";
// import { getSpanishNumDays } from "@shared/functions";
// import { MdDeleteForever } from "react-icons/md";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import WifiIcon from "@mui/icons-material/Wifi";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
// import { FaCog } from "react-icons/fa";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
// import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const LoansTypes = () => {
  const { state, changeApertura } = useContext(Context);
  const { user, route: r2 } = state;
  const [loading, setLoading] = useState(false);

  const { data: routeN, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  const { data: routeLoanTypes, isLoading: ltLoading } = UseRouteLoanType(r2.creditor_id);
  const { data: variants, isLoading: lvLoading } = UseAllLoanVariant(r2.creditor_id);

  if (ltLoading || routeLoading) return <Loading />;
  if (loading) return <FullLoadingScreen />;

  const handleChangeApertura = async (week_date, collect_date) => {
    setLoading(true);
    await changeApertura(r2.creditor_id, r2.user_id, week_date, collect_date);
    await mutate();
    setLoading(false);
  };

  // if (import.meta.env.PROD)
  //   return (
  //     <div className="container m-5">
  //       <Typography variant="h5">Pagina en progreso</Typography>
  //     </div>
  //   );

  const handleCreateLoanType = () => {};
  const handleModifyLoanType = () => {};

  // The following has been hidden, a_day, blacked, daysOff

  //   <Td>
  //   <i>
  //     <Typography variant="body1">Despues {routeLoanTypes[type].blacked} Cuotas</Typography>
  //   </i>
  // </Td>

  //   <Td>
  //   <i>
  //     <Typography variant="body1">Despues {routeLoanTypes[type].daysOff} Dias libres</Typography>
  //   </i>
  // </Td>

  // <Td>
  // <i>
  //   {/* Dias de cobro */}
  //   {routeLoanTypes[type].type === 1 ? (
  //     <Typography variant="body1">Todo los Dias</Typography>
  //   ) : routeLoanTypes[type].type === 15 ? (
  //     <Typography variant="body1">Los 15 o 30</Typography>
  //   ) : routeLoanTypes[type].type === 30 ? (
  //     <Typography variant="body1">Mensualmente</Typography>
  //   ) : routeLoanTypes[type].a_day === 0 && [7, 14].includes(routeLoanTypes[type].type) ? (
  //     <Typography variant="body1">Todo los Dias</Typography>
  //   ) : (
  //     <Typography variant="body1">Los {getSpanishNumDays(routeLoanTypes[type].a_day)} </Typography>
  //   )}
  // </i>
  // </Td>

  const [checked, setChecked] = useState(["wifi"]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      {/* <CreateSpense
        handleCreateLoanType={handleCreateLoanType}
        creditor_id={r2.creditor_id}
        date={apertura.today_date}
        user_id={user.user_id}
      /> */}

      <Card className="p-2 m-2">
        <Typography variant="h5" gutterBottom>
          Modalidad de pr&eacute;stamos!
        </Typography>

        <div className="m-2" id="user-panel" style={{ display: "inline-flex" }}>
          <span className="pr-1">
            <b>
              <Chip label={`R${routeN.creditor_id}`} />
            </b>
          </span>

          <Typography variant="subtitle1" component="p">
            {routeN.route_name} ({routeN.location})
          </Typography>
        </div>
      </Card>
      <div className="container mt-3 mb-5">
        <Typography variant="body1" paragraph>
          En esta pagina puede agregar o modificar varios tipos de frequencia de pago, eg: Pr&eacute;stamos Diarios,
          Semanales, Quincenales, 15 & 30 o Mensuales.
        </Typography>

        <div className="mt-3">
          <div className="mb-5">
            <Table>
              <Thead>
                <Tr className="font-weight-bold">
                  <Th>Modalidad</Th>
                  <Th>Porcentaje</Th>
                  <Th>Plazo</Th>
                  {/* <Th>Dia de Cobro</Th> */}
                  <Th>Mora</Th>
                  {/* <Th>Vencido</Th> */}
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {routeLoanTypes.types.map((type) => (
                  <Tr key={routeLoanTypes[type].type_id} className="mb-1">
                    <Td>
                      <i>
                        {routeLoanTypes[type].type === 1 ? (
                          <Typography variant="body1">Diarios</Typography>
                        ) : routeLoanTypes[type].type === 15 ? (
                          <Typography variant="body1">Quincenales</Typography>
                        ) : routeLoanTypes[type].type === 30 ? (
                          <Typography variant="body1">Mensuales</Typography>
                        ) : routeLoanTypes[type].type === 14 ? (
                          <Typography variant="body1">Bi-Semanales</Typography>
                        ) : (
                          <Typography variant="body1">Semanales</Typography>
                        )}
                      </i>
                    </Td>
                    <Td>
                      <i>
                        <Typography variant="body1">{routeLoanTypes[type].percent}%</Typography>
                      </i>
                    </Td>
                    <Td>
                      <i>
                        <Typography variant="body1">{routeLoanTypes[type].cuotas.toString()} Cuotas</Typography>
                      </i>
                    </Td>

                    <Td>
                      <i>
                        <Typography variant="body1">
                          {routeLoanTypes[type].mCuotas} Cuotas {routeLoanTypes[type].mPerc}%
                        </Typography>
                      </i>
                    </Td>

                    <Td>
                      <Link to={""}>Ver Detalle</Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>

          {lvLoading ? (
            <Loading />
          ) : (
            <List
              sx={{ width: "100%", maxWidth: 600, bgcolor: "background.paper" }}
              subheader={<ListSubheader>Variedades De prestamos</ListSubheader>}>
              {variants.map((item) => (
                <ListItem key={item.v_name}>
                  <ListItemIcon>
                    <WifiIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.v_name} secondary={item.description} />
                  <Switch onChange={handleToggle(item.vType_id)} checked={checked.indexOf(item.vType_id) !== -1} />
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </div>
    </>
  );
};

export default LoansTypes;
