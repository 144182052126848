import Box from "@nodes/@mui/material/Box";
// import Typography from "@nodes/@mui/material/Typography";
// import Divider from "@nodes/@mui/material/Typography";
import DebtorLoanCard from "./DebtorLoanCard";

const RenderDebtorLoans = ({ title = "Lista de Prestamos", loans = [] }) => {
  if (loans.length === 0) return null;

  return (
    <Box>
      {loans.map((loan) => (
        <Box key={loan.money_id} paddingBottom={1}>
          <DebtorLoanCard loan={loan} />
        </Box>
      ))}
    </Box>
  );
};

export default RenderDebtorLoans;
