import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { Context } from "../context/DataContext";
import SearchBox from "@shared/components/common/SearchBox";
import LoanCard from "@shared/components/LoanCard";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { RemoveAccents, printAbsAmount, indentifySearchType } from "@shared/functions";
import Loading from "@shared/components/Loading";
import { UseLoansV2 } from "@shared/server/fetcher";
import Card from "../components/Card";
// import NoInternetContent from "@shared/components/NoInternetContent";
import useDebounce from "@shared/hooks/useDebounce";

const Loans = () => {
  const { state } = useContext(Context);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filteredLoans, setFilteredLoans] = useState([]);
  const { user, route: r2 } = state;

  // const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  // const { data: apertura, isLoading: aLoading } = UseSharedApertura(r2?.creditor_id, r2.r_type, r2.day);
  const { data: loans, isLoading } = UseLoansV2(r2.creditor_id, user.user_id);

  const loader = useRef(null);
  const loansXPage = 10;

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useDebounce(
    () => {
      if (!loans) return;
      if (searchText.length < 3) {
        setFilteredLoans(loans.slice(0, page * loansXPage));
        setLoading(false);
      } else {
        setFilteredLoans(
          loans
            .filter(
              (y) =>
                RemoveAccents(y.loanSearch).toLowerCase().indexOf(indentifySearchType(searchText.toLowerCase())) > -1
            )
            .slice(0, loansXPage + 10)
        );
      }
    },

    [loans, searchText, page],
    searchText.length < 3 ? 0 : 800
  );

  const atrasosSum = loans?.reduce((acc, obj) => {
    return obj.statusText === "Atrasos" ? acc + +obj.statusAmount : acc;
  }, 0);

  const vencidosSum = loans?.reduce((acc, obj) => {
    return obj.statusText === "Vencido" ? acc + +obj.balance : acc;
  }, 0);

  const balanceSum = loans?.reduce((acc, obj) => {
    return +obj.balance > 0 ? acc + +obj.balance : acc;
  }, 0);

  const capitalSum = loans?.reduce((acc, obj) => {
    // return obj.balance > 0 ? acc + +obj.balance : acc;
    return obj.balance > 0 ? acc + (+obj.npayments - +obj.completed) * (+obj.cuota - +obj.interest) : acc;
  }, 0);

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <div style={{ minHeight: "110%" }}>
        {loans?.length === 0 ? (
          <div className="container mt-5 mb-5">
            <Typography variant="h5" componet="h3" gutterBottom>
              No hay Pr&eacute;stamos registrado!
            </Typography>

            <Typography variant="body1" componet="p" paragraph>
              En esta pagina puede ver todo los pr&eacute;stamos.
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              Por el momento, no tiene ningun Pr&eacute;stamo!
            </Typography>
          </div>
        ) : (
          <>
            {loading || isLoading ? (
              <Loading />
            ) : (
              <div className="mt-4 mb-5">
                <Box component="div" p={1}>
                  <Typography variant="h5" componet="h3">
                    Lista de Pr&eacute;stamos ({loans?.length})
                  </Typography>

                  <SearchBox
                    onChange={handleSearch}
                    placeHolder="Escriba para buscar Pr&eacute;stamo..."
                    value={searchText}
                  />
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Card style={{ background: "#00b3fe", color: "#fff" }}>
                        <Typography variant="body1">Capital {printAbsAmount(capitalSum)}</Typography>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card style={{ background: "#ffc107", color: "#fff" }}>
                        <Typography variant="body1">Atrasos {printAbsAmount(atrasosSum)}</Typography>
                      </Card>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Card style={{ background: "#17a2b8", color: "#fff" }}>
                        <Typography variant="body1">Balance {printAbsAmount(balanceSum)}</Typography>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card style={{ background: "#dc3545", color: "#fff" }}>
                        <Typography variant="body1">Vencidos {printAbsAmount(vencidosSum)}</Typography>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>

                <Grid container spacing={1}>
                  {filteredLoans.map((loan) => (
                    <Grid key={loan.money_id} item xs={12} sm={10} md={6} lg={4} xl={3}>
                      <LoanCard loan={loan} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </>
        )}
      </div>

      <Box ref={loader} component="div" p={2}>
        {!loading && searchText.length < 3 && loans?.length > page * loansXPage && (
          <Typography variant="h5" paragraph>
            Por favor espere...
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default Loans;
