import { useEffect } from "react";
import { useLocation, useNavigate } from "@nodes/react-router-dom";
import Card from "@nodes/@mui/material/Card";
import CardContent from "@nodes/@mui/material/CardContent";
import Button from "@nodes/@mui/material/Button";
import Container from "@nodes/@mui/material/Container";
import Typography from "@nodes/@mui/material/Typography";

const NotFound = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Page not found.", pathname);
  }, [pathname]);

  return (
    <Card className="mt-5 mb-5" raised>
      <CardContent>
        <Typography className="mt-3" component="p" variant="h5">
          Esta pagina no se encuentra!
        </Typography>
        <Typography className="mt-4" component="p" variant="body1">
          <i>Recuerde notificar al Administrador de esta aplicacion para que arregle este fallo</i>.
        </Typography>

        <Typography className="mt-3 mb-5" component="p" variant="body1">
          Haga clic en el siguiente boton para ir a la pagina de Incio!
        </Typography>

        <Container maxWidth="xs">
          <Button fullWidth variant="contained" color="primary" onClick={() => navigate("/", { replace: true })}>
            Ir a Inicio
          </Button>
        </Container>
      </CardContent>
    </Card>
  );
};

export default NotFound;
