import { useContext } from "react";
import { Context } from "../context/DataContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { string, ref, object } from "yup";
import { Form, RenderInput, RenderDropdown } from "@shared/components/Form";
import Loading from "@shared/components/Loading";
import { UseSecurityQuestions } from "@shared/server/fetcher";

const SecurityQuestions = () => {
  const { data: response, isLoading } = UseSecurityQuestions();
  const { state, saveSecurityQuestions } = useContext(Context);
  const { user } = state;

  if (isLoading) return <Loading />;

  const initialValues = {
    user_id: user.user_id,
    question1: 0,
    answer1: "",
    canswer1: "",
    question2: 0,
    answer2: "",
    canswer2: "",
    question3: 0,
    answer3: "",
    canswer3: "",
  };

  const validation = object({
    answer1: string().required("Repuesta es requerido!").min(3, "Repuesta es muy corto!"),
    canswer1: string()
      .required("Vuelva a ingresar la misma repuesta!")
      .oneOf([ref("answer1"), null], "Las 2 repuesta no coinciden!"),
    answer2: string().required("Repuesta es requerido!").min(3, "Repuesta es muy corto!"),
    canswer2: string()
      .required("Vuelva a ingresar la misma repuesta!")
      .oneOf([ref("answer2"), null], "Las 2 repuesta no coinciden!"),
    answer3: string().required("Repuesta es requerido!").min(3, "Repuesta es muy corto!"),
    canswer3: string()
      .required("Vuelva a ingresar la misma repuesta!")
      .oneOf([ref("answer3"), null], "Las 2 repuesta no coinciden!"),
  });

  const button = { label: "Guardar Preguntas", variant: "contained", color: "primary" };
  const submithandler = (data) => saveSecurityQuestions(data, () => history.replace("/dashboard"));

  return (
    <div className="mb-5 mt-4 container">
      <Typography variant="h5" component="h3" gutterBottom>
        Preguntas de Seguridad!
      </Typography>
      <Typography variant="subtitle1" component="p" paragraph>
        Las preguntas de Seguridad se utilizan para restablecer la contraseña, en caso de que la olvide.
      </Typography>

      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Card raised className="mt-2">
          <CardContent>
            <Box paddingBottom={2}>
              {RenderDropdown("question1", "Pregunta 1", response.slice(0, 3), {
                label: "Elija una pregunta de Seguridad!",
                value: 0,
              })}
            </Box>
            <Box paddingBottom={2}>{RenderInput("answer1", "Repuesta Secreta", "password")}</Box>
            <Box paddingBottom={2}>{RenderInput("canswer1", "Confirme Repuesta Secreta", "password")}</Box>
          </CardContent>
        </Card>

        <Card raised className="mt-2">
          <CardContent>
            <Box paddingBottom={2}>
              {RenderDropdown("question2", "Pregunta 2", response.slice(3, 6), {
                label: "Elija una pregunta de Seguridad!",
                value: 0,
              })}
            </Box>
            <Box paddingBottom={2}>{RenderInput("answer2", "Repuesta Secreta", "password")}</Box>
            <Box paddingBottom={2}>{RenderInput("canswer2", "Confirme Repuesta Secreta", "password")}</Box>
          </CardContent>
        </Card>

        <Card raised className="mt-2">
          <CardContent>
            <Box paddingBottom={2}>
              {RenderDropdown("question3", "Pregunta 3", response.slice(6, 9), {
                label: "Elija una pregunta de Seguridad!",
                value: 0,
              })}
            </Box>
            <Box paddingBottom={2}>{RenderInput("answer3", "Repuesta Secreta", "password")}</Box>
            <Box paddingBottom={2}>{RenderInput("canswer3", "Confirme Repuesta Secreta", "password")}</Box>
          </CardContent>
        </Card>
        <br />
      </Form>
    </div>
  );
};

export default SecurityQuestions;
