import Card from "@nodes/@mui/material/Card";
import { formatPhoneNumber, concatPhoneLink } from "../functions";

// <PhoneIcon /> <span>{formatPhoneNumber(loan.routePhone)}</span>

const RenderRouteDetailCredit = ({ loan = {} }) => {
  return (
    <Card variant="outlined">
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <b>Ruta</b> {loan.route_name}
        </li>
        <li className="list-group-item">
          <b>Zona</b> {loan.location}
        </li>
        <li className="list-group-item">
          <b>Telefono</b> <a href={concatPhoneLink(loan.routePhone)}>{formatPhoneNumber(loan.routePhone)} </a>
          {loan.routePhone2 && (
            <span>
              {" "}
              | <a href={concatPhoneLink(loan.routePhone2)}>{formatPhoneNumber(loan.routePhone2)} </a>
            </span>
          )}
        </li>
      </ul>
    </Card>
  );
};

export default RenderRouteDetailCredit;
