
import Card from "@nodes/@mui/material/Card";
import Typography from "@nodes/@mui/material/Typography";
import Box from "@nodes/@mui/material/Box";
import PersonIcon from "@nodes/@mui/icons-material/Person";
import { Link } from "@nodes/react-router-dom";
import { FiTrendingDown, FiTrendingUp } from "@nodes/react-icons/fi";
import { printAbsAmount } from "../functions";

const XcobrarLoanListChanges = ({ loan }) => {
  return (
    <Box paddingBottom={1}>
      <Card variant="outlined">
        <table className="table table-sm">
          <tbody>
            <tr>
              <td colSpan="3">
                <Typography variant="subtitle2" component="p" className="font-weight-bold">
                  <Link style={{ color: "#000" }} to={`/loan/detail/${loan.money_id}/${loan.debtor_id}`}>
                    <PersonIcon /> {loan.name}
                  </Link>
                </Typography>
              </td>
            </tr>

            <tr>
              <td style={{ width: 40 }}>
                {loan.xCobrar < 0 ? (
                  <FiTrendingDown size="1.3em" className="text-danger" />
                ) : (
                  <FiTrendingUp className="text-success" size="1.3em" />
                )}
              </td>
              <td style={{ width: "45%" }}>xCobrar {printAbsAmount(loan.xCobrar)}</td>
              <td>{loan.note}</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Box>
  );
};

export default XcobrarLoanListChanges;
