import { useContext } from "react";
import { Context } from "../context/DataContext";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderLoanPayments from "@shared/components/RenderLoanPayments";
// import RenderLoanPaymentsDR from "@shared/components/RenderLoanPaymentsDR";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import NoInternetContent from "@shared/components/NoInternetContent";
import useNetwork from "@shared/hooks/useNetwork";
// import Alert from "@mui/lab/Alert";
// import NetworkDetector from "@shared/HOC/NetworkDetector";
import {
  UseLoanv2,
  UseLoansV2,
  UseRoute,
  UseLoanPaymentsv2,
  UseUserConfig,
  UseOwnerConfig,
} from "@shared/server/fetcher";
import { printAmount, newDate, printDate } from "@shared/functions";

const LoanPayments = () => {
  const isOnline = useNetwork();
  const { id: money_id, id2: debtor_id } = useParams();
  const { state } = useContext(Context);
  const { route: r2, user } = state;

  // const { data: apertura, isLoading: aLoading } = UseSharedApertura(r2.creditor_id, r2.r_type, r2.day);

  const { data: loans, isLoading: isLoadingL } = UseLoansV2(r2.creditor_id, user.user_id);
  const filteredLoan = loans?.find((x) => +x.money_id === +money_id);
  const shouldFetch = !isLoadingL && !filteredLoan ? true : false;
  const { data: currentLoan2, isLoading: isLoadingS } = UseLoanv2(r2.creditor_id, money_id, debtor_id, shouldFetch);
  const currentLoan = filteredLoan ?? currentLoan2;

  const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  const { data: userConfig, isLoading: userConfigLoading } = UseUserConfig(user.user_id);
  const { data: routeConfig, isLoading: routeLoadingConfig } = UseOwnerConfig(r2.user_id);

  const { data: payments, isLoading: isLoadingPayments, key } = UseLoanPaymentsv2(money_id, debtor_id);
  console.log(key);

  if (!payments && !isOnline) return <NoInternetContent />;
  if ((shouldFetch && isLoadingS) || routeLoading || isLoadingL) return <Loading />;

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentLoan}>
          <LoanDetailMenu loan={currentLoan} />
        </DebtorNameHeader>

        <Box component="div" p={1}>
          <DisplayLoanStatus loan={currentLoan} />
        </Box>

        {isLoadingPayments || userConfigLoading || routeLoadingConfig ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <Grid container className="mb-1">
              <Grid item xs={6}>
                <Box component="div" p={1}>
                  <Typography variant="body2" component="p">
                    {currentLoan.a_count > 0 ? <span>Empez&oacute; en</span> : <span>Empieza en</span>}
                  </Typography>

                  <Typography variant="h6" component="h4">
                    <span className="badge badge-dark p-2">{printDate(newDate(currentLoan.start_date))}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box component="div" p={1}>
                  <Typography variant="body2" component="p">
                    Cuota
                  </Typography>
                  <Typography variant="h6" component="h4">
                    <span className="badge badge-dark p-2">{printAmount(currentLoan.cuota)}</span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <RenderLoanPayments
              data={{ route, loan: currentLoan, configs: { ...routeConfig, ...userConfig } }}
              payments={payments}
            />

            {/* <RenderLoanPaymentsDR
              data={{ route, loan: currentLoan, configs: { ...routeConfig, ...userConfig } }}
              
              payments={payments}
            /> */}
          </Box>
        )}
      </Container>
    </div>
  );
};

// export default NetworkDetector(LoanPayments);
export default LoanPayments;
