import { useForm, useWatch } from "@nodes/react-hook-form";
import { InputNumber } from "./form/InputNumber";
import { InputSlider } from "./form/InputSlider";
import { InputRadio } from "./form/InputRadio";
import { InputDropdown } from "./form/InputDropdown";
import { compareObjects } from "@shared/functions";
// import { InputDropdownNative } from "./form/InputDropdownNative";
// import { InputMultiCheckbox } from "./form/InputMultiCheckbox";
// import Typography from "@nodes/@mui/material/Typography";

import { Button, Box, Divider } from "@nodes/@mui/material";
import { GrUserSettings } from "@nodes/react-icons/gr";
import { DevTool } from "@nodes/@hookform/devtools";

export const RenderDebtorConfigForm = ({ defaultValues, submithandler }) => {
  const { handleSubmit, control, setValue, formState } = useForm({
    defaultValues,
  });

  console.log({ defaultValues });

  const formValues = useWatch({ control });

  const Percentage = () => {
    return `Aplicar porciento de Mora (${formValues.dMPerc}%)`;
  };

  const { isSubmitting } = formState;

  const handleSubmitHandler = async (data) => {
    if (compareObjects(defaultValues, data)) return;
    await submithandler(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitHandler)}>
        <Box paddingBottom={2} paddingTop={1}>
          <InputDropdown
            name="dRenCuotas"
            control={control}
            label="Permitir le renovar prestamos"
            configs={{ helperText: "Restando cuanta cuota este cliente puede renovar!", variant: "standard" }}
            options={[
              { label: "En cualquier cuota", value: 0 },
              { label: "Restando 1 cuota", value: 1 },
              { label: "Restando 2 cuotass", value: 2 },
              { label: "Restando 3 cuotass", value: 3 },
              { label: "Restando 4 cuotass", value: 4 },
              { label: "Restando 5 cuotass", value: 5 },
              { label: "Restando 6 cuotass", value: 6 },
              { label: "No permitir Renovos", value: -1 },
            ]}
          />
        </Box>

        <Box paddingBottom={2} paddingTop={1}>
          <InputNumber
            name="dLimit"
            control={control}
            type="number"
            label="Limite de Prestamo"
            placeholder={"Escriba cantidad del limite de prestamo!"}
            configs={{
              prefix: "$",
              helperText:
                "Que cantidad es lo mas que se le puede entregar a este cliente, si esta cantidad es $0 no hay limite!",
            }}
          />
        </Box>

        <Box>
          <InputRadio
            name="dRCed"
            control={control}
            label="Requerir numero de cedula para entrega?"
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
          />
        </Box>

        <Box>
          <InputRadio
            name="dRTel"
            control={control}
            label="Requerir numero de telefono para entrega?"
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
          />
        </Box>

        <Box paddingBottom={1}>
          <InputRadio
            name="dMultiLoan"
            control={control}
            label="Permitir le multiple prestamos al cliente?"
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
          />
        </Box>

        <Divider sx={{ marginBottom: 1 }} />

        <Box paddingBottom={2} paddingRight={3} paddingLeft={1}>
          <InputSlider
            name="dMPerc"
            control={control}
            setValue={setValue}
            value={defaultValues.dMPerc}
            label={<Percentage />}
            configs={{
              startEndorsement: (
                <span>
                  <b>0%</b>
                </span>
              ),
              endEndorsement: (
                <span>
                  <b>10%</b>
                </span>
              ),
              max: 10,
              min: 0,
              step: 0.5,
              helperText: "Para desactivar mora para este cliente, ponga el porciento el 0%.",
            }}
          />
        </Box>

        {/* // Because this configs is confusing for normal people, its hard coded until someone needs special mora configs */}
        {/* <Box paddingBottom={2} paddingTop={1}>
          <InputDropdown
            name="dMCuotas"
            control={control}
            label="Prestamos con pagos en atrasos"
            options={[
              { label: "Por favor, elija una opcion", value: 0 },
              { label: "1 pago consecutiva en atraso", value: 1 },
              { label: "2 pagos consecutiva en atraso", value: 2 },
              { label: "3 pagos consecutiva en atraso", value: 3 },
              { label: "4 pagos consecutiva en atraso", value: 4 },
              { label: "5 pagos consecutiva en atraso", value: 5 },
              { label: "6 pagos consecutiva en atraso", value: 6 },
              { label: "7 pagos consecutiva en atraso", value: 7 },
              { label: "8 pagos consecutiva en atraso", value: 8 },
            ]}
          />
        </Box> */}

        <Box paddingBottom={2} paddingTop={1}>
          <InputDropdown
            name="DMAtrasos"
            control={control}
            label="Prestamos con cuota en atrasos"
            configs={{
              variant: "standard",
            }}
            options={[
              { label: "Por favor, elija una opcion", value: 0 },
              { label: "Despues de 1 cuota en atrasos", value: 1 },
              { label: "Despues de 2 cuotas en atrasos", value: 2 },
              { label: "Despues de 3 cuotas en atrasos", value: 3 },
              { label: "Despues de 4 cuotas en atrasos", value: 4 },
              { label: "Despues de 5 cuotas en atrasos", value: 5 },
              { label: "Despues de 6 cuotas en atrasos", value: 6 },
              { label: "Despues de 7 cuotas en atrasos", value: 7 },
              { label: "Despues de 8 cuotas en atrasos", value: 8 },
            ]}
          />
        </Box>

        <Box paddingBottom={1}>
          <InputRadio
            name="dMPast"
            control={control}
            label="Generar mora a prestamos vencidos?"
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
          />
        </Box>

        <Box paddingBottom={2} paddingTop={1}>
          <InputDropdown
            name="dBlacked"
            control={control}
            label="Numero de cuotas para mantener en lista de cobro"
            configs={{
              variant: "standard",
              helperText: "Losa prestamos vencidos de este cliente seran mostrado por el numero de cuota selecionado.",
            }}
            options={[
              { label: "Por favor, elija una opcion", value: 0 },
              { label: "Mostrar por 15 Cuotas", value: 15 },
              { label: "Mostrar por 30 Cuotas", value: 30 },
              { label: "Mostrar por 45 Cuotas", value: 45 },
              { label: "Mostrar por 60 Cuotas", value: 60 },
              { label: "Mostrar por 75 Cuotas", value: 75 },
              { label: "Mostrar por 100 Cuotas", value: 100 },
            ]}
          />
        </Box>

        <Box paddingBottom={2} paddingTop={2}>
          <Button startIcon={<GrUserSettings />} disabled={isSubmitting} type="submit" fullWidth variant={"contained"}>
            Guardar Configuracion!
          </Button>
        </Box>
      </form>

      <DevTool control={control} />
    </>
  );
};

export default RenderDebtorConfigForm;
