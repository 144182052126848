import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { FaUserEdit, FaUserCog } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdOutlinePassword } from "react-icons/md";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";

export default function EmployeeMenu({ user_id, owner_id }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="settings" onClick={handleClick} size="large">
        <FaUserCog />
      </IconButton>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => navigate(`/user/personal/${user_id}/employee`)}>
          <FaUserEdit size="1.6em" className="pr-1" />
          Cambiar Informaci&oacute;n
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => navigate(`/user/username/${user_id}/employee`)}>
          <RiLockPasswordFill size="1.6em" className="pr-1" />
          Cambiar Usuario
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => navigate(`/user/password/${user_id}/employee`)}>
          <MdOutlinePassword size="1.6em" className="pr-1" />
          Cambiar Contrase&ntilde;a
        </MenuItem>

        <MenuItem className="text-danger" onClick={() => navigate(`/employee/delete/${owner_id}/${user_id}`)}>
          <DeleteForeverIcon className="pr-1" />
          Eliminar Empleado
        </MenuItem>
      </Menu>
    </div>
  );
}
