import { useState, useEffect } from "react";
// import Typography from "@mui/material/Typography";
// import { Context } from "../context/DataContext";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
// import CardContent from "@mui/material/CardContent";
// import CardHeader from "@mui/material/CardHeader";
// import PersonIcon from "@mui/icons-material/Person";
// import {NumericFormat } from "react-number-format";
// import { Link } from "react-router-dom";
// import { getLoanSelector } from "../context/Selectors";
// import DebtorLoanCard from "@shared/components/DebtorLoanCard";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
// import { getPaymentSelector } from "../context/Selectors";
import RenderPaymentDetail from "@shared/components/RenderPaymentDetail";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import { UseLoanPaymentsv2 } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";

const PaymentDetails = () => {
  // const { state } = useContext(Context);
  // const [currentPayment, setCurrentPayment] = useState(null);
  const { id: payment_id, id2: money_id, id3: debtor_id } = useParams();
  // const { user, route } = state;

  const { data: payments, isLoading: pLoading } = UseLoanPaymentsv2(money_id, debtor_id);
  const currentPayment = payments?.find((x) => x.payment_id === +payment_id);

  console.log({ currentPayment });

  // const handleDeletePayment = (payload) => {
  //   deletePayment(payload);
  //   history.replace("/collected");
  // };

  // useEffect(() => {
  //   if (LoanPayments) {
  //     setCurrentPayment(getPaymentSelector(LoanPayments, payment_id));
  //   }
  //   //eslint-disable-next-line
  // }, [LoanPayments]);

  if (pLoading || !currentPayment) return <Loading />;

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentPayment}>
          <LoanDetailMenu loan={currentPayment} />
        </DebtorNameHeader>

        <Box component="div" p={1}>
          <RenderPaymentDetail payment={currentPayment} />
        </Box>
      </Container>
    </div>
  );
};

export default PaymentDetails;
