import { useState } from "react";
import { BsPinAngleFill, BsPinAngle } from "react-icons/bs";

import CircularProgress from "@mui/material/CircularProgress";

const LoanPinned = ({ loan, toggleLoanPin, mutate }) => {
  const [loading, setLoading] = useState("idle");
  const handleClick = async () => {
    setLoading("loading");
    await toggleLoanPin(loan);
    mutate();
    setLoading("completed");
  };

  return (
    <>
      {loading === "loading" ? (
        <CircularProgress className="mt-2" size="1.3em" />
      ) : (
        <div onClick={handleClick}>
          {+loan.pinned === 1 ? (
            <BsPinAngleFill size="1.5em" className=" mt-2" />
          ) : (
            <BsPinAngle size="1.5em" className="mt-2" />
          )}
        </div>
      )}
    </>
  );
};

export default LoanPinned;
