import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { printAmount, printDate, newDate } from "@shared/functions";
import { parseISO } from "date-fns";
import Chip from "@mui/material/Chip";

const RenderLoanDeleteDetail = ({ loan = {} }) => {
  return (
    <div>
      <Typography variant="h6" component="h4">
        <img
          alt=""
          src={loan.isMain === 0 ? "/../../cobro-icon-57x57.png" : "/../../main-icon-57x57.png"}
          style={{ width: 22, height: 22, marginRight: 3 }}
        />
        Detalle del Pr&eacute;stamo
        <b className="p-2">
          <Chip label={`P${loan.oldLoanId ?? loan.money_id}`} />
        </b>
      </Typography>

      <Grid container>
        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Entregado
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2"> {printDate(parseISO(loan.created_time), "dd-MMM-yy")}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              &Uacute;ltimo Pago
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(newDate(loan.last_date))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cantidad
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.amount)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cuota
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.cuota)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Balance
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.balance)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cuotas Pagas
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                {loan.completed} / {loan.npayments}
              </span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default RenderLoanDeleteDetail;
