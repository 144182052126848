import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, FormHelperText } from "@nodes/@mui/material";
import { Controller } from "@nodes/react-hook-form";

export const InputRadio = ({ name, control, label, options = [], configs = {} }) => {
  const generateRadioOptions = () => {
    return options.map((singleOption, index) => (
      <FormControlLabel key={index} value={singleOption.value} label={singleOption.label} control={<Radio />} />
    ));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => (
        <>
          <FormControl error={!!error} component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup row onChange={onChange} value={value} onBlur={onBlur}>
              {generateRadioOptions()}
            </RadioGroup>
            {configs.helperText && <FormHelperText>{configs.helperText}</FormHelperText>}
            {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        </>
      )}
    />
  );
};
