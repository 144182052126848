import { useParams } from "@nodes/react-router";
import Loading from "../components/Loading";
import { Table, Thead, Tbody, Tr, Th, Td } from "@nodes/react-super-responsive-table";
import { UseCustomerBalance, UseCustomerInvoicesv2, UseBankInformationv2 } from "../server/fetcher";
import RenderPaymentAccount from "../components/RenderPaymentAccount";
import { AiFillPhone } from "@nodes/react-icons/ai";
import { ImUser } from "@nodes/react-icons/im";
import { BsFillCircleFill } from "@nodes/react-icons/bs";
import { printAmount, formatPhoneNumber, printDate, newDate } from "../functions";
// import "./styles/invoice.css";
import { Link } from "@nodes/react-router-dom";
import { mainApp } from "@/config";
import Typography from "@nodes/@mui/material/Typography";
import Box from "@nodes/@mui/material/Box";
import Card from "@nodes/@mui/material/Card";
import CardContent from "@nodes/@mui/material/CardContent";
import { parseISO } from "@nodes/date-fns";

const CustomerBalance = () => {
  const { id: customer_id, id2: mainOnly } = useParams();
  const { data, isLoading } = UseCustomerBalance(customer_id, mainOnly || 1);
  const { data: invoices, isLoading: isLInvoices } = UseCustomerInvoicesv2(customer_id, 6);
  const { data: bankInformation, isLoading: bankLoading } = UseBankInformationv2(customer_id);

  if (bankLoading) return <Loading />;

  if (+customer_id === 0)
    return (
      <div className="container mt-3 mb-5">
        <Card raised>
          <CardContent>
            <Box component="div" p={1}>
              <Typography variant="h6" component="h4" gutterBottom>
                Inicie sesion
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                Para poder ver su factua debe inicar sesion
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <br />
        <RenderPaymentAccount bankInformation={bankInformation} />
      </div>
    );

  if (!data && data === false)
    return (
      <div className="container mt-3 mb-5">
        <Card raised>
          <CardContent>
            <Box component="div" p={1}>
              <Typography variant="h6" component="h4" gutterBottom>
                No hay factura de pago!
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                Por el momento usted no tiene nignuna factura sin pagar.
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                Vuelva a esta pagina el primero del proximo mes para ver su factura de pago.
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Las factura de pagos son generada mensual todo los dia primero del mes.
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <br />
        <RenderPaymentAccount bankInformation={bankInformation} />
      </div>
    );

  if (isLoading) return <Loading />;

  return (
    <div className="page-content container mt-3 mb-5">
      <div className="page-header text-blue-d2">
        <h1 className="page-title text-secondary-d1">
          Factura
          {data.invoice_id && (
            <small className="page-info">
              <i className="fa fa-angle-double-right text-80"></i> # {data.invoice_id}{" "}
              {mainApp === 1 && <Link to={`/invoice/${data.invoice_id}/${customer_id}`}>Ver Detalle</Link>}
            </small>
          )}
        </h1>
        <br />
      </div>

      <div className="container px-0">
        <div className="row mt-4">
          <div className="col-12 col-lg-12">
            <div className="row">
              <div className="col-sm-6">
                <div>
                  <span className="text-600 text-110 text-blue align-middle">{data.name}</span>
                </div>
                <div className="text-grey-m2">
                  <div className="my-1">
                    <ImUser /> <b className="text-600">Cliente W{data.customer_id}</b>
                  </div>
                </div>
                <div className="text-grey-m2">
                  <div className="my-1">
                    <AiFillPhone /> <b className="text-600">{formatPhoneNumber(data.phone)}</b>
                  </div>
                </div>
                <div className="text-grey-m2">
                  <div className="my-1">
                    - <b className="text-600">Pago Mensual: {printAmount(+data.monthly)}</b>
                  </div>
                </div>
                <div className="text-grey-m2">
                  <div className="my-1">
                    -{" "}
                    <b className="text-600">
                      &Uacute;ltimo Pago:{" "}
                      {data.lastPaidDate ? printDate(parseISO(data.lastPaidDate), "dd-MMM-yyyy") : "Ninguno Ahun"}
                    </b>
                  </div>
                </div>
              </div>

              <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                <hr className="d-sm-none" />
                <div className="text-grey-m2">
                  {+data.prevBal > 0 && +data.balance > 0 && (
                    <div className="my-2">
                      <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                      <span className="text-600 text-90">Balance Atrasado: </span> {printAmount(data.prevBal)}
                    </div>
                  )}

                  <div className="my-2">
                    <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                    <span className="text-600 text-90">Balance Actual: </span> {printAmount(+data.balance)}
                  </div>

                  {data.paidCredit > 0 && (
                    <div className="my-2">
                      <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                      <span className="text-600 text-90">Credito / Promo: </span> {printAmount(data.paidCredit)}
                    </div>
                  )}

                  {data.paidReferral > 0 && (
                    <div className="my-2">
                      <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                      <span className="text-600 text-90">Pago de Referidos: </span> {printAmount(data.paidReferral)}
                    </div>
                  )}

                  <div className="my-2">
                    <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                    <span className="text-600 text-90">Pagar Cantidad: </span> {printAmount(Math.max(+data.balance, 0))}
                  </div>

                  <div className="my-2">
                    <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                    <span className="text-600 text-90">Pago Recibido: </span> {printAmount(+data.paidAmount)}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <hr />
              <RenderPaymentAccount bankInformation={bankInformation} />
            </div>

            {isLInvoices ? (
              <Loading />
            ) : (
              <>
                {mainApp === 1 && (
                  <div className="mt-4">
                    <Typography variant="h6">Facturas Anteriores!</Typography>
                    <Table>
                      <Thead>
                        <Tr className="font-weight-bold">
                          <Th>Fecha</Th>
                          <Th>Atrasos</Th>
                          <Th>Balance</Th>
                          <Th>Pagado</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {invoices.map((item) => (
                          <Tr key={item.invoice_id} className="mb-1">
                            <Td>{printDate(newDate(item.invoiceDate))}</Td>
                            <Td className={+item.prevBal > 0 ? "text-danger" : ""}>{printAmount(item.prevBal)}</Td>
                            <Td>{printAmount(item.newBal)}</Td>
                            <Td>{printAmount(item.paidAmount)}</Td>

                            <Td>
                              <Link to={`/invoice/${item.invoice_id}/${customer_id}`}>
                                <u>Ver Factura!</u>
                              </Link>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerBalance;
