import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import SearchBox from "@shared/components/common/SearchBox";
// import { CgUserList } from "react-icons/cg";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import { RemoveAccents, sortArrayObjs, indentifySearchType } from "@shared/functions";
import { getXcobrarLoansOnly } from "../context/Selectors";
import { FcEmptyFilter, FcClearFilters } from "react-icons/fc";
import { AiOutlinePushpin } from "react-icons/ai";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import XcobrarLoan from "@shared/components/XcobrarLoan";
import { printAmount } from "@shared/functions";
// import Skeleton from "../components/SkeletonElement";
// import BottomNavigation from "../components/BottomNavigation";

const XCobrar = ({ loans = [], route = {} }) => {
  const [searchText, setSearchText] = useState("");
  // const [xCobrar, setXcobrar] = useState({ count: 0, amount: 0, list: [] });
  // const [loanFilter, setLoanFilter] = useState("xCobrar");
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("allLoans");
  const [filterDescription, setFilterDescription] = useState("");

  useEffect(() => {
    let loansResult = loans;
    if (selectedFilter === "allLoans") {
      setFilterDescription(<i>Mostrando todo los pr&eacute;stamos!</i>);
    } else if (selectedFilter === "xCobrar") {
      loansResult = getXcobrarLoansOnly(loans, route.hideVencidos);
      setFilterDescription(<i>Pr&eacute;stamos x Cobrar y Vencidos recientemente!</i>);
    } else if (selectedFilter === "Al Dia") {
      loansResult = loans.filter((loan) => loan.statusText === "Al Dia");
      setFilterDescription(<i>Pr&eacute;stamos x Cobrar al Dia!</i>);
    } else if (selectedFilter === "atrasos") {
      loansResult = loans.filter((loan) => loan.statusText === "Atrasos");
      setFilterDescription(<i>Pr&eacute;stamos con pagos en Atrasos!</i>);
    } else if (selectedFilter === "sale_hoy") {
      loansResult = loans.filter((loan) => loan.a_count === loan.npayments);
      setFilterDescription(<i>Pr&eacute;stamos saliente de esta semana!</i>);
    } else if (selectedFilter === "xRenewal") {
      const xRenewalLoans = loans.filter((loan) => loan.npayments - loan.a_count < 3 && loan.statusText !== "Vencido");
      const sorted = sortArrayObjs(xRenewalLoans, "current_week", "completed");
      loansResult = sorted.reverse();
      setFilterDescription(<i>Pr&eacute;stamos para renovar en sus ultima 3 semanas!</i>);
    } else if (selectedFilter === "new_loan") {
      loansResult = loans.filter((loan) => loan.a_count < 2);
      setFilterDescription(<i>Pr&eacute;stamos nuevo, renovado la semana pasada!</i>);
    } else if (selectedFilter === "adelantos") {
      loansResult = loans.filter((loan) => loan.statusText === "Adelanto");
      setFilterDescription(<i>Pr&eacute;stamos con pagos de Adelanto!</i>);
    } else if (selectedFilter === "vencidos") {
      loansResult = loans.filter((loan) => loan.statusText === "Vencido");
      setFilterDescription(<i>Pr&eacute;stamos vencido con balance!</i>);
    }

    setFilteredLoans(loansResult);
  }, [loans, selectedFilter]);

  const getFilteredDebtor = () => {
    if (searchText.length < 3) return filteredLoans;

    const userSearch = indentifySearchType(searchText);

    let filtered = loans;
    filtered = filtered.filter((m) => RemoveAccents(m.loanSearch).toUpperCase().indexOf(userSearch.toUpperCase()) >= 0);
    filtered = filtered.slice(0, 10);

    return filtered;
  };

  const handleSearch = (query) => {
    setSearchText(query);
  };

  const handleFilterLoans = (filter) => {
    handleClose();
    setSelectedFilter(filter);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loansFilter = getFilteredDebtor(filteredLoans, loans);

  const xCobrarSums = loans?.reduce((acc, obj) => {
    return +obj.xCobrarAmount > 0 ? acc + +obj.xCobrarAmount : acc;
  }, 0);

  return (
    <div className="mt-3">
      <Container maxWidth="md" disableGutters>
        <Box component="div" p={1}>
          <Typography variant="h5" component="h4" gutterBottom>
            Lista de Cobro {printAmount(xCobrarSums)}!
          </Typography>
        </Box>

        {/* <Link to={"/debtors/organize"}>
                <Button className="text-info">
                  <CgUserList size="1.8em" />
                </Button>
              </Link> */}

        {/* {!loansFetched && [1, 2, 3, 4, 5, 6].map((n) => <Skeleton key={n} />)} */}
        {!loans && <FullLoadingScreen />}

        <Box component="div" p={1}>
          <Grid container>
            <Grid item xs={10}>
              <SearchBox
                onChange={handleSearch}
                placeHolder="Escriba para buscar Pr&eacute;stamo..."
                value={searchText}
              />
            </Grid>
            <Grid item xs={2}>
              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {selectedFilter === "allLoans" ? <FcEmptyFilter size="1.8em" /> : <FcClearFilters size="1.8em" />}
              </Button>
              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleFilterLoans("allLoans")}>
                  {selectedFilter === "allLoans" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar todo
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("xCobrar")}>
                  {selectedFilter === "xCobrar" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar X Cobrar
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("new_loan")}>
                  {selectedFilter === "new_loan" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Pr&eacute;stamos Nuevos
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("sale_hoy")}>
                  {selectedFilter === "sale_hoy" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Pr&eacute;stamos Saliente
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("xRenewal")}>
                  {selectedFilter === "xRenewal" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Pr&eacute;stamos Renovo
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("Al Dia")}>
                  {selectedFilter === "Al Dia" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar Al Dia
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("adelantos")}>
                  {selectedFilter === "adelantos" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar en Adelantos
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("atrasos")}>
                  {selectedFilter === "atrasos" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar en Atrasos
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("vencidos")}>
                  {selectedFilter === "vencidos" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar Vencidos
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>

          <Typography variant="body2" style={{ marginTop: -6, marginBottom: 6 }}>
            {filterDescription}
          </Typography>
        </Box>

        {loans.length === 0 && (
          <div className="container mt-4 mb-5">
            <Typography variant="h5" componet="h3" gutterBottom>
              Lista de Pr&eacute;stamos!
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              Aqui se muestra la lista de pr&eacute;stamos.
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              Por el momento, no hay pr&eacute;stamos que mostrar!
            </Typography>
          </div>
        )}

        {loansFilter.map((loan) => (
          // <Grid key={loan.money_id} item xs={12} sm={10} md={6} lg={4} xl={3}>
          <XcobrarLoan key={loan.money_id} loan={loan} />
          // </Grid>
        ))}
      </Container>
    </div>
  );
};

export default XCobrar;
