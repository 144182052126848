import { string, object, addMethod, mixed } from "@nodes/yup";
// import Paper from "@nodes/@mui/material/Paper";
import { validateCedula } from "../functions";
import { InputCustomFormat } from "./form/InputCustomFormat";
import { useForm } from "@nodes/react-hook-form";
import { InputText } from "./form/InputText";
import { InputNumber } from "./form/InputNumber";
// import { InputSlider } from "./form/InputSlider";
// import { InputRadio } from "./form/InputRadio";
import { Button, Box, Divider, Grid, Typography, Stack, FormLabel } from "@nodes/@mui/material";
import { yupResolver } from "@nodes/@hookform/resolvers/yup";
// import { GrUserSettings } from "@nodes/react-icons/gr";
import { InputDropdown } from "./form/InputDropdown";
import { compareObjects } from "../functions";
import { DevTool } from "@nodes/@hookform/devtools";

const RenderDebtorForm = ({ title, defaultValues, submithandler, cedula, button }) => {
  addMethod(mixed, "validateCedula", validateCedula);
  const validation = object({
    name: string()
      .required("Nombre es requerido!")
      .min(6, "Nombre es muy corto!")
      .matches(/^([^0-9]*)$/, "Nombre no puede tener numeros!"),
    phone: string().required("Telefono es requerido!").min(10, "Telefono es muy corto!"),
    cedula: mixed().validateCedula(cedula === false ? false : true),
  });

  const { handleSubmit, control, setValue, formState, watch } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
  });

  const idType = watch("idType");

  const { isSubmitting } = formState;

  const handleSubmitHandler = async (data) => {
    if (compareObjects(defaultValues, data)) return;
    const birthdate = `${data.year}-${data.month}-${data.day}`;
    await submithandler({ ...data, birthdate });
  };

  return (
    <div>
      <Typography variant="h5" component="h3" gutterBottom>
        {title}
      </Typography>

      <form onSubmit={handleSubmit(handleSubmitHandler)}>
        {/* <Box paddingBottom={2}>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <InputText
                name="name"
                label="Nombre Completo"
                control={control}
                placeholder={"Escriba nombre del Cliente!"}
              />
            </Grid>
            <Grid item xs={5}>
              <InputText
                name="nickname"
                label="Algun apodo?"
                control={control}
                placeholder={"Apodo del cliente, si tiene!"}
              />
            </Grid>
          </Grid>
        </Box> */}

        <Box paddingBottom={2}>
          <InputText
            name="name"
            label="Nombre Completo"
            control={control}
            placeholder={"Escriba nombre del Cliente!"}
          />
        </Box>

        <Box paddingBottom={2}>
          <InputText
            name="nickname"
            label="Algun apodo?"
            control={control}
            placeholder={"Apodo del cliente, si tiene!"}
          />
        </Box>

        <Box paddingBottom={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <InputCustomFormat
                name="phone"
                control={control}
                label="Telefono"
                placeholder={"Numero de telefono!"}
                configs={{
                  type: "tel",
                  overwrite: false,
                  mask: "(#00) 000-0000",
                  definitions: {
                    "#": /[1-9]/,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputCustomFormat
                name="phone2"
                control={control}
                label="Telefono 2"
                placeholder={"Numero de telefono!"}
                configs={{
                  type: "tel",
                  overwrite: false,
                  mask: "(#00) 000-0000",
                  definitions: {
                    "#": /[1-9]/,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box paddingBottom={2}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <InputDropdown
              name="idType"
              control={control}
              label="Seleccione un documento"
              configs={{ size: "small", variant: "standard" }}
              options={[
                { label: "Otro Documento", value: 0 },
                { label: <span>C&eacute;dula</span>, value: 1 },
                { label: "Pasaporte", value: 2 },
                { label: "RNC", value: 3 },
              ]}
            />

            {idType === 1 ? (
              <InputCustomFormat
                name="idNumber"
                control={control}
                label={<span>Numero de C&eacute;dula</span>}
                placeholder={"Escriba Numero de Cedula!"}
                configs={{
                  type: "tel",
                  overwrite: false,
                  mask: "#00-0000000-0",
                  definitions: {
                    "#": /[0-9]/,
                  },
                }}
              />
            ) : idType === 2 ? (
              <InputText
                name="idNumber"
                label="Numero de Pasaporte"
                control={control}
                placeholder={"Escriba numero de pasaporte!"}
              />
            ) : idType === 3 ? (
              <InputCustomFormat
                name="idNumber"
                control={control}
                label="Numero de RNC"
                placeholder={"Escriba Numero de RNC!"}
                configs={{
                  type: "tel",
                  overwrite: false,
                  mask: "#00-00000-0",
                  definitions: {
                    "#": /[0-9]/,
                  },
                }}
              />
            ) : (
              <InputCustomFormat
                name="idNumber"
                control={control}
                label="Otro Documento"
                placeholder={"Otro Documento!"}
                configs={{
                  overwrite: false,
                  mask: "#00-0000000-0",
                  definitions: {
                    "#": /[0-9]/,
                  },
                }}
              />
            )}
          </Stack>
        </Box>

        <Box paddingBottom={2}>
          <FormLabel component="legend">Fecha de Nacimiento</FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <InputNumber
                name="day"
                control={control}
                label="Dia"
                placeholder={"Que dia nacio?"}
                configs={{ thousandSeparator: false }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputNumber
                name="month"
                control={control}
                label="Mes"
                placeholder={"Que mes nacio?"}
                configs={{ thousandSeparator: false }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputNumber
                name="year"
                control={control}
                label="Año"
                placeholder={"Que año nacio?"}
                configs={{ thousandSeparator: false }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box paddingBottom={2}>
          <InputText
            name="email"
            label="Correo Electronico"
            control={control}
            configs={{ type: "email" }}
            placeholder={"Escriba su primer apellido!"}
          />
        </Box>

        <Box paddingBottom={2}>
          <InputText
            name="address"
            label="Direccion de Casa"
            control={control}
            placeholder={"Escriba la direccion de trabajo!"}
          />
        </Box>
        <Box paddingBottom={2}>
          <InputText
            name="occupation"
            label="Ocupacion de Trabajo"
            control={control}
            placeholder={"Escriba la Ocupacion de trabajo!"}
          />
        </Box>

        <Box paddingBottom={2}>
          <InputText
            name="work_address"
            label="Direccion de Trabajo"
            control={control}
            placeholder={"Escriba la direccion de trabajo!"}
          />
        </Box>

        <Box paddingBottom={2}>
          <InputText
            name="next_to"
            label="Proximo a Lugar"
            control={control}
            placeholder={"Escriba un aproximado a la direccion!"}
          />
        </Box>

        <Box paddingBottom={2}>
          <InputText
            name="cosigner"
            label="Garante"
            control={control}
            placeholder={"Escriba el nombre del garante, si hay!"}
          />
        </Box>

        <Box paddingBottom={2}>
          <InputText
            name="note"
            control={control}
            label="Otra informacion!"
            placeholder="Aqui puede escribir otro anotes para el cliente!"
            configs={{ rows: 2, multiline: true, variant: "outlined" }}
          />
        </Box>

        <Box paddingBottom={2}>
          <Button disabled={isSubmitting} type="submit" fullWidth variant={button.variant}>
            {button.label}
          </Button>
        </Box>

        <DevTool control={control} />
      </form>
    </div>
  );
};

export default RenderDebtorForm;
