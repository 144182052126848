import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../context/DataContext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { UseUserLinkedRoutes, UseUser, UseLoansV2 } from "@shared/server/fetcher";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { string, object } from "yup";
import { Form, RenderInput } from "@shared/components/Form";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RenderBackButton from "@shared/components/RenderBackButton";
import Loading from "@shared/components/Loading";
import { deleteRoute, deleteRouteLoans, deleteOwnerLoans } from "@shared/api/route";
import { useSnackbar } from "notistack";

const DeleteRoute = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // const { deleteRoute, deleteOwnerLoans, deleteRouteLoans } = useContext(Context);

  const { id: creditor_id, id2: user_id, type } = useParams();
  const { mutate } = UseUserLinkedRoutes(user_id);
  const { data: user, isLoading } = UseUser(user_id);
  const { mutate: loansMutate } = UseLoansV2(creditor_id, user?.user_id);

  if (isLoading) return <Loading />;

  const initialPersonalValues = {
    user_id,
    salt: user.salt,
    creditor_id,
    password: "",
  };

  const validationPersonal = object({
    password: string().required("Nombre es requerido!"),
  });

  const buttonPersonal = {
    icon: <DeleteForeverIcon />,
    label: "Borrar Datos",
    variant: "contained",
    color: "primary",
  };

  const submitHandlder = async (data) => {
    try {
      if (type === "route") {
        await deleteRoute(data);
        mutate();
        enqueueSnackbar("Ruta eliminada con exito", { variant: "info" });
        navigate("/routes/choose", { replace: true });
        return;
      }

      if (type === "loans") {
        await deleteRouteLoans(data);
        loansMutate();
        enqueueSnackbar("Los pr&eacute;stamos de esta ruta han sido borrado", { variant: "info" });
      }

      if (type === "owner") {
        await deleteOwnerLoans(data);
        loansMutate();
        enqueueSnackbar("Todo los pr&eacute;stamos han sido borrado", { variant: "info" });
      }

      navigate("/dashboard", { replace: true });
    } catch (error) {}
  };

  return (
    <div className="container mt-5">
      <Card>
        <CardContent>
          {type === "loans" && (
            <>
              <Typography variant="h5" component="h3" className="text-danger" gutterBottom>
                Confirmaci&oacute;n
              </Typography>

              <Typography variant="h6" component="h4" gutterBottom>
                Decea borrar los pr&eacute;stamos?
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                Todo los pr&eacute;stamos con balance y sin balance seran borrado para esta ruta.
              </Typography>
            </>
          )}

          {type === "owner" && (
            <>
              <Typography variant="h5" component="h3" className="text-danger" gutterBottom>
                Confirmaci&oacute;n
              </Typography>

              <Typography variant="h6" component="h4" gutterBottom>
                Decea borrar los pr&eacute;stamos de todas las rutas?
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                Todo los pr&eacute;stamos con balance y sin balance seran borrado para todas sus rutas.
              </Typography>
            </>
          )}

          {type === "route" && (
            <>
              <Typography variant="h5" component="h3" className="text-danger" gutterBottom>
                Confirmaci&oacute;n
              </Typography>

              <Typography variant="h6" component="h4" gutterBottom>
                Decea borrar este Ruta?
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                Despues de ser borrada, usted perder&aacute; el aceso a esta ruta.
              </Typography>
            </>
          )}

          <Form
            initialValues={initialPersonalValues}
            validation={validationPersonal}
            submithandler={submitHandlder}
            button={buttonPersonal}>
            <Typography variant="body1">Para poder borrar, tiene que verificar su contraseña.</Typography>
            <Box paddingTop={3} paddingBottom={4}>
              {RenderInput("password", "Contraseña", "password")}
            </Box>
          </Form>
          <div className="mt-4">
            <RenderBackButton />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeleteRoute;
