import { useEffect } from "@nodes/react";
import Tab from "@nodes/@mui/material/Tab";
import Tabs from "@nodes/@mui/material/Tabs";
import { spanishRouteTypes } from "../functions";

const RenderLoanTypeTabs = ({ loanType, setLoanType, types = [] }) => {
  const length = types?.length;

  useEffect(() => {
    if (length === 1) {
      setLoanType(types[0]);
    }
  }, []);

  const handleTabChange = (event, type) => {
    setLoanType(type);
  };

  if (length === 1) return null;

  return (
    <Tabs
      textColor="primary"
      variant={length > 3 ? "scrollable" : "fullWidth"}
      centered={length > 3 ? false : true}
      scrollButtons="auto"
      value={loanType}
      onChange={handleTabChange}>
      {types.map((type, index) => (
        <Tab key={index} label={spanishRouteTypes(type)} value={type} />
      ))}
    </Tabs>
  );
};

export default RenderLoanTypeTabs;
