import Typography from "@nodes/@mui/material/Typography";
import Box from "@nodes/@mui/material/Box";

const Loading = ({ label = null }) => {
  return (
    <>
      <div className="mt-5 d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading ...</span>
        </div>
      </div>

      {label && (
        <Box paddingTop={2}>
          <Typography align="center" variant="body1">
            {label}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Loading;
