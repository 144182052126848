import { useContext, useState, useEffect, useRef } from "react";
import { Context } from "../context/DataContext";
// import FloatingButton from "@shared/components/FloatingButton";
import PeopleIcon from "@mui/icons-material/People";
import { MdOutlineFavorite } from "react-icons/md";
// import Container from "@mui/material/Container";
import { CgSmileNone } from "react-icons/cg";
import { UseDebtors, UseLoansV2 } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
// import NoInternetContent from "@shared/components/NoInternetContent";
import FabDebtorCreate from "../components/FabDebtorCreate";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import RenderDebtorsAll from "@shared/components/RenderDebtorsAll";
import RenderDebtorsFave from "@shared/components/RenderDebtorsFave";
import RenderDebtorsInactive from "@shared/components/RenderDebtorsInactive";
import RenderDebtorsNew from "@shared/components/RenderDebtorsNew";
import { CiStar } from "react-icons/ci";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { state } = useContext(Context);
  const { user, route: r2 } = state;

  const { data: debtors, isLoading } = UseDebtors(r2.creditor_id, user.user_id);
  const { data: loans } = UseLoansV2(r2.creditor_id, user.user_id);

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <FabDebtorCreate />
      <Tabs
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={selectedTab}
        onChange={handleTabChange}>
        <Tab icon={<PeopleIcon size="1.5em" />} label="Clientes" />
        <Tab icon={<MdOutlineFavorite size="1.5em" className="text-success" />} label="Favoritos" />
        <Tab icon={<CgSmileNone size="1.6em" className="text-warning" />} label="Inactivos" />
        <Tab icon={<CiStar size="1.6em" className="text-primary" />} label="Nuevo" />
      </Tabs>

      {selectedTab === 0 && <RenderDebtorsAll debtors={debtors} />}
      {selectedTab === 1 && <RenderDebtorsFave debtors={debtors} />}
      {selectedTab === 2 && <RenderDebtorsInactive debtors={debtors} loans={loans} />}
      {selectedTab === 3 && <RenderDebtorsNew debtors={debtors} />}
    </>
  );
};

export default Settings;
