import backend from "@shared/server/backend";

export const createDebtor = async (data) => {
  const response = await backend.post("/debtor/create", { ...data });
  return response.data;
};

export const updateDebtor = async (data) => {
  const response = await backend.put("/debtor", { data });
  return response.data;
};

export const updateDebtorCOnfig = async (data) => {
  const response = await backend.put("/debtor/config", { ...data });
  return response.data;
};

export const deleteDebtorCOnfig = async ({ debtor_id }) => {
  const response = await backend.delete(`/debtor/config/${debtor_id}`);
  return response.data;
};
