import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Context } from "../context/DataContext";
import { FaHandHoldingUsd } from "react-icons/fa";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderDebtorLoans from "@shared/components/RenderDebtorLoans";
import DebtorMenu from "@shared/components/DebtorMenu";
import Loading from "@shared/components/Loading";
import { UseDebtor, UseDebtorPaidLoans } from "@shared/server/fetcher";

const DebtorPaidLoans = () => {
  const navigate = useNavigate();
  const { id: debtor_id } = useParams();
  const { state } = useContext(Context);
  const { user } = state;

  const { data: debtor, isLoading: isLoadingDebtor } = UseDebtor(debtor_id, user.user_id);
  const { data: loans, isLoading: isLoadingLoans } = UseDebtorPaidLoans(debtor_id, user.user_id);

  if (isLoadingDebtor) return <Loading />;

  return (
    <div className="mt-2 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={debtor}>
          <DebtorMenu debtor={debtor} profile />
        </DebtorNameHeader>

        {loans?.length === 0 && (
          <div className="container">
            <Box component="div" paddingBottom={2}>
              <Typography variant="h6" component="p">
                No pr&eacute;stamos saldado encontrado
              </Typography>

              <Typography variant="body1" component="p">
                No pr&eacute;stamos previamente saldado fueron encotrado!
              </Typography>
            </Box>

            <Box paddingBottom={2}>
              <Button
                fullWidth
                startIcon={<FaHandHoldingUsd size="1.2em" />}
                variant="outlined"
                onClick={() => navigate("/loan/create/" + debtor.dUniqueId)}>
                Nuevo Pr&eacute;stamo!
              </Button>
            </Box>
          </div>
        )}

        {isLoadingLoans ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <RenderDebtorLoans title="Pr&eacute;stamos Saldado o Borrado" loans={loans} />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default DebtorPaidLoans;
