import { useContext, useState } from "react";
import { Context } from "../context/DataContext";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { userEmail } from "@shared/email";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Alert from "@nodes/@mui/material/Alert";
import Grow from "@mui/material/Grow";
import Card from "@mui/material/Card";
// import SwipeButton from "/components/SwipeButton";
import SubmitButton from "@shared/components/SubmitButton";
import RenderContactInfo from "@shared/components/RenderContactInfo";
import { projectName } from "@/config";
import { AiOutlineSend } from "@nodes/react-icons/ai";

const Contact = () => {
  const { state } = useContext(Context);
  const { user } = state;
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [sent, setSent] = useState(false);

  const handleSetMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  const handleSetPhone = (e) => {
    setPhone(e.target.value);
  };

  const handleSendEmail = async () => {
    setSent(false);
    let newUser = user;
    if (user.user_id === 0) {
      if (!message || !name || name.length < 3 || !phone || phone.length < 10) return;
      newUser = { ...user, phone, firstname: name, lastname: "NotAvaible" };
    } else {
      if (!message) return;
    }

    const res = await userEmail({ ...newUser, message, project_name: projectName });
    if (res) {
      setMessage("");
      setSent(true);
    } else {
      toast.error("Mensaje no fue enviado!", {
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Typography variant="h5" component="h3" gutterBottom>
            Alguna Pregunta?
          </Typography>
          <Divider className="mt-1 mb-2" />

          <Typography variant="subtitle1" component="p">
            Escriba su pregunta en la cajita hacia abajo!
          </Typography>

          {!user.user_id && (
            <>
              <TextField
                variant="outlined"
                placeholder="Escriba Nonbre aqui!"
                margin="normal"
                fullWidth
                type="text"
                onChange={handleSetName}
                value={name}
              />{" "}
              <TextField
                variant="outlined"
                placeholder="Escriba telefono aqui!"
                margin="normal"
                fullWidth
                type="text"
                onChange={handleSetPhone}
                value={phone}
              />
            </>
          )}

          <TextField
            minRows="3"
            variant="outlined"
            placeholder="Escriba su Mensaje aqui!"
            margin="normal"
            fullWidth
            multiline
            type="text"
            onChange={handleSetMessage}
            value={message}
          />

          <Typography variant="body2" component="p" paragraph>
            Si tienes una emergencia, por favor escriba me ami Whatsapp directamente!
          </Typography>

          {sent && (
            <Box component="div" p={1}>
              <Grow direction="up" in>
                <Alert variant="outlined" severity="success">
                  <Typography variant="body1" component="p">
                    Su mensaje fue enviado!
                  </Typography>
                </Alert>
              </Grow>
            </Box>
          )}

          {/* <SwipeButton text="ENVIAR MENSAJE!" handleSubmit={handleSendEmail} /> */}

          <Box paddingTop={2}>
            {/* <Button fullWidth variant="contained" color="secondary" onClick={() => handleDeleteEntrega(currentLoan)}>
            Cancelar Entrega
          </Button> */}

            <SubmitButton
              autoFetch={false}
              color="primary"
              text="Enviar Mensage!"
              startIcon={<AiOutlineSend />}
              callBack={handleSendEmail}
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={5}>
          <Card variant="outlined" className="mt-5">
            <Box m={2}>
              <RenderContactInfo />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
