import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from "@nodes/@mui/material";
import { Controller } from "@nodes/react-hook-form";

export const InputDropdown = ({ name, control, label, options = [], configs = {} }) => {
  const generateSingleOptions = () => {
    return options?.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.img && <img alt="option.img" style={{ width: 16, height: 16 }} src={`/../../${option.img}`} />}{" "}
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl fullWidth={configs.fullWidth ?? true}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Controller
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <Select
              variant={configs.variant ?? "outlined"}
              disabled={configs.disabled ?? false}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={label}
              size={configs.size ?? "small"}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: configs.backgroundColor ?? "#bababa",
                  },
                },
              }}>
              {generateSingleOptions()}
            </Select>
            {configs.helperText && <FormHelperText>{configs.helperText}</FormHelperText>}
          </>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};
