import Box from "@nodes/@mui/material/Box";
import Typography from "@nodes/@mui/material/Typography";
import Grid from "@nodes/@mui/material/Grid";
import { parseISO } from "@nodes/date-fns";
import { printAmount, printDate, newDate, spanishRouteTypes } from "../functions";
import Chip from "@nodes/@mui/material/Chip";

const RenderLoanDetail = ({ loan = {} }) => {
  // const endDate = calcEndDate(loan);
  return (
    <div>
      <Typography variant="h6" component="h4">
        <img
          alt=""
          src={loan.isMain === 0 ? "/../../cobro-icon-57x57.png" : "/../../main-icon-57x57.png"}
          style={{ width: 22, height: 22, marginRight: 3 }}
        />
        Detalle del Prestamo
        <b className="p-2">
          <Chip label={`P${loan.oldLoanId ?? loan.money_id}`} />
        </b>
      </Typography>

      <Grid container>
        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Entregado en
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(parseISO(loan.created_time))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              {loan.a_count > 0 ? <span>Empez&oacute; en</span> : <span>Empieza en</span>}
            </Typography>
            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(newDate(loan.start_date))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              {loan.a_count > loan.npayments ? <span>Venci&oacute; en</span> : <span>Vence en</span>}
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(newDate(loan.end_date))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              &Uacute;ltimo Pago
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(newDate(loan.last_date))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cantidad
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.amount)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Balance
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.balance + loan.balMora)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cuota
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.cuota)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cuotas Paga
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                {loan.completed} / {loan.npayments}
              </span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              X Cobrar
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                {loan.xCobrarTotal > 0 ? printAmount(loan.xCobrarTotal) : "$0"}
              </span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Mora Pendiente
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.balMora)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Tipo de Prestamo
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{spanishRouteTypes(loan.type)}</span>
            </Typography>
          </Box>
        </Grid>

        {/* <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Creado en
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(parseISO(loan.created_time))}</span>
            </Typography>
          </Box>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default RenderLoanDetail;
