import Box from "@nodes/@mui/material/Box";
import Button from "@nodes/@mui/material/Button";
import Typography from "@nodes/@mui/material/Typography";
import Grid from "@nodes/@mui/material/Grid";
import { printAbsAmount, printDate, newDate } from "../functions";
import Chip from "@nodes/@mui/material/Chip";
import Alert from "@nodes/@mui/material/Alert";
// import PrintIcon from "@nodes/@mui/icons-material/Print";
import DeleteForeverIcon from "@nodes/@mui/icons-material/DeleteForever";
import { RiArrowGoBackLine } from "@nodes/react-icons/ri";
import { parseISO } from "@nodes/date-fns";
import { mainApp } from "@/config";

const RenderPaymentDetail = ({ payment = {} }) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h6" component="h4">
            <img
              alt=""
              src={payment.isMain === 0 ? "/../../cobro-icon-57x57.png" : "/../../main-icon-57x57.png"}
              style={{ width: 22, height: 22, marginRight: 3 }}
            />
            Detalle del Pago
            <b className="p-2">
              <Chip label={`${payment.payment_id}`} />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {/* <PrintIcon onClick={() => handlePaymentPrint(0)} className="text-info" /> */}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Realizado en
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                {/* {printDate(parseISO(payment.created_time), "dd-MMM-yy hh:mm:ss aaa")} */}
              </span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cantidad
            </Typography>
            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAbsAmount(payment.pAmount)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Realizado por
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{payment.created_user}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Fecha
            </Typography>

            <Typography variant="h6" component="h4">
              {/* <span className="badge badge-dark p-2">{printDate(newDate(payment.payment_date))}</span> */}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Balance
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAbsAmount(payment.balance)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Mora
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAbsAmount(payment.pMora)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Atrasos
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAbsAmount(payment.atrasos > 0 ? payment.atrasos : 0)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Adelantos
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAbsAmount(payment.atrasos < 0 ? payment.atrasos : 0)}</span>
            </Typography>
          </Box>
        </Grid>

        {payment.deletedBy !== 0 && (
          <>
            <Grid item xs={6}>
              <Box component="div" p={1}>
                <Typography variant="body2" component="p">
                  Borrado Por
                </Typography>

                <Typography variant="h6" component="h4">
                  <span className="badge badge-dark p-2">{payment.deletedUser}</span>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box component="div" p={1}>
                <Typography variant="body2" component="p">
                  Borrado en
                </Typography>

                <Typography variant="h6" component="h4">
                  <span className="badge badge-dark p-2">
                    {/* {printDate(new Date(1000 * payment.deletedTime), "dd-MMM-yy hh:mm:ss aaa")} */}
                  </span>
                </Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default RenderPaymentDetail;
