import { useEffect, useState } from "@nodes/react";
import { FormLabel, Slider, FormHelperText, Stack } from "@nodes/@mui/material";
import { Controller } from "@nodes/react-hook-form";

export const InputSlider = ({ name, control, value, setValue, label, configs = {} }) => {
  const [inputValue, setInputValue] = useState(+value || 0);

  useEffect(() => {
    setValue(name, inputValue);
  }, [name, setValue, value, inputValue]);

  const handleChange = ({ target }) => {
    setInputValue(target.value);
  };
  return (
    <>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState, formState }) => (
          <>
            {configs.helperText && <FormHelperText>{configs.helperText}</FormHelperText>}
            <Stack spacing={2} direction="row" alignItems="center">
              {configs.startEndorsement ?? null}
              <Slider
                value={inputValue}
                onChange={handleChange}
                color={configs.color ?? "secondary"}
                valueLabelDisplay={configs.valueLabelDisplay ?? "auto"}
                min={configs.min ?? 0}
                marks={configs.marks ?? true}
                max={configs.max ?? 100}
                step={configs.step ?? 1}
              />
              {configs.endEndorsement ?? null}
            </Stack>
          </>
        )}
      />
    </>
  );
};
