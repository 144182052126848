import { Card as MUICard, CardContent } from "@mui/material";

const Card = ({ children, style }) => {
  return style ? (
    <MUICard style={{ backgroundColor: `${style.background}`, color: `${style.color}` }}>
      <CardContent>{children}</CardContent>
    </MUICard>
  ) : (
    <MUICard>
      <CardContent>{children}</CardContent>
    </MUICard>
  );
};

export default Card;
