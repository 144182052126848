import { useContext, useState } from "react";
import { mixed, addMethod, string, object } from "yup";
import { Context } from "../context/DataContext";
import { Form, RenderInput } from "@shared/components/Form";
import Box from "@mui/material/Box";
import { UseDebtorCedulaData, UseDebtorCedulaImg } from "@shared/server/fetcher";
// import Divider from "@mui/material/Divider";
import Alert from "@nodes/@mui/material/Alert";
import Modal from "react-bootstrap/Modal";
import Divider from "@nodes/@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { NumericFormat } from "@nodes/react-number-format";
import RenderLoanDetailCredit from "@shared/components/RenderLoanDetailCredit";
import RenderCedulaImage from "@shared/components/RenderCedulaImage";
import RenderCreditCheckLoans from "@shared/components/RenderCreditCheckLoans";
import RenderRouteDetailCredit from "@shared/components/RenderRouteDetailCredit";
// import XcobrarCreditCheck from "@shared/components/XcobrarCreditCheck";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
import { CgSearchLoading } from "react-icons/cg";
// import Loading from "@shared/components/Loading";
import { printAmount, getLoansBreakDown, validateCedula, validatePhone, replaceNonDigits } from "@shared/functions";

const DataCreditoSearch = () => {
  addMethod(mixed, "validateCedula", validateCedula);
  addMethod(mixed, "validatePhone", validatePhone);
  const { dataCreditSearch, state } = useContext(Context);
  const [modalData, setModalData] = useState({});
  const { route } = state;
  const [loansBreakDown, setLoansBreakDown] = useState(null);
  const [cedula, setCedula] = useState(null);

  // const { data: routeN, isLoading } = UseRoute(route.creditor_id, user.user_id);

  const { data: cedulaData } = UseDebtorCedulaData(cedula);
  const { data: cedulaImage, isLoading: cedulaLoading } = UseDebtorCedulaImg(cedula, cedulaData?.timestamp);

  // if (isLoading) return <Loading />;

  const initialValues = {
    name: "",
    phone: "",
    cedula: "",
  };

  const validation = object({
    cedula: mixed().validateCedula(),
  });

  const validation2 = object({
    name: string().required("Nombre es requerido!").min(3, "Nombre es muy corto!"),
    phone: mixed().validatePhone(),
  });

  const button = {
    icon: <CgSearchLoading size="1.3em" />,
    label: "Buscar Data Credito",
    variant: "contained",
    color: "primary",
  };

  const submithandler = async (data) => {
    const cleanedCedula = replaceNonDigits(data.cedula);
    if (+cleanedCedula > 0) {
      setCedula(cleanedCedula);
    }
    const loans = await dataCreditSearch(route.creditor_id, data.cedula, data.name, data.phone);
    const res = getLoansBreakDown(loans);
    setLoansBreakDown(res);
  };

  return (
    <div className="container mt-5 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Data Credito Interno!
      </Typography>

      <Typography variant="subtitle1" component="p" paragraph>
        Aqui puede buscar un cliente en el data Credito Interno para ver pr&eacute;stamos registrado para ese mismo
        cliente.
      </Typography>

      {cedulaData && (
        <div className="container mt-3">
          <Typography variant="body1" component="p" gutterBottom>
            <b>{cedulaData.name}</b>
          </Typography>
        </div>
      )}

      {cedula && <RenderCedulaImage cedula={cedulaImage} loading={cedulaLoading && cedulaData ? true : false} />}

      <Typography variant="h6" component="p" gutterBottom align="center">
        <b>{<NumericFormat value={cedula} displayType={"text"} format="###-#######-#" />}</b>
      </Typography>

      {loansBreakDown?.activeCount === 0 &&
        loansBreakDown?.saldadoCount === 0 &&
        loansBreakDown?.vencidosCount === 0 && (
          <Box component={"div"} p={1}>
            <Alert variant="outlined" severity="info">
              <Typography variant="h6" component="p">
                No pr&eacute;stamos encontrado!
              </Typography>
            </Alert>
          </Box>
        )}

      {loansBreakDown && (
        <>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="subtitle1" component="p">
                Deuda Total:
              </Typography>
              <Typography variant="h5" component="h3" className="font-weight-bold">
                {printAmount(loansBreakDown?.balanceTotal || 0)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" component="p">
                Cuotas Total:
              </Typography>
              <Typography variant="h5" component="h3" className="font-weight-bold">
                {printAmount(loansBreakDown?.wPaymentTotal || 0)}
              </Typography>
            </Grid>
          </Grid>

          <Divider />
          <Divider />
          <br />
        </>
      )}

      <RenderCreditCheckLoans loansBreakDown={loansBreakDown} setModalData={setModalData} />

      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="1. Busqueda con C&eacute;dula" />
            <CardContent>
              <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
                <Box paddingBottom={5}>{RenderInput("cedula", "Numero de Cedula", "tel")}</Box>
              </Form>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="2. Busqueda con N&uacute;mero" />
            <CardContent>
              <Form
                initialValues={initialValues}
                validation={validation2}
                submithandler={submithandler}
                button={button}>
                <Box paddingBottom={3}>{RenderInput("name", "Nombre Completo")}</Box>
                <Box paddingBottom={5}>{RenderInput("phone", "Numero de Telefono", "tel")}</Box>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Modal
        show={modalData.name ? true : false}
        onHide={() => setModalData({})}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography variant="h6">
              {modalData.name}
              {/* <b className="p-2">
                    <Chip label={`C${modalData.debtor_id}`} />
                  </b> */}
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box component="div" p={1}>
            <DisplayLoanStatus loan={modalData} />
          </Box>

          <Box component="div" p={1}>
            <RenderLoanDetailCredit loan={modalData} />
          </Box>

          {((modalData.balance > 0 && modalData.current_week > modalData.npayments) ||
            modalData.user_id === route.user_id) && (
            <Box component="div" p={1}>
              <RenderRouteDetailCredit loan={modalData} />
            </Box>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setModalData({})}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DataCreditoSearch;
