import { useEffect } from "react";
import Typography from "@nodes/@mui/material/Typography";
import { useFormikContext } from "@nodes/formik";
import { Form, RenderInput } from "./Form";
import Grid from "@nodes/@mui/material/Grid";
import Box from "@nodes/@mui/material/Box";
import { loanModifyCentral } from "../functions/validations";
import { mainApp } from "@/config";

const GetFormikValues = ({ setValues }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    setValues(values);
  }, [values, setValues]);
  return null;
};

const RenderLoanModifyForm = ({ initialValues, setFormValues, submithandler, button }) => {
  return (
    <>
      <Typography variant="h6" component="h4" gutterBottom>
        Modificar Prestamo!
      </Typography>

      <Form initialValues={initialValues} validation={loanModifyCentral} submithandler={submithandler} button={button}>
        <Box paddingBottom={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {mainApp === 1 && <Box paddingBottom={2}>{RenderInput("given_date", "Fecha de Entrega", "date")}</Box>}
            </Grid>
            <Grid item xs={6}>
              {mainApp === 1 && <Box paddingBottom={2}>{RenderInput("start_date", "Fecha de Inicio", "date")}</Box>}
            </Grid>
          </Grid>
        </Box>

        <Box paddingBottom={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("percentage", "Porciento", "number", false, "standard")}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("npayments", "Numero de Cuotas", "number", false, "standard")}</Box>
            </Grid>
          </Grid>
        </Box>

        <Box paddingBottom={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("amount", "Cantidad", "number")}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("actanotarial", "Acta Notarial", "number")}</Box>
            </Grid>
          </Grid>
        </Box>

        <Box paddingBottom={2}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box paddingBottom={3}>{RenderInput("adelanto", "Pago de Adelanto", "number")}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box paddingBottom={3}>{RenderInput("discount", "Descuento Adicional", "number")}</Box>
            </Grid>
          </Grid>
        </Box>

        {mainApp === 1 && (
          <>
            <Box paddingBottom={2}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Box paddingBottom={3}>{RenderInput("mora", "Mora Pendiente", "number")}</Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        <GetFormikValues setValues={setFormValues} />
      </Form>
    </>
  );
};

export default RenderLoanModifyForm;
