import { useContext } from "react";
import { Context } from "../context/DataContext";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DisplayAppEnvName from "@shared/components/DisplayAppEnvName";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { freeTrialsMonth } from "@shared/constants";
import useMediaQuery from "@shared/hooks/useMediaQuery";
// import { useLocation } from "react-router-dom";
import RenderLoginForm from "@shared/components/RenderLoginForm";

const Login = () => {
  const fullScreen = useMediaQuery("min-width:820px");
  const navigate = useNavigate();
  const { state, signinUser } = useContext(Context);
  const { user } = state;
  // const location = useLocation();

  if (user.user_id !== 0) return <Navigate to="/dashboard" />;

  // const destination = location.state ? location.state.from : "/dashboard";
  const submithandler = (data) => signinUser(data, () => navigate("/routes/choose", { replace: true }));

  return (
    <Container fixed maxWidth="sm">
      <RenderLoginForm submithandler={submithandler} defa />
      <Box component="div" paddingTop={3}>
        <Link className="text-primary" to="/register">
          <Button fullWidth={!fullScreen} variant="outlined" color="primary">
            Quiero ser cliente
          </Button>
        </Link>

        <Typography className="mt-3" variant="subtitle1" component="p">
          Haga clic en Quiero ser cliente para empezar su Demo gratuito de {freeTrialsMonth} meces!
        </Typography>
      </Box>
      <DisplayAppEnvName />
    </Container>
  );
};

export default Login;
