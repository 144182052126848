import { useEffect, useContext } from "react";
import { Context } from "../context/DataContext";
import { useNavigate } from "react-router-dom";

const ResolveAuth = () => {
  const navigate = useNavigate();
  const { restoreAppState } = useContext(Context);
  // const { route } = state;

  useEffect(() => {
    console.log("AppWorkflow - useEffect from ResolveAuth.js");
    restoreAppState(navigate);

    //eslint-disable-next-line
  }, []);

  // return route.creditor_id === 0 ? <Navigate to="/routes/choose" /> : null;
  return null;
};

export default ResolveAuth;
