import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../context/DataContext";
import RenderDebtorForm from "@shared/components/RenderDebtorForm";
import { FaUserEdit } from "react-icons/fa";
import Loading from "@shared/components/Loading";
import { UseDebtors } from "@shared/server/fetcher";
import RenderBackButton from "@shared/components/RenderBackButton";
import { updateDebtor } from "@shared/api/debtor";

const DebtorEdit = () => {
  const navigate = useNavigate();
  const { id: dUniqueId } = useParams();
  const { state } = useContext(Context);
  const { user, route } = state;

  const { data: debtors, isLoading: debtorLoading, mutate } = UseDebtors(route.creditor_id, user.user_id);
  const debtor = debtors?.find((x) => x.dUniqueId === +dUniqueId);

  if (debtorLoading) return <Loading />;

  const button = {
    icon: <FaUserEdit size="0.9em" />,
    label: "Actualizar Cliente",
    variant: "contained",
    color: "primary",
  };

  console.log(debtor.idType);

  const submithandler = async (data) => {
    // await updateDebtor(data);
    // mutate2(`/debtors/${route.creditor_id}/${user.user_id}`);

    try {
      await updateDebtor(data);
      await mutate();
      navigate(`/debtor/profile/${dUniqueId}`, { replace: true });
    } catch (error) {}
  };

  return (
    <div className="container mt-3 mb-5">
      <RenderDebtorForm
        title="Actualizar Cliente!"
        defaultValues={debtor}
        submithandler={submithandler}
        button={button}
        cedula={false}
      />
      <div className="mt-4">
        <RenderBackButton />
      </div>
    </div>
  );
};

export default DebtorEdit;
