import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import SearchBox from "@shared/components/common/SearchBox";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import { NumericFormat } from "react-number-format";
import CollectedPaymentMenu from "../components/CollectedPaymentMenu";
import DateRangePicker from "@shared/components/DateRangePicker";
import { Link } from "react-router-dom";
import { RemoveAccents } from "@shared/functions";
import { getCollectedLoans } from "../context/Selectors";
// import BottomNavigation from "../components/BottomNavigation";
import { UseCollected, UseAPerturaCuadre } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
// import NoInternetContent from "@shared/components/NoInternetContent";

const Collected = () => {
  const { state } = useContext(Context);
  const [searchText, setSearchText] = useState("");
  const [currentData, setCurrentData] = useState({ amount: 0, count: 0, list: [] });
  const { route: r2 } = state;
  const [currentDates, setCurrentDates] = useState(null);

  // const { data: route, isLoading: routeLoading } = UseRoute(r2.creditor_id, user.user_id);
  // const { data: apertura, isLoading: aLoading } = UseSharedApertura(
  //   r2?.creditor_id,
  //   r2.r_type,
  //   r2.day
  // );

  const { data: apertura, isLoading: acLoading } = UseAPerturaCuadre(r2.creditor_id);

  const { data: collected, isLoading } = UseCollected(
    r2.creditor_id,
    currentDates?.start_date ?? apertura?.start_date,
    currentDates?.end_date ?? apertura?.range_date
  );

  useEffect(() => {
    if (apertura && !currentDates?.start_date) {
      setCurrentDates({
        start_date: apertura.start_date,
        end_date: apertura.range_date,
      });
    }

    if (collected) {
      setCurrentData(getCollectedLoans(collected, false));
    }
    //eslint-disable-next-line
  }, [collected]);

  if (isLoading || acLoading) return <Loading />;
  // if (!collected) return <NoInternetContent />;

  const getFilteredDebtor = (debtors) => {
    let filtered = debtors;

    if (searchText.length > 2) {
      filtered = filtered.filter((m) => RemoveAccents(m.name).toUpperCase().indexOf(searchText.toUpperCase()) >= 0);
    }

    return filtered;
  };

  const loansFilter = getFilteredDebtor(currentData.list);

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <>
      {currentData.count === 0 ? (
        <div className="container mt-4 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Pr&eacute;stamos Cobrado!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los pagos aplicado.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no ha aplicado ningun Pago!
          </Typography>
          <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} />
        </div>
      ) : (
        <div className="mt-3">
          <Box component="div" p={1}>
            <Typography variant="h6" component="h4">
              Pr&eacute;stamos cobrado ({currentData.count}):
              <NumericFormat value={currentData.amount} displayType={"text"} thousandSeparator={true} prefix={" $"} />
            </Typography>
            <Typography variant="body1" component="h4">
              Lista de Pr&eacute;stamos cobrado esta fecha
            </Typography>

            <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} />
            <SearchBox
              onChange={handleSearch}
              placeHolder="Escriba para buscar Pr&eacute;stamo..."
              value={searchText}
            />
          </Box>

          <div className="mb-5">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {loansFilter.map((loan) => (
                  <tr key={loan.payment_id} className={loan.pDeposit > 0 ? "bg-warning" : ""}>
                    <td>
                      <Typography variant="subtitle2" component="p" className="font-weight-bold">
                        <Link
                          style={{ color: "#000" }}
                          to={`/payment/receipt/${loan.payment_id}/${loan.money_id}/${loan.debtor_id}`}>
                          <PersonIcon /> {loan.name}
                        </Link>
                      </Typography>
                    </td>
                    <td>
                      <NumericFormat value={loan.pAmount} displayType={"text"} thousandSeparator={true} prefix={" $"} />
                    </td>
                    <td>
                      <CollectedPaymentMenu loan={loan} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* <BottomNavigation  /> */}
    </>
  );
};

export default Collected;
