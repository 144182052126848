import Typography from "@nodes/@mui/material/Typography";
import Divider from "@nodes/@mui/material/Divider";
// import Button from "@nodes/@mui/material/Button";
import Box from "@nodes/@mui/material/Box";
import Card from "@nodes/@mui/material/Card";
// import { Link } from "@nodes/react-router-dom";
// import RenderRoutesBalance from "../components/RenderRoutesBalance";
import RenderPaymentAccount from "../components/RenderPaymentAccount";
import RenderContactInfo from "../components/RenderContactInfo";
import useMediaQuery from "../hooks/useMediaQuery";

const RenderDisabledCustomer = ({ bankInformation }) => {
  const fullScreen = useMediaQuery("min-width:820px");
  return (
    <div className="container mt-3 mb-5">
      <Typography variant="h4" align="center">
        Atención!
      </Typography>

      <div className="mt-2 mb-3">
        <Divider />
        <Divider />
      </div>

      <Typography variant="subtitle1" gutterBottom>
        <b> Cuenta desabilitada por falta de Pagos.</b>
      </Typography>

      <Typography variant="body1" paragraph>
        Para aceder a su ruta debe ponerse al dia con sus pagos.
      </Typography>

      {/* <RenderRoutesBalance userRoutesBalance={state.userRoutesBalance} /> */}

      <RenderPaymentAccount bankInformation={bankInformation} />

      <Card variant="outlined">
        <Box m={2}>
          <RenderContactInfo />
        </Box>
      </Card>

      {/* <Box component="div" p={2}>
        <Link to={`/customer/balance/${}/1`}>
          <Button fullWidth={!fullScreen} variant="outlined" color="primary">
            Volver Atras
          </Button>
        </Link>
      </Box> */}
    </div>
  );
};

export default RenderDisabledCustomer;
