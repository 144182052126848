import { useMemo, useEffect } from "@nodes/react";
import { useForm, useWatch } from "@nodes/react-hook-form";
import { InputNumber } from "./form/InputNumber";
import * as Yup from "@nodes/yup";
// import { InputButtonGroup } from "./form/InputButtonGroup";
import { InputText } from "./form/InputText";
import { InputDate } from "./form/InputDate";
import { InputRadio } from "./form/InputRadio";
import { InputDropdown } from "./form/InputDropdown";
import { InputDropdownMulti } from "./form/InputDropdownMulti";
import RenderNewLoanCalculation from "./RenderNewLoanCalculation";
import { yupResolver } from "@nodes/@hookform/resolvers/yup";
import { Button, Box, Typography } from "@nodes/@mui/material";
import { DevTool } from "@nodes/@hookform/devtools";
import { mainApp } from "@/config";

export function validatePhone(required = true) {
  return this.test("validatePhone", required, function (value) {
    const { path, createError } = this;

    if (!value) {
      if (!required) return true;
      return createError({ path, message: "Telefono es requeridad!" });
    }

    const c = String(value);
    if (c === "0000000000") {
      return createError({ path, message: "Telefono no es valido!" });
    }

    if (c.length !== 10) {
      return createError({ path, message: "Telefono no es valido!" });
    }

    return true;
  });
}

Yup.addMethod(Yup.mixed, "validatePhone", validatePhone);

export const loanCreateSchema = Yup.object({
  percentage: Yup.number()
    .typeError("Cantidad de porciento es invalido!")
    .min(0, "Porciento es muy bajito!")
    .max(100, "Porciento es muy alto!"),
  amount: Yup.number()
    .typeError("Cantidad de prestamo es invalida!")
    .integer("Cantidad de prestamo es requerida!")
    .min(500, "Cantidad de prestamo es muy bajita!"),
  actanotarial: Yup.number()
    .typeError("Cantidad de contracto (Acta) es invalida!")
    .min(0, "Contracto de (Acta) es muy corta!"),
  adelanto: Yup.mixed().notOneOf([null, "", 1, 2, 3, 4, 5], "Cantidad de Adelanto es Invalida!"),
  discount: Yup.number().integer("Descuento Adicional Invalida!").min(0, "Cantidad de Adelanto Invalida!"),

  npayments: Yup.number()
    .required("1 Numero de cuotas es requerida!")
    .min(0, "1 Numero de cuotas es muy corta!")
    .max(100, "1 Numero de cuotas es muy alta!"),

  // customCuotas: Yup.when("npayments", (npayments) => {
  //   if (+npayments === 0) {
  //     return Yup.number()
  //       .typeError("2 Numero de cuotas es invalidad!")
  //       .min(1, "2 Numero de cuotas muy bajita!")
  //       .max(100, "2 Numero de cuotas muy alta!");
  //   }
  // }),
});

export const RenderLoanCreateForm = ({ defaultValues, submithandler, loansVariant = [], loantypeData }) => {
  const { handleSubmit, reset, control, setValue, formState, register, setError } = useForm({
    defaultValues,
    resolver: yupResolver(loanCreateSchema),
  });

  const { isDirty, isSubmitting, touchedFields, errors } = formState;

  useEffect(() => {
    reset({ ...defaultValues });
  }, [loantypeData]);

  const formValues = useWatch({ control });

  const handleSubmitHandler = async (data) => {
    const npayments = +data.npayments || +data.customCuotas;

    const cuota = Math.ceil(((+data.percentage / 100) * +data.amount + +data.amount) / npayments);
    const ownerDaysOff = loantypeData.daysOff;

    await submithandler({ ...data, cuota, ownerDaysOff });
  };

  const cuota = useMemo(() => {
    const cuotaRes = Math.ceil(
      ((+formValues.percentage / 100) * +formValues.amount + +formValues.amount) /
        (+formValues.npayments || +formValues.customCuotas)
    );
    return cuotaRes || 0;
  }, [formValues.percentage, formValues.amount, formValues.customCuotas, formValues.npayments]);

  const entrega = useMemo(() => {
    return +formValues.amount - +formValues.actanotarial - 0 - +formValues.adelanto - +formValues.discount;
  }, [formValues.amount, formValues.actanotarial, formValues.adelanto, formValues.discount]);

  const profit = useMemo(() => {
    return (+formValues.npayments || +formValues.customCuotas) * +cuota - +formValues.amount;
  }, [formValues.npayments, formValues.customCuotas, cuota, formValues.amount]);

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitHandler)}>
        {loansVariant.length > 1 && (
          <Box paddingBottom={2} paddingTop={2}>
            <InputDropdown
              name="loan_variant"
              control={control}
              label="Tipo de Prestamo"
              configs={{ size: "medium", variant: "standard" }}
              options={[{ v_name: "Seleccione tipo de Prestamo!", v_id: 0 }, ...loansVariant].map((x) => {
                return {
                  label: x.v_name,
                  value: x.v_id,
                };
              })}
            />
            {errors.loan_variant && (
              <Typography variant="body2">
                <span style={{ color: "#ef5350" }}> {errors?.loan_variant?.message}</span>
              </Typography>
            )}
          </Box>
        )}

        {mainApp === 1 && (
          <Box paddingBottom={2} paddingTop={1}>
            <InputDate name="start_date" control={control} label="Fecha de Inicio" configs={{ variant: "standard" }} />
          </Box>
        )}

        <Box paddingBottom={2}>
          <InputNumber
            name="percentage"
            control={control}
            type="number"
            placeholder={"Escriba el porciento del prestamo!"}
            label="Porciento"
            configs={{ suffix: "%" }}
          />
        </Box>

        <Box paddingBottom={1}>
          <InputNumber
            name="amount"
            control={control}
            label="Cantidad Prestada"
            placeholder={"Escriba la cantidad del prestamo!"}
            configs={{ prefix: "$" }}
          />
        </Box>

        <Box paddingTop={2} paddingBottom={0}>
          <InputRadio
            name={"npayments"}
            control={control}
            label={"Numero de Cuotas"}
            // Sort numeric array
            // options={[...loantypeData.cuotas.split(","), ...extraCuotas]
            //   .sort((a, b) => a.toString().localeCompare(b, undefined, { numeric: true }))
            //   .map((x) => {
            options={[...loantypeData.cuotas, 94576]
              .sort((a, b) => a.toString().localeCompare(b, undefined, { numeric: true }))
              .map((x) => {
                if (x === 94576 && mainApp === 1) {
                  return {
                    label: `Otra Cuota`,
                    value: `${x}`,
                  };
                }

                return {
                  label: `${x}`,
                  value: `${x}`,
                };
              })}
          />

          {+formValues.npayments === 94576 && (
            <Box paddingBottom={1}>
              <InputText
                name="customCuotas"
                control={control}
                type="number"
                placeholder={"Escriba el plazo, numero de Cuotas!"}
                label="Numero de Cuotas"
                configs={{ type: "number" }}
              />
            </Box>
          )}
        </Box>

        <Box paddingBottom={2}>
          <InputNumber
            name="actanotarial"
            control={control}
            placeholder={"Cantidad del contracto (Acta Notarial)!"}
            label="Contracto (Acta)"
            configs={{ prefix: "$" }}
          />
        </Box>

        <Box paddingBottom={2}>
          <InputNumber
            name="adelanto"
            control={control}
            placeholder={"Pago recibido por adelanto para el prestamo!"}
            label="Pago de Adelanto"
            configs={{ prefix: "$" }}
          />
        </Box>

        <Box paddingBottom={2}>
          <InputNumber
            name="discount"
            control={control}
            placeholder={"Cantidad algun otro descuento!"}
            label="Descuento Adicional"
            configs={{ prefix: "$" }}
          />
        </Box>

        {formValues.loan_type === 1 && (
          <Box paddingBottom={2} paddingTop={1}>
            <InputDropdownMulti
              name="daysOff"
              control={control}
              setValue={setValue}
              label="Seleccione dias libres"
              configs={{
                variant: "standard",
                size: "medium",
                backgroundColor: "#f15759",
                helperText: (
                  <span>
                    Los dias libres seleccionados, no habr&aacute; apertura autom&aacute;tica para el pr&eacute;stamo.
                  </span>
                ),
              }}
              options={[
                { value: "1", label: "Lunes" },
                { value: "2", label: "Martes" },
                { value: "3", label: "Miercoles" },
                { value: "4", label: "Jueves" },
                { value: "5", label: "Vienes" },
                { value: "6", label: "Sabado" },
                { value: "7", label: "Domingo" },
              ]}
            />
          </Box>
        )}

        <Box div="div" p={1} paddingBottom={2}>
          <RenderNewLoanCalculation data={{ cuota, entrega, profit }} />
        </Box>

        <Box paddingBottom={2}>
          <Button
            disabled={
              // !isDirty ||
              // (!touchedFields?.npayments && !touchedFields?.customCuotas) ||
              // errors?.npayments ||
              isSubmitting || entrega < 0
            }
            type="submit"
            fullWidth
            variant={"contained"}>
            Guardar Nuevo Prestamo!
          </Button>
        </Box>
      </form>

      <DevTool control={control} />
    </>
  );
};

export default RenderLoanCreateForm;
