import backend from "@shared/server/backend";

export const resetPassword = async (data) => {
  const response = await backend.post("/user/password/reset", { ...data });
  return response.data;
};

export const updatePassword = async (data) => {
  const response = await backend.post("/user/password", { data });
  return response.data;
};

export const updateConfig = async (data) => {
  const response = await backend.put("/user/config", { ...data });
  return response.data;
};

export const updateSecurirityConfig = async (data) => {
  const response = await backend.put("/owner/security/config", { ...data });
  return response.data;
};

export const updateGeneralConfigs = async (data) => {
  const response = await backend.put("/owner/general/config", { ...data });
  return response.data;
};

export const updateOwnerHolidays = async (data) => {
  const response = await backend.put("/owner/holidays/config", { ...data });
  return response.data;
};

export const registerUser = async (data) => {
  const response = await backend.post("/register", { ...data });
  return response.data;
};

export const updatePersonal = async (data) => {
  const response = await backend.post("/user/personalInfo", {
    data: { ...data, firstname: data.fname, lastname: data.lname },
  });

  return response.data;
};

export const updateUsername = async (data) => {
  const response = await backend.post("/user/username", { data });
  return response.data;
};
